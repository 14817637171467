/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {ErrorMessage, FormikErrors, FormikValues, FormikTouched} from 'formik'
import {Select, Input} from 'formik-antd'
import {useAuth0} from '@auth0/auth0-react'
import {MedicineDto, MedicineService} from '../../../../../../../generated/model'
import {Spin} from 'antd'
import {updateToken} from '../../../../../../utils/AuthToken'
import {useSetRecoilState} from 'recoil'
import {caseForDedupMedicineState} from '../../../State'
import {CompanyDetails} from '../../../_shared/components/company-details/CompanyDetails'
import {MedicinePackages} from '../../../_shared/components/medicine-packages/MedicinePackages'

interface Props {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  values: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const StepCommonCompany: FC<Props> = (props) => {
  const [value, setValue] = useState<MedicineDto>(props.values.case.medicine)
  const [options, setOptions] = useState<MedicineDto[]>([])
  const [fetching, setFetching] = useState<boolean>(false)
  const setCaseForDedupMedicineState = useSetRecoilState(caseForDedupMedicineState)

  const {getAccessTokenSilently} = useAuth0()
  const onSearch = async (filter: string) => {
    setFetching(true)

    updateToken(await getAccessTokenSilently())
    const result = await MedicineService.searchMedicines(filter)
    setOptions(result)
    setFetching(false)
  }
  const onSelect = (value: any, option: any) => {
    const key = option.key
    const selected = options.filter((x) => x.id === key)[0]

    const cse = props.values['case']
    cse.medicine = selected
    cse.medicineId = selected.id

    props.setFieldValue('case', cse)
    setValue(selected)

    setCaseForDedupMedicineState({medicineId: cse.medicineId})
  }

  function validateMedicine(value: {key: string}) {
    let error
    if (!value || !value.key || value.key === '') {
      error = 'Medicine is required!'
    }
    return error
  }

  useEffect(() => {
    const getData = async () => {
      setFetching(true)
      updateToken(await getAccessTokenSilently())

      await onSearch('')
    }

    getData()
    // eslint-disable-next-line
  }, [getAccessTokenSilently])
  return (
    <div className='mx-auto mw-600px w-100'>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Case ID</label>
        <label className='form-control'>{props.values['case'].serial.readableId}</label>
        <div className='form-text'>Case ID will be generated automatically.</div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Custom Case Identifier</label>

        <Input type='text' name='case.identifier' placeholder='' />

        <div className='form-text'>Provide custom case identifier if required.</div>
        <div className='fv-plugins-message-container invalid-feedback d-block'>
          <ErrorMessage name='case.identifier' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Medicine</label>
        <Select
          validate={validateMedicine}
          name='medicineTemp'
          labelInValue
          optionLabelProp='label'
          onSelect={onSelect}
          filterOption={false}
          onSearch={onSearch}
          placeholder='Select medicine'
          style={{width: '100%'}}
          showSearch
          notFoundContent={fetching ? <Spin size='small' /> : null}
        >
          {options.map((element: MedicineDto) => (
            <Select.Option key={element.id!} value={element.id!} label={element.name!}>
              {element.name}
            </Select.Option>
          ))}
        </Select>

        <div className='fv-plugins-message-container invalid-feedback d-block'>
          <ErrorMessage name='medicineTemp' />
        </div>

        {value && <MedicinePackages medicine={value} />}
        {value && value.company && <CompanyDetails company={value.company} />}
      </div>
    </div>
  )
}

export {StepCommonCompany}
