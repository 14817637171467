/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {FormikErrors, FormikValues, FormikTouched, ErrorMessage} from 'formik'
import {FieldInputProps} from 'formik/dist/types'
import {DatePicker, Input, Select} from 'formik-antd'
import {useRecoilValue} from 'recoil'
import {useCaseState} from '../../../../State'
import {UseCase} from '../../../../Model'

interface Step3Props {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  values: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>
}

const StepSideEffectProperties: FC<Step3Props> = (props) => {
  const onSelectStartOfReaction = (value: any) => {
    const cse = props.values['sideEffect']
    cse.medication.startOfTherapy = value.utc().format()
    props.setFieldValue('sideEffect', cse)
    props.setFieldValue('medStartOfTherapyTemp', value)
  }
  const onSelectEndOfReaction = (value: any) => {
    const cse = props.values['sideEffect']
    cse.medication.endOfTherapy = value.utc().format()
    props.setFieldValue('endOfReaction', cse)
    props.setFieldValue('medEndOfTherapyTemp', value)
  }
  const updateDescription = (event: any) => {
    const val = event.target!.value

    const cse = props.values['sideEffect']
    cse.medication.description = val
    props.setFieldValue('sideEffect', cse)
  }
  const onSelect = (value: any, option: any) => {
    const cse = props.values['sideEffect']
    cse.medication.wayOfAdministration = option.key
    props.setFieldValue('sideEffect', cse)
  }
  const useCase = useRecoilValue(useCaseState)
  return (
    <div className='w-100' style={{minHeight: 530}}>
      <div className='row'>
        <div className='mb-10 col-lg-6'>
          <label className='form-label mb-3'>Start Of Therapy</label>

          <DatePicker
            name='medStartOfTherapyTemp'
            showTime={true}
            placeholder='Please select'
            onSelect={onSelectStartOfReaction}
            popupStyle={{zIndex: 10024}}
          />
          <div className='fv-plugins-message-container invalid-feedback d-block'>
            <ErrorMessage name='medStartOfTherapyTemp' />
          </div>
        </div>

        <div className='col-lg-5'>
          <label className='form-label mb-3'>End Of Therapy</label>

          <DatePicker
            name='medEndOfTherapyTemp'
            showTime={true}
            placeholder='Ongoing'
            onSelect={onSelectEndOfReaction}
            popupStyle={{zIndex: 10024}}
          />
          <div className='fv-plugins-message-container invalid-feedback d-block'>
            <ErrorMessage name='medEndOfTherapyTemp' />
          </div>
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Indication reason</label>

        <Input.TextArea
          onChange={updateDescription}
          rows={3}
          name='sideEffect.medication.description'
          placeholder=''
        />
      </div>
      {(useCase === UseCase.Company &&
        props.values.sideEffect.medication.descriptionTranslated &&
        props.values.sideEffect.medication.descriptionTranslated.length) > 0 && (
        <div className='mb-10 fv-row'>
          <label className='form-label mb-3'>Indication reason (Translated)</label>

          <Input.TextArea
            rows={5}
            readOnly={true}
            name='sideEffect.medication.descriptionTranslated'
            placeholder=''
          />
        </div>
      )}

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Dosage</label>
        <Input type='text' name='sideEffect.medication.dosage' placeholder='' />
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Charge Number</label>
        <Input type='text' name='sideEffect.medication.chargeNumber' placeholder='' />
      </div>

      <div className='fv-row'>
        <label className='form-label mb-3'>Way of administration</label>

        <Select
          name='medWayOfAdministrationTemp'
          dropdownStyle={{zIndex: 1200}}
          labelInValue
          optionLabelProp='label'
          placeholder='Select way of administration'
          onSelect={onSelect}
          style={{width: '100%'}}
        >
          <Select.Option key={'oral'} value={'oral'} label={'oral'}>
            oral
          </Select.Option>
          <Select.Option key={'rectal'} value={'rectal'} label={'rectal'}>
            rectal
          </Select.Option>
          <Select.Option key={'inhaled'} value={'inhaled'} label={'inhaled'}>
            inhaled
          </Select.Option>
          <Select.Option key={'nasal'} value={'nasal'} label={'nasal'}>
            nasal
          </Select.Option>
          <Select.Option key={'local'} value={'local'} label={'local'}>
            local
          </Select.Option>
          <Select.Option
            key={'inj_intravenous'}
            value={'inj_intravenous'}
            label={'inj_intravenous'}
          >
            Injection (intravenous)
          </Select.Option>
          <Select.Option key={'inj_subcutan'} value={'inj_subcutan'} label={'inj_subcutan'}>
            Injection (subcutan)
          </Select.Option>
          <Select.Option
            key={'inj_intramuscular'}
            value={'inj_intramuscular'}
            label={'inj_intramuscular'}
          >
            Injection (intramuscular)
          </Select.Option>
        </Select>
      </div>
    </div>
  )
}

export {StepSideEffectProperties}
