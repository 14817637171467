/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {Change, ChangeEventType} from '../../../../../../../generated/model'

interface Props {
  changes: Change[]
}

const HistoryChangeList: React.FC<Props> = (props) => {
  return (<ul>
        {props.changes?.map((ch) => (
            <li key={ch.fieldName}>
              <div>
                {ch.entityName}.{ch.fieldName} ({ch.eventType})
              </div>
              {(ch.eventType === ChangeEventType.ADD || ch.eventType === ChangeEventType.UPDATE) && (
                  <div>New value: {ch.newValue}</div>
              )}
              {(ch.eventType === ChangeEventType.REMOVE ||
                  ch.eventType === ChangeEventType.UPDATE) && <div>Old value: {ch.oldValue}</div>}

              {(ch.changes && ch.changes.length > 0) && (<div>
                <HistoryChangeList changes={ch.changes}/>
              </div>)}
            </li>
        ))}
      </ul>
  )
}

export {HistoryChangeList}
