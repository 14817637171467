/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CaseDocumentDto } from '../models/CaseDocumentDto';
import { request as __request } from '../core/request';

export class CaseDocumentService {

    /**
     * @param id 
     * @param requestBody 
     * @returns CaseDocumentDto Success
     * @throws ApiError
     */
    public static async saveDocument(
id: string,
requestBody?: CaseDocumentDto,
): Promise<CaseDocumentDto> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/case/${id}/document`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param id 
     * @returns CaseDocumentDto Success
     * @throws ApiError
     */
    public static async getDocuments(
id: string,
): Promise<Array<CaseDocumentDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/pv/v1/case/${id}/documents`,
        });
        return result.body;
    }

    /**
     * @param id 
     * @param documentId 
     * @returns any Success
     * @throws ApiError
     */
    public static async deleteDocumentById(
id: string,
documentId: string,
): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/pv/v1/case/${id}/document/${documentId}`,
        });
        return result.body;
    }

}