/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {CaseDto} from '../../../../../../../generated/model'
import {OverviewDataPatient} from './OverviewDataPatient'
import {CompanyDetails} from '../../../_shared/components/company-details/CompanyDetails'
import {MedicinePackages} from '../../../_shared/components/medicine-packages/MedicinePackages'
import {OverviewDataReporter} from './OverviewDataReporter'
import {CaseIdentifier} from '../../../_shared/components/case-identifier/CaseIdentifier'
import {useHistory} from 'react-router-dom'

interface Props {
  case: CaseDto
}

const OverviewData: React.FC<Props> = (props) => {
  const caseData = props.case

  const history = useHistory()
  const detailsCaseClick = (id: string) => {
    history.push(`/cases/details/${id}`)
  }

  return (
    <div className='card'>
      <div className='card-body p-9'>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>Global Case ID / Serial</label>

          <div className='col-lg-8'>
            <span className='fw-bolder fs-6 text-dark'>{caseData.serial?.globalId}</span>
            <span className='px-2'>({caseData.serial?.serialId})</span>
          </div>
        </div>

        {caseData.duplicateTo && (
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Duplicate to</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                <a
                  href='#'
                  onClick={(e) => {
                    e.preventDefault()
                    detailsCaseClick(caseData.duplicateTo!.id!)
                  }}
                >
                  <CaseIdentifier data={caseData.duplicateTo!} />
                </a>
              </span>
            </div>
          </div>
        )}

        {caseData.category && (
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Category</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{caseData.category}</span>
            </div>
          </div>
        )}

        {caseData.seriousness && (
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Seriousness</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{caseData.seriousness}</span>
            </div>
          </div>
        )}
        {caseData.validity !== null && (
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Validity</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {caseData.validity ? 'Valid' : 'Not valid'}
              </span>
            </div>
          </div>
        )}

        {caseData.caseInput && (
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>PV Input</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {caseData.caseInput.serial?.globalId}
              </span>
            </div>
          </div>
        )}

        <OverviewDataPatient patient={props.case.patient!} patientData={props.case.patientData!} />
        <OverviewDataReporter caseReporters={props.case.caseReporters!} />

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>Medicament</label>

          <div className='col-lg-8 fv-row'>
            <div>
              <div className='fw-bold fs-6'>{caseData.medicine!.name}</div>
              <div className='pt-2'>
                <MedicinePackages medicine={caseData.medicine!} showHeader={false} />
              </div>
            </div>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>Company</label>

          <div className='col-lg-8 d-flex align-items-center'>
            <div>
              <CompanyDetails company={caseData.company!} showHeader={false} showNameBold={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {OverviewData}
