import {CaseStatus} from '../../../../../../../generated/model'

export const CompanyStatus: CaseStatus[] = [
  CaseStatus.CREATED_COMPANY,
  CaseStatus.ARCHIVED_COMPANY,
  CaseStatus.DELETED_COMPANY,
  CaseStatus.IN_REVIEW,
  CaseStatus.DUPLICATE_COMPANY,
  CaseStatus.ACCEPTED,
  CaseStatus.DECLINED,
  CaseStatus.EXPORTED,
  CaseStatus.SUBMITTED,
  CaseStatus.WITHDRAWN,
]
export const DoctorStatus: CaseStatus[] = [
  CaseStatus.CREATED,
  CaseStatus.ARCHIVED,
  CaseStatus.DELETED,
  CaseStatus.IN_REVIEW,
  CaseStatus.DUPLICATE,
  CaseStatus.ACCEPTED,
  CaseStatus.DECLINED,
  CaseStatus.EXPORTED,
  CaseStatus.SUBMITTED,
  CaseStatus.WITHDRAWN,
]
