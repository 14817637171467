/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CaseStatus {
    CREATED = 'Created',
    DELETED = 'Deleted',
    DUPLICATE = 'Duplicate',
    ARCHIVED = 'Archived',
    WITHDRAWN = 'Withdrawn',
    DECLINED = 'Declined',
    SUBMITTED = 'Submitted',
    CREATED_COMPANY = 'Created_Company',
    DELETED_COMPANY = 'Deleted_Company',
    DUPLICATE_COMPANY = 'Duplicate_Company',
    ARCHIVED_COMPANY = 'Archived_Company',
    ACCEPTED = 'Accepted',
    IN_REVIEW = 'InReview',
    EXPORTED = 'Exported',
}