export interface FooterModel {
  product: string
  mode?: string
}

export enum Role {
  User = 'User',
  Admin = 'Admin',
}

export interface RoleContext {
  roles: Role[]
  hasRole: (role: Role) => boolean
}
