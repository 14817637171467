/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {toAbsoluteUrl} from "../../../../../../_metronic/helpers";

type Props = {
    image: string
    title: string
    description: string
    statusDesc: string
}

const Statistic: FC<Props> = ({
                                  image,
                                  title,
                                  description,
                                  statusDesc,
                              }) => {
    return (
        <div className='card h-100'>
            <div className='card-header flex-nowrap border-0 pt-9'>
                <div className='card-title m-0'>
                    <div className='symbol symbol-45px w-45px bg-light me-5'>
                        <img src={toAbsoluteUrl(image)} alt='Metronic' className='p-3'/>
                    </div>

                    <a href='#' className='fs-4 fw-bold text-hover-primary text-gray-600 m-0'>
                        {title}
                    </a>
                </div>
            </div>

            <div className='card-body d-flex flex-column px-9 pt-6 pb-8'>
                <div className='fs-2tx fw-bolder mb-3'>{description}</div>

                <div className='d-flex align-items-center flex-wrap mb-5 mt-auto fs-6'>
                    <div className='fw-bold text-gray-400'>{statusDesc}</div>
                </div>

            </div>
        </div>
    )
}

export {Statistic}
