/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

interface Props {
    inputData: string,
}

const OverviewEmail: React.FC<Props> = (props) => {
    const data = JSON.parse(props.inputData);
    return (
        <>
            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>From</label>

                <div className='col-lg-8'>
                    <div>
                        <div>
                            {data.From}
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Subject</label>

                <div className='col-lg-8'>
                    <div>
                        <div>
                            {data.Subject}
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mb-12'>
                <label className='col-lg-4 fw-bold text-muted'>Content:</label>
            </div>
            <div className='row mb-12'>
                <div className={`card `}>
                    <p className='py-3' dangerouslySetInnerHTML={{__html: data.Body}}></p>
                </div>

            </div>
        </>
    )
}

export {OverviewEmail}

