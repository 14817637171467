/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'

import {PatientIdentifier} from '../../../_shared/components/patient-identifier/PatientIdentifier'
import {CaseIdentifier} from '../../../_shared/components/case-identifier/CaseIdentifier'
import {CaseDto} from '../../../../../../../generated/model'
import {useHistory} from 'react-router-dom'

interface Props {
    caseData: CaseDto
}

const DeleteCaseData: FC<Props> = (props) => {
    const history = useHistory()
    const detailsCaseClick = (id: string) => {
        history.push(`/cases/details/${id}`)
    }

    const caseData = props.caseData
    return (
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
            <div className='card-header cursor-pointer'>
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Case Details</h3>
                </div>
            </div>
            <div className='card-body p-9'>
                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Case ID</label>
                    <div className='col-lg-8'>
            <span className='fw-bolder fs-6 text-dark'>
              <CaseIdentifier data={caseData}/>
            </span>
                    </div>
                </div>
                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Patient</label>

                    <div className='col-lg-8'>
            <span className='fw-bolder fs-6 text-dark'>
              <PatientIdentifier data={caseData.patient!}/>
            </span>
                    </div>
                </div>

                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Medicament</label>

                    <div className='col-lg-8 fv-row'>
                        <span className='fw-bold fs-6'>{caseData.medicine!.name}</span>
                    </div>
                </div>

                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Company</label>

                    <div className='col-lg-8 d-flex align-items-center'>
                        <span className='fw-bold fs-6'>{caseData.company!.name}</span>
                    </div>
                </div>

                <br/>

                <div className='row mb-10'>
                    <a
                        className='fw-bolder'
                        href='#'
                        onClick={(e) => {
                            e.preventDefault()
                            detailsCaseClick(caseData.id!)
                        }}
                    >
                        More case details
                    </a>
                </div>
            </div>
        </div>
    )
}

export {DeleteCaseData}
