/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Statistic} from "./Statistic";
import {StatisticsCore} from "../../../../../../generated/model";

interface Props {
    statistics: StatisticsCore
}

const Statistics: FC<Props> = (props) => {
    return (
        <div className='row g-6 g-xl-9'>
            <div className='col-sm-6 col-xl-4'>
                <Statistic
                    image='/media/icons/duotone/Home/Building.svg'
                    title='Companies'
                    description={props.statistics.companyCount!.toString()}
                    statusDesc='Number of known companies'
                />
            </div>
            <div className='col-sm-6 col-xl-4'>
                <Statistic
                    image='/media/icons/duotune/med002.svg'
                    title='Medicines'
                    description={props.statistics.medicineCount!.toString()}
                    statusDesc='Total number of medicines'
                />
            </div>
            <div className='col-sm-6 col-xl-4'>
                <Statistic
                    image='/media/icons/duotone/Design/Brush.svg'
                    title='Side Effects'
                    description={props.statistics.sideEffectCount!.toString()}
                    statusDesc='Current number of known side effects'
                />
            </div>
        </div>
    )
}

export {Statistics}
