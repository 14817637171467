/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SerialEntityType {
    CASE = 'Case',
    PATIENT = 'Patient',
    INPUT = 'Input',
    EXPORT = 'Export',
    REPORTER = 'Reporter',
}