/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'

import {useHistory, useParams} from 'react-router-dom'
import {useAuth0} from '@auth0/auth0-react'
import {CaseDto, CaseService} from '../../../../../../generated/model'
import {LoadingBoundary} from '../../../../_shared/LoadingBoundary'
import {ErrorBoundary} from '../../../../errors/ErrorBoundary'
import {createErrorData, ErrorData} from '../../../../errors/model/ErrorData'
import {updateToken} from '../../../../../utils/AuthToken'
import {DeleteCaseData} from './components/DeleteCaseData'
import {DeleteCaseNotice} from './components/DeleteCaseNotice'

const DeleteCase: FC = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorData>()
  const [caseData, setCaseData] = useState<CaseDto>()

  const {id} = useParams<{id: string}>()
  const {getAccessTokenSilently} = useAuth0()
  const history = useHistory()

  useEffect(() => {
    const getCase = async () => {
      updateToken(await getAccessTokenSilently())

      const data = await CaseService.getCaseForEdit(id)
      setCaseData(data)
      setLoading(false)
    }
    getCase()
  }, [getAccessTokenSilently, id])

  const deleteCase = async () => {
    try {
      setLoading(true)
      updateToken(await getAccessTokenSilently())

      await CaseService.deleteCaseById(caseData?.id!)

      history.push('/cases/list')
    } catch (e) {
      setError(createErrorData(e))
      setLoading(false)
    }
  }

  return (
    <LoadingBoundary loading={loading}>
      <ErrorBoundary error={error}>
        {caseData && (
          <div className='card'>
            <div id='kt_account_deactivate' className='collapse show'>
              <div className='card-body border-top p-9'>
                <DeleteCaseNotice caseData={caseData!} />

                <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                  <DeleteCaseData caseData={caseData!} />
                  <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    <button
                      id='kt_account_deactivate_account_submit'
                      type='submit'
                      onClick={deleteCase}
                      className='btn btn-danger fw-bold'
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </ErrorBoundary>
    </LoadingBoundary>
  )
}

export {DeleteCase}
