/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {CaseEventDto} from '../../../../../../../generated/model'
import {Tag} from "antd";

interface Props {
  ev: CaseEventDto
}

const HistoryComment: React.FC<Props> = (props) => {
  const data = props.ev!.data!.commentData!
  return (
      <>
          <h6>Comment <Tag>{data.action}</Tag></h6>
    <ul>
      <li>Comment: {data.comment}</li>
    </ul>
          </>
  )
}

export {HistoryComment}
