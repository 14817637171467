/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {CaseInputDto, CaseInputStatus, CaseStatus} from '../../../../../generated/model'
import moment from 'moment'
import {SortOrder} from 'antd/es/table/interface'

export default function getColumns(
  detailsCaseClick: (id: string) => void,
  deleteCaseClick: (id: string) => void
) {
  return [
    {
      title: 'Updated At',
      dataIndex: '.',
      key: 'modifiedAt',
      sorter: (a: CaseInputDto, b: CaseInputDto) =>
        moment(a.modifiedAt).unix() - moment(b.modifiedAt).unix(),
      defaultSortOrder: 'descend' as SortOrder,
      render: (text: string, record: CaseInputDto) => (
        <span>{moment(record.modifiedAt).format('DD.MM.yyyy')}</span>
      ),
    },
    {
      title: 'Input Id',
      dataIndex: '.',
      key: 'identifier',
      render: (text: string, record: CaseInputDto) => (
        <a
          href='#'
          onClick={(e) => {
            e.preventDefault()
            detailsCaseClick(record.id!)
          }}
        >
          <>
            <span>{record?.serial?.readableId}</span>
          </>
        </a>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'inputType',
      key: 'inputType',
      render: (text: string) => <span className='badge badge-light-success'>{text}</span>,
    },
    {
      title: 'Data',
      dataIndex: 'inputData',
      key: 'data',
      render: (text: string) => {
        const data = JSON.parse(text)
        return (
          <>
            <div>
              <u>From</u>: {data.From}
            </div>
            <div>
              <u>Subject</u>: {data.Subject}
            </div>
          </>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a: CaseInputDto, b: CaseInputDto) =>
        a!.status!.toString().localeCompare(b!.status!.toString()),
      sortDirections: ['ascend' as SortOrder, 'descend' as SortOrder],
      render: (text: string) => <span className='badge badge-light-info'>{text}</span>,
      onFilter: (value: any, record: CaseInputDto) => record!.status!.toString() === value,
      defaultFilteredValue: [CaseInputStatus.CREATED],
      filters: [
        {
          text: 'Created',
          value: CaseInputStatus.CREATED,
        },
        {
          text: 'Converted',
          value: CaseInputStatus.CONVERTED,
        },
        {
          text: 'Abandoned',
          value: CaseInputStatus.ABANDONED,
        },
      ],
    },
    {
      title: 'Actions',
      dataIndex: '.',
      key: 'actions',
      width: '150px',
      render: (text: string, record: any) => {
        return (
          <>
            {record.status === CaseStatus.CREATED && (
              <>
                <a
                  href='#'
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm disabled'
                  onClick={(e) => {
                    e.preventDefault()
                    deleteCaseClick(record.id)
                  }}
                >
                  <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
                </a>
              </>
            )}
          </>
        )
      },
    },
  ]
}
