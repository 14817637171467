/* eslint-disable jsx-a11y/anchor-is-valid */
import {CaseStatus} from "../../../../../../generated/model";

export function getCaseStatusName (status: CaseStatus) : string {
  switch (status){
    case CaseStatus.CREATED:
    case CaseStatus.CREATED_COMPANY:
      return "Created";
    case CaseStatus.DELETED:
    case CaseStatus.DELETED_COMPANY:
      return "Deleted";
    case CaseStatus.DUPLICATE:
    case CaseStatus.DUPLICATE_COMPANY:
      return "Duplicate";
    case CaseStatus.ARCHIVED:
    case CaseStatus.ARCHIVED_COMPANY:
      return "Archived";
    case CaseStatus.WITHDRAWN:
      return "Withdrawn";
    case CaseStatus.DECLINED:
      return "Declined";
    case CaseStatus.SUBMITTED:
      return "Submitted";
    case CaseStatus.ACCEPTED:
      return "Accepted";
    case CaseStatus.IN_REVIEW:
      return "In Review";
    case CaseStatus.EXPORTED:
      return "Exported";
  }

  return status;
}
