/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ErrorMessage, FormikErrors, FormikValues, FormikTouched} from 'formik'
import {Select} from 'formik-antd'
import {CaseCategory, CaseSeriousness} from '../../../../../../generated/model'
import {FieldInputProps} from 'formik/dist/types'
import {useIntl} from 'react-intl'

interface Props {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  values: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>
}

const StepEvaluation: FC<Props> = (props) => {
  const intl = useIntl()

  function validateCategory(value: {key: string}) {
    let error
    if (!value || !value.key || value.key === '') {
      error = 'Category is required!'
    }
    return error
  }
  function validateSeriousness(value: {key: string}) {
    let error
    if (!value || !value.key || value.key === '') {
      error = 'Seriousness is required!'
    }
    return error
  }

  return (
    <div className='mx-auto mw-600px w-100'>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Category</label>

        <Select
          name='categoryTemp'
          labelInValue
          validate={validateCategory}
          dropdownStyle={{zIndex: 1200}}
          optionLabelProp='label'
          filterOption={false}
          onSelect={(value: any, option: any) => {
            const key = option.key
            const cse = props.values['case']
            cse.category = CaseCategory[key! as keyof typeof CaseCategory]
            props.setFieldValue('case', cse)
          }}
          placeholder='Select category'
          style={{width: '100%'}}
        >
          {Object.keys(CaseCategory).map((element: string) => (
            <Select.Option
              key={element}
              value={element}
              label={intl.formatMessage({id: `CASE_CATEGORY_${element}`})}
            >
              {intl.formatMessage({id: `CASE_CATEGORY_${element}`})}
            </Select.Option>
          ))}
        </Select>
        <div className='fv-plugins-message-container invalid-feedback d-block'>
          <ErrorMessage name='categoryTemp' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Seriousness</label>

        <Select
          name='seriousnessTemp'
          labelInValue
          dropdownStyle={{zIndex: 1200}}
          optionLabelProp='label'
          filterOption={false}
          validate={validateSeriousness}
          onSelect={(value: any, option: any) => {
            const key = option.key
            const cse = props.values['case']
            cse.seriousness = CaseSeriousness[key! as keyof typeof CaseSeriousness]
            props.setFieldValue('case', cse)
          }}
          placeholder='Select seriousness'
          style={{width: '100%'}}
        >
          {Object.keys(CaseSeriousness).map((element: string) => (
            <Select.Option
              key={element}
              value={element}
              label={intl.formatMessage({id: `CASE_SERIOUSNESS_${element}`})}
            >
              {intl.formatMessage({id: `CASE_SERIOUSNESS_${element}`})}
            </Select.Option>
          ))}
        </Select>
        <div className='fv-plugins-message-container invalid-feedback d-block'>
          <ErrorMessage name='seriousnessTemp' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <div className='form-check form-check-custom form-check-solid me-5'>
          <input
            className='form-check-input'
            type='checkbox'
            id='inlineCheckboxValidity'
            checked={props.values['case'].validity}
            {...props.getFieldProps('case.validity')}
          />
          <label className='form-check-label fw-bold' htmlFor='inlineCheckboxRestoredToHealth'>
            Validity
          </label>
        </div>
      </div>
    </div>
  )
}

export {StepEvaluation}
