/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {ErrorMessage, FormikErrors, FormikTouched, FormikValues} from 'formik'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {CaseDto} from '../../../../../../../generated/model'
import {DatePicker, Input, Select} from 'formik-antd'

interface Step1Props {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  values: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const Patient: FC<Step1Props> = (props: Step1Props) => {
  const [expanded, setExpanded] = useState(false)

  const onSelect = (value: any, option: any) => {
    const cse = props.values['case']
    if (!cse.patient.latestData) cse.patient.latestData = {}

    cse.patient.latestData.sex = option.key

    props.setFieldValue('case', cse)
  }
  const onSelectBirthday = (value: any) => {
    const cse = props.values['case']
    if (!cse.patient.latestData) cse.patient.latestData = {}

    cse.patient.latestData.birthday = value.utc().format()
    props.setFieldValue('case', cse)
    props.setFieldValue('birthdayTemp', value)
  }

  function validateSex(value: {key: string}) {
    let error
    if (!value || !value.key || value.key === '') {
      error = 'Sex is required!'
    }
    return error
  }
  function validateBirthday(value: string) {
    let error
    if (!value) {
      error = 'Birthday is required!'
    }
    return error
  }

  const onChangeWeight = (value: string) => {
    onChangeInternal(
      value,
      (cse) => (cse!.patient!.latestData!.bodyWeight = value === '' ? null : Number(value))
    )
  }
  const onChangeHeight = (value: string) => {
    onChangeInternal(
      value,
      (cse) => (cse!.patient!.latestData!.height = value === '' ? null : Number(value))
    )
  }
  const onChangeOccupation = (value: string) => {
    onChangeInternal(
      value,
      (cse) => (cse!.patient!.latestData!.occupation = value === '' ? null : value)
    )
  }
  const onChangeInternal = (value: string, setter: (cse: CaseDto) => void) => {
    const cse = props.values['case']
    if (!cse.patient.latestData) cse.patient.latestData = {}

    setter(cse)
    props.setFieldValue('case', cse)
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        onClick={() => setExpanded(!expanded)}
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Patient Data</h3>
        </div>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            onClick={() => setExpanded(!expanded)}
          >
            {expanded && (
              <KTSVG path='/media/icons/duotone/Navigation/Angle-up.svg' className='svg-icon-2' />
            )}
            {!expanded && (
              <KTSVG path='/media/icons/duotone/Navigation/Angle-down.svg' className='svg-icon-2' />
            )}
          </button>
        </div>
      </div>
      {expanded && (
        <div className='card-body border-top p-9'>
          <div className='row mb-6'>
            <label className='col-lg-3 col-form-label fw-bold required fs-6'>Birthday</label>

            <div className='col-lg-3'>
              <DatePicker
                name='birthdayTemp'
                validate={validateBirthday}
                showTime={false}
                placeholder='DatePicker'
                className='form-control '
                onSelect={onSelectBirthday}
                popupStyle={{zIndex: 10024}}
              />
              <div className='fv-plugins-message-container invalid-feedback d-block'>
                <ErrorMessage name='birthdayTemp' />
              </div>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-3 col-form-label fw-bold required fs-6'>Sex</label>

            <div className='col-lg-3'>
              <Select
                name='sexTemp'
                labelInValue
                optionLabelProp='label'
                placeholder='Select sex'
                onSelect={onSelect}
                validate={validateSex}
                style={{width: '100%'}}
              >
                <Select.Option key={'Male'} value={'Male'} label={'Male'}>
                  Male
                </Select.Option>
                <Select.Option key={'Female'} value={'Female'} label={'Female'}>
                  Female
                </Select.Option>
              </Select>
              <div className='fv-plugins-message-container invalid-feedback d-block'>
                <ErrorMessage name='sexTemp' />
              </div>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-3 col-form-label fw-bold fs-6'>Occupation</label>

            <div className='col-lg-6'>
              <Input
                type='text'
                name='case.patient.latestData.occupation'
                placeholder=''
                onChange={(e) => onChangeOccupation(e.currentTarget.value)}
              />
              <div className='fv-plugins-message-container invalid-feedback d-block'>
                <ErrorMessage name='case.patient.latestData.occupation' />
              </div>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-3 col-form-label fw-bold fs-6'>Weight (in kg)</label>

            <div className='col-lg-2'>
              <Input
                type='number'
                name='case.patient.latestData.bodyWeight'
                placeholder=''
                onChange={(e) => onChangeWeight(e.currentTarget.value)}
              />
              <div className='fv-plugins-message-container invalid-feedback d-block'>
                <ErrorMessage name='case.patient.latestData.bodyWeight' />
              </div>
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-3 col-form-label fw-bold fs-6'>Height (in cm)</label>

            <div className='col-lg-2'>
              <Input
                type='number'
                name='case.patient.latestData.height'
                placeholder=''
                onChange={(e) => onChangeHeight(e.currentTarget.value)}
              />
              <div className='fv-plugins-message-container invalid-feedback d-block'>
                <ErrorMessage name='case.patient.latestData.height' />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export {Patient}
