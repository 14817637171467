/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CaseDedupEventsDto } from '../models/CaseDedupEventsDto';
import type { CaseDedupEventsInfoDto } from '../models/CaseDedupEventsInfoDto';
import type { CaseDedupEventsRequestDto } from '../models/CaseDedupEventsRequestDto';
import type { CaseDedupEventsRequestInfoDto } from '../models/CaseDedupEventsRequestInfoDto';
import type { CaseDedupEventsRequestType } from '../models/CaseDedupEventsRequestType';
import type { CaseDedupMedicineDto } from '../models/CaseDedupMedicineDto';
import type { CaseDedupMedicineInfoDto } from '../models/CaseDedupMedicineInfoDto';
import type { CaseDedupMedicineRequestDto } from '../models/CaseDedupMedicineRequestDto';
import type { CaseDedupMedicineRequestInfoDto } from '../models/CaseDedupMedicineRequestInfoDto';
import type { CaseDedupPatientDto } from '../models/CaseDedupPatientDto';
import type { CaseDedupPatientInfoDto } from '../models/CaseDedupPatientInfoDto';
import type { CaseDedupPatientRequestDto } from '../models/CaseDedupPatientRequestDto';
import type { CaseDedupPatientRequestInfoDto } from '../models/CaseDedupPatientRequestInfoDto';
import { request as __request } from '../core/request';

export class CaseDeduplicationService {

    /**
     * @param requestBody 
     * @returns CaseDedupMedicineInfoDto Success
     * @throws ApiError
     */
    public static async caseDedupMedicineInfo(
requestBody?: CaseDedupMedicineRequestInfoDto,
): Promise<CaseDedupMedicineInfoDto> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/case/dedup/medicine/info`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns CaseDedupMedicineDto Success
     * @throws ApiError
     */
    public static async caseDedupMedicine(
requestBody?: CaseDedupMedicineRequestDto,
): Promise<CaseDedupMedicineDto> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/case/dedup/medicine`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns CaseDedupPatientInfoDto Success
     * @throws ApiError
     */
    public static async caseDedupPatientInfo(
requestBody?: CaseDedupPatientRequestInfoDto,
): Promise<CaseDedupPatientInfoDto> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/case/dedup/patient/info`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns CaseDedupPatientDto Success
     * @throws ApiError
     */
    public static async caseDedupPatient(
requestBody?: CaseDedupPatientRequestDto,
): Promise<CaseDedupPatientDto> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/case/dedup/patient`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns CaseDedupEventsInfoDto Success
     * @throws ApiError
     */
    public static async caseDedupEventsInfo(
requestBody?: CaseDedupEventsRequestInfoDto,
): Promise<CaseDedupEventsInfoDto> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/case/dedup/events/info`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns CaseDedupEventsDto Success
     * @throws ApiError
     */
    public static async caseDedupEvents(
requestBody?: CaseDedupEventsRequestDto,
): Promise<CaseDedupEventsDto> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/case/dedup/events`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param id 
     * @returns CaseDedupEventsInfoDto Success
     * @throws ApiError
     */
    public static async caseDedupInfo(
id: string,
): Promise<CaseDedupEventsInfoDto> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/case/dedup/info/${id}`,
        });
        return result.body;
    }

    /**
     * @param id 
     * @param type 
     * @returns CaseDedupEventsDto Success
     * @throws ApiError
     */
    public static async caseDedup(
id: string,
type?: CaseDedupEventsRequestType,
): Promise<CaseDedupEventsDto> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/case/dedup/${id}`,
            query: {
                'type': type,
            },
        });
        return result.body;
    }

}