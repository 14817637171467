/* eslint-disable jsx-a11y/anchor-is-valid */
import React  from 'react'

import {CaseCommentDto} from '../../../../../../../generated/model'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import moment from 'moment'
import {convertUseCaseToSource} from '../../../../Model'
import {CommentsNew} from "./CommentsNew";
import {useRecoilValue} from "recoil";
import {useCaseState} from "../../../../State";
import {Popconfirm} from "antd";

interface Props {
  comments: CaseCommentDto[]
  saveComment: (comment: CaseCommentDto, read:boolean) => void
  deleteComment: (comment: CaseCommentDto) => void

}

const Comments: React.FC<Props> = (props) => {
  const useCase = useRecoilValue(useCaseState)
  return (
    <>
      {(!props.comments || props.comments?.length === 0) && (
        <div className='card mb-5 mb-xxl-8'>
          <div className='card-body pb-0'>
            <div className='mb-7'>
              <div className='text-gray-800 mb-5'>No comments</div>
            </div>
          </div>
        </div>
      )}

      {props.comments?.map((cmt) => (
        <div key={cmt.id} className='card mb-5 mb-xxl-8'>
          <div className='card-body pb-0'>
            <div className='d-flex align-items-center mb-5'>
              <div className='d-flex align-items-center flex-grow-1'>
                <div className='symbol symbol-45px me-5'>
                  <img alt='Logo' src={cmt.userPicture!} />
                </div>

                <div className='d-flex flex-column'>
                  <a
                    href='#'
                    className={`text-gray-800 text-hover-primary fs-6 ${
                      cmt.source !== convertUseCaseToSource(useCase) &&
                      cmt.read === false &&
                      'fw-bolder'
                    }`}
                  >
                    {cmt.user}
                  </a>
                  <span
                    className={`text-gray-400 ${
                      cmt.source !== convertUseCaseToSource(useCase) &&
                      cmt.read === false &&
                      'fw-bold'
                    } `}
                  >
                    {moment(cmt.createdAt).format('DD.MM.yyyy HH:mm')}
                  </span>
                </div>
              </div>

              <div className='my-0'>
                <Popconfirm
                    title="Are you sure to delete this attachment?"
                    onConfirm={() => props.deleteComment(cmt)}
                    okText="Yes"
                    cancelText="No"
                >
                  <a href='#' className='btn btn-danger btn-sm me-2'>
                    Delete
                  </a>
                </Popconfirm>
              </div>
            </div>

            <div className='mb-7'>
              {/* begin::Text */}
              <div className='text-gray-800 mb-5'>{cmt.comment}</div>

              {cmt.source !== convertUseCaseToSource(useCase) && (
                <div className='d-flex align-items-center mb-5'>
                  {cmt.read === false && (
                    <a
                      href='#'
                      className='btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4'
                      onClick={() => props.saveComment(cmt, true)}
                    >
                      <KTSVG
                        path='/media/icons/duotone/Communication/Chat-check.svg'
                        className='svg-icon-3'
                      />
                      Mark as read
                    </a>
                  )}

                  {cmt.read === true && (
                    <a
                      href='#'
                      className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2'
                      onClick={() => props.saveComment(cmt, false)}
                    >
                      <KTSVG
                        path='/media/icons/duotone/Communication/Chat6.svg'
                        className='svg-icon-2'
                      />
                      Mark as unread
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}

      <CommentsNew saveComment={props.saveComment}/>
    </>
  )
}

export {Comments}
