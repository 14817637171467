export enum ReporterAccountType {
  Me = 'Me',
  New = 'New',
  Existing = 'Existing',
}

export enum PatientAccountType {
  New = 'New',
  Existing = 'Existing',
}

export enum CaseStepType {
  Common = 'Common',

  Reporters = 'Reporters',

  PatientId = 'PatientId',
  PatientData = 'PatientData',
  Events = 'Events',

  Evaluation = 'Evaluation',
  Duplicate = 'Duplicate',

  // sub-flows
  ReporterData = 'ReporterData',
  EventData = 'EventData',
}

export enum SideEffectStepType {
  SideEffect = 'SideEffect',
  SideEffectProperties = 'SideEffectProperties',
  ReactionProperties = 'ReactionProperties',
  ReactionNarrative = 'ReactionNarrative',
  OutcomeProperties = 'OutcomeProperties',
  OutcomeNarrative = 'OutcomeNarrative',
}

export enum ReporterStepType {
  ReporterId = 'ReporterId',
  ReporterData = 'ReporterData',
}

export interface IManageCase {
  // additional conditional properties
  patientAccountType: PatientAccountType
  sexTemp: {
    key: string | undefined
    value: string | undefined
    label: string | undefined
  }

  // temp fields
  reportedAtTemp: string
  submitDeadlineTemp: string
  birthdayTemp: string

  categoryTemp: {
    key: string | undefined
    value: string | undefined
    label: string | undefined
  }
  seriousnessTemp: {
    key: string | undefined
    value: string | undefined
    label: string | undefined
  }
}
