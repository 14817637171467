/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {useHistory} from 'react-router-dom'
import {CaseInputDto, CaseInputStatus} from '../../../../../generated/model'
import {KTSVG} from '../../../../../_metronic/helpers'
import moment from 'moment'
import {HeaderActions} from './HeaderActions'
import {HeaderNav} from './HeaderNav'

interface Props {
  caseInput: CaseInputDto
  transitionTo: (to: CaseInputStatus) => void
}

const DetailsCaseHeader: React.FC<Props> = (props) => {
  const history = useHistory()

  const backClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    history.push('/inputs/list')
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <span className='text-gray-800  fs-2 fw-bolder me-1'>
                      {props.caseInput.serial?.readableId}
                    </span>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <span className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                      <KTSVG
                        path='/media/icons/duotone/Map/Marker1.svg'
                        className='svg-icon-4 me-1'
                      />
                      {props.caseInput.serial?.serialId}
                    </span>
                  </div>
                </div>

                <div className='d-flex my-4'>
                  <a href='#' onClick={backClick} className='btn btn-primary btn-sm me-2'>
                    Back
                  </a>
                </div>
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTSVG
                          path='/media/icons/duotone/Design/Border.svg'
                          className='svg-icon-3 svg-icon-success me-2'
                        />
                        <div className='fs-2 fw-bolder'>{props.caseInput.status}</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Status</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTSVG
                          path='/media/icons/duotone/Files/File-plus.svg'
                          className='svg-icon-3  me-2'
                        />
                        <div className='fs-2 fw-bolder'>
                          {moment(props.caseInput.createdAt).format('DD.MM.yyyy')}
                        </div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Created On</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTSVG
                          path='/media/icons/duotone/Files/File-done.svg'
                          className='svg-icon-3  me-2'
                        />
                        <div className='fs-2 fw-bolder'>
                          {moment(props.caseInput.modifiedAt).format('DD.MM.yyyy')}
                        </div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Updated On</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <HeaderActions caseInput={props.caseInput} transitionTo={props.transitionTo} />

          <HeaderNav />
        </div>
      </div>
    </>
  )
}

export {DetailsCaseHeader}
