/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Redirect, Route, Switch} from "react-router-dom";
import {ExportsListWrapper} from "./exports-list/ExportsListWrapper";
import {DetailsExportsWrapper} from "./exports-details/DetailsExportsWrapper";

const ExportsWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <Switch>
                <Route path='/exports/list'>
                    <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.EXPORTS'})}</PageTitle>
                    <ExportsListWrapper/>
                </Route>
                <Route path='/exports/details/:id'>
                    <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.EXPORTS.DETAILS'})}</PageTitle>
                    <DetailsExportsWrapper/>
                </Route>
                <Redirect to='/exports/list'/>
            </Switch>
        </>
    )
}

export {ExportsWrapper}
