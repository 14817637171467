/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CaseSource, CaseStatus} from '../../../../../../../../generated/model'
import {CaseDto} from '../../../../../../../../generated/model'
import {ButtonSubmit} from './components/ButtonSubmit'
import {ButtonDuplicate} from './components/ButtonDuplicate'
import {ButtonArchive} from './components/ButtonArchive'
import {ButtonUnarchive} from './components/ButtonUnarchive'
import {ButtonUnduplicate} from './components/ButtonUnduplicate'
import {ButtonWithdraw} from './components/ButtonWithdraw'

interface Props {
  transitionTo: (to: CaseStatus) => void
  caseData: CaseDto
}

const HeaderActionsDoctor: React.FC<Props> = (props) => {
  const caseData = props.caseData
  let actions = []

  if (caseData.source === CaseSource.DOCTOR) {
    if (caseData.status === CaseStatus.CREATED) {
      actions.push(<ButtonSubmit key='submit' transitionTo={props.transitionTo} />)
      actions.push(<ButtonArchive key='archive' transitionTo={props.transitionTo} />)
      actions.push(<ButtonDuplicate key='duplicate' transitionTo={props.transitionTo} />)
    } else if (caseData.status === CaseStatus.ARCHIVED) {
      actions.push(<ButtonUnarchive key='unarchive' transitionTo={props.transitionTo} />)
    } else if (caseData.status === CaseStatus.DUPLICATE) {
      actions.push(<ButtonUnduplicate key='unduplicate' transitionTo={props.transitionTo} />)
    } else if (caseData.status === CaseStatus.SUBMITTED) {
      actions.push(<ButtonWithdraw key='withdraw' transitionTo={props.transitionTo} />)
    } else if (caseData.status === CaseStatus.WITHDRAWN) {
      actions.push(<ButtonSubmit key='submit' transitionTo={props.transitionTo} />)
      actions.push(<ButtonArchive key='archive' transitionTo={props.transitionTo} />)
    }
  } else if (caseData.source === CaseSource.COMPANY) {
    // nothing yet
  }
  return (
    <div className='d-flex flex-column'>
      <div className='text-dark me-2 fw-bolder pb-1 px-4'>Actions</div>
      <div className='border border-gray-300 border-dashed rounded py-4 px-4 mx-3 mb-3'>
        <div className='d-flex flex-start'>{actions.length > 0 ? actions : <span>-</span>}</div>
      </div>
    </div>
  )
}

export {HeaderActionsDoctor}
