/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'

import {CaseExportDto, CaseExportService} from '../../../../../../../generated/model'
import {useAuth0} from '@auth0/auth0-react'
import {updateToken} from '../../../../../../utils/AuthToken'
import {Versions} from './Versions'
import {LoadingBoundary} from '../../../../../_shared/LoadingBoundary'
import {ErrorBoundary} from '../../../../../errors/ErrorBoundary'
import {createErrorData, ErrorData} from '../../../../../errors/model/ErrorData'
import {useParams} from 'react-router-dom'

interface Props {}

const VersionsWrapper: React.FC<Props> = (props) => {
  const [exports, setExports] = useState<CaseExportDto[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<ErrorData>()

  const {getAccessTokenSilently} = useAuth0()
  const {id} = useParams<{id: string}>()

  const load = async () => {
    updateToken(await getAccessTokenSilently())

    try {
      setLoading(true)
      updateToken(await getAccessTokenSilently())

      const data = await CaseExportService.getCaseExportsById(id!)
      setExports(data)
      setLoading(false)
    } catch (e) {
      setError(createErrorData(e))
      setExports(undefined)
      setLoading(false)
    }
  }

  useEffect(
    () => {
      load()
    }, // eslint-disable-next-line
    [getAccessTokenSilently, id]
  )

  return (
    <LoadingBoundary loading={loading}>
      <ErrorBoundary error={error}>{exports && <Versions exports={exports} />}</ErrorBoundary>
    </LoadingBoundary>
  )
}

export {VersionsWrapper}
