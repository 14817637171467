/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {ErrorMessage, FormikErrors, FormikValues, FormikTouched} from 'formik'
import {Select} from 'formik-antd'
import {Spin} from 'antd'
import {useAuth0} from '@auth0/auth0-react'
import {ReporterService, ReporterDto} from '../../../../../../generated/model'
import {updateToken} from '../../../../../utils/AuthToken'
import {ReporterIdentifier} from '../../_shared/components/reporter-identifier/ReporterIdentifier'

interface Props {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  values: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const StepReporterExisting: FC<Props> = (props) => {
  const [fetching, setFetching] = useState<boolean>(false)
  const [options, setOptions] = useState<ReporterDto[]>([])
  const {getAccessTokenSilently} = useAuth0()

  const onSearch = async (filter: string) => {
    setFetching(true)

    updateToken(await getAccessTokenSilently())

    const result = await ReporterService.autocompleteReporters(filter)
    setOptions(result)
    setFetching(false)
  }
  const onSelect = (value: any, option: any) => {
    const key = option.key
    const selected = options.filter((x) => x.id === key)[0]

    const caseReporter = props.values['caseReporter']
    caseReporter.reporterId = selected.id
    caseReporter.reporter = selected
    props.setFieldValue('caseReporter', caseReporter)
  }

  return (
    <div className='mb-10 fv-row'>
      <label className='form-label mb-3 required'>Reporter</label>

      <Select
        name='reporter'
        labelInValue
        optionLabelProp='label'
        filterOption={false}
        onSearch={onSearch}
        onSelect={onSelect}
        placeholder='Select reporter'
        style={{width: '100%'}}
        showSearch
        notFoundContent={fetching ? <Spin size='small' /> : null}
      >
        {options.map((element: ReporterDto) => (
          <Select.Option key={element.id!} value={element.id!} label={element.serial?.readableId}>
            <ReporterIdentifier data={element} />
          </Select.Option>
        ))}
      </Select>
      <div className='form-text'>Select an existing Reporter (via ID or Identifier)</div>
      <div className='fv-plugins-message-container invalid-feedback d-block'>
        <ErrorMessage name='patient' />
      </div>
    </div>
  )
}

export {StepReporterExisting}
