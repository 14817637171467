/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {CaseEventDto} from '../../../../../../../generated/model'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {Tag} from 'antd'
import {getCaseStatusName} from "../../../_shared/helpers/CasesStatusHelpers";
import {HistoryChangeData} from "./HistoryChangeData";

interface Props {
  ev: CaseEventDto
}

const HistoryTransition: React.FC<Props> = (props) => {
  const data = props.ev!.data!.transitionData!
  const changeData = props.ev!.data!.changeData
  return (
    <>
      <h6>Transition</h6>
      {data?.from ? (
        <>
          <span>
            <Tag>{getCaseStatusName(data!.from!)}</Tag>
            <KTSVG path='/media/icons/duotone/Navigation/Arrow-right.svg' className='svg-icon-4' />
          </span>
          <span className='px-2'>
            <Tag>{getCaseStatusName(data!.to!)}</Tag>
          </span>
        </>
      ) : (
        <span>
          <Tag>{getCaseStatusName(data!.to!)}</Tag>
        </span>
      )}

      {data.comment && <div className='py-3'>
        Comment: {data.comment}
      </div>}

      {changeData && <div className='py-3'>
        <HistoryChangeData ev={props.ev}/>
      </div>}
    </>
  )
}

export {HistoryTransition}
