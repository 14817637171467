/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from "../../../../../../_metronic/assets/ts/_utils";

type Props = {
    title: string
    subtitle: string,
    chartColor: string,
    percentage: number
}

const Percentage: FC<Props> = ({
                                   title,
                                   subtitle,
                                   chartColor,
                                   percentage
                               }) => {
    const chartRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (!chartRef.current) {
            return
        }

        const chart = new ApexCharts(chartRef.current, chartOptions(percentage, chartColor, '200px'))
        if (chart) {
            chart.render()
        }

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartRef])

    return (
        <div className='card h-100'>
            <div className='card-header border-0 py-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>{title}</span>
                    <span className='text-muted fw-bold fs-7'>{subtitle}</span>
                </h3>
            </div>

            <div className='card-body d-flex flex-column'>
                <div className='flex-grow-1'>
                    <div ref={chartRef} className='mixed-widget-4-chart'></div>
                </div>

                <div className='pt-5'>
                    <a href='#' className={`btn btn-${chartColor} w-100 py-3 disabled`}>
                        Show Details
                    </a>
                </div>
            </div>
        </div>
    )
}

const chartOptions = (value: number, chartColor: string, chartHeight: string): ApexOptions => {
    const baseColor = getCSSVariableValue('--bs-' + chartColor)
    const lightColor = getCSSVariableValue('--bs-light-' + chartColor)
    const labelColor = getCSSVariableValue('--bs-gray-700')

    return {
        series: [value],
        chart: {
            fontFamily: 'inherit',
            height: chartHeight,
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: '65%',
                },
                dataLabels: {
                    name: {
                        show: false,
                        fontWeight: '700',
                    },
                    value: {
                        color: labelColor,
                        fontSize: '30px',
                        fontWeight: '700',
                        offsetY: 12,
                        show: true,
                        formatter: function (val) {
                            return val + '%'
                        },
                    },
                },
                track: {
                    background: lightColor,
                    strokeWidth: '100%',
                },
            },
        },
        colors: [baseColor],
        stroke: {
            lineCap: 'round',
        },
        labels: ['Progress'],
    }
}

export {Percentage}
