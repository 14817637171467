/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Form, Formik, FormikValues} from 'formik'
import {useLocation} from "react-router";
import {initialValues, IRegisterAccount, registrationSchema} from "./helpers/Model";
import {Header} from "./components/Header";
import {Data} from "./components/Data";
import {ErrorBoundary} from "../../../modules/errors/ErrorBoundary";
import {createErrorData, ErrorData} from "../../../modules/errors/model/ErrorData";
import {RegisterService} from "../../../../generated/model";
import {useHistory} from "react-router-dom";


export function RegistrationShare() {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<ErrorData>();

    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const shareId = query.get("shareId");
    const companyName = query.get("companyName");
    const caseId = query.get("caseId");
    const caseIdentifier = query.get("caseIdentifier");

    const history = useHistory();
    const submitStep = async (values: IRegisterAccount, actions: FormikValues) => {
        try {
            setLoading(true)

            await RegisterService.registerUserByShare({
                tenantId: shareId!,
                caseId: caseId!,
                firstName: values.firstname,
                lastName: values.lastname,
                email: values.email,
                password: values.password,
                acceptedTac: values.acceptTerms
            })

            history.push(`/cases/details/${caseId}/overview`)
        } catch (e) {
            setError(createErrorData(e))
            setLoading(false)
        }
    }
    return (
        <>
            <ErrorBoundary error={error}>
            </ErrorBoundary>
            <Header caseId={caseIdentifier!} companyName={companyName!}/>
            <Formik validationSchema={registrationSchema} initialValues={initialValues} onSubmit={submitStep}>
                {({errors, touched, values, setFieldValue}) => (
                    <Form
                        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                        noValidate
                        id='kt_create_case_form'
                    >
                        <Data errors={errors} touched={touched} values={values} setFieldValue={setFieldValue}/>

                        {/* begin::Form group */}
                        <div className='text-center'>
                            <button
                                type='submit'
                                id='kt_sign_up_submit'
                                className='btn btn-lg btn-primary w-100 mb-5'
                                disabled={loading}
                            >
                                {!loading && <span className='indicator-label'>Submit</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
                                )}
                            </button>
                        </div>
                        {/* end::Form group */}
                    </Form>
                )}
            </Formik>
        </>
    )
}
