/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {CaseEventDto} from '../../../../../../../generated/model'
import moment from "moment";

interface Props {
  ev: CaseEventDto
}

const HistoryExport: React.FC<Props> = (props) => {
  const data = props.ev!.data!.exportData!
  return (
      <>
          <h6>Export</h6>
    <ul>
      <li>Export Nr: #{data.submitVersion}</li>
      <li>Deadline was: {moment(data.submitDeadline).format('DD.MM.yyyy HH:mm')}</li>
      <li>
        <a href={`/exports/details/${data.exportsId}`}>Export</a>
      </li>
    </ul>
          </>
  )
}

export {HistoryExport}
