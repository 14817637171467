/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CaseEventDto } from '../models/CaseEventDto';
import type { MeddraLowLevelTermDto } from '../models/MeddraLowLevelTermDto';
import { request as __request } from '../core/request';

export class CaseSideEffectService {

    /**
     * @param id 
     * @returns CaseEventDto Success
     * @throws ApiError
     */
    public static async getSideEffects(
id: string,
): Promise<Array<CaseEventDto>> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/case/${id}/side-effects`,
        });
        return result.body;
    }

    /**
     * @param filter 
     * @returns MeddraLowLevelTermDto Success
     * @throws ApiError
     */
    public static async searchCaseLowLevelTerms(
filter?: string,
): Promise<Array<MeddraLowLevelTermDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/pv/v1/lowLevelTerm/search`,
            query: {
                'filter': filter,
            },
        });
        return result.body;
    }

}