/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {CaseKpiService, CaseKpiStatisticsDto} from '../../../../../generated/model'
import {ErrorBoundary} from '../../../errors/ErrorBoundary'
import {createErrorData, ErrorData} from '../../../errors/model/ErrorData'
import {updateToken} from '../../../../utils/AuthToken'
import {Statistics} from './components/Statistics'
import {LoadingBoundary} from '../../../_shared/LoadingBoundary'

const StatisticsWrapper: FC = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ErrorData>()
  const [data, setData] = useState<CaseKpiStatisticsDto>()

  const {getAccessTokenSilently} = useAuth0()

  useEffect(() => {
    const getData = async () => {
      try {
        updateToken(await getAccessTokenSilently())

        const data = await CaseKpiService.getPvKpiStatistics()
        setData(data)
        setError(undefined)
        setLoading(false)
      } catch (e) {
        setError(createErrorData(e))
        setData(undefined)
        setLoading(false)
      }
    }

    getData()
  }, [getAccessTokenSilently])

  return (
    <>
      <LoadingBoundary loading={loading}>
        <ErrorBoundary error={error}>
          {data && <Statistics statistics={data!} /> }
        </ErrorBoundary>
      </LoadingBoundary>
    </>
  )
}

export {StatisticsWrapper}
