/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ErrorMessage, FormikErrors, FormikTouched, FormikValues} from 'formik'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {ReporterAccountType} from '../../management/_shared_helpers/Model'
import {StepReporterExisting} from './StepReporterExisting'
import {StepReporterNew} from './StepReporterNew'
import {ReporterIdentifier} from '../../_shared/components/reporter-identifier/ReporterIdentifier'
import {Input} from 'formik-antd'
import {caseReporterFromReporterMe} from '../helpers/Model'

interface Props {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  values: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const StepReporterId: FC<Props> = (props) => {
  const onChange = (type: ReporterAccountType) => {
    let caseReporter = props.values['caseReporter']

    if (type === ReporterAccountType.Me) {
      caseReporter = caseReporterFromReporterMe(props.values.reporterMe)
    } else if (type === ReporterAccountType.New) {
      caseReporter.serial = props.values.reporterId
      caseReporter.reporter = {
        latestData: {},
        serial: props.values.reporterId,
      }
      caseReporter.reporterData = undefined
      caseReporter.reporterDataId = undefined
    } else if (type === ReporterAccountType.Existing) {
      caseReporter.serial = undefined
      caseReporter.reporter = undefined
      caseReporter.reporterData = undefined
      caseReporter.reporterDataId = undefined
    }
    props.setFieldValue('caseReporter', caseReporter)
    props.setFieldValue('reporterAccountType', type)
  }

  return (
    <div className='w-100'>
      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-4'>
            <Input
              type='radio'
              className='btn-check'
              name='reporterAccountType'
              checked={props.values.reporterAccountType === ReporterAccountType.Me}
              onChange={() => onChange(ReporterAccountType.Me)}
              value={ReporterAccountType.Me}
              id='kt_create_account_form_account_type_me'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
              htmlFor='kt_create_account_form_account_type_me'
            >
              <KTSVG path='/media/icons/duotone/Interface/User.svg' className='svg-icon-3x me-5' />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                  Me (<ReporterIdentifier data={props.values.reporterMe} />)
                </span>
              </span>
            </label>
          </div>
          <div className='col-lg-4'>
            <Input
              type='radio'
              className='btn-check'
              name='reporterAccountType'
              checked={props.values.reporterAccountType === ReporterAccountType.Existing}
              onChange={() => onChange(ReporterAccountType.Existing)}
              value={ReporterAccountType.Existing}
              id='kt_create_account_form_account_type_personal'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
              htmlFor='kt_create_account_form_account_type_personal'
            >
              <KTSVG path='/media/icons/duotone/Interface/User.svg' className='svg-icon-3x me-5' />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>Existing Reporter</span>
              </span>
            </label>
          </div>
          <div className='col-lg-4'>
            <Input
              type='radio'
              className='btn-check'
              name='reporterAccountType'
              checked={props.values.reporterAccountType === ReporterAccountType.New}
              onChange={() => onChange(ReporterAccountType.New)}
              value={ReporterAccountType.New}
              id='kt_create_account_form_account_type_corporate'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center'
              htmlFor='kt_create_account_form_account_type_corporate'
            >
              <KTSVG
                path='/media/icons/duotone/Interface/Briefcase.svg'
                className='svg-icon-3x me-5'
              />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>New Reporter</span>
              </span>
            </label>
          </div>
          <div className='fv-plugins-message-container invalid-feedback'>
            <ErrorMessage name='reporterAccountType' />
          </div>
        </div>
      </div>

      {props.values['reporterAccountType'] === ReporterAccountType.Existing && (
        <StepReporterExisting
          values={props.values}
          errors={props.errors}
          setFieldValue={props.setFieldValue}
          touched={props.touched}
        />
      )}

      {props.values['reporterAccountType'] === ReporterAccountType.New && (
        <StepReporterNew
          values={props.values}
          errors={props.errors}
          setFieldValue={props.setFieldValue}
          touched={props.touched}
        />
      )}
    </div>
  )
}

export {StepReporterId}
