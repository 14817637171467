import {CaseStepType} from '../../_shared_helpers/Model'
import {
  CaseCreateDto,
  CaseDedupEventsRequestInfoDto,
  CaseDedupPatientRequestInfoDto,
  CaseSideEffectDto,
} from '../../../../../../../generated/model'
import {UseCase} from '../../../../Model'
import {ICreateCase} from './Model'

export const getSchemas = (step: CaseStepType) => {
  switch (step) {
    case CaseStepType.Common:
      break
    case CaseStepType.PatientId:
      break
    case CaseStepType.PatientData:
      break
    case CaseStepType.Reporters:
      break
    case CaseStepType.Evaluation:
      break
    case CaseStepType.Events:
      break
  }
}
export const getPrevStep = (step: CaseStepType, values: ICreateCase, useCase: UseCase) => {
  let newStep = step

  switch (step) {
    case CaseStepType.Reporters:
      newStep = CaseStepType.Common
      break
    case CaseStepType.PatientId:
      newStep = CaseStepType.Reporters
      break
    case CaseStepType.PatientData:
      newStep = CaseStepType.PatientId
      break
    case CaseStepType.Events:
      newStep = CaseStepType.PatientData
      break
    case CaseStepType.Evaluation:
      newStep = CaseStepType.Events
      break
    case CaseStepType.Duplicate:
      newStep = CaseStepType.Common
      break
  }

  return newStep
}
export const getNextStep = (step: CaseStepType, values: ICreateCase, useCase: UseCase) => {
  let newStep = step

  switch (step) {
    case CaseStepType.Common:
      newStep = CaseStepType.Reporters
      break
    case CaseStepType.Reporters:
      newStep = CaseStepType.PatientId
      break
    case CaseStepType.PatientId:
      newStep = CaseStepType.PatientData
      break
    case CaseStepType.PatientData:
      newStep = CaseStepType.Events
      break
    case CaseStepType.Events:
      if (useCase === UseCase.Company) newStep = CaseStepType.Evaluation
      break
  }

  return newStep
}

export function convertDedupPatientInfo(cse: CaseCreateDto): CaseDedupPatientRequestInfoDto {
  return {
    medicineId: cse.medicineId,

    patient: {
      birthday: cse.patient?.latestData?.birthday,
      sex: cse.patient?.latestData?.sex,
      bodyWeight: cse.patient?.latestData?.bodyWeight,
      height: cse.patient?.latestData?.height,
      occupation: cse.patient?.latestData?.occupation,
    },

    bodyWeightRange: 10,
    includeBodyWeight: cse.patient?.latestData?.bodyWeight !== undefined,

    heightRange: 10,
    includeHeight: cse.patient?.latestData?.height !== undefined,

    includeOccupation: false,
  }
}
export function convertDedupEventsInfo(cse: CaseCreateDto): CaseDedupEventsRequestInfoDto {
  return {
    patient: convertDedupPatientInfo(cse),
    events: cse.caseSideEffects?.map((x) => {
      return {lowLevelTermId: x.lowLevelTerm?.id}
    }),
  }
}

export function convertDedupEventsInfoInSdEdit(
  cse: CaseCreateDto,
  eventsNotEditing: CaseSideEffectDto[],
  eventInEdit: CaseSideEffectDto
): CaseDedupEventsRequestInfoDto {
  const dedup = convertDedupEventsInfo(cse)
  dedup.events = eventsNotEditing?.map((x) => {
    return {lowLevelTermId: x.lowLevelTerm?.id}
  })
  if (!dedup.events) dedup.events = []
  dedup.events.push({lowLevelTermId: eventInEdit?.lowLevelTerm!.id})
  return dedup
}
