/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {CaseDtoPageDto, CaseRequestDto, CaseService} from '../../../../../generated/model'
import {updateToken} from '../../../../utils/AuthToken'
import {useRecoilValue} from 'recoil'
import {useCaseState} from '../../State'
import {UseCase} from '../../Model'
import {CasesListDoctor} from './_mode_doctor/CasesListDoctor'
import {CasesListCompany} from './_mode_company/CasesListCompany'
import {DefaultRequestDataCompany, DefaultRequestDataDoctor} from './Model'
import {LoadingBoundary} from '../../../_shared/LoadingBoundary'

const CasesListWrapper: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<CaseDtoPageDto>()

  const useCase = useRecoilValue(useCaseState)
  const [request, setRequestData] = useState<CaseRequestDto>(
    useCase === UseCase.Company ? DefaultRequestDataCompany() : DefaultRequestDataDoctor()
  )

  const {getAccessTokenSilently} = useAuth0()
  const getCases = async (req: CaseRequestDto) => {
    try {
      updateToken(await getAccessTokenSilently())

      const cases = await CaseService.getAllCases(req)
      setData(cases)
      setLoading(false)
    } catch (e) {
      console.log(e.message)
    }
  }
  useEffect(() => {
    getCases(request)
    // eslint-disable-next-line
  }, [getAccessTokenSilently])

  const onChangeTable = async (req: CaseRequestDto) => {
    setRequestData(req)
    await getCases(req)
  }

  return (
    <LoadingBoundary loading={!data && loading}>
      <>
        {data && (
          <>
            {useCase === UseCase.Company && (
              <CasesListCompany
                data={data}
                loading={loading}
                request={request}
                onChangeTable={onChangeTable}
              />
            )}
            {useCase === UseCase.Doctor && (
              <CasesListDoctor
                data={data}
                loading={loading}
                request={request}
                onChangeTable={onChangeTable}
              />
            )}
          </>
        )}
      </>
    </LoadingBoundary>
  )
}

export {CasesListWrapper}
