/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {FormikErrors, FormikValues, FormikTouched, Field} from 'formik'
import {Select} from 'formik-antd'
import {CaseReporterType} from '../../../../../../generated/model'

interface Props {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  values: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const StepReporterData: FC<Props> = (props) => {
  function validate(value: string, name: string) {
    let error
    if (!value) {
      error = `${name} is required!`
    }
    return error
  }
  const onSelect = (value: any, option: any) => {
    const caseReporter = props.values['caseReporter']
    caseReporter.reporterType =
      option.key === 'None' ? undefined : (option.key as keyof typeof CaseReporterType)
    props.setFieldValue('caseReporter', caseReporter)
  }
  return (
    <div className='w-100'>
      <div className='mb-10 row'>
        <div className='col-lg-4 fv-row'>
          <label className='form-label mb-3'>Classification</label>
          <Select
            name='reporterTypeTemp'
            labelInValue
            optionLabelProp='label'
            placeholder='Select classification'
            onSelect={onSelect}
            dropdownStyle={{zIndex: 1200}}
            style={{width: '100%'}}
          >
            <Select.Option key={'None'} value={'None'} label={'-'}>
              -
            </Select.Option>
            <Select.Option key={'Primary'} value={'Primary'} label={'Primary'}>
              Primary
            </Select.Option>
            <Select.Option key={'Secondary'} value={'Secondary'} label={'Secondary'}>
              Secondary
            </Select.Option>
          </Select>
        </div>
        <div className='col-lg-8 fv-row'>
          <label className='form-label mb-3'>Comment</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='caseReporter.reporterComment'
            placeholder=''
          />
        </div>
      </div>
      <div className='mb-5 row border-top' />
      <div className='mb-10 row'>
        <label className='form-label mb-3 required'>Name</label>

        <div className='col-lg-4 fv-row'>
          <Field
            type='text'
            validate={validate}
            className='form-control form-control-lg form-control-solid'
            name='caseReporter.reporter.latestData.givenName'
            placeholder='Given Name'
          />
        </div>
        <div className='col-lg-4 fv-row'>
          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='caseReporter.reporter.latestData.middleName'
            placeholder='Middle Name'
          />
        </div>
        <div className='col-lg-4 fv-row'>
          <Field
            type='text'
            validate={validate}
            className='form-control form-control-lg form-control-solid'
            name='caseReporter.reporter.latestData.familyName'
            placeholder='Family Name'
          />
        </div>
      </div>
      <div className='mb-10 row'>
        <div className='col-lg-6 fv-row'>
          <label className='form-label mb-3'>Email</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='caseReporter.reporter.latestData.email'
            placeholder=''
          />
        </div>
        <div className='col-lg-6 fv-row'>
          <label className='form-label mb-3'>Title</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='caseReporter.reporter.latestData.title'
            placeholder=''
          />
        </div>
      </div>
      <div className='mb-10 row'>
        <div className='col-lg-6 fv-row'>
          <label className='form-label mb-3'>Organisation</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='caseReporter.reporter.latestData.organisation'
            placeholder=''
          />
        </div>

        <div className='col-lg-6 fv-row'>
          <label className='form-label mb-3'>Department</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='caseReporter.reporter.latestData.department'
            placeholder=''
          />
        </div>
      </div>
      <div className='mb-10 row'>
        <label className='form-label mb-3'>Address</label>

        <div className='col-lg-4 fv-row'>
          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='caseReporter.reporter.latestData.countryCode'
            placeholder='Country Code'
          />
        </div>
        <div className='col-lg-4 fv-row'>
          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='caseReporter.reporter.latestData.stateOrProvince'
            placeholder='State or Province'
          />
        </div>
        <div className='col-lg-4 fv-row'>
          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='caseReporter.reporter.latestData.postcode'
            placeholder='Postcode'
          />
        </div>
      </div>
      <div className='row mb-10'>
        <div className='col-lg-6 fv-row'>
          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='caseReporter.reporter.latestData.city'
            placeholder='City'
          />
        </div>
        <div className='col-lg-6 fv-row'>
          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='caseReporter.reporter.latestData.street'
            placeholder='Street'
          />
        </div>
      </div>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Telephone</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='caseReporter.reporter.latestData.telephone'
          placeholder=''
        />
      </div>
    </div>
  )
}

export {StepReporterData}
