/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useRecoilValue} from 'recoil'
import {CaseSideEffectInEdit, caseSideEffectInEditState} from '../../State'
import {ManageSideEffect} from '../../side-effects/manage/ManageSideEffect'
import {CaseSideEffectDto} from '../../../../../../generated/model'

interface EventsStepProps {
  handleInsert: (sideEffect: CaseSideEffectDto) => void
  handleUpdate: (sideEffect: CaseSideEffectDto) => void
  showSideEffects: () => void

  editModel?: CaseSideEffectInEdit
}

const StepEventData: FC<EventsStepProps> = (props) => {
  const editStateRecoil = useRecoilValue(caseSideEffectInEditState)
  const editState = props.editModel ?? editStateRecoil

  return (
    <ManageSideEffect
      handleUpdate={props.handleUpdate}
      handleInsert={props.handleInsert}
      close={() => props.showSideEffects()}
      sideEffect={editState?.sideEffect}
      case={editState?.case!}
    />
  )
}

export {StepEventData}
