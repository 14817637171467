/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CaseDto} from "../../../../../../../generated/model";
import {SortOrder} from "antd/lib/table/interface";
import moment from "moment";
import {CaseIdentifier} from "../../../_shared/components/case-identifier/CaseIdentifier";
import {getCaseStatusName} from "../../../_shared/helpers/CasesStatusHelpers";

export default function getColumns(detailsCaseClick: (id: string) => void) {
  return [
    {
      title: 'Updated At',
      dataIndex: '.',
      key: 'modifiedAt',
      sorter: (a: CaseDto, b: CaseDto,) =>
        moment(a.modifiedAt).unix() - moment(b.modifiedAt).unix(),
      defaultSortOrder: 'descend' as SortOrder,
      render: (text: string, record: CaseDto) => (
        <span>{moment(record.modifiedAt).format('DD.MM.yyyy')}</span>
      ),
    },
    {
      title: 'Case Id',
      dataIndex: '.',
      key: 'identifier',
      render: (text: string, record: CaseDto,) => (
        <a
          href='#'
          onClick={(e) => {
            e.preventDefault()
            detailsCaseClick(record.id!)
          }}
        >
          <CaseIdentifier data={record!} />
        </a>
      ),
    },
    {
      title: 'Status',
      dataIndex: '.',
      key: 'status',
      sorter: (a: CaseDto, b: CaseDto,) =>
        a.status!.toString().localeCompare(b!.status!.toString()),
      sortDirections: ['ascend' as SortOrder, 'descend' as SortOrder],
      render: (text: string, record: CaseDto) => <span className='badge badge-light-info'>{getCaseStatusName(record!.status!)}</span>,
    },
  ]
}
