/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'

import {Redirect, Route, Switch, useParams} from 'react-router-dom'
import {useAuth0} from '@auth0/auth0-react'
import {CaseService} from '../../../../../generated/model'
import {LoadingBoundary} from '../../../_shared/LoadingBoundary'
import {ErrorBoundary} from '../../../errors/ErrorBoundary'
import {ErrorData} from '../../../errors/model/ErrorData'
import {updateToken} from '../../../../utils/AuthToken'
import {DetailsCaseHeader} from './components/header/Header'
import {Overview} from './components/overview/Overview'
import {HistoryWrapper} from './components/history/HistoryWrapper'
import {useRecoilState} from 'recoil'
import {caseDetailsState} from './State'
import {Events} from './components/events/OverviewEvents'
import {CommentsWrapper} from './components/comments/CommentsWrapper'
import {AttachmentsWrapper} from './components/attachments/AttachmentsWrapper'
import {DuplicatesWrapper} from './components/duplicates/DuplicatesWrapper'
import {VersionsWrapper} from './components/versions/VersionsWrapper'

const DetailsCaseWrapper: FC = (props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [error] = useState<ErrorData>()
  const [caseData, setCaseDetailsState] = useRecoilState(caseDetailsState)

  const {getAccessTokenSilently} = useAuth0()
  const {id} = useParams<{id: string}>()

  useEffect(
    () => {
      const getCase = async () => {
        updateToken(await getAccessTokenSilently())

        const data = await CaseService.getCaseById(id)
        setCaseDetailsState(data)
        setLoading(false)
      }
      getCase()
    }, // eslint-disable-next-line
    [getAccessTokenSilently, id]
  )

  return (
    <LoadingBoundary loading={loading}>
      <ErrorBoundary error={error}>
        {caseData && (
          <>
            <DetailsCaseHeader case={caseData} />
            <Switch>
              <Route path='/cases/details/:id/overview'>
                <Overview case={caseData} />
              </Route>
              <Route path='/cases/details/:id/events'>
                <Events />
              </Route>
              <Route path='/cases/details/:id/comments'>
                <CommentsWrapper />
              </Route>
              <Route path='/cases/details/:id/documents'>
                <AttachmentsWrapper />
              </Route>
              <Route path='/cases/details/:id/history'>
                <HistoryWrapper />
              </Route>
              <Route path='/cases/details/:id/versions'>
                <VersionsWrapper />
              </Route>
              <Route path='/cases/details/:id/duplicates'>
                <DuplicatesWrapper />
              </Route>
              <Redirect from='/cases/details/:id' exact={true} to='/cases/details/:id/overview' />
              <Redirect to='/cases/details/:id/overview' />
            </Switch>
          </>
        )}
      </ErrorBoundary>
    </LoadingBoundary>
  )
}

export {DetailsCaseWrapper}
