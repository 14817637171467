/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CaseExportDto, CaseExportMode} from '../../../../../../../generated/model'
import {SortOrder} from 'antd/lib/table/interface'
import moment from 'moment'

export default function getColumns(detailsCaseClick: (id: string) => void) {
  return [
    {
      title: 'Version',
      dataIndex: 'submitVersion',
      key: 'submitVersion',
    },
    {
      title: 'Type',
      dataIndex: 'exportMode',
      key: '.',
      render: (text: string, record: CaseExportDto) => (
        <>
          {record.exportMode === CaseExportMode.DOCTOR_SUBMIT_EXPORT && (
            <span>Initial Submit Version</span>
          )}
          {record.exportMode === CaseExportMode.EXPORT && <span>Export</span>}
        </>
      ),
    },
    {
      title: 'Created At',
      dataIndex: '.',
      key: 'createdAt',
      sorter: (a: CaseExportDto, b: CaseExportDto) =>
        moment(a.modifiedAt).unix() - moment(b.modifiedAt).unix(),
      defaultSortOrder: 'descend' as SortOrder,
      render: (text: string, record: CaseExportDto) => (
        <span>{moment(record.modifiedAt).format('DD.MM.yyyy')}</span>
      ),
    },
    {
      title: 'Official Submit Deadline',
      dataIndex: 'submitDeadline',
      key: 'submitDeadline',
      sorter: (a: CaseExportDto, b: CaseExportDto) =>
        moment(a.submitDeadline).unix() - moment(b.submitDeadline).unix(),
      defaultSortOrder: 'descend' as SortOrder,
      render: (text: string, record: CaseExportDto) => (
        <span>{moment(record.submitDeadline).format('DD.MM.yyyy')}</span>
      ),
    },
    {
      title: 'Data',
      dataIndex: '.',
      key: 'data',
      render: (text: string, record: CaseExportDto) => (
        <a href={`/exports/details/${record.casesExportId}`}>Export</a>
      ),
    },
  ]
}
