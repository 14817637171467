import {MenuItem} from './MenuItem'
import {useIntl} from 'react-intl'
import {useRecoilValue} from 'recoil'
import {useCaseState} from '../../../../app/modules/pv/State'
import {UseCase} from '../../../../app/modules/pv/Model'
import {roleContextState} from "../../../../app/modules/State";
import {Role} from "../../../../app/modules/Model";

export function MenuInner() {
  const intl = useIntl()
  const useCase = useRecoilValue(useCaseState)
  const roleContext = useRecoilValue(roleContextState)
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
      <MenuItem title={intl.formatMessage({id: 'MENU.CASES'})} to='/cases' />
      {useCase === UseCase.Company && (
        <MenuItem title={intl.formatMessage({id: 'MENU.INPUTS'})} to='/inputs' />
      )}
      <MenuItem title={intl.formatMessage({id: 'MENU.EXPORTS'})} to='/exports' />
      {useCase === UseCase.Company && (
        <MenuItem title={intl.formatMessage({id: 'MENU.KPI'})} to='/kpi' />
      )}
      {roleContext?.hasRole(Role.Admin) && (
        <MenuItem title={intl.formatMessage({id: 'MENU.ADMIN'})} to='/administration' />
      )}
    </>
  )
}
