import React, {FC} from 'react'
import {CaseEventDto} from "../../../../../../generated/model";
import {getCaseStatusName} from "../../../cases/_shared/helpers/CasesStatusHelpers";

interface Props {
    event: CaseEventDto
}

const ActivityDataTransition: FC<Props> = (props) => {
    const data = props.event?.data?.transitionData!;
  return (
      <>
          {data.from && <div>Transition. From: {getCaseStatusName(data.from)} to {getCaseStatusName(data.to!)}</div>}
          {!data.from && <div>Transition. To {getCaseStatusName(data.to!)}</div>}
      </>
  )
}

export {ActivityDataTransition}
