/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {Languages} from './Languages'
import {withAuth0, WithAuth0Props} from '@auth0/auth0-react'

class HeaderUserMenu extends Component<WithAuth0Props> {
    render() {
        const {user, logout} = this.props.auth0
        return (
            <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
                data-kt-menu='true'
            >
                <div className='menu-item px-3'>
                    <div className='menu-content d-flex align-items-center px-3'>
                        <div className='symbol symbol-50px me-5'>
                            <img alt='Logo' src={user?.picture}/>
                        </div>

                        <div className='d-flex flex-column'>
                            <div className='fw-bolder d-flex align-items-center fs-5'>
                                {user?.given_name} {user?.family_name}
                                <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>
                  {user?.profile}
                </span>
                            </div>
                            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                                {user?.nickname}
                            </a>
                        </div>
                    </div>
                </div>

                <div className='separator my-2'></div>

                <div className='menu-item px-5'>
                    <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
                        My Profile
                    </Link>
                </div>

                <div className='separator my-2'></div>

                <Languages/>

                <div className='menu-item px-5 my-1'>
                    <Link to='/crafted/account/settings' className='menu-link px-5'>
                        Account Settings
                    </Link>
                </div>

                <div className='separator my-2'></div>

                <div className='menu-item px-5'>
                    <Link
                        to='#'
                        className='menu-link px-5'
                        onClick={() => logout({returnTo: window.location.origin})}
                    >
                        Sign Out
                    </Link>
                </div>
            </div>
        )
    }
}

export default withAuth0(HeaderUserMenu)
