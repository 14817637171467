/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {CaseReporterDto} from '../../../../../../../generated/model'
import {ReporterIdentifier} from '../../../_shared/components/reporter-identifier/ReporterIdentifier'

interface Props {
  caseReporters: CaseReporterDto[]
}

const OverviewDataReporter: React.FC<Props> = (props) => {
  return (
    <div className='row'>
      <label className='col-lg-4 fw-bold text-muted'>Reporters</label>

      <div className='col-lg-8'>
        {props.caseReporters.map((caseReporter) => (
          <div className='mb-7'>
            <div>
              <span className='fw-bolder fs-6 text-dark'>
                <ReporterIdentifier data={caseReporter.reporter!} />
              </span>
              <span className='px-2'>
                ({caseReporter.reporter!.serial!.globalId} /{' '}
                {caseReporter.reporter!.serial!.serialId})
              </span>
            </div>

            <div>
              {caseReporter.reporterData?.title && <span>{caseReporter.reporterData?.title} </span>}
              {caseReporter.reporterData?.givenName}
              {caseReporter.reporterData?.middleName && (
                <span> {caseReporter.reporterData?.middleName}</span>
              )}
              <span> {caseReporter.reporterData?.familyName}</span>
            </div>
            {caseReporter.reporterData?.department && (
              <div>Department: {caseReporter.reporterData?.department}</div>
            )}
            {caseReporter.reporterData?.organisation && (
              <div>Organisation: {caseReporter.reporterData?.organisation}</div>
            )}
            {caseReporter.reporterData?.telephone && (
              <div>Telephone: {caseReporter.reporterData?.telephone}</div>
            )}
            <div>
              <span>Addresse: </span>
              <span>{caseReporter.reporterData?.countryCode}</span>
              <span> {caseReporter.reporterData?.postcode}</span>
              <span> {caseReporter.reporterData?.stateOrProvince}</span>
              <span> {caseReporter.reporterData?.city}</span>
              <span> {caseReporter.reporterData?.street}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export {OverviewDataReporter}
