/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {CaseEventDto, CaseEventType} from '../../../../../../../generated/model'
import {HistoryTransition} from './HistoryTransition'
import {HistoryChangeData} from './HistoryChangeData'
import {HistoryExport} from "./HistoryExport";
import { HistoryComment } from './HistoryComment';
import {HistoryAttachment} from "./HistoryAttachment";

interface Props {
  ev: CaseEventDto
}

const HistoryChange: React.FC<Props> = (props) => {
  return (
    <>
      {props.ev.eventType === CaseEventType.TRANSITION && <HistoryTransition ev={props.ev} />}
      {props.ev.eventType === CaseEventType.CHANGE && <HistoryChangeData ev={props.ev} />}
      {props.ev.eventType === CaseEventType.EXPORT && <HistoryExport ev={props.ev} />}
      {props.ev.eventType === CaseEventType.COMMENT && <HistoryComment ev={props.ev} />}
      {props.ev.eventType === CaseEventType.ATTACHMENT && <HistoryAttachment ev={props.ev} />}

    </>
  )
}

export {HistoryChange}
