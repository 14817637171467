/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'

type Props = {
    title: string
    subtitle: string,
    timesForExport: { label: string, value: number, color: string }[]
}

const Partition: FC<Props> = ({
                                   title,
                                   subtitle,
                                  timesForExport,
                               }) => {
    const chartRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (!chartRef.current) {
            return
        }

        const chart = new ApexCharts(chartRef.current, chartOptions(timesForExport, '200px'))
        if (chart) {
            chart.render()
        }

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartRef])

    return (
        <div className='card h-100'>
            <div className='card-header border-0 py-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>{title}</span>
                    <span className='text-muted fw-bold fs-7'>{subtitle}</span>
                </h3>
            </div>

            <div className='card-body d-flex flex-column'>
                <div className='flex-grow-1'>
                    <div ref={chartRef} className='mixed-widget-4-chart'></div>
                </div>

                <div className='pt-5'>
                    <a href='#' className={`btn w-100 py-3 disabled`}>
                        Show Details
                    </a>
                </div>
            </div>
        </div>
    )
}

const chartOptions = (timesForExport: { label: string, value: number, color: string }[], chartHeight: string): ApexOptions => {
    // const baseColor = getCSSVariableValue('--bs-' + chartColor)
    //const lightColor = getCSSVariableValue('--bs-light-' + chartColor)
    //const labelColor = getCSSVariableValue('--bs-gray-700')

    const values = timesForExport.map(x => x.value);
    const labels = timesForExport.map(x => x.label);
    const colors = timesForExport.map(x => x.color);

    return {
        series: values,
        chart: {
            fontFamily: 'inherit',
            height: chartHeight,
            type: 'donut',
        },
        colors: colors,
        labels: labels,
    }
}

export {Partition}
