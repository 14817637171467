import React, {FC} from 'react'
import {useHistory} from 'react-router-dom'
import {KTSVG} from '../../../../../_metronic/helpers'
import {RecentCaseCommentDto} from '../../../../../generated/model'

interface Props {
  data: RecentCaseCommentDto[]
}

const Notifications: FC<Props> = (props) => {
  const history = useHistory()
  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bolder text-dark'>Notifications</h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-0'>
        {props.data.map((cm) => (
          <div className='d-flex align-items-center bg-light-info rounded p-5 mb-7'>
            {/* begin::Icon */}
            <span className='svg-icon svg-icon-info me-5'>
              {' '}
              <KTSVG path='/media/icons/duotone/Home/Library.svg' className='svg-icon-1' />
            </span>
            {/* end::Icon */}
            {/* begin::Title */}
            <div className='flex-grow-1 me-2'>
              <span>New comment on Case: </span>
              <a
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                  history.push(`cases/details/${cm.caseId}/overview`)
                }}
              >
                {cm.caseSerial?.readableId}
              </a>
              <span className='text-muted fw-bold d-block'>Comment: {cm.comment?.comment}</span>
            </div>
            {/* end::Title */}
          </div>
        ))}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {Notifications}
