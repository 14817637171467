/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'

import {useHistory} from 'react-router-dom'

const DeleteCaseHeader: FC = (props) => {
    const history = useHistory()

    const backClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        history.push('/cases/list')
    }

    return (
        <div className={`card mb-5 mb-xl-8}`}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>Delete Case</span>
                </h3>
                <div className='card-toolbar'>
                    <a
                        href='#'
                        onClick={backClick}
                        className='btn btn-primary btn-sm'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_create_project'
                    >
                        Back
                    </a>
                </div>
            </div>
            <div className='card-body py-3'>
                <div className='table-responsive'></div>
            </div>
        </div>
    )
}

export {DeleteCaseHeader}
