/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, Key, useEffect, useState} from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {CaseMedicineService, MedicineDto} from '../../../../../../../generated/model'
import {Select, Spin} from 'antd'
import {updateToken} from '../../../../../../utils/AuthToken'

interface Props {
  onSelected: (medicine: MedicineDto) => void
  onClear: () => void
}

const CaseMedicineSelector: FC<Props> = (props) => {
  const [options, setOptions] = useState<MedicineDto[]>([])
  const [fetching, setFetching] = useState<boolean>(false)

  const {getAccessTokenSilently} = useAuth0()
  const onSearch = async (filter: string) => {
    setFetching(true)

    updateToken(await getAccessTokenSilently())
    const result = await CaseMedicineService.searchCaseMedicines(filter)
    setOptions(result)
    setFetching(false)
  }
  const onSelect = (value: string, option: {key?: Key | undefined}) => {
    const key = option.key
    const selected = options.filter((x) => x.id === key)[0]

    props.onSelected(selected)
  }

  useEffect(() => {
    const getData = async () => {
      setFetching(true)
      updateToken(await getAccessTokenSilently())

      await onSearch('')
    }

    getData()
    // eslint-disable-next-line
  }, [getAccessTokenSilently])
  return (
    <Select
      labelInValue
      optionLabelProp='label'
      onSelect={onSelect}
      filterOption={false}
      onSearch={onSearch}
      placeholder='Select medicine'
      style={{width: '100%'}}
      allowClear
      showSearch
      onClear={props.onClear}
      notFoundContent={fetching ? <Spin size='small' /> : null}
    >
      {options.map((element: MedicineDto) => (
        <Select.Option key={element.id!} value={element.id!} label={element.name!}>
          {element.name}
        </Select.Option>
      ))}
    </Select>
  )
}

export {CaseMedicineSelector}
