/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Popover} from "antd";

interface Props {
    export: () => void
}

const ButtonExport: React.FC<Props> = (props) => {
  return (
      <Popover content={
          <div>
              <div>...</div>
              <div>...</div>
              <div>....</div>
          </div>
      } title="Export">
          <button
              className='btn btn-sm btn-light-primary fw-bolder mx-2'
              onClick={() => props.export()}
          >
              Export
          </button>
      </Popover>
  )
}

export {ButtonExport}
