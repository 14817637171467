/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {PatientDto} from '../../../../../../../generated/model'

interface Props {
  data: PatientDto
}

const PatientIdentifier: React.FC<Props> = (props) => {
  return (
    <>
      {props.data.serial!.readableId}
      {props.data.identifier && <span> ({props.data.identifier})</span>}
    </>
  )
}
export {PatientIdentifier}
