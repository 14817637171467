/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CaseSideEffectOutcomeDto} from '../../../../../../../generated/model'
import {UseCase} from "../../../../Model";
import {useRecoilValue} from "recoil";
import {useCaseState} from "../../../../State";

interface Props {
  data: CaseSideEffectOutcomeDto
}

const CaseEventSummaryOutcome: React.FC<Props> = (props) => {
  const medicationData = props.data!
  const useCase = useRecoilValue(useCaseState)
  return (
    <>
      <div className='col-xl-12'>
        <div className='row flex-grow-1'>
          <div className='col-xl-4'>
            <div className='fs-7 text-muted fw-bold'>Restored to Health</div>

            <div className='d-flex align-items-center'>
              <div className='fs-4'>{medicationData.restoredToHealth ? 'yes' : 'no'}</div>
            </div>
          </div>
          <div className='col-xl-8'>
            <div className='fs-7 text-muted fw-bold'>Permanent damage / Consequential damage</div>

            <div className='d-flex align-items-center'>
              <div className='fs-4'>{medicationData.permDamage ? 'yes' : 'no'}</div>
            </div>
          </div>
        </div>

        <div className='row flex-grow-1 my-3'>
          <div className='col-xl-4'>
            <div className='fs-7 text-muted fw-bold'>Not Restored To Health</div>

            <div className='d-flex align-items-center'>
              <div className='fs-4'>{medicationData.notRestoredToHealth ? 'yes' : 'no'}</div>
            </div>
          </div>
          <div className='col-xl-4'>
            <div className='fs-7 text-muted fw-bold'>Unknown</div>

            <div className='d-flex align-items-center'>
              <div className='fs-4'>{medicationData.unknown ? 'yes' : 'no'}</div>
            </div>
          </div>
        </div>
        <div className='row flex-grow-1 my-3'>
          <div className='col-xl-4'>
            <div className='fs-7 text-muted fw-bold'>Results In Death</div>

            <div className='d-flex align-items-center'>
              <div className='fs-4'>{medicationData.resultsInDeath ? 'yes' : 'no'}</div>
            </div>
          </div>
          {medicationData.resultsInDeath && (
            <div className='col-xl-8'>
              <div className='fs-7 text-muted fw-bold'>Cause of death</div>

              <div className='d-flex align-items-center'>
                <div className='fs-4'>
                  {medicationData.resultsInDeathCauseOfDeath ? 'yes' : 'no'}
                </div>
              </div>
            </div>
          )}
        </div>
        {medicationData.resultsInDeath && (
          <div className='row flex-grow-1 my-3'>
            <div className='col-xl-4'>
              <div className='fs-7 text-muted fw-bold'>
                Results In Death: Medicine contribution possible
              </div>

              <div className='d-flex align-items-center'>
                <div className='fs-4'>{medicationData.resultsInDeathMedContrib ? 'yes' : 'no'}</div>
              </div>
            </div>
            <div className='col-xl-4'>
              <div className='fs-7 text-muted fw-bold'>Results In Death: Not from medicine</div>

              <div className='d-flex align-items-center'>
                <div className='fs-4'>{medicationData.resultsInDeathNotFromMed ? 'yes' : 'no'}</div>
              </div>
            </div>
            <div className='col-xl-4'>
              <div className='fs-7 text-muted fw-bold'>Results In Death: Due to Effect</div>

              <div className='d-flex align-items-center'>
                <div className='fs-4'>
                  {medicationData.resultsInDeathDueToEffect ? 'yes' : 'no'}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className='row flex-grow-1 my-3'>
          <div className='col-xl-12'>
            <div className='fs-7 text-muted fw-bold'>Additional Information</div>

            <div className='d-flex align-items-center'>
              <div className='fs-4'>{medicationData.description ?? '-'}</div>
            </div>
          </div>
        </div>
        {(useCase === UseCase.Company && medicationData.descriptionTranslated && medicationData.descriptionTranslated.length > 0) && (
            <div className='row flex-grow-1 my-3'>
              <div className='col-xl-12'>
                <div className='fs-7 text-muted fw-bold'>Additional Information (Translated)</div>

                <div className='d-flex align-items-center'>
                  <div className='fs-4'>{medicationData.descriptionTranslated ?? '-'}</div>
                </div>
              </div>
            </div>
        )}
      </div>
    </>
  )
}
export {CaseEventSummaryOutcome}
