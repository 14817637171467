/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'

import {CaseCommentDto, CaseCommentService} from '../../../../../../../generated/model'
import {useAuth0} from '@auth0/auth0-react'
import {updateToken} from "../../../../../../utils/AuthToken";
import {Comments} from "./Comments";
import {LoadingBoundary} from "../../../../../_shared/LoadingBoundary";
import {ErrorBoundary} from "../../../../../errors/ErrorBoundary";
import {createErrorData, ErrorData} from "../../../../../errors/model/ErrorData";
import {useParams} from "react-router-dom";

interface Props {
}

const CommentsWrapper: React.FC<Props> = (props) => {
  const [comments, setComments] = useState<CaseCommentDto[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<ErrorData>()

  const {getAccessTokenSilently} = useAuth0()
  const {id} = useParams<{id: string}>()

  const load = async () => {
    updateToken(await getAccessTokenSilently())

    try {
      setLoading(true)
      updateToken(await getAccessTokenSilently())

      const data = await CaseCommentService.getComments(id!)
      setComments(data)
      setLoading(false)
    } catch (e) {
      setError(createErrorData(e))
      setComments(undefined)
      setLoading(false)
    }
  }

  const saveComment = async (comment: CaseCommentDto, read: boolean) => {
    comment.read = read
    try {
      setLoading(true)
      updateToken(await getAccessTokenSilently())

      await CaseCommentService.saveComment(id!, comment)

      await load();
    } catch (e) {
      setError(createErrorData(e))
      setComments(undefined)
      setLoading(false)
    }
  }
  const deleteComment = async (comment: CaseCommentDto) => {
    try {
      setLoading(true)
      updateToken(await getAccessTokenSilently())

      await CaseCommentService.deleteCommentById(id!, comment.id!)

      await load();
    } catch (e) {
      setError(createErrorData(e))
      setComments(undefined)
      setLoading(false)
    }
  }
  useEffect(() => {
    load()
  }, // eslint-disable-next-line
  [getAccessTokenSilently, id])

  return (
      <LoadingBoundary loading={loading}>
        <ErrorBoundary error={error}>
      {comments &&
      <Comments comments={comments} saveComment={saveComment} deleteComment={deleteComment} />}
        </ErrorBoundary>
      </LoadingBoundary>
  )
}

export {CommentsWrapper}

