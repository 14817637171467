/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReporterDto } from '../models/ReporterDto';
import { request as __request } from '../core/request';

export class CaseReporterService {

    /**
     * @param filter 
     * @returns ReporterDto Success
     * @throws ApiError
     */
    public static async searchCaseReporters(
filter?: string,
): Promise<Array<ReporterDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/pv/v1/reporter/search`,
            query: {
                'filter': filter,
            },
        });
        return result.body;
    }

}