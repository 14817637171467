import React, {useState} from 'react'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {caseDedupDuplicateFoundState, caseForDedupMedicineState, caseStepState} from '../../../../State'
import {DedupMedicineListModal} from '../../../../deduplication/medicine/list/DedupMedicineListModal'
import {CaseDedupMedicineInfoData} from './CaseDedupMedicineInfoData'
import {CaseDto} from "../../../../../../../../generated/model";
import {CaseStepType} from "../../../../management/_shared_helpers/Model";

type Props = {}

const CaseDedupMedicineInfo: React.FC<Props> = (props) => {
  const dataForDedup = useRecoilValue(caseForDedupMedicineState)
  const [showModal, setShowModal] = useState<boolean>(false)
  const setCaseDedupDuplicateFoundState = useSetRecoilState(caseDedupDuplicateFoundState)
  const setCaseStepState = useSetRecoilState(caseStepState)

  const foundDuplicate = (dto: CaseDto) => {
    setCaseDedupDuplicateFoundState(dto);
    setCaseStepState(CaseStepType.Duplicate)
    setShowModal(false);
  }
  return (
    <>
      {dataForDedup && (
        <DedupMedicineListModal
          show={showModal}
          foundDuplicate={foundDuplicate}
          handleClose={() => setShowModal(false)}
          medicineId={dataForDedup.medicineId!}
        />
      )}

      <div className={`card mb-5 mb-xl-8}`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Deduplication Information</span>
            <span className='text-muted mt-1 fw-bold fs-7'>
              <React.Suspense
                fallback={<span className='text-muted mt-1 fw-bold fs-7'>Loading...</span>}
              >
                <CaseDedupMedicineInfoData />
              </React.Suspense>
            </span>
          </h3>
          <div className='card-toolbar'>
            <a
              href='#'
              className={`btn btn-sm btn-light-primary ${!dataForDedup && 'disabled'}`}
              onClick={(e) => {
                e.preventDefault()
                setShowModal(true)
              }}
            >
              Details
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export {CaseDedupMedicineInfo}
