/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CaseInputDto, CaseInputStatus} from '../../../../../generated/model'

interface Props {
  caseInput: CaseInputDto
  transitionTo: (to: CaseInputStatus) => void
}

const HeaderActions: React.FC<Props> = (props) => {
  return (
    <>
      <div className='d-flex flex-column'>
        <div className='text-dark me-2 fw-bolder pb-1 px-4'>Actions</div>
        <div className='border border-gray-300 border-dashed rounded py-4 px-4 mx-3 mb-3'>
          <div className='d-flex flex-start'>
            {props.caseInput.status === CaseInputStatus.CREATED && (
              <>
                <a
                  href={`/cases/new?inputId=${props.caseInput.id}`}
                  className='btn btn-sm btn-primary fw-bolder'
                  target='_blank'
                  rel='noreferrer'
                >
                  Create New case
                </a>
                <button
                  className='btn btn-sm btn-light-primary fw-bolder mx-2'
                  onClick={() => props.transitionTo(CaseInputStatus.CONVERTED)}
                >
                  Finish
                </button>
                <button
                  className='btn btn-sm btn-light-primary fw-bolder'
                  onClick={() => props.transitionTo(CaseInputStatus.ABANDONED)}
                >
                  Abandon
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export {HeaderActions}
