/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {useHistory} from 'react-router-dom'
import {CaseDto, } from '../../../../../../../../generated/model'
import {KTSVG} from '../../../../../../../../_metronic/helpers'
import {CaseStatus} from "../../../../../../../../generated/model";

interface Props {
  case: CaseDto
}

const HeaderDataButtonsDoctor: React.FC<Props> = (props) => {
  const history = useHistory()

  const editCaseClick = (id: string) => {
    history.push(`/cases/edit/${id}`)
  }

  return (
      <>
        {(props.case.status === CaseStatus.CREATED ||  props.case.status === CaseStatus.WITHDRAWN ||  props.case.status === CaseStatus.DECLINED) && (
            <>
            <div className='px-3'>
            <a
                href='#'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                onClick={(e) => {
                  e.preventDefault()
                  editCaseClick(props.case.id!);
                }}
            >
              <KTSVG
                  path='/media/icons/duotone/Communication/Write.svg'
                  className='svg-icon-3'
              />
            </a>
            </div>
            </>)
        }
      </>
  )
}

export {HeaderDataButtonsDoctor}
