/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {CaseInputDto, CaseInputType} from '../../../../../generated/model'
import {OverviewEmail} from './OverviewEmail'

interface Props {
  caseInput: CaseInputDto
}

const Overview: React.FC<Props> = (props) => {
  return (
    <div className='card'>
      <div id='kt_account_deactivate' className='collapse show'>
        <div className='card-body border-top'>
          <div className='card' id='kt_profile_details_view'>
            <div className='card-body p-9'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Global Case ID / Serial</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {props.caseInput.serial?.globalId}
                  </span>
                  <span className='px-2'>({props.caseInput.serial?.serialId})</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Type</label>

                <div className='col-lg-8'>
                  <div>
                    <div>{props.caseInput.inputType}</div>
                  </div>
                </div>
              </div>

              {props.caseInput.inputType === CaseInputType.EMAIL && (
                <OverviewEmail inputData={props.caseInput.inputData!} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Overview}
