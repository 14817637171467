/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {FormikErrors, FormikValues, FormikTouched, ErrorMessage} from 'formik'
import {
  MeddraLowLevelTermDto,
  MeddraLowLevelTermService,
} from '../../../../../../../../generated/model'
import {useAuth0} from '@auth0/auth0-react'
import {updateToken} from '../../../../../../../utils/AuthToken'
import {Select} from 'formik-antd'
import {Spin} from 'antd'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {caseForDedupEventsState, caseSideEffectInEditState} from '../../../../State'
import {convertDedupEventsInfoInSdEdit} from '../../../../management/new/helpers/Utils'
import Highlighter from 'react-highlight-words'

interface Props {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  values: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const SideEffectAutocomplete: FC<Props> = (props) => {
  const [fetching, setFetching] = useState<boolean>(false)
  const [options, setOptions] = useState<MeddraLowLevelTermDto[]>([])
  const [searchWords, setSearchWords] = useState<string[]>([])
  const {getAccessTokenSilently} = useAuth0()

  const editState = useRecoilValue(caseSideEffectInEditState)
  const setCaseForDedupEventsState = useSetRecoilState(caseForDedupEventsState)

  const onSearch = async (filter: string) => {
    if (!filter || filter.length < 3) return
    setFetching(true)

    updateToken(await getAccessTokenSilently())

    const searchWords = filter.split(' ')
    const result = await MeddraLowLevelTermService.searchLowLevelTerms(filter)
    setSearchWords(searchWords)
    setOptions(result)
    setFetching(false)
  }
  const onSelect = async (value: any, option: any) => {
    const key = option.key
    const selected = options.filter((x) => x.id === key)[0]
    onSelectLlt(selected)
  }

  const onSelectSub = (nameField: string, id: string, name: string) => {
    props.setFieldValue(nameField, {key: id, value: id, label: name})
  }

  const onSelectLlt = async (selected: MeddraLowLevelTermDto) => {
    const details = await MeddraLowLevelTermService.getLowLevelTermDetails(selected.id!)
    const model = props.values['sideEffect']
    model.lowLevelTerm = details.lowLevelTerm
    model.preferedTerm = details.preferedTerm
    model.highLevelTerm = details.highLevelTerm
    model.highLevelGroupTerm = details.highLevelGroupTerm
    model.systemOrganClass = details.systemOrganClass
    props.setFieldValue('sideEffect', model)

    onSelectSub('meddraLltTemp', model.lowLevelTerm.id, model.lowLevelTerm.name)
    onSelectSub('meddraPtTemp', model.preferedTerm.id, model.preferedTerm.name)
    onSelectSub('meddraHltTemp', model.highLevelTerm.id, model.highLevelTerm.name)
    onSelectSub('meddraHlgtTemp', model.highLevelGroupTerm.id, model.highLevelGroupTerm.name)
    onSelectSub('meddraSocTemp', model.systemOrganClass.id, model.systemOrganClass.name)

    let dedupVals = convertDedupEventsInfoInSdEdit(
      editState!.case!,
      editState!.sideEffectsNotInEdit,
      model
    )
    setCaseForDedupEventsState(dedupVals)
  }
  function validate(value: {key: string}) {
    let error = undefined
    if (!value || !value.key || value.key === '') {
      error = 'Side Effect is required!'
    }
    return error
  }

  useEffect(() => {
    const getDetails = () => {
      if (props.values.sideEffect && props.values.sideEffect.lowLevelTerm) {
        onSelectSub(
          'meddraLltTemp',
          props.values.sideEffect.lowLevelTerm.id,
          props.values.sideEffect.lowLevelTerm.name
        )
        onSelectSub(
          'meddraPtTemp',
          props.values.sideEffect.preferedTerm.id,
          props.values.sideEffect.preferedTerm.name
        )
        onSelectSub(
          'meddraHltTemp',
          props.values.sideEffect.highLevelTerm.id,
          props.values.sideEffect.highLevelTerm.name
        )
        onSelectSub(
          'meddraHlgtTemp',
          props.values.sideEffect.highLevelGroupTerm.id,
          props.values.sideEffect.highLevelGroupTerm.name
        )
        onSelectSub(
          'meddraSocTemp',
          props.values.sideEffect.systemOrganClass.id,
          props.values.sideEffect.systemOrganClass.name
        )
      }
    }
    getDetails()
    // eslint-disable-next-line
  }, [getAccessTokenSilently])

  return (
    <div className='w-100' style={{minHeight: 530}}>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Low Level Term (LLT)</label>

        <Select
          validate={validate}
          name='meddraLltTemp'
          labelInValue
          optionLabelProp='label'
          filterOption={false}
          onSearch={onSearch}
          onSelect={onSelect}
          placeholder='Select side effect (LLT)'
          style={{width: '100%'}}
          dropdownStyle={{zIndex: 1200}}
          showSearch
          notFoundContent={fetching ? <Spin size='small' /> : null}
        >
          {options.map((element: MeddraLowLevelTermDto) => (
            <Select.Option key={element.id!} value={element.id!} label={element.name}>
              <Highlighter
                searchWords={searchWords}
                autoEscape={true}
                textToHighlight={`${element.name} [${element.code}]`}
              />
            </Select.Option>
          ))}
        </Select>
        <div className='fv-plugins-message-container invalid-feedback d-block'>
          <ErrorMessage name='patient' />
        </div>
        {props.values?.sideEffect?.lowLevelTerm && (
          <div className='mb-10 fv-row'>
            <label className='form-label mb-3 pt-5'>Code</label>
            <div>{props.values.sideEffect.lowLevelTerm.code}</div>
          </div>
        )}
      </div>
      {props.values?.sideEffect?.lowLevelTerm && (
        <>
          <div className='mb-10 fv-row border-top'>
            <div>
              <label className='form-label mb-3 pt-5'>System Organ Class (SOC)</label>
              <div>
                {props.values.sideEffect?.systemOrganClass?.name} -{' '}
                {props.values.sideEffect?.systemOrganClass?.code}
              </div>
            </div>
            <div>
              <label className='form-label mb-3 pt-5'>High Level Group Term (HLGT)</label>
              <div>
                {props.values.sideEffect?.highLevelGroupTerm?.name} -{' '}
                {props.values.sideEffect?.highLevelGroupTerm?.code}
              </div>
            </div>
            <div>
              <label className='form-label mb-3 pt-5'>High Level Term (HLT)</label>
              <div>
                {props.values.sideEffect?.highLevelTerm?.name} -{' '}
                {props.values.sideEffect?.highLevelTerm?.code}
              </div>
            </div>
            <div>
              <label className='form-label mb-3 pt-5'>Prefered Term (PT)</label>
              <div>
                {props.values.sideEffect?.preferedTerm?.name} -{' '}
                {props.values.sideEffect?.preferedTerm?.code}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export {SideEffectAutocomplete}
