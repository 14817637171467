/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'

import {useAuth0} from '@auth0/auth0-react'
import {CaseExportService, CasesExportDto} from '../../../../generated/model'
import {LoadingBoundary} from '../../../modules/_shared/LoadingBoundary'
import {ErrorBoundary} from '../../../modules/errors/ErrorBoundary'
import {ErrorData} from '../../../modules/errors/model/ErrorData'
import {updateToken} from '../../../utils/AuthToken'
import {Redirect, Route, Switch, useParams} from 'react-router-dom'
import {DetailsCaseHeader} from './components/Header'
import {Overview} from './components/Overview'
import {CasesList} from './components/CasesList'

const DetailsExportsWrapper: FC = (props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [caseData, setCaseData] = useState<CasesExportDto>()
  const [error] = useState<ErrorData>()

  const {getAccessTokenSilently} = useAuth0()
  const {id} = useParams<{id: string}>()

  useEffect(() => {
    const getExports = async () => {
      updateToken(await getAccessTokenSilently())

      const data = await CaseExportService.getExportById(id)
      setCaseData(data)
      setLoading(false)
    }
    getExports()
  }, [getAccessTokenSilently, id])

  return (
    <LoadingBoundary loading={loading}>
      <ErrorBoundary error={error}>
        {caseData && (
          <>
            <DetailsCaseHeader casesExport={caseData} />
            <Switch>
              <Route path='/exports/details/:id/overview'>
                <Overview casesExport={caseData} />
              </Route>
              <Route path='/exports/details/:id/cases'>
                <CasesList data={caseData.exports!} />
              </Route>
              <Redirect
                from='/exports/details/:id'
                exact={true}
                to='/exports/details/:id/overview'
              />
              <Redirect to='/exports/details/:id/overview' />
            </Switch>
          </>
        )}
      </ErrorBoundary>
    </LoadingBoundary>
  )
}

export {DetailsExportsWrapper}
