import clsx from 'clsx'
import React, {Component} from 'react'
import {withAuth0, WithAuth0Props} from '@auth0/auth0-react'
import HeaderUserMenu from '../../../partials/layout/header-menus/HeaderUserMenu'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

class Topbar extends Component<WithAuth0Props> {
  render() {
    const {user} = this.props.auth0
    return (
      <div className='d-flex align-items-stretch flex-shrink-0'>
        <div className='topbar d-flex align-items-stretch flex-shrink-0'>
          {/* begin::User */}
          <div
            className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
            id='kt_header_user_menu_toggle'
          >
            {/* begin::Toggle */}
            <div
              className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
            >
              <img className='h-25px w-25px rounded' src={user?.picture} alt='metronic' />
            </div>
            <HeaderUserMenu />
            {/* end::Toggle */}
          </div>
          {/* end::User */}
        </div>
      </div>
    )
  }
}

export default withAuth0(Topbar)
