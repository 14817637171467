/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MedicineDto } from '../models/MedicineDto';
import { request as __request } from '../core/request';

export class CaseMedicineService {

    /**
     * @param filter 
     * @returns MedicineDto Success
     * @throws ApiError
     */
    public static async searchCaseMedicines(
filter?: string,
): Promise<Array<MedicineDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/pv/v1/medicine/search`,
            query: {
                'filter': filter,
            },
        });
        return result.body;
    }

}