/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CaseCommentDto } from '../models/CaseCommentDto';
import type { RecentCaseCommentDto } from '../models/RecentCaseCommentDto';
import { request as __request } from '../core/request';

export class CaseCommentService {

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async saveComment(
id: string,
requestBody?: CaseCommentDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/case/${id}/comment`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param id 
     * @returns CaseCommentDto Success
     * @throws ApiError
     */
    public static async getComments(
id: string,
): Promise<Array<CaseCommentDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/pv/v1/case/${id}/comments`,
        });
        return result.body;
    }

    /**
     * @param id 
     * @param commentId 
     * @returns any Success
     * @throws ApiError
     */
    public static async deleteCommentById(
id: string,
commentId: string,
): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/pv/v1/case/${id}/comment/${commentId}`,
        });
        return result.body;
    }

    /**
     * @returns RecentCaseCommentDto Success
     * @throws ApiError
     */
    public static async getRecentComments(): Promise<Array<RecentCaseCommentDto>> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/caseComments/recent`,
        });
        return result.body;
    }

}