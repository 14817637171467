/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'

import {StorageService} from '../../../../../../../generated/model'
import {ErrorMessage, FormikErrors, FormikTouched, FormikValues} from "formik";
import {useAuth0} from "@auth0/auth0-react";
import {UploadProps} from "antd/lib/upload/interface";
import {UploadRequestOption as RcCustomRequestOptions} from "rc-upload/lib/interface";
import {updateToken} from "../../../../../../utils/AuthToken";
import {BlockBlobParallelUploadOptions, ContainerClient} from "@azure/storage-blob";
import {v4 as uuidv4} from "uuid";
import {createErrorData, ErrorData} from "../../../../../errors/model/ErrorData";
import {Button, Upload} from "antd";
import {KTSVG} from "../../../../../../../_metronic/helpers";
import {ErrorBoundary} from "../../../../../errors/ErrorBoundary";

interface Props {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  values: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const AttachmentsForm: React.FC<Props> = (props) => {
  const [error, setError] = useState<ErrorData>()

  const {getAccessTokenSilently} = useAuth0()
  const uploadProps: UploadProps = {
    maxCount: 1,
    customRequest: async (options: RcCustomRequestOptions) => {
      try {
        const token = await getAccessTokenSilently()
        updateToken(token);

        const sas = await StorageService.getBlobSasWrite();

        const file = options.file as File;

        const containerClient = new ContainerClient(sas);

        const blobName = uuidv4();
        const blobClient = containerClient.getBlockBlobClient(blobName);
        const blobOptions : BlockBlobParallelUploadOptions = {
          blobHTTPHeaders: { blobContentType: file.type,
              blobContentDisposition: "attachment; filename=" + file.name! } ,


          metadata: {
            "name": file.name,
            "id": blobName.toString()
          }
        };
        await blobClient.uploadData(file, blobOptions);

        props.setFieldValue("originalName", file.name);
        props.setFieldValue("storageName", blobName);

        if(options.onSuccess)
          options.onSuccess("Done", new XMLHttpRequest());
      }
      catch (e){
        if( options.onError)
          options.onError({status: 403, name: '', message: 'Could not upload file.'}, "Could not upload file. Error: " + e);
        setError(createErrorData(e))
      }
    },
  }
  return (
        <div className='mx-5 mw-800px '>

          <ErrorBoundary error={error}/>
          <div className='mb-10 fv-row'>
              <Upload {...uploadProps}>
                <Button
                    className={`btn btn-sm btn-light-primary `}
                    icon={
                      <KTSVG
                          path='/media/icons/duotone/Communication/Add-user.svg'
                          className='svg-icon-2'
                      />
                    }
                >
                  <span>Select File</span>
                </Button>
              </Upload>
          </div>
          <div className='mb-10 fv-row'>
            <label className='form-label mb-3 required'>Original Name</label>
            <label className='form-control'>{props.values.originalName}</label>
            <div className='fv-plugins-message-container invalid-feedback d-block'>
              <ErrorMessage name='originalName' />
            </div>
        </div>

          <div className='mb-10 fv-row'>
              <label className='form-label mb-3 required'>Description</label>

              <textarea
                  className='form-control form-control-lg form-control-solid'
                  rows={4}
                  value={props.values.description}
                  onChange={(e) => {
                    e.preventDefault()
                    props.setFieldValue("description", e.target.value)
                  }}
              />

              <div className='fv-plugins-message-container invalid-feedback d-block'>
                <ErrorMessage name='description' />
              </div>
          </div>

        <div >
          <div className='mr-2'>
            <button
                type='submit'
                id='kt_sign_up_submit'
                className='btn btn-primary me-3'
            >
              <span className='indicator-label'>Save</span>
            </button>
          </div>
          </div>
        </div>
  )
}

export {AttachmentsForm}
