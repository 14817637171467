/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useRecoilValue} from 'recoil'
import {CaseReporterInEdit, caseReporterInEditState} from '../../State'
import {CaseReporterDto} from '../../../../../../generated/model'
import {ManageReporter} from '../../reporters/ManageReporter'

interface EventsStepProps {
  handleInsert: (sideEffect: CaseReporterDto) => void
  handleUpdate: (sideEffect: CaseReporterDto) => void
  showReporters: () => void

  editModel?: CaseReporterInEdit
}

const StepReporterData: FC<EventsStepProps> = (props) => {
  const editStateRecoil = useRecoilValue(caseReporterInEditState)
  const editState = props.editModel ?? editStateRecoil

  return (
    <ManageReporter
      handleUpdate={props.handleUpdate}
      handleInsert={props.handleInsert}
      close={() => props.showReporters()}
      reporter={editState?.reporter}
      case={editState?.case!}
    />
  )
}

export {StepReporterData}
