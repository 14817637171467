/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {CaseDto} from '../../../../../../../generated/model'
import {useHistory} from 'react-router-dom'
import getColumns from './DuplicatesColumns'
import {Table} from 'antd'

interface Props {
  duplicates: CaseDto[]
}

const Duplicates: React.FC<Props> = (props) => {
  const history = useHistory()
  const detailsCaseClick = (id: string) => {
    history.push(`/cases/details/${id}`)
  }
  let columns = getColumns(detailsCaseClick)
  return (
    <div className={`card mb-5 mb-xl-8}`}>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <Table dataSource={props.duplicates} columns={columns} rowKey='id' />
        </div>
      </div>
    </div>
  )
}

export {Duplicates}
