import React, {FC} from 'react'
import {RecentCaseEventDto} from '../../../../../generated/model'
import moment from 'moment'
import {useHistory} from 'react-router-dom'
import {ActivityData} from "./components/ActivityData";

interface Props {
  data: RecentCaseEventDto[]
}

const Activity: FC<Props> = (props) => {
  const history = useHistory()
  return (
    <div className='card card-xxl-stretch mb-5'>
      <div className='card-header border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder mb-2 text-dark'>Activity</span>
        </h3>
      </div>
      <div className='card-body pt-0'>
        <div className='timeline-label'>
          {props.data.map((act) => (
            <div className='timeline-item' key={act.caseSerial?.serialId}>
              <div className='timeline-label fw-bolder text-gray-800 fs-6'>
                {moment(act.event?.createdAt).format('DD.MM HH:mm')}
              </div>
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-warning fs-1'></i>
              </div>
              <div className='fw-mormal timeline-content text-muted ps-3'>
                <div>
                  <span>Case: </span>
                  <a
                    href='#'
                    onClick={(e) => {
                      e.preventDefault()
                      history.push(`cases/details/${act.caseId}/overview`)
                    }}
                  >
                    {act.caseSerial?.readableId}
                  </a>
                </div>
                  <ActivityData data={act}/>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export {Activity}
