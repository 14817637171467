/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'

import {CaseCommentDto} from '../../../../../../../generated/model'
import {KTSVG} from '../../../../../../../_metronic/helpers'

interface Props {
  saveComment: (comment: CaseCommentDto, read:boolean) => void
}

const CommentsNew: React.FC<Props> = (props) => {
  const [comment, setComment] = useState<string>('')

  return (
      <div className='card mb-5 mb-xxl-8'>
        <div className='card-body pb-0'>
          <form className='position-relative mb-6'>
            <textarea
                className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
                rows={5}
                placeholder='New comment ...'
                value={comment}
                onChange={(e) => setComment(e.target.value)}
            ></textarea>

            <div className='position-absolute top-0 end-0 me-n5'>
              <span className='btn btn-icon btn-sm btn-active-color-primary pe-0 me-2 disabled'>
                <KTSVG path='/media/icons/duotone/General/Clip.svg' className='svg-icon-3 mb-3' />
              </span>
            </div>
          </form>

          <div className='d-flex align-items-center mb-5'>
            <a
                href='#'
                className={`btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4 ${
                    comment.length === 0 && 'disabled'
                }`}
                onClick={() =>
                    props.saveComment({
                      comment: comment,
                      read: false,
                    }, false)
                }
            >
              <KTSVG path='/media/icons/duotone/General/Save.svg' className='svg-icon-3' />
              Save
            </a>

            {comment?.length > 0 && (
                <a
                    href='#'
                    className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2'
                    onClick={() => setComment('')}
                >
                  <KTSVG path='/media/icons/duotone/Code/Stop.svg' className='svg-icon-2' />
                  Cancel
                </a>
            )}
          </div>
        </div>
      </div>
  )
}

export {CommentsNew}
