import React, {useEffect} from 'react'
import {parseUser} from '../utils/UserModel'
import {useAuth0} from '@auth0/auth0-react'
import {useSetRecoilState} from 'recoil'
import {roleContextState} from '../modules/State'
import {Role} from '../modules/Model'

function enumFromStringValue<T>(enm: {[s: string]: T}, value: string): T | undefined {
  return (Object.values(enm) as unknown as string[]).includes(value)
    ? (value as unknown as T)
    : undefined
}

const RoleProvider: React.FC = (props) => {
  const setRoleContextState = useSetRecoilState(roleContextState)
  const {user} = useAuth0()
  useEffect(() => {
    const userModel = parseUser(user)
    const rawRoles = userModel.roles

    const roles: Role[] = []
    if (rawRoles)
      for (const rawRole of rawRoles) {
        const role = enumFromStringValue(Role, rawRole)
        if (role) roles.push(role)
      }

    setRoleContextState({
      roles: [],
      hasRole: (role: Role) => roles?.indexOf(role) >= 0,
    })

    // parse roles
  }, [user, setRoleContextState])

  return <>{props.children}</>
}
export default RoleProvider
