/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Modal} from 'react-bootstrap-v5'
import {CaseDto} from "../../../../../../../../../../generated/model";
import {KTSVG} from "../../../../../../../../../../_metronic/helpers";
import {DuplicateForm} from "./DuplicateForm";

type Props = {
  show: boolean
  handleClose: () => void
  handleSaved : () => void;

  case: CaseDto
}

const DuplicateModal: React.FC<Props> = (props) => {
  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      className='modal fade'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={props.show}
      onHide={props.handleClose}
      onShow={() => {}}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>Case Duplicate</h2>

          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={props.handleClose}>
            <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
          </div>
        </div>

        <div className='modal-body'>
          <DuplicateForm case={props.case} onSaved={props.handleSaved}/>
        </div>
      </div>
    </Modal>
  )
}

export {DuplicateModal}
