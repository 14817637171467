/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CaseInputDto } from '../models/CaseInputDto';
import type { CaseInputMailDto } from '../models/CaseInputMailDto';
import type { CaseInputStatus } from '../models/CaseInputStatus';
import { request as __request } from '../core/request';

export class CaseInputService {

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async newMail(
requestBody?: CaseInputMailDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/input/mail`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param id 
     * @returns CaseInputDto Success
     * @throws ApiError
     */
    public static async getInputById(
id: string,
): Promise<CaseInputDto> {
        const result = await __request({
            method: 'GET',
            path: `/api/pv/v1/input/${id}`,
        });
        return result.body;
    }

    /**
     * @returns CaseInputDto Success
     * @throws ApiError
     */
    public static async getAllInputs(): Promise<Array<CaseInputDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/pv/v1/input`,
        });
        return result.body;
    }

    /**
     * @param id 
     * @param target 
     * @returns CaseInputDto Success
     * @throws ApiError
     */
    public static async transitionInput(
id: string,
target?: CaseInputStatus,
): Promise<CaseInputDto> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/input/${id}/transition`,
            query: {
                'target': target,
            },
        });
        return result.body;
    }

}