import {CaseDedupPatientRequestInfoDto} from '../../../../../../../../generated/model'

export const isValid = (data?: CaseDedupPatientRequestInfoDto): boolean => {
  if (!data || !data.patient) return false

  const patient = data.patient
  if (!patient.birthday || !patient.sex) return false

  return true
}
