/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {PatientDataDto, PatientDto} from '../../../../../../../generated/model'
import {PatientIdentifier} from '../../../_shared/components/patient-identifier/PatientIdentifier'
import moment from 'moment'

interface Props {
  patient: PatientDto
  patientData: PatientDataDto
}

const OverviewDataPatient: React.FC<Props> = (props) => {
  return (
    <div className='row mb-7'>
      <label className='col-lg-4 fw-bold text-muted'>Patient</label>

      <div className='col-lg-8'>
        <div>
          <div>
            <span className='fw-bolder fs-6 text-dark'>
              <PatientIdentifier data={props.patient} />
            </span>
            <span className='px-2'>
              ({props.patient.serial!.globalId} / {props.patient.serial!.serialId})
            </span>
          </div>

          {props.patientData?.birthday && (
            <div>Birthday: {moment(props.patientData?.birthday).format('DD.MM.yyyy')}</div>
          )}
          <div>Sex: {props.patientData?.sex}</div>
          {props.patientData?.bodyWeight! > 0 && (
            <div>Body Weight: {props.patientData?.bodyWeight}</div>
          )}
          {props.patientData?.height! > 0 && <div>Height: {props.patientData?.height}</div>}
        </div>
      </div>
    </div>
  )
}

export {OverviewDataPatient}
