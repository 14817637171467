/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CaseExportDto} from '../../../../../generated/model'
import moment from 'moment'
import {SortOrder} from 'antd/es/table/interface'
import {CaseIdentifier} from '../../../../modules/pv/cases/_shared/components/case-identifier/CaseIdentifier'
import {getCaseStatusName} from "../../../../modules/pv/cases/_shared/helpers/CasesStatusHelpers";

export default function getColumns(detailsCaseClick: (id: string) => void) {
  return [
    {
      title: 'Updated At',
      dataIndex: '.',
      key: 'modifiedAt',
      sorter: (a: CaseExportDto, b: CaseExportDto) =>
        moment(a.modifiedAt).unix() - moment(b.modifiedAt).unix(),
      defaultSortOrder: 'descend' as SortOrder,
      render: (text: string, record: CaseExportDto) => (
        <span>{moment(record.modifiedAt).format('DD.MM.yyyy')}</span>
      ),
    },
    {
      title: 'Case Id',
      dataIndex: '.',
      key: 'identifier',
      render: (text: string, record: CaseExportDto) => (
        <a
          href='#'
          onClick={(e) => {
            e.preventDefault()
            detailsCaseClick(record.caseId!)
          }}
        >
          <CaseIdentifier data={record.case!} />
        </a>
      ),
    },
    {
      title: 'Status',
      dataIndex: '.',
      key: 'status',
      sorter: (a: CaseExportDto, b: CaseExportDto) =>
        a.case!.status!.toString().localeCompare(b!.case!.status!.toString()),
      sortDirections: ['ascend' as SortOrder, 'descend' as SortOrder],
      render: (text: string, record: CaseExportDto) => <span className='badge badge-light-info'>{getCaseStatusName(record.case!.status!)}</span>,
    },
  ]
}
