/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Popover} from "antd";
import {CaseStatus} from "../../../../../../../../../generated/model";

interface Props {
    transitionTo: (caseStatus: CaseStatus) => void
}

const ButtonUnarchive: React.FC<Props> = (props) => {
  return (
      <Popover content={
          <div>
              <div>...</div>
              <div>...</div>
              <div>....</div>
          </div>
      } title="Archive">
          <button
              className='btn btn-sm btn-light-primary fw-bolder mx-2'
              onClick={() => props.transitionTo(CaseStatus.CREATED)}
          >
              Re-Activate
          </button>
      </Popover>
  )
}

export {ButtonUnarchive}
