/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {CasesListWrapper} from './list/CasesListWrapper'
import {Redirect, Route, Switch} from 'react-router-dom'
import {NewCaseWrapper} from './management/new/NewCaseWrapper'
import {DetailsCaseWrapper} from './details/DetailsCaseWrapper'
import {DeleteCaseWrapper} from './management/delete/DeleteCaseWrapper'
import {EditCaseSpWrapper} from './management/edit-single-page/EditSpCaseWrapper'

const CasesWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <Switch>
        <Route path='/cases/list'>
          <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CASES'})}</PageTitle>
          <CasesListWrapper />
        </Route>
        <Route path='/cases/new'>
          <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CASES.NEW'})}</PageTitle>
          <NewCaseWrapper />
        </Route>
        <Route path='/cases/edit/:id'>
          <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CASES.EDIT'})}</PageTitle>
          <EditCaseSpWrapper />
        </Route>
        <Route path='/cases/delete/:id'>
          <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CASES.DELETE'})}</PageTitle>
          <DeleteCaseWrapper />
        </Route>
        <Route path='/cases/details/:id'>
          <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CASES.DETAILS'})}</PageTitle>
          <DetailsCaseWrapper />
        </Route>
        <Redirect to='/cases/list' />
      </Switch>
    </>
  )
}

export {CasesWrapper}
