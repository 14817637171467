/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {CompanyDto} from '../../../../../../../generated/model'

interface Props {
  company: CompanyDto
  showHeader?: boolean
  showNameBold?: boolean
}

const CompanyDetails: FC<Props> = (props) => {
  const companyData = props.company ? JSON.parse(props.company?.data!) : {}
  return (
    <>
      {(props.showHeader === true || props.showHeader === undefined) && (
        <label className='form-label mb-3 pt-5'>Company</label>
      )}
      <div className={`${props.showNameBold === true && 'fw-bold'}`}>{props.company?.name}</div>
      <div>
        {companyData.ADDRESS.ZIP} {companyData.ADDRESS.CITY}, {companyData.ADDRESS.STREET}{' '}
        {companyData.ADDRESS.STREETNUMBER} ({companyData.ADDRESS.COUNTRYCODE})
      </div>
      {companyData?.ADDRESS.CONTACT_LIST && companyData?.ADDRESS.CONTACT_LIST.length > 0 && (
        <>
          <div className='pt-3' />
          {companyData?.ADDRESS.CONTACT_LIST?.map((entry: any, index: number) => (
            <div key={index}>
              {entry.CONTACTTYPECODE === 'E' && <span>Email: </span>}
              {entry.CONTACTTYPECODE === 'T' && <span>Telephone: </span>}
              {entry.CONTACTTYPECODE === 'F' && <span>Fax: </span>}
              {entry.CONTACTTYPECODE === 'I' && <span>Website: </span>}
              <span>{entry.CONTACTENTRY}</span>
            </div>
          ))}
        </>
      )}
    </>
  )
}

export {CompanyDetails}
