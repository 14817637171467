import {CaseSideEffectDto} from '../../../../../../../generated/model'
import moment from 'moment'
import countryList from 'react-select-country-list'

export enum SideEffectSelectionMode {
  Autocomplete,
  Selector,
}

export interface IManageSideEffect {
  // real entity
  sideEffect: CaseSideEffectDto

  // temp properties
  sideEffectTemp?: {key: string; value: string; label: string}

  startOfReactionTemp: string
  endOfReactionTemp?: string

  medWayOfAdministrationTemp?: {key: string; value: string; label: string}

  medStartOfTherapyTemp?: string
  medEndOfTherapyTemp?: string

  occuredInCountryTemp?: {key: string; value: string; label: string}

  // additional conditional properties
  editMode: boolean
  sideEffectSelectionMode: SideEffectSelectionMode
}

export function createInitialState(sideEffect?: CaseSideEffectDto): IManageSideEffect {
  return {
    sideEffectSelectionMode: SideEffectSelectionMode.Autocomplete,

    sideEffect:
      sideEffect != null
        ? JSON.parse(JSON.stringify(sideEffect)) // workaround: cannot assign read only property
        : {
            reaction: {startOfReaction: moment().utc().format()},
            medication: {},
            outcome: {},
          },
    editMode: sideEffect != null ? true : false,
    sideEffectTemp: {
      key: sideEffect?.lowLevelTermId!,
      value: sideEffect?.lowLevelTermId!,
      label: sideEffect?.lowLevelTermId!,
    },

    startOfReactionTemp:
      sideEffect != null ? sideEffect.reaction!.startOfReaction! : moment().utc().format(),
    endOfReactionTemp:
      sideEffect != null && sideEffect.reaction?.endOfReaction
        ? sideEffect.reaction?.endOfReaction
        : undefined,

    medStartOfTherapyTemp:
      sideEffect != null && sideEffect.medication?.startOfTherapy
        ? sideEffect.medication?.startOfTherapy
        : undefined,
    medEndOfTherapyTemp:
      sideEffect != null && sideEffect.medication?.endOfTherapy
        ? sideEffect.medication?.endOfTherapy
        : undefined,

    medWayOfAdministrationTemp: sideEffect
      ? {
          value: sideEffect?.medication?.wayOfAdministration!,
          label: sideEffect?.medication?.wayOfAdministration!,
          key: sideEffect?.medication?.wayOfAdministration!,
        }
      : undefined,

      occuredInCountryTemp: (sideEffect && sideEffect.reaction && sideEffect.reaction.occuredInCountry)
          ? {
              value: sideEffect?.reaction?.occuredInCountry!,
              label: countryList().getLabel(sideEffect?.reaction?.occuredInCountry!),
              key: sideEffect?.reaction?.occuredInCountry!,
          }
          : undefined,
  }
}
