/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {ErrorMessage, Field, FormikErrors, FormikTouched, FormikValues} from 'formik'
import {Link} from 'react-router-dom'

interface Props {
    errors: FormikErrors<FormikValues>
    touched: FormikTouched<FormikValues>
    values: FormikValues,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
}

export function Data(props: Props) {
    return (
        <>
            <div className='row fv-row mb-7'>
                <div className='col-xl-6'>
                    <label className='form-label fw-bolder text-dark fs-6'>First name</label>
                    <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        name='firstname'
                        placeholder='First Name'
                    />
                    <div className='fv-plugins-message-container invalid-feedback d-block'>
                        <ErrorMessage name='firstname'/>
                    </div>
                </div>
                <div className='col-xl-6'>
                    {/* begin::Form group Lastname */}
                    <label className='form-label fw-bolder text-dark fs-6'>Last name</label>

                    <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        name='lastname'
                        placeholder='Last Name'
                    />
                    <div className='fv-plugins-message-container invalid-feedback d-block'>
                        <ErrorMessage name='lastname'/>
                    </div>
                    {/* end::Form group */}
                </div>
            </div>

            <div className='fv-row mb-7'>
                <label className='form-label fw-bolder text-dark fs-6'>Email</label>

                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='email'
                    placeholder='Email'
                />
                <div className='fv-plugins-message-container invalid-feedback d-block'>
                    <ErrorMessage name='email'/>
                </div>
            </div>

            <div className='mb-10 fv-row' data-kt-password-meter='true'>
                <div className='mb-1'>
                    <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                    <Field
                        type='password'
                        className='form-control form-control-lg form-control-solid'
                        name='password'
                        placeholder='Password'
                    />
                    <div className='fv-plugins-message-container invalid-feedback d-block'>
                        <ErrorMessage name='password'/>
                    </div>
                </div>
            </div>
            <div className='fv-row mb-5'>
                <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
                <Field
                    type='password'
                    className='form-control form-control-lg form-control-solid'
                    name='passwordConfirmation'
                    placeholder='Password confirmation'
                />
                <div className='fv-plugins-message-container invalid-feedback d-block'>
                    <ErrorMessage name='passwordConfirmation'/>
                </div>
            </div>

            <div className='fv-row mb-10'>
                <div className='form-check form-check-custom form-check-solid'>
                    <Field
                        type='checkbox'
                        className='form-check-input'
                        name='acceptTerms'
                    />
                    <label
                        className='form-check-label fw-bold text-gray-700 fs-6'
                        htmlFor='kt_login_toc_agree'
                    >
                        I Agree the{' '}
                        <Link to='/auth/terms' className='ms-1 link-primary'>
                            terms and conditions
                        </Link>
                        .
                    </label>
                </div>
                <div className='fv-plugins-message-container invalid-feedback d-block'>
                    <ErrorMessage name='acceptTerms'/>
                </div>
            </div>

        </>
    )
}
