/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CaseSideEffectMedicationDto} from '../../../../../../../generated/model'
import {useRecoilValue} from "recoil";
import {useCaseState} from "../../../../State";
import {UseCase} from "../../../../Model";

interface Props {
  data: CaseSideEffectMedicationDto
}

const CaseEventSummaryMedication: React.FC<Props> = (props) => {
  const medicationData = props.data!
  const useCase = useRecoilValue(useCaseState)
  return (
    <>
      <div className='col-xl-12'>
        <div className='row flex-grow-1'>
          <div className='col-xl-4'>
            <div className='fs-7 text-muted fw-bold'>Start of Therapy</div>

            <div className='d-flex align-items-center'>
              <div className='fs-4'>{medicationData.startOfTherapy ?? '-'}</div>
            </div>
          </div>
          <div className='col-xl-4'>
            <div className='fs-7 text-muted fw-bold'>End of Therapy</div>

            <div className='d-flex align-items-center'>
              <div className='fs-4'>{medicationData.startOfTherapy ?? '-'}</div>
            </div>
          </div>
        </div>

        <div className='row flex-grow-1 my-3'>
          <div className='col-xl-12'>
            <div className='fs-7 text-muted fw-bold'>Indication Reason</div>

            <div className='d-flex align-items-center'>
              <div className='fs-4'>{medicationData.description ?? '-'}</div>
            </div>
          </div>
        </div>
        {(useCase === UseCase.Company && medicationData.descriptionTranslated && medicationData.descriptionTranslated.length > 0) && (
            <div className='row flex-grow-1 my-3'>
              <div className='col-xl-12'>
                <div className='fs-7 text-muted fw-bold'>Indication Reason (Translated)</div>

                <div className='d-flex align-items-center'>
                  <div className='fs-4'>{medicationData.descriptionTranslated ?? '-'}</div>
                </div>
              </div>
            </div>
        )}

        <div className='row flex-grow-1 my-3'>
          <div className='col-xl-4'>
            <div className='fs-7 text-muted fw-bold'>Dosage</div>

            <div className='d-flex align-items-center'>
              <div className='fs-4'>{medicationData.dosage ?? '-'}</div>
            </div>
          </div>
          <div className='col-xl-4'>
            <div className='fs-7 text-muted fw-bold'>Charge Number</div>

            <div className='d-flex align-items-center'>
              <div className='fs-4'>{medicationData.chargeNumber ?? '-'}</div>
            </div>
          </div>
          <div className='col-xl-4'>
            <div className='fs-7 text-muted fw-bold'>Way of Administration</div>

            <div className='d-flex align-items-center'>
              <div className='fs-4'>{medicationData.wayOfAdministration ?? '-'}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export {CaseEventSummaryMedication}
