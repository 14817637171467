/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {CaseSideEffectDto} from '../../../../../../../generated/model'
import {CaseEventSummaryMedication} from './CaseEventSummaryMedication'
import {CaseEventSummaryReaction} from './CaseEventSummaryReaction'
import {CaseEventSummaryOutcome} from './CaseEventSummaryOutcome'

interface Props {
  data: CaseSideEffectDto
}

export enum EventData {
  SIDE_EFFECT = 'SIDE_EFFECT',
  REACTION = 'REACTION',
  OUTCOME = 'OUTCOME',
}

const CaseEventSummaryDetails: React.FC<Props> = (props) => {
  const eventData = props.data!
  const [type, setType] = useState<EventData>(EventData.SIDE_EFFECT)
  return (
    <>
      <div className='row flex-grow-1'>
        <div className='col-xl-12'>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 ${
                  type === EventData.SIDE_EFFECT && 'active'
                }`}
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                  setType(EventData.SIDE_EFFECT)
                }}
              >
                Side Effect
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 ${
                  type === EventData.REACTION && 'active'
                }`}
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                  setType(EventData.REACTION)
                }}
              >
                Reaction
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 ${
                  type === EventData.OUTCOME && 'active'
                }`}
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                  setType(EventData.OUTCOME)
                }}
              >
                Outcome
              </a>
            </li>
          </ul>
        </div>
        <div className='d-flex align-items-center py-3 border-top'>
          {type === EventData.SIDE_EFFECT && (
            <CaseEventSummaryMedication data={eventData.medication!} />
          )}

          {type === EventData.REACTION && <CaseEventSummaryReaction data={eventData.reaction!} />}

          {type === EventData.OUTCOME && <CaseEventSummaryOutcome data={eventData.outcome!} />}
        </div>
      </div>
    </>
  )
}
export {CaseEventSummaryDetails}
