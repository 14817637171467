/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useAuth0} from "@auth0/auth0-react";
import {RegistrationShare} from "./RegistrationShare";
import {RegistrationLink} from "./RegistrationLink";


export function RegistrationShareWrapper() {
    const [loading, setLoading] = useState(true)
    const [loggedIn, setLoggedIn] = useState(false)

    const {getAccessTokenSilently} = useAuth0()
    useEffect(() => {
        const getAt = async () => {
            try {
                const token = await getAccessTokenSilently();
                if (token)
                    setLoggedIn(true);
            } catch (e) {
                setLoggedIn(false);
            }
            setLoading(false)
        }
        getAt()

    }, [getAccessTokenSilently])


    return (
        <>
            {!loading && !loggedIn &&
            <RegistrationShare/>
            }
            {!loading && loggedIn &&
            <RegistrationLink/>
            }
        </>
    )
}
