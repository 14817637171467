/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {
  CaseArchiveDto,
  CaseDto,
  CaseService,
} from '../../../../../../../../../../generated/model'
import {ErrorMessage, Form, Formik, FormikValues} from 'formik'
import * as Yup from 'yup'
import {Input} from 'formik-antd'
import {updateToken} from '../../../../../../../../../utils/AuthToken'
import {createErrorData, ErrorData} from '../../../../../../../../errors/model/ErrorData'
import {useAuth0} from '@auth0/auth0-react'
import {ErrorBoundary} from '../../../../../../../../errors/ErrorBoundary'

type Props = {
  case: CaseDto

  onSaved: () => void
}

interface ArchiveCaseModel {
  cse: CaseArchiveDto
}

function getInitialValues(cse: CaseDto): ArchiveCaseModel {
  return {
    cse: {
      comment: ''
    }
  }
}
const currentSchema = Yup.object().shape({})

const ArchiveForm: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ErrorData>()

  const {getAccessTokenSilently} = useAuth0()
  const submitData = async (values: ArchiveCaseModel, actions: FormikValues) => {
    try {
      setLoading(true)
      updateToken(await getAccessTokenSilently())

      await CaseService.archive(props.case.id!, values.cse)
      props.onSaved()

      setLoading(false)
    } catch (e) {
      setError(createErrorData(e))
      setLoading(false)
    }
  }

  return (
    <>
      <ErrorBoundary error={error} />
      <Formik
        validationSchema={currentSchema}
        initialValues={getInitialValues(props.case)}
        onSubmit={submitData}
      >
        {({errors, touched, values, setFieldValue, getFieldProps}) => (
          <Form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
          >
            <div className='mb-10 fv-row'>
              <label className='form-label mb-3'>Comment</label>

              <Input.TextArea rows={5} name='cse.comment' placeholder='' />
              <div className='fv-plugins-message-container invalid-feedback d-block'>
                <ErrorMessage name='cse.comment' />
              </div>
            </div>


            <div className='d-flex flex-stack pt-15'>
              <div className='mr-2' />
              <div>
                <button type='submit' className='btn btn-lg btn-primary me-3' disabled={loading}>
                  <span className='indicator-label'>Save</span>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export {ArchiveForm}
