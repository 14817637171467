import React, {FC} from 'react'
import {CaseDto} from '../../../../../generated/model'
import {CasesListCompany} from "./_mode_company/CasesListCompany";

interface Props {
  data: CaseDto[]
}

const CasesToSubmit: FC<Props> = (props) => {
  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      <div className='card-header border-0'>
        <h3 className='card-title fw-bolder text-dark'>Cases to export</h3>
      </div>
      <div className='card-body pt-0'>
          <CasesListCompany data={props.data}/>
      </div>
    </div>
  )
}

export {CasesToSubmit}
