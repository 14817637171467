import {User} from '@auth0/auth0-spa-js'

export interface UserModel {
  user?: User

  familyName?: string
  givenName?: string

  roles?: []
}

export function parseUser(user?: User): UserModel {
  return {
    user,
    familyName: user && user['http://medkomm.com/family_name'],
    givenName: user && user['http://medkomm.com/given_name'],

    roles: user && user['http://medkomm.com/roles'],
  }
}
