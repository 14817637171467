/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {CaseDto, CaseSideEffectDto} from '../../../../../../generated/model'
import {Form, Formik, FormikValues} from 'formik'
import {createInitialState, IManageSideEffect} from './helpers/Model'
import {
  createOutcomeDescription,
  createReactionDescription,
  getNextStep,
  getPrevStep,
  getSchemas,
} from './helpers/Utils'
import {StepSideEffect} from './steps/StepSideEffect'
import {StepReactionNarrative} from './steps/StepReactionNarrative'
import {StepReactionProperties} from './steps/StepReactionProperties'
import {StepOutcomeProperties} from './steps/StepOutcomeProperties'
import {StepOutcomeNarrative} from './steps/StepOutcomeNarrative'
import {StepSideEffectProperties} from './steps/StepSideEffectProperties'
import {useSetRecoilState} from 'recoil'
import {caseSideEffectStepState} from '../../State'
import {SideEffectStepType} from '../../management/_shared_helpers/Model'

type Props = {
  handleInsert: (sideEffect: CaseSideEffectDto) => void
  handleUpdate: (sideEffect: CaseSideEffectDto) => void
  close: () => void

  sideEffect?: CaseSideEffectDto
  case: CaseDto
}

const ManageSideEffect: React.FC<Props> = (props) => {
  const [currentStep, setCurrentStep] = useState<SideEffectStepType>(SideEffectStepType.SideEffect)
  const [currentSchema, setCurrentSchema] = useState(getSchemas(SideEffectStepType.SideEffect))
  const [initValues] = useState<IManageSideEffect>(createInitialState(props.sideEffect))
  const setCaseSideEffectStepState = useSetRecoilState(caseSideEffectStepState)

  const prevStep = () => {
    const newStep = getPrevStep(currentStep)
    setupStep(newStep)
  }
  const nextStep = () => {
    const newStep = getNextStep(currentStep)
    setupStep(newStep)
  }

  const setupStep = (step: SideEffectStepType) => {
    setCurrentStep(step)
    setCurrentSchema(getSchemas(step))
    setCaseSideEffectStepState(step)
  }

  const submitStep = async (values: FormikValues, actions: FormikValues) => {
    if (currentStep === SideEffectStepType.OutcomeNarrative) {
      // save
      if (props.sideEffect != null) props.handleUpdate(values.sideEffect)
      else props.handleInsert(values.sideEffect)
    } else if (currentStep === SideEffectStepType.ReactionProperties) {
      const cse = values['sideEffect']
      createReactionDescription(cse)

      actions.setFieldValue('sideEffect', cse)
    }

    if (currentStep === SideEffectStepType.OutcomeProperties) {
      const cse = values['sideEffect']
      createOutcomeDescription(cse)

      actions.setFieldValue('sideEffect', cse)
    }

    nextStep()
  }

  let btnText = 'Continue'
  if (currentStep === SideEffectStepType.OutcomeNarrative) btnText = 'Save'

  return (
    <>
      <div className='d-flex flex-row-fluid flex-center bg-white rounded'>
        <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
          {({errors, touched, values, setFieldValue, getFieldProps}) => (
            <Form className='py-10 w-100 w-xl-700px px-10' noValidate id='kt_create_account_form'>
              {currentStep === SideEffectStepType.SideEffect && (
                <div className='pb-5 current' data-kt-stepper-element='content'>
                  <StepSideEffect
                    errors={errors}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </div>
              )}
              {currentStep === SideEffectStepType.SideEffectProperties && (
                <div className='pb-5 current' data-kt-stepper-element='content'>
                  <StepSideEffectProperties
                    errors={errors}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    getFieldProps={getFieldProps}
                  />
                </div>
              )}
              {currentStep === SideEffectStepType.ReactionNarrative && (
                <div className='pb-5 current' data-kt-stepper-element='content'>
                  <StepReactionNarrative
                    errors={errors}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </div>
              )}

              {currentStep === SideEffectStepType.ReactionProperties && (
                <div className='pb-5 current' data-kt-stepper-element='content'>
                  <StepReactionProperties
                    errors={errors}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    getFieldProps={getFieldProps}
                  />
                </div>
              )}

              {currentStep === SideEffectStepType.OutcomeProperties && (
                <div className='pb-5 current' data-kt-stepper-element='content'>
                  <StepOutcomeProperties
                    errors={errors}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    getFieldProps={getFieldProps}
                  />
                </div>
              )}

              {currentStep === SideEffectStepType.OutcomeNarrative && (
                <div className='pb-5 current' data-kt-stepper-element='content'>
                  <StepOutcomeNarrative
                    errors={errors}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </div>
              )}
              <div className='d-flex justify-content-between pt-0'>
                <div className='mr-2'>
                  {currentStep !== SideEffectStepType.SideEffect && (
                    <button
                      onClick={prevStep}
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                    >
                      <KTSVG
                        path='/media/icons/duotone/Navigation/Left-2.svg'
                        className='svg-icon-4 me-1'
                      />
                      Back
                    </button>
                  )}
                </div>

                <div>
                  <button type='submit' className='btn btn-primary me-3'>
                    <span className='indicator-label'>
                      {btnText}
                      <KTSVG
                        path='/media/icons/duotone/Navigation/Right-2.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </span>
                  </button>
                </div>
              </div>
              {/*<FormikDebug style={{maxWidth: 400}}/>*/}
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

export {ManageSideEffect}
