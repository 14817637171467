/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Spin} from "antd";

export interface Props {
    loading: boolean,
    message?: string

}

const LoadingBoundary: React.FC<Props> = (props) => {
    return (
        <>
            {props.loading === true
                ? <div className='card' style={{justifyContent: "center", minHeight: 50, margin: 5}}>
                    <Spin size="default"/>
                </div>
                : props.children
            }
        </>
    )
}

export {LoadingBoundary}
