/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MeddraSystemOrganClassDto } from '../models/MeddraSystemOrganClassDto';
import type { MeddraSystemOrganClassUpdateDto } from '../models/MeddraSystemOrganClassUpdateDto';
import { request as __request } from '../core/request';

export class MeddraSystemOrganClassService {

    /**
     * @param language 
     * @returns MeddraSystemOrganClassDto Success
     * @throws ApiError
     */
    public static async getMeddraSystemOrganClasses(
language?: string,
): Promise<Array<MeddraSystemOrganClassDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/core/v1/meddra/systemOrganClass`,
            query: {
                'language': language,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async saveMeddraSystemOrganClass(
requestBody?: MeddraSystemOrganClassUpdateDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/core/v1/meddra/systemOrganClass`,
            body: requestBody,
        });
        return result.body;
    }

}