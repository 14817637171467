/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {CaseStepType} from '../_shared_helpers/Model'
import {CaseDto} from '../../../../../../generated/model'
import {StepReporterData} from './StepReporterData'

interface Props {
  setupStep: (stepType: CaseStepType) => void

  case: CaseDto
}

const SharedStepsReporterData: FC<Props> = (props) => {
  const cse = props.case
  const setupStep = props.setupStep
  return (
    <>
      <div className='current' data-kt-stepper-element='content'>
        <StepReporterData
          showReporters={() => setupStep(CaseStepType.Reporters)}
          handleInsert={(sd) => {
            if (!cse.caseReporters) cse.caseReporters = []
            cse.caseReporters!.push(sd)
            setupStep(CaseStepType.Reporters)
          }}
          handleUpdate={(sd) => {
            const effects = cse!.caseReporters!
            for (let i = 0; i < effects.length; i++) {
              if (effects[i].id === sd.id) {
                effects[i] = sd
                setupStep(CaseStepType.Reporters)
                return
              }
            }
          }}
        />
      </div>
    </>
  )
}

export {SharedStepsReporterData}
