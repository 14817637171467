/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'

import {CaseSideEffectDto, CaseSideEffectService} from '../../../../../../../../generated/model'
import {useAuth0} from "@auth0/auth0-react";
import {useParams} from "react-router-dom";
import {updateToken} from "../../../../../../../utils/AuthToken";
import {createErrorData, ErrorData} from "../../../../../../errors/model/ErrorData";
import { ErrorBoundary } from '../../../../../../errors/ErrorBoundary'
import { LoadingBoundary } from '../../../../../../_shared/LoadingBoundary'
import {Table} from "antd";
import {CaseEventSummaryDetails} from "../../../../_shared/components/case-event-summary/CaseEventSummaryDetails";
import {getEventColumns} from "./EventsCompanyColumns";

interface Props {
}

const EventsCompany: React.FC<Props> = (props) => {
  const [events, setEvents] = useState<CaseSideEffectDto[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<ErrorData>()

  const {getAccessTokenSilently} = useAuth0()
  const {id} = useParams<{id: string}>()

  const load = async () => {
    updateToken(await getAccessTokenSilently())

    try {
      setLoading(true)
      updateToken(await getAccessTokenSilently())

      const data = await CaseSideEffectService.getSideEffects(id!)
      setEvents(data)
      setLoading(false)
    } catch (e) {
      setError(createErrorData(e))
      setEvents(undefined)
      setLoading(false)
    }
  }

  useEffect(() => {
        load()
      }, // eslint-disable-next-line
      [getAccessTokenSilently, id])
  return (
      <LoadingBoundary loading={loading}>
        <ErrorBoundary error={error}>
    <div className={`card `}>
      <div className='card-header border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Events</span>
        </h3>
      </div>
      <div className='card-body py-3'>
      <Table
          dataSource={events}
          columns={getEventColumns()}
          rowKey='id'
          expandable={{
            expandedRowRender: (record) => <CaseEventSummaryDetails data={record} />,
            rowExpandable: (record) => true,
          }}
      />
    </div>
    </div>
        </ErrorBoundary>
      </LoadingBoundary>
  )
}

export {EventsCompany}
