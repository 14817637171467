/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Popover} from "antd";
import {CaseDto, } from "../../../../../../../../../generated/model";
import {ArchiveModal} from "./archive/ArchiveModal";

interface Props {
    reload: () => void,
    case: CaseDto
}

const ButtonArchive: React.FC<Props> = (props) => {
    const [showModal, setShowModal] = useState<boolean>(false)
  return (
      <>
          <ArchiveModal
              show={showModal}
              handleClose={() => setShowModal(false)}
              handleSaved={() => {
                  setShowModal(false);
                  props.reload();
              }}
              case={props.case!}
          />
      <Popover content={
          <div>
              <div>...</div>
              <div>...</div>
              <div>....</div>
          </div>
      } title="Archive">
          <button
              className='btn btn-sm btn-light-primary fw-bolder mx-2'
              onClick={() => setShowModal(true)}
          >
              Archive
          </button>
      </Popover>
      </>
  )
}

export {ButtonArchive}
