import {CaseReporterDto, ReporterDto, SerialDto} from '../../../../../../generated/model'
import {ReporterAccountType} from '../../management/_shared_helpers/Model'

export interface IManageReporter {
  // real entity
  caseReporter: CaseReporterDto

  // temp properties
  reporterAccountType: ReporterAccountType
  reporterTypeTemp: {
    key: string | undefined
    value: string | undefined
    label: string | undefined
  }

  reporterMe: ReporterDto
  reporterId: SerialDto

  // additional conditional properties
  editMode: boolean
}

export function createInitialState(
  reporterMe: ReporterDto,
  reporterId: SerialDto,
  caseReporter?: CaseReporterDto
): IManageReporter {
  if (caseReporter && caseReporter.reporter && !caseReporter.reporter.latestData)
    caseReporter.reporter.latestData = caseReporter.reporterData

  let reporterTypeTemp = {key: 'None', value: 'None', label: '-'}
  if (caseReporter && caseReporter.reporterType)
    reporterTypeTemp = {
      key: caseReporter.reporterType,
      value: caseReporter.reporterType,
      label: caseReporter.reporterType,
    }

  return {
    reporterAccountType: ReporterAccountType.Me,
    reporterId,
    reporterMe,
    reporterTypeTemp,

    caseReporter: caseReporter ?? caseReporterFromReporterMe(reporterMe),
    editMode: caseReporter != null,
  }
}

export const caseReporterFromReporterMe = (reporterMe: ReporterDto): CaseReporterDto => {
  if (!reporterMe.latestData) reporterMe.latestData = {}

  return {
    reporter: reporterMe,
    reporterData: reporterMe.latestData,
  }
}
