import React, {FC} from 'react'
import {CaseEventType, RecentCaseEventDto} from "../../../../../../generated/model";
import {ActivityDataTransition} from "./ActivityDataTransition";

interface Props {
  data: RecentCaseEventDto
}

const ActivityData: FC<Props> = (props) => {
  return (
      <>
          {props.data.event?.eventType === CaseEventType.TRANSITION && <ActivityDataTransition event={props.data.event}/>}
      </>
  )
}

export {ActivityData}
