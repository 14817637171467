/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Field, ErrorMessage, FormikErrors, FormikValues, FormikTouched} from 'formik'

interface Props {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  values: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const StepReporterNew: FC<Props> = (props) => {
  return (
    <>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Reporter ID</label>

        <label className='form-control form-control-lg '>
          {props.values['caseReporter'].serial.readableId}
        </label>

        <div className='form-text'>Patient ID will be generated automatically.</div>
      </div>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Custom Reporter Identifier</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='caseReporter.reporter.identifier'
          placeholder='Provide a meaningful patient identifier (Unique)'
        />
        <div className='form-text'>Provide custom reporter identifier if required.</div>
        <div className='fv-plugins-message-container invalid-feedback d-block'>
          <ErrorMessage name='patient.identifier' />
        </div>
      </div>
    </>
  )
}

export {StepReporterNew}
