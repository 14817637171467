/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {CaseStepType} from '../_shared_helpers/Model'
import {StepEventData} from './StepEventData'
import {CaseDto} from '../../../../../../generated/model'
import {useSetRecoilState} from 'recoil'
import {caseForDedupEventsState} from '../../State'
import {convertDedupEventsInfo} from '../new/helpers/Utils'

interface Props {
  setupStep: (stepType: CaseStepType) => void

  case: CaseDto
}

const SharedStepsEventData: FC<Props> = (props) => {
  const setCaseForDedupEventsState = useSetRecoilState(caseForDedupEventsState)

  const cse = props.case
  const setupStep = props.setupStep
  return (
    <>
      <div className='current' data-kt-stepper-element='content'>
        <StepEventData
          showSideEffects={() => setupStep(CaseStepType.Events)}
          handleInsert={(sd) => {
            if (!cse.caseSideEffects) cse.caseSideEffects = []
            cse.caseSideEffects!.push(sd)
            setupStep(CaseStepType.Events)
            setCaseForDedupEventsState(convertDedupEventsInfo(props.case))
          }}
          handleUpdate={(sd) => {
            const effects = cse!.caseSideEffects!
            for (let i = 0; i < effects.length; i++) {
              if (effects[i].id === sd.id) {
                effects[i] = sd
                setupStep(CaseStepType.Events)
                setCaseForDedupEventsState(convertDedupEventsInfo(props.case))
                return
              }
            }
          }}
        />
      </div>
    </>
  )
}

export {SharedStepsEventData}
