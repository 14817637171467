/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, Key, useState} from 'react'
import {CaseStatus} from '../../../../../../../generated/model'
import {Select} from 'antd'
import {useRecoilValue} from 'recoil'
import {useCaseState} from '../../../../State'
import {UseCase} from '../../../../Model'
import {CompanyStatus, DoctorStatus} from './Model'
import {getCaseStatusName} from '../../helpers/CasesStatusHelpers'

interface Props {
  onSelected: (status: CaseStatus[]) => void
  selected: CaseStatus[]
  onClear: () => void
}

const CaseStatusSelector: FC<Props> = (props) => {
  const [selected, setSelected] = useState<
    {
      key: string
      value: string
      label: string
    }[]
  >(
    props.selected
      ? props.selected.map((x) => {
          return {key: x, value: x, label: x}
        })
      : []
  )
  const onSelect = (value: any, option: {key?: Key | undefined}) => {
    const status = option.key as CaseStatus
    selected.push({key: status, value: status, label: getCaseStatusName(status)})
    setSelected(selected)
    props.onSelected(selected.map((x) => x.key as CaseStatus))
  }
  const onDeselect = (value: any, option: {key?: Key | undefined}) => {
    const status = option.key as CaseStatus
    const index = selected.map((x) => x.key).indexOf(status)
    selected.splice(index, 1)
    setSelected(selected)
    props.onSelected(selected.map((x) => x.key as CaseStatus))
  }
  const useCase = useRecoilValue(useCaseState)
  const values = Object.values(useCase === UseCase.Company ? CompanyStatus : DoctorStatus).sort()
  return (
    <Select
      optionLabelProp='label'
      onSelect={onSelect}
      onDeselect={onDeselect}
      filterOption={false}
      mode={'multiple'}
      placeholder='Select status'
      style={{width: '100%'}}
      allowClear
      defaultValue={selected.sort((a, b) => a.key.localeCompare(b.key)).map((x) => x.key)}
      onClear={() => {
        setSelected([])
        props.onClear()
      }}
      maxTagCount={'responsive'}
    >
      {values.map((element: CaseStatus) => (
        <Select.Option key={element} value={element} label={getCaseStatusName(element)}>
          {getCaseStatusName(element)}
        </Select.Option>
      ))}
    </Select>
  )
}

export {CaseStatusSelector}
