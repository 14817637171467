/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import moment from 'moment'
import {SortOrder} from 'antd/es/table/interface'
import {CaseDto, CaseSource} from '../../../../../../generated/model'
import {CaseIdentifier} from '../../_shared/components/case-identifier/CaseIdentifier'
import {PatientIdentifier} from '../../_shared/components/patient-identifier/PatientIdentifier'

export default function getColumnsCompany(foundDuplicate: (dto: CaseDto) => void) {
  return [
    {
      title: 'Updated At',
      dataIndex: '.',
      key: 'modifiedAt',
      sorter: (a: CaseDto, b: CaseDto) => moment(a.modifiedAt).unix() - moment(b.modifiedAt).unix(),
      defaultSortOrder: 'descend' as SortOrder,
      render: (text: string, record: CaseDto) => (
        <span>{moment(record.modifiedAt).format('DD.MM.yyyy')}</span>
      ),
    },
    {
      title: 'Case Id',
      dataIndex: '.',
      key: 'identifier',
      render: (text: string, record: CaseDto) => (
        <a
          href='#'
          onClick={(e) => {
            e.preventDefault()
            //detailsCaseClick(record.id!)
          }}
        >
          <>
            {record.source === CaseSource.DOCTOR && <span>{record?.serial?.globalId}</span>}
            {record.source === CaseSource.COMPANY && <CaseIdentifier data={record} />}
          </>
        </a>
      ),
    },
    {
      title: 'Patient',
      dataIndex: '.',
      key: 'patient',
      render: (text: string, record: CaseDto) => (
        <>
          {record.source === CaseSource.DOCTOR && <span>{record.patient?.serial?.globalId}</span>}
          {record.source === CaseSource.COMPANY && <PatientIdentifier data={record.patient!} />}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a: CaseDto, b: CaseDto) =>
        a!.status!.toString().localeCompare(b!.status!.toString()),
      render: (text: string) => <span className='badge badge-light-info'>{text}</span>,
      sortDirections: ['ascend' as SortOrder, 'descend' as SortOrder],
    },
    {
      title: 'Action',
      dataIndex: '.',
      key: 'action',
      render: (text: string, record: CaseDto) =>
          <a href='#' onClick={(e) => {
          e.preventDefault(); foundDuplicate(record)}
          } className='btn btn-primary btn-sm me-2'>
            Found Duplicate
          </a>
    },
  ]
}
