/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MeddraPreferedTermDto } from '../models/MeddraPreferedTermDto';
import type { MeddraPreferedTermUpdateDto } from '../models/MeddraPreferedTermUpdateDto';
import { request as __request } from '../core/request';

export class MeddraPreferedTermService {

    /**
     * @param hltId 
     * @param language 
     * @returns MeddraPreferedTermDto Success
     * @throws ApiError
     */
    public static async getMeddraPreferedTerm(
hltId?: string,
language?: string,
): Promise<Array<MeddraPreferedTermDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/core/v1/meddra/preferedTerm`,
            query: {
                'hltId': hltId,
                'language': language,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async saveMeddraPreferedTerm(
requestBody?: MeddraPreferedTermUpdateDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/core/v1/meddra/preferedTerm`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async saveMeddraPreferedTermBatch(
requestBody?: Array<MeddraPreferedTermUpdateDto>,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/core/v1/meddra/preferedTerm/batch`,
            body: requestBody,
        });
        return result.body;
    }

}