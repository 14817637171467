/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {ErrorMessage, FormikErrors, FormikValues, FormikTouched} from 'formik'
import {Input, Select} from 'formik-antd'
import {Spin} from 'antd'
import {useAuth0} from '@auth0/auth0-react'
import {PatientDto, PatientService} from '../../../../../../../generated/model'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {updateToken} from '../../../../../../utils/AuthToken'
import {PatientIdentifier} from '../../../_shared/components/patient-identifier/PatientIdentifier'
import {PatientAccountType} from '../../_shared_helpers/Model'
import moment from 'moment'

interface Props {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  values: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const StepPatientId: FC<Props> = (props) => {
  const [fetching, setFetching] = useState<boolean>(false)
  const [options, setOptions] = useState<PatientDto[]>([])
  const {getAccessTokenSilently} = useAuth0()
  const onSearch = async (filter: string) => {
    setFetching(true)

    updateToken(await getAccessTokenSilently())

    const result = await PatientService.autocompletePatients(filter)
    setOptions(result)
    setFetching(false)
  }

  function validatePatient(value: {key: string}) {
    let error
    if (props.values['patientAccountType'] === PatientAccountType.Existing) {
      if (!value || !value.key || value.key === '') {
        error = 'Patient is required!'
      }
    }
    return error
  }
  const onChange = (type: PatientAccountType) => {
    const cse = props.values['case']

    if (type === PatientAccountType.New) {
      cse.patient.serial = props.values.patientId
    } else if (type === PatientAccountType.Existing) {
      cse.patient = {}
      props.setFieldValue('cse', cse)
    }

    props.setFieldValue('patientAccountType', type)
  }

  const onSelect = (value: any, option: any) => {
    const key = option.key
    const selected = options.filter((x) => x.id === key)[0]

    const cse = props.values['case']
    cse.patient = selected
    if (cse?.patient?.latestData?.birthday) {
      props.setFieldValue('birthdayTemp', moment(cse.patient.latestData.birthday).utc())
    }

    props.setFieldValue('case', cse)

    let sexTemp = props.values['sexTemp']
    if (!sexTemp) sexTemp = {}

    sexTemp.value = cse.patient.latestData?.sex
    sexTemp.label = cse.patient.latestData?.sex
    sexTemp.key = cse.patient.latestData?.sex
    props.setFieldValue('sexTemp', sexTemp)
  }

  return (
    <div className='mx-auto mw-600px w-100'>
      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-6'>
            <Input
              type='radio'
              className='btn-check'
              name='patientAccountType'
              checked={props.values.patientAccountType === PatientAccountType.Existing}
              onChange={() => onChange(PatientAccountType.Existing)}
              value={PatientAccountType.Existing}
              id='kt_create_account_form_account_type_personal'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
              htmlFor='kt_create_account_form_account_type_personal'
            >
              <KTSVG path='/media/icons/duotone/Interface/User.svg' className='svg-icon-3x me-5' />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>Existing Patient</span>
              </span>
            </label>
          </div>

          <div className='col-lg-6'>
            <Input
              type='radio'
              className='btn-check'
              name='patientAccountType'
              checked={props.values.patientAccountType === PatientAccountType.New}
              onChange={() => onChange(PatientAccountType.New)}
              value={PatientAccountType.New}
              id='kt_create_account_form_account_type_corporate'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center'
              htmlFor='kt_create_account_form_account_type_corporate'
            >
              <KTSVG
                path='/media/icons/duotone/Interface/Briefcase.svg'
                className='svg-icon-3x me-5'
              />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>New Patient</span>
              </span>
            </label>
          </div>

          <div className='fv-plugins-message-container invalid-feedback'>
            <ErrorMessage name='patientAccountType' />
          </div>
        </div>
      </div>

      {props.values['patientAccountType'] === PatientAccountType.Existing && (
        <>
          <div className='mb-10 fv-row'>
            <label className='form-label mb-3 required'>Patient</label>

            <Select
              validate={validatePatient}
              name='patient'
              labelInValue
              optionLabelProp='label'
              filterOption={false}
              onSearch={onSearch}
              onSelect={onSelect}
              placeholder='Select patient'
              style={{width: '100%'}}
              showSearch
              notFoundContent={fetching ? <Spin size='small' /> : null}
            >
              {options.map((element: PatientDto) => (
                <Select.Option
                  key={element.id!}
                  value={element.id!}
                  label={element.serial?.readableId}
                >
                  <PatientIdentifier data={element} />
                </Select.Option>
              ))}
            </Select>
            <div className='form-text'>Select an existing Patient (via ID or Identifier)</div>
            <div className='fv-plugins-message-container invalid-feedback d-block'>
              <ErrorMessage name='patient' />
            </div>
          </div>
        </>
      )}

      {props.values['patientAccountType'] === PatientAccountType.New && (
        <>
          <div className='mb-10 fv-row'>
            <label className='form-label mb-3 required'>Patient ID</label>

            <label className='form-control'>{props.values['case'].patient.serial.readableId}</label>

            <div className='form-text'>Patient ID will be generated automatically.</div>
          </div>
          <div className='mb-10 fv-row'>
            <label className='form-label mb-3'>Custom Patient Identifier</label>

            <Input
              type='text'
              name='case.patient.identifier'
              placeholder='Provide a meaningful patient identifier (Unique)'
            />
            <div className='form-text'>Provide custom patient identifier if required.</div>
            <div className='fv-plugins-message-container invalid-feedback d-block'>
              <ErrorMessage name='case.patient.identifier' />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export {StepPatientId}
