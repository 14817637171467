import React from 'react'
import {Tag} from 'antd'
import {useRecoilValue} from 'recoil'
import {caseDedupPatientState, caseForDedupPatientState} from '../../../../State'
import {isValid} from './CaseDedupPatientInfoHelper'

type Props = {}

const CaseDedupPatientInfoData: React.FC<Props> = (props) => {
  const data = useRecoilValue(caseDedupPatientState)
  const dataForDedup = useRecoilValue(caseForDedupPatientState)

  return (
    <>
      {isValid(dataForDedup) ? (
        <span className='text-muted mt-1 fw-bold fs-7'>
          <span>
            Cases with same <b>medicine</b> and similar <b>patient data</b>:{' '}
          </span>
          <span>
            Last 2 days: <Tag>{data?.cases2dCnt}</Tag>
          </span>
          <span>
            Last 7 days: <Tag>{data?.cases7dCnt}</Tag>
          </span>
          <span>
            Total: <Tag>{data?.casesAllCnt}</Tag>
          </span>
        </span>
      ) : (
        <span className='text-muted mt-1 fw-bold fs-7'>
          Please provide at least Birthday and Sex.
        </span>
      )}
    </>
  )
}
export {CaseDedupPatientInfoData}
