/* eslint-disable jsx-a11y/anchor-is-valid */

export function getEventColumns(): any[] {
  return [
    {
      title: 'Side Effect',
      dataIndex: '.',
      key: 'sideEffectName',
      render: (text: string, record: any) => {
        return (
          <span>
            {record?.lowLevelTerm?.name}[{record?.lowLevelTerm?.code}]
          </span>
        )
      },
    },
  ]
}
