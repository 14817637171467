/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useRef, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'

import {Form, Formik, FormikValues} from 'formik'
import {StepCommonDoctor} from './steps/StepCommonDoctor'
import {createInitialState, ICreateCase} from './helpers/Model'
import {useAuth0} from '@auth0/auth0-react'
import {
  convertDedupEventsInfo,
  convertDedupPatientInfo,
  getNextStep,
  getPrevStep,
  getSchemas,
} from './helpers/Utils'
import {save, saveDuplicate} from './helpers/Api'
import {useHistory} from 'react-router-dom'
import {ErrorBoundary} from '../../../../errors/ErrorBoundary'
import {createErrorData, ErrorData} from '../../../../errors/model/ErrorData'
import {CaseDto, CaseStatus, Serial} from '../../../../../../generated/model'
import {useLocation} from 'react-router'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {
  caseDedupDuplicateFoundState,
  caseForDedupEventsState,
  caseForDedupMedicineState,
  caseForDedupPatientState,
  caseStepState,
} from '../../State'
import {FormikProps} from 'formik/dist/types'
import {CaseStepType} from '../_shared_helpers/Model'
import {useCaseState} from '../../../State'
import {SharedSteps} from '../_shared_steps/_SharedSteps'
import {SharedStepsEventData} from '../_shared_steps/_SharedStepsEventData'
import {StepPatientId} from './steps/StepPatientId'
import {StepDuplicate} from './steps/StepDuplicate'
import {UseCase} from '../../../Model'
import {StepCommonCompany} from './steps/StepCommonCompany'
import {SharedStepsReporterData} from '../_shared_steps/_SharedStepsReporterData'

interface Props {
  caseId: Serial
  patientId: Serial
}

const NewCase: React.FC<Props> = (props) => {
  const currentStep = useRecoilValue(caseStepState)
  const useCase = useRecoilValue(useCaseState)
  const [currentSchema, setCurrentSchema] = useState(getSchemas(CaseStepType.Common))
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ErrorData>()

  const setCaseForDedupMedicineState = useSetRecoilState(caseForDedupMedicineState)
  const setCaseForDedupPatientState = useSetRecoilState(caseForDedupPatientState)
  const setCaseForDedupEventsState = useSetRecoilState(caseForDedupEventsState)
  const setCaseStepState = useSetRecoilState(caseStepState)

  const caseDedupDuplicateFound = useRecoilValue(caseDedupDuplicateFoundState)

  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const inputId = query.get('inputId')

  const [initValues] = useState<ICreateCase>(
    createInitialState(props.caseId, props.patientId, inputId)
  )
  const prevStep = (values: ICreateCase) => {
    const newStep = getPrevStep(currentStep, values, useCase)
    setupStep(newStep, values)
  }
  const nextStep = (values: ICreateCase) => {
    const newStep = getNextStep(currentStep, values, useCase)
    setupStep(newStep, values)
  }

  const setupStep = (step: CaseStepType, values: ICreateCase) => {
    setCurrentSchema(getSchemas(step))
    setDeduplicationState(step, values)
    setCaseStepState(step)
  }

  const setDeduplicationState = (step: CaseStepType, values: ICreateCase) => {
    switch (step) {
      case CaseStepType.Common:
        setCaseForDedupPatientState(undefined)
        setCaseForDedupEventsState(undefined)
        break
      case CaseStepType.PatientId:
        setCaseForDedupMedicineState({medicineId: values.case.medicineId})
        setCaseForDedupPatientState(undefined)
        setCaseForDedupEventsState(undefined)
        break
      case CaseStepType.PatientData:
        setCaseForDedupPatientState(convertDedupPatientInfo(values.case))
        setCaseForDedupEventsState(undefined)
        break
      case CaseStepType.Reporters:
        break
      case CaseStepType.Events:
        setCaseForDedupEventsState(convertDedupEventsInfo(values.case))
        break
    }
  }

  const {getAccessTokenSilently} = useAuth0()
  const history = useHistory()
  const submitStep = async (values: ICreateCase, actions: FormikValues) => {
    if (useCase === UseCase.Doctor && currentStep === CaseStepType.Events) {
      await saveCase(values, actions)
    } else if (useCase === UseCase.Company && currentStep === CaseStepType.Evaluation) {
      await saveCase(values, actions)
    } else if (currentStep === CaseStepType.Duplicate) {
      await saveCaseDuplicate(values, caseDedupDuplicateFound!)
    } else {
      nextStep(values)
    }
  }
  const saveCase = async (values: ICreateCase, actions: FormikValues) => {
    try {
      setLoading(true)
      const result = await save(values, actions, await getAccessTokenSilently())

      history.push(`/cases/details/${result.id}`)
    } catch (e) {
      setError(createErrorData(e))
      setLoading(false)
    }
  }
  const saveCaseDuplicate = async (values: ICreateCase, duplicate: CaseDto) => {
    try {
      setLoading(true)
      await saveDuplicate(values, duplicate, await getAccessTokenSilently())
      if (
        duplicate.status === CaseStatus.CREATED_COMPANY ||
        duplicate.status === CaseStatus.ACCEPTED ||
        duplicate.status === CaseStatus.IN_REVIEW ||
        duplicate.status === CaseStatus.EXPORTED
      ) {
        history.push(`/cases/edit/${duplicate.id}`)
      } else {
        history.push(`/cases/details/${duplicate.id}`)
      }
    } catch (e) {
      setError(createErrorData(e))
      setLoading(false)
    }
  }

  let btnText = 'Continue'
  if (currentStep === CaseStepType.Duplicate) btnText = 'Save and Edit Reference'
  if (useCase === UseCase.Doctor && currentStep === CaseStepType.Events) btnText = 'Save'
  if (useCase === UseCase.Company && currentStep === CaseStepType.Evaluation) btnText = 'Save'

  const formRef: React.Ref<FormikProps<ICreateCase>> = useRef(null)

  return (
    <>
      <div className='card'>
        <ErrorBoundary error={error}></ErrorBoundary>

        <div className='card-body'>
          <Formik
            validationSchema={currentSchema}
            initialValues={initValues}
            onSubmit={submitStep}
            enableReinitialize={true}
            innerRef={formRef}
          >
            {({errors, touched, values, setFieldValue, getFieldProps}) => (
              <Form
                className={`mx-auto mw-800px w-100 pt-0 pb-0`}
                noValidate
                id='kt_create_case_form'
              >
                {currentStep === CaseStepType.Common && (
                  <div className='current' data-kt-stepper-element='content'>
                    {useCase === UseCase.Doctor && (
                      <StepCommonDoctor
                        errors={errors}
                        touched={touched}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    {useCase === UseCase.Company && (
                      <StepCommonCompany
                        errors={errors}
                        touched={touched}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </div>
                )}

                {currentStep === CaseStepType.PatientId && (
                  <div className='current' data-kt-stepper-element='content'>
                    <StepPatientId
                      errors={errors}
                      touched={touched}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                )}
                {currentStep === CaseStepType.Duplicate && (
                  <div className='current' data-kt-stepper-element='content'>
                    <StepDuplicate
                      errors={errors}
                      touched={touched}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                )}

                <SharedSteps
                  errors={errors}
                  touched={touched}
                  values={values}
                  setFieldValue={setFieldValue}
                  getFieldProps={getFieldProps}
                  currentStep={currentStep}
                  setupStep={(stepType) => setupStep(stepType, values)}
                />

                {currentStep !== CaseStepType.EventData &&
                  currentStep !== CaseStepType.ReporterData && (
                    <div className='d-flex flex-stack pt-15'>
                      <div className='mr-2'>
                        {currentStep !== CaseStepType.Common && (
                          <button
                            onClick={() => prevStep(values)}
                            type='button'
                            className='btn btn-light-primary me-3'
                          >
                            <KTSVG
                              path='/media/icons/duotone/Navigation/Left-2.svg'
                              className='svg-icon-4 me-1'
                            />
                            Back
                          </button>
                        )}
                      </div>

                      <div>
                        <button type='submit' className='btn btn-primary me-3' disabled={loading}>
                          <span className='indicator-label'>
                            {btnText}
                            <KTSVG
                              path='/media/icons/duotone/Navigation/Right-2.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                          </span>
                        </button>
                      </div>
                    </div>
                  )}

                {/*<FormikDebug style={{maxWidth: 400}}/>*/}
              </Form>
            )}
          </Formik>

          {currentStep === CaseStepType.EventData && (
            <SharedStepsEventData
              case={formRef.current!.values.case!}
              setupStep={(stepType) => setupStep(stepType, formRef.current!.values)}
            />
          )}
          {currentStep === CaseStepType.ReporterData && (
            <SharedStepsReporterData
              case={formRef.current!.values.case!}
              setupStep={(stepType) => setupStep(stepType, formRef.current!.values)}
            />
          )}
        </div>
      </div>
    </>
  )
}

export {NewCase}
