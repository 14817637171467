/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useLayout} from '../core'

import {useRecoilValue} from 'recoil'
import {footerState} from '../../../app/modules/State'

const Footer: FC = () => {
  const {classes} = useLayout()
  const footer = useRecoilValue(footerState)
  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className='text-dark order-2 order-md-1'>
          <span className='text-muted fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
          <a href='#' className='text-gray-800 text-hover-primary'>
            InnoTeam{footer.product && <span> - {footer.product} -</span>}
            {footer.mode && <span> Mode: {footer.mode}</span>}
          </a>
        </div>

        <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
          <li className='menu-item'>
            <a href='#' className='menu-link ps-0 pe-2'>
              About
            </a>
          </li>
          <li className='menu-item'>
            <a href='#' className='menu-link pe-0 pe-2'>
              Contact
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export {Footer}
