/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useAuth0} from "@auth0/auth0-react";
import {updateToken} from "../../../../../../utils/AuthToken";
import {CaseDeduplicationService, CaseDedupMedicineRequestType, CaseDto} from "../../../../../../../generated/model";
import {CasesList} from "../../_shared/CasesList";

type Props = {
    medicineId: string
    foundDuplicate: (dto: CaseDto) => void
}

const DedupMedicineList: React.FC<Props> = (props) => {
    const [loading, setLoading] = useState<boolean>(true)
    const [type, setType] = useState<CaseDedupMedicineRequestType>(CaseDedupMedicineRequestType.LAST2DAYS)
    const [data, setData] = useState<CaseDto[]>()

    const {getAccessTokenSilently} = useAuth0()

    const load = async (type: CaseDedupMedicineRequestType) => {
        setLoading(true)
        updateToken(await getAccessTokenSilently());
        setType(type);

        const result = await CaseDeduplicationService.caseDedupMedicine({medicineId: props.medicineId, type: type});
        setData(result.cases!);
        setLoading(false)
    }
    useEffect(() => {
        load(CaseDedupMedicineRequestType.LAST2DAYS)
    }, [getAccessTokenSilently])

    return (
        <div className='card'>
            <div className='card-header' style={{minHeight: 40, padding: 0}}>
                <h3 className='align-items-start flex-column'>
                    <span className='text-muted fw-bold fs-6'>Cases with same <b>medicine</b></span>
                </h3>
                <div>
                    <ul className='nav'>
                        <li className='nav-item'>
                            <a
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 ${type === CaseDedupMedicineRequestType.LAST2DAYS && 'active'}`}
                                href='#'
                                onClick={(e) => {
                                    e.preventDefault();
                                    load(CaseDedupMedicineRequestType.LAST2DAYS)
                                }}
                            >
                                Last 2 Days
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 ${type === CaseDedupMedicineRequestType.LAST7DAYS && 'active'}`}
                                href='#'
                                onClick={(e) => {
                                    e.preventDefault();
                                    load(CaseDedupMedicineRequestType.LAST7DAYS)
                                }}
                            >
                                Last 7 Days
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 ${type === CaseDedupMedicineRequestType.ALL && 'active'}`}
                                href='#'
                                onClick={(e) => {
                                    e.preventDefault();
                                    load(CaseDedupMedicineRequestType.ALL)
                                }}
                            >
                                All
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='card-body py-3' style={{minHeight: 200, padding: 0}}>
                <CasesList data={data!} loading={loading} foundDuplicate={props.foundDuplicate}/>
            </div>
        </div>
    )
}

export {DedupMedicineList}
