/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CaseSideEffectReactionDto} from '../../../../../../../generated/model'
import {UseCase} from "../../../../Model";
import {useRecoilValue} from "recoil";
import {useCaseState} from "../../../../State";

interface Props {
  data: CaseSideEffectReactionDto
}

const CaseEventSummaryReaction: React.FC<Props> = (props) => {
  const medicationData = props.data!
  const useCase = useRecoilValue(useCaseState)
  return (
    <>
      <div className='col-xl-12'>
        <div className='row flex-grow-1'>
          <div className='col-xl-4'>
            <div className='fs-7 text-muted fw-bold'>Start of Reaction</div>

            <div className='d-flex align-items-center'>
              <div className='fs-4'>{medicationData.startOfReaction ?? '-'}</div>
            </div>
          </div>
          <div className='col-xl-4'>
            <div className='fs-7 text-muted fw-bold'>End of Reaction</div>

            <div className='d-flex align-items-center'>
              <div className='fs-4'>{medicationData.endOfReaction ?? '-'}</div>
            </div>
          </div>
          <div className='col-xl-4'>
            <div className='fs-7 text-muted fw-bold'>Cccured in country</div>

            <div className='d-flex align-items-center'>
              <div className='fs-4'>{medicationData.occuredInCountry ?? '-'}</div>
            </div>
          </div>
        </div>

        <div className='row flex-grow-1 my-3'>
          <div className='col-xl-4'>
            <div className='fs-7 text-muted fw-bold'>Hospitalisation</div>

            <div className='d-flex align-items-center'>
              <div className='fs-4'>{medicationData.hospitalisation ? 'yes' : 'no'}</div>
            </div>
          </div>
          <div className='col-xl-4'>
            <div className='fs-7 text-muted fw-bold'>Life Threatening</div>

            <div className='d-flex align-items-center'>
              <div className='fs-4'>{medicationData.lifeThreatening ? 'yes' : 'no'}</div>
            </div>
          </div>
          <div className='col-xl-4'>
            <div className='fs-7 text-muted fw-bold'>Incapacitating</div>

            <div className='d-flex align-items-center'>
              <div className='fs-4'>{medicationData.incapacitating ? 'yes' : 'no'}</div>
            </div>
          </div>
        </div>
        <div className='row flex-grow-1 my-3'>
          <div className='col-xl-8'>
            <div className='fs-7 text-muted fw-bold'>Other medically important condition</div>

            <div className='d-flex align-items-center'>
              <div className='fs-4'>
                {medicationData.otherMedicallyImportantCondition ? 'yes' : 'no'}
              </div>
            </div>
          </div>
          <div className='col-xl-4'>
            <div className='fs-7 text-muted fw-bold'>Birth Defect</div>

            <div className='d-flex align-items-center'>
              <div className='fs-4'>{medicationData.birthDefect ? 'yes' : 'no'}</div>
            </div>
          </div>
        </div>
        <div className='row flex-grow-1 my-3'>
          <div className='col-xl-12'>
            <div className='fs-7 text-muted fw-bold'>Additional Information</div>

            <div className='d-flex align-items-center'>
              <div className='fs-4'>{medicationData.description ?? '-'}</div>
            </div>
          </div>
        </div>
        {(useCase === UseCase.Company &&medicationData.descriptionTranslated && medicationData.descriptionTranslated.length > 0) && (
          <div className='row flex-grow-1 my-3'>
            <div className='col-xl-12'>
              <div className='fs-7 text-muted fw-bold'>Additional Information (Translated)</div>

              <div className='d-flex align-items-center'>
                <div className='fs-4'>{medicationData.descriptionTranslated ?? '-'}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
export {CaseEventSummaryReaction}
