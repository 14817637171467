/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {CaseDto, CaseShareStatus, CaseStatus} from '../../../../../../../generated/model'
import {HeaderActions} from './HeaderActions'
import {HeaderNav} from './HeaderNav'
import {HeaderShared} from './HeaderShared'
import {HeaderData} from './HeaderData'
import {CaseDedupCaseInfo} from '../../../_shared/components/case-deduplication-info/standalone/CaseDedupCaseInfo'
import {useRecoilValue} from 'recoil'
import {useCaseState} from '../../../../State'
import {UseCase} from '../../../../Model'

interface Props {
  case: CaseDto
}

const DetailsCaseHeader: React.FC<Props> = (props) => {
  const useCase = useRecoilValue(useCaseState)
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <HeaderData case={props.case} />

        {props.case.shareStatus === CaseShareStatus.SHARED && <HeaderShared case={props.case} />}
        {useCase === UseCase.Company &&
          (props.case.status === CaseStatus.SUBMITTED ||
            props.case.status === CaseStatus.ACCEPTED) && (
            <CaseDedupCaseInfo caseId={props.case.id!} />
          )}

        <HeaderActions />
        <HeaderNav />
      </div>
    </div>
  )
}

export {DetailsCaseHeader}
