import {CaseSource} from '../../../generated/model'

export enum UseCase {
  Unknown = 'Unknown',
  Doctor = 'Doctor',
  Company = 'Company',
}

export const convertUseCaseToSource = (useCase: UseCase): CaseSource | undefined => {
  switch (useCase) {
    case UseCase.Unknown:
      return undefined
    case UseCase.Doctor:
      return CaseSource.DOCTOR
    case UseCase.Company:
      return CaseSource.COMPANY
  }
}
