/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {CSSProperties} from 'react'
import {Tooltip} from 'antd'
import {CaseDto, CaseSource} from '../../../../../../generated/model'
import {CaseIdentifier} from '../../../cases/_shared/components/case-identifier/CaseIdentifier'
import ColumnMedicineStyle from '../../../cases/_shared/css/ColumnMedicine'
import moment from 'moment'
import {SortOrder} from 'antd/es/table/interface'
import {calculateRemainingTime, calculateRemainingTimeStatus, RemainingTimeStatus} from './Utils'
import {ReportersIdentifier} from '../../../cases/_shared/components/reporters-identifier/ReportersIdentifier'

const RemainingTimeStatusOverdueStyle: CSSProperties = {
  color: 'red',
  fontStyle: 'normal',
  fontWeight: 'bold',
}
const RemainingTimeStatusCloseStyle: CSSProperties = {
  color: 'red',
  fontStyle: 'normal',
}
const RemainingTimeStatusNearingStyle: CSSProperties = {
  color: 'blue',
  fontStyle: 'normal',
}
const RemainingTimeStatusFarStyle: CSSProperties = {
  fontStyle: 'normal',
}

export default function getColumnsCompany(detailsCaseClick: (id: string) => void) {
  return [
    {
      title: 'Case Id',
      dataIndex: '.',
      key: 'identifier',
      render: (text: string, record: CaseDto) => (
        <a
          href={`/cases/details/${record.id}`}
          onClick={(e) => {
            e.preventDefault()
            detailsCaseClick(record.id!)
          }}
        >
          <>
            {record.source === CaseSource.DOCTOR && <span>{record?.serial?.globalId}</span>}
            {record.source === CaseSource.COMPANY && <CaseIdentifier data={record} />}
          </>
        </a>
      ),
    },
    {
      title: 'Updated At',
      dataIndex: '.',
      key: 'modifiedAt',
      sorter: (a: CaseDto, b: CaseDto) => moment(a.modifiedAt).unix() - moment(b.modifiedAt).unix(),
      defaultSortOrder: 'descend' as SortOrder,
      render: (text: string, record: CaseDto) => (
        <span>{moment(record.modifiedAt).format('DD.MM.yyyy HH:mm')}</span>
      ),
      ellipsis: true,
      width: 150,
    },
    {
      title: () => <Tooltip title='Internal Deadline'>Internal Deadline</Tooltip>,
      dataIndex: '.',
      key: 'submitDeadline',
      sorter: (a: CaseDto, b: CaseDto) =>
        moment(a.submitDeadline).unix() - moment(b.submitDeadline).unix(),
      defaultSortOrder: 'descend' as SortOrder,
      render: (text: string, record: CaseDto) => (
        <span>{moment(record.submitDeadline).format('DD.MM.yyyy HH:mm')}</span>
      ),
      ellipsis: true,
      width: 150,
    },
    {
      title: () => <Tooltip title='Remaining Time'>Remaining Time</Tooltip>,
      dataIndex: '.',
      key: 'remainingTime',
      render: (text: string, record: CaseDto) => {
        const status = calculateRemainingTimeStatus(record)
        const remainingTime = calculateRemainingTime(record)

        let style: CSSProperties
        switch (status) {
          case RemainingTimeStatus.Overdue:
            style = RemainingTimeStatusOverdueStyle
            break
          case RemainingTimeStatus.Close:
            style = RemainingTimeStatusCloseStyle
            break
          case RemainingTimeStatus.Nearing:
            style = RemainingTimeStatusNearingStyle
            break
          case RemainingTimeStatus.Far:
            style = RemainingTimeStatusFarStyle
            break
        }

        return <span style={style}>{remainingTime}</span>
      },
      ellipsis: true,
      width: 150,
    },

    {
      title: () => <Tooltip title='Official Deadline'>Official Deadline</Tooltip>,
      dataIndex: '.',
      key: 'submitDeadline',
      sorter: (a: CaseDto, b: CaseDto) =>
        moment(a.submitOfficialDeadline).unix() - moment(b.submitOfficialDeadline).unix(),
      defaultSortOrder: 'descend' as SortOrder,
      render: (text: string, record: CaseDto) => (
        <span>
          {record.submitOfficialDeadline &&
            moment(record.submitOfficialDeadline).format('DD.MM.yyyy HH:mm')}
        </span>
      ),
      ellipsis: true,
      width: 150,
    },
    {
      title: 'Reporters',
      dataIndex: '.',
      key: 'reporters',
      render: (text: string, record: CaseDto) => (
        <>
          <ReportersIdentifier data={record.caseReporters!} />
        </>
      ),
    },
    {
      title: 'Medicine',
      dataIndex: '.',
      key: 'medicine',
      sorter: (a: CaseDto, b: CaseDto) =>
        (a.medicine?.name ?? '').localeCompare(b.medicine?.name ?? ''),
      render: (text: string, record: CaseDto) => (
        <Tooltip title={record?.medicine?.name}>
          <div style={ColumnMedicineStyle}>{record?.medicine?.name}</div>
        </Tooltip>
      ),
    },
  ]
}
