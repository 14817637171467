/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/RegisterPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {Switch, Route, useParams} from 'react-router-dom'
import MasterLayout from '../../_metronic/layout/MasterLayout'
import PrivateRoutes from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Wrapper} from "../../auth0-init";
import {Auth0Provider} from "@auth0/auth0-react";
import {RegisterPage} from "../pages/register/RegisterPage";
import {useIntl} from "react-intl";
import {OpenAPI} from "../../generated/model";

const Routes: FC = () => {
    // TODO: Update on change!
    const intl = useIntl()
    const lang = intl.locale;
    OpenAPI.HEADERS = {"X-Language": lang};

    let redirectUri = window.location.origin;

    const {redirect} = useParams<{ redirect?: string }>();
    if( redirect && redirect.length > 0 )
        redirectUri = redirect;

    return (
        <Auth0Provider
            domain='innomed.eu.auth0.com'
            clientId='wAY136D8ou5c2gj2J91gBZ67PGwmCE5x'
            audience='https://meddkomm.backend'
            redirectUri={redirectUri}
        >
            <Switch>
                <Route path='/error' component={ErrorsPage}/>
                <Route path='/register' component={RegisterPage}/>

                <Wrapper>
                    <MasterLayout>
                        <PrivateRoutes/>
                    </MasterLayout>
                </Wrapper>
            </Switch>
        </Auth0Provider>
    )
}

export {Routes}
