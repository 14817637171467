/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {CaseExportService, CaseService, CaseStatus} from '../../../../../../../generated/model'
import {useRecoilState, useRecoilValue} from 'recoil'
import {useCaseState} from '../../../../State'
import {UseCase} from '../../../../Model'
import {caseDetailsState} from '../../State'
import {updateToken} from '../../../../../../utils/AuthToken'
import {useAuth0} from '@auth0/auth0-react'
import {LoadingBoundary} from '../../../../../_shared/LoadingBoundary'
import {HeaderActionsDoctor} from "./_mode_doctor/HeaderActionsDoctor";
import {HeaderActionsCompany} from "./_mode_company/HeaderActionsCompany";
import {useHistory} from "react-router-dom";
import {createErrorData, ErrorData} from "../../../../../errors/model/ErrorData";
import {ErrorBoundary} from "../../../../../errors/ErrorBoundary";

interface Props {}

const HeaderActions: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const useCase = useRecoilValue(useCaseState)
  const [caseData, setCaseDetailsState] = useRecoilState(caseDetailsState)
  const [error, setError] = useState<ErrorData>()

  const {getAccessTokenSilently} = useAuth0()

  const transitionTo = async (to: CaseStatus) => {
    try {
      setLoading(true)
      updateToken(await getAccessTokenSilently())

      const cases = await CaseService.transition(caseData!.id!, to!)
      setCaseDetailsState(cases)
      setLoading(false)
    } catch (e) {
      setError(createErrorData(e))
      setLoading(false)
    }
  }
  const share = async () => {
    try {
      setLoading(true)
      updateToken(await getAccessTokenSilently())

      const cases = await CaseService.share(caseData!.id!)
      setCaseDetailsState(cases)
      setLoading(false)
    } catch (e) {
      setError(createErrorData(e))
      setLoading(false)
    }
  }

  const reload = async () => {
    try {
      setLoading(true)
      updateToken(await getAccessTokenSilently())

      const cases = await CaseService.getCaseById(caseData!.id!)
      setCaseDetailsState(cases)
      setLoading(false)
    } catch (e) {
      setError(createErrorData(e))
      setLoading(false)
    }
  }

  const history = useHistory()
  const exportCase = async () => {
    try {
      setLoading(true)
      updateToken(await getAccessTokenSilently())

      const result = await CaseExportService.export({caseIds: [caseData!.id!]})
      history.push(`/exports/details/${result.id}`)

      setLoading(false)
    } catch (e) {
      setError(createErrorData(e))
      setLoading(false)
    }
  }

  return (
    <>
      {caseData && (
        <LoadingBoundary loading={loading}>
          <ErrorBoundary error={error}>
          {useCase === UseCase.Doctor && <HeaderActionsDoctor caseData={caseData} transitionTo={transitionTo}/>}
          {useCase === UseCase.Company && <HeaderActionsCompany caseData={caseData} transitionTo={transitionTo}
                                                                share={share} export={exportCase} reload={reload}/>}
          </ErrorBoundary>
        </LoadingBoundary>
      )}
    </>
  )
}

export {HeaderActions}
