import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import FallbackView from '../../_metronic/partials/content/fallback-view/FallbackView'
import {withAuthenticationRequired} from '@auth0/auth0-react'
import {InputsWrapper} from '../pages/inputs/InputsWrapper'
import {ExportsWrapper} from '../pages/exports/ExportsWrapper'
import {PvWrapper} from '../modules/pv/PvWrapper'
import {KpiWrapper} from '../modules/pv/kpi/KpiWrapper'
import RoleProvider from '../config/RoleProvider'
import {AdminWrapper} from '../modules/admin/AdminWrapper'

function PrivateRoutes() {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))

  return (
    <RoleProvider>
      <Suspense fallback={<FallbackView />}>
        <Switch>
          <Route path='/dashboard' component={PvWrapper} />
          <Route path='/cases' component={PvWrapper} />
          <Route path='/inputs' component={InputsWrapper} />
          <Route path='/exports' component={ExportsWrapper} />
          <Route path='/kpi' component={KpiWrapper} />
          <Route path='/crafted/pages/profile' component={ProfilePage} />
          <Route path='/crafted/account' component={AccountPage} />
          <Route path='/administration' component={AdminWrapper} />
          <Redirect exact from='/' to='/dashboard' />
          <Redirect to='error/404' />
        </Switch>
      </Suspense>
    </RoleProvider>
  )
}

export default withAuthenticationRequired(PrivateRoutes, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <div>Redirecting you to the login page...</div>,
})
