/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ErrorMessage, FormikErrors, FormikValues, FormikTouched} from 'formik'
import {DatePicker, Input, Select} from 'formik-antd'
import {useSetRecoilState} from 'recoil'
import {caseForDedupPatientState} from '../../State'
import {convertDedupPatientInfo} from '../new/helpers/Utils'
import {CaseDto} from '../../../../../../generated/model'

interface Props {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  values: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const StepPatientData: FC<Props> = (props) => {
  const setCaseForDedupPatientState = useSetRecoilState(caseForDedupPatientState)

  const onSelect = (value: any, option: any) => {
    const cse = props.values['case']
    if (!cse.patient.latestData) cse.patient.latestData = {}

    cse.patient.latestData.sex = option.key

    props.setFieldValue('case', cse)

    setCaseForDedupPatientState(convertDedupPatientInfo(cse))
  }
  const onSelectBirthday = (value: any) => {
    const cse = props.values['case']
    if (!cse.patient.latestData) cse.patient.latestData = {}

    cse.patient.latestData.birthday = value.utc().format()
    props.setFieldValue('case', cse)
    props.setFieldValue('birthdayTemp', value)

    setCaseForDedupPatientState(convertDedupPatientInfo(cse))
  }

  function validateSex(value: {key: string}) {
    let error
    if (!value || !value.key || value.key === '') {
      error = 'Sex is required!'
    }
    return error
  }
  function validateBirthday(value: string) {
    let error
    if (!value) {
      error = 'Birthday is required!'
    }
    return error
  }

  const onChangeWeight = (value: string) => {
    onChangeInternal(
      value,
      (cse) => (cse!.patient!.latestData!.bodyWeight = value === '' ? null : Number(value))
    )
  }
  const onChangeHeight = (value: string) => {
    onChangeInternal(
      value,
      (cse) => (cse!.patient!.latestData!.height = value === '' ? null : Number(value))
    )
  }
  const onChangeOccupation = (value: string) => {
    onChangeInternal(
      value,
      (cse) => (cse!.patient!.latestData!.occupation = value === '' ? null : value)
    )
  }
  const onChangeInternal = (value: string, setter: (cse: CaseDto) => void) => {
    const cse = props.values['case']
    if (!cse.patient.latestData) cse.patient.latestData = {}

    setter(cse)
    props.setFieldValue('case', cse)

    setCaseForDedupPatientState(convertDedupPatientInfo(cse))
  }

  return (
    <div className='mx-auto mw-600px w-100'>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Birthday</label>
        <DatePicker
          name='birthdayTemp'
          validate={validateBirthday}
          showTime={false}
          placeholder='DatePicker'
          className='form-control '
          onSelect={onSelectBirthday}
          popupStyle={{zIndex: 10024}}
        />
        <div className='fv-plugins-message-container invalid-feedback d-block'>
          <ErrorMessage name='birthdayTemp' />
        </div>
      </div>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Sex</label>

        <Select
          name='sexTemp'
          labelInValue
          optionLabelProp='label'
          placeholder='Select sex'
          onSelect={onSelect}
          validate={validateSex}
          style={{width: '100%'}}
        >
          <Select.Option key={'Male'} value={'Male'} label={'Male'}>
            Male
          </Select.Option>
          <Select.Option key={'Female'} value={'Female'} label={'Female'}>
            Female
          </Select.Option>
        </Select>
        <div className='fv-plugins-message-container invalid-feedback d-block'>
          <ErrorMessage name='sexTemp' />
        </div>
      </div>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 '>Occupation</label>

        <Input
          type='text'
          name='case.patient.latestData.occupation'
          placeholder=''
          onChange={(e) => onChangeOccupation(e.currentTarget.value)}
        />
        <div className='fv-plugins-message-container invalid-feedback d-block'>
          <ErrorMessage name='case.patient.latestData.occupation' />
        </div>
      </div>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Weight (in kg)</label>

        <Input
          type='number'
          name='case.patient.latestData.bodyWeight'
          placeholder=''
          onChange={(e) => onChangeWeight(e.currentTarget.value)}
        />
        <div className='fv-plugins-message-container invalid-feedback d-block'>
          <ErrorMessage name='case.patient.latestData.bodyWeight' />
        </div>
      </div>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Height (in cm)</label>

        <Input
          type='number'
          name='case.patient.latestData.height'
          placeholder=''
          onChange={(e) => onChangeHeight(e.currentTarget.value)}
        />
        <div className='fv-plugins-message-container invalid-feedback d-block'>
          <ErrorMessage name='case.patient.latestData.height' />
        </div>
      </div>
    </div>
  )
}

export {StepPatientData}
