/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Popover} from "antd";
import {CaseSource, CaseStatus} from "../../../../../../../../../generated/model";

interface Props {
    source: CaseSource
    transitionTo: (caseStatus: CaseStatus) => void
}

const ButtonUnduplicate: React.FC<Props> = (props) => {
  return (
      <Popover content={
          <div>
              <div>...</div>
              <div>...</div>
              <div>....</div>
          </div>
      } title="Unmark as Duplicate">
          <button
              className='btn btn-sm btn-light-primary fw-bolder mx-2'
              onClick={() => props.transitionTo(props.source === CaseSource.COMPANY ? CaseStatus.CREATED_COMPANY : CaseStatus.ACCEPTED)}
          >
              Unmark as Duplicate
          </button>
      </Popover>
  )
}

export {ButtonUnduplicate}
