/* eslint-disable jsx-a11y/anchor-is-valid */
export interface ErrorData {
    message: string

    subMessages?: string[]
}

export function createErrorData(e: any): ErrorData {
    if( e.body && e.body.message)
        return {message: e.body.message};

    if( e.body ){
        try {
            const json = JSON.parse(e.body);
            if (json && json.title && json.errors) {
                return {
                    message: json.title.toString(),
                    subMessages: json.errors?.id?.map((err: string) => err)
                };
            }
        }
        catch {}
    }

    return {message: e.message.toString()};
}