/* eslint-disable no-useless-concat */
import {ReporterStepType} from '../../management/_shared_helpers/Model'

export const getSchemas = (step: ReporterStepType) => {
  switch (step) {
    case ReporterStepType.ReporterId:
      break
    case ReporterStepType.ReporterData:
      break
  }
}
export const getPrevStep = (step: ReporterStepType) => {
  let newStep = step

  switch (step) {
    case ReporterStepType.ReporterData:
      newStep = ReporterStepType.ReporterId
      break
  }

  return newStep
}
export const getNextStep = (step: ReporterStepType) => {
  let newStep = step

  switch (step) {
    case ReporterStepType.ReporterId:
      newStep = ReporterStepType.ReporterData
      break
  }

  return newStep
}
