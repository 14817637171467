/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'

import {CaseDocumentDto, StorageService} from '../../../../../../../generated/model'
import moment from "moment";
import {AttachmentsModal} from "./AttachmentsModal";
import {ErrorBoundary} from "../../../../../errors/ErrorBoundary";
import {createErrorData, ErrorData} from "../../../../../errors/model/ErrorData";
import {Popconfirm} from "antd";

interface Props {
  documents: CaseDocumentDto[]
  saveDocument: (document: CaseDocumentDto) => void
  deleteDocument: (document: CaseDocumentDto) => void
}

const Attachments: React.FC<Props> = (props) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [error, setError] = useState<ErrorData>()

  const downloadFile = async (dto: CaseDocumentDto) => {
    try {
      const url = await StorageService.getBlobSasRead(dto.storageName!);
      window.open(url, '_blank', 'noopener,noreferrer');
    }
    catch (e){
        setError(createErrorData(e))
      }
  }

  return (
      <>
        <AttachmentsModal
            show={showModal}
            handleClose={() => setShowModal(false)}
            saveDocument={(doc) => {setShowModal(false); props.saveDocument(doc)}}
        />
    <div className='card'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Attachments</span>
          <span className='text-muted mt-1 fw-bold fs-7'>
            Create new or view previous attachments ...
          </span>
        </h3>
        <div className='card-toolbar'>
          <a
              href='#'
              className={`btn btn-sm btn-light-primary`}
              onClick={(e) => {
                e.preventDefault()
                setShowModal(true)
              }}
          >
            Upload new file
          </a>
        </div>
      </div>
      <div className='card-body'>
        <ErrorBoundary error={error}/>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bolder text-muted bg-light'>
                <th className='ps-4 rounded-start min-w-150px'>Upload Date</th>
                <th className='ps-4 rounded-start min-w-150px'>Name</th>
                <th className='ps-4 min-w-300px'>Description</th>
                <th className='ps-4 min-w-100px'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {props.documents?.map((ev) => (
                <tr key={ev.id}>
                  <td>{moment(ev.createdAt).format('DD.MM.yyyy HH:mm')}</td>
                  <td>{ev.originalName}</td>
                  <td>{ev.description}</td>
                  <td>
                    <a href='#' onClick={(e) => {
                      e.preventDefault();
                      downloadFile(ev)
                    }} className='btn btn-primary btn-sm me-2'>
                      Download
                    </a>
                    <Popconfirm
                        title="Are you sure to delete this comment?"
                        onConfirm={() => props.deleteDocument(ev)}
                        okText="Yes"
                        cancelText="No"
                    >
                    <a href='#' className='btn btn-danger btn-sm me-2'>
                      Delete
                    </a>
                    </Popconfirm>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
      </>
  )
}

export {Attachments}
