/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {useRecoilState, useSetRecoilState} from 'recoil'
import {useCaseState} from './State'
import {LoadingBoundary} from '../_shared/LoadingBoundary'
import {UseCase} from './Model'
import {useAuth0} from '@auth0/auth0-react'
import {parseUseCase} from '../../utils/UseCaseModel'
import {CasesWrapper} from './cases/CasesWrapper'
import {footerState} from '../State'
import {DashboardWrapper} from "./dashboard/DashboardWrapper";

const PvWrapper: FC = () => {
  const [useCase, setUseCase] = useRecoilState(useCaseState)
  const {user} = useAuth0()
  const setCaseStepState = useSetRecoilState(footerState)

  useEffect(() => {
    const initialSetup = () => {
      const uc = parseUseCase(user)
      setUseCase(uc)
      setCaseStepState({product: 'Pv', mode: uc})
    }
    initialSetup()
  }, [setUseCase, setCaseStepState, user])
  return (
    <>
      <LoadingBoundary loading={useCase === UseCase.Unknown}>
        {useCase !== UseCase.Unknown && (
          <Switch>
            <Route path='/cases'>
              <CasesWrapper />
            </Route>
            <Route path='/dashboard' component={DashboardWrapper} />
            <Redirect to='/dashboard' />
          </Switch>
        )}
      </LoadingBoundary>
    </>
  )
}

export {PvWrapper}
