/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ErrorMessage, FormikErrors, FormikValues, FormikTouched} from 'formik'
import {Input} from 'formik-antd'
import {useRecoilValue} from "recoil";
import {useCaseState} from "../../../../State";
import {UseCase} from "../../../../Model";

interface Step2Props {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  values: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const StepOutcomeNarrative: FC<Step2Props> = (props) => {
    const useCase = useRecoilValue(useCaseState)
  return (
    <div className='w-100' style={{minHeight: 530}}>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Description (Generated)</label>

        <Input.TextArea
          rows={7}
          readOnly={true}
          name='sideEffect.outcome.descriptionGenerated'
          placeholder=''
        />
      </div>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Additional Information</label>

        <Input.TextArea rows={5} name='sideEffect.outcome.description' placeholder='' />
        <div className='fv-plugins-message-container invalid-feedback d-block'>
          <ErrorMessage name='sideEffect.outcome.description' />
        </div>
      </div>
        {(useCase === UseCase.Company && props.values.sideEffect.outcome.descriptionTranslated && props.values.sideEffect.outcome.descriptionTranslated.length) > 0 && (
        <div className='mb-10 fv-row'>
            <label className='form-label mb-3'>Additional Information (Translated)</label>

            <Input.TextArea rows={5} readOnly={true} name='sideEffect.outcome.descriptionTranslated' placeholder='' />
        </div>)}
    </div>
  )
}

export {StepOutcomeNarrative}
