/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CaseAcceptDto } from '../models/CaseAcceptDto';
import type { CaseArchiveDto } from '../models/CaseArchiveDto';
import type { CaseCreateDto } from '../models/CaseCreateDto';
import type { CaseDto } from '../models/CaseDto';
import type { CaseDtoPageDto } from '../models/CaseDtoPageDto';
import type { CaseDuplicateDto } from '../models/CaseDuplicateDto';
import type { CaseRequestDto } from '../models/CaseRequestDto';
import type { CaseStatus } from '../models/CaseStatus';
import type { CaseUpdateDto } from '../models/CaseUpdateDto';
import type { SerialDto } from '../models/SerialDto';
import { request as __request } from '../core/request';

export class CaseService {

    /**
     * @returns SerialDto Success
     * @throws ApiError
     */
    public static async getNewCaseId(): Promise<SerialDto> {
        const result = await __request({
            method: 'GET',
            path: `/api/pv/v1/case/new/id`,
        });
        return result.body;
    }

    /**
     * @param id 
     * @returns CaseDto Success
     * @throws ApiError
     */
    public static async getCaseForEdit(
id: string,
): Promise<CaseDto> {
        const result = await __request({
            method: 'GET',
            path: `/api/pv/v1/case/${id}/edit`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns CaseDtoPageDto Success
     * @throws ApiError
     */
    public static async getAllCases(
requestBody?: CaseRequestDto,
): Promise<CaseDtoPageDto> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/case/all`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param id 
     * @returns CaseDto Success
     * @throws ApiError
     */
    public static async getCaseById(
id: string,
): Promise<CaseDto> {
        const result = await __request({
            method: 'GET',
            path: `/api/pv/v1/case/${id}`,
        });
        return result.body;
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static async deleteCaseById(
id: string,
): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/pv/v1/case/${id}`,
        });
        return result.body;
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns CaseDto Success
     * @throws ApiError
     */
    public static async updateCase(
id: string,
requestBody?: CaseUpdateDto,
): Promise<CaseDto> {
        const result = await __request({
            method: 'PUT',
            path: `/api/pv/v1/case/${id}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param id 
     * @returns CaseDto Success
     * @throws ApiError
     */
    public static async getCaseDuplicatesById(
id: string,
): Promise<Array<CaseDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/pv/v1/case/${id}/duplicates`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns CaseDto Success
     * @throws ApiError
     */
    public static async createCase(
requestBody?: CaseCreateDto,
): Promise<CaseDto> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/case`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param duplicateId 
     * @param requestBody 
     * @returns CaseDto Success
     * @throws ApiError
     */
    public static async createCaseDuplicate(
duplicateId?: string,
requestBody?: CaseCreateDto,
): Promise<CaseDto> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/case/duplicate`,
            query: {
                'duplicateId': duplicateId,
            },
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param id 
     * @param target 
     * @returns CaseDto Success
     * @throws ApiError
     */
    public static async transition(
id: string,
target?: CaseStatus,
): Promise<CaseDto> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/case/${id}/transition`,
            query: {
                'target': target,
            },
        });
        return result.body;
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns CaseDto Success
     * @throws ApiError
     */
    public static async accept(
id: string,
requestBody?: CaseAcceptDto,
): Promise<CaseDto> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/case/${id}/transition/accept`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param id 
     * @param duplicateRefId 
     * @param requestBody 
     * @returns CaseDto Success
     * @throws ApiError
     */
    public static async duplicate(
id: string,
duplicateRefId?: string,
requestBody?: CaseDuplicateDto,
): Promise<CaseDto> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/case/${id}/transition/duplicate`,
            query: {
                'duplicateRefId': duplicateRefId,
            },
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns CaseDto Success
     * @throws ApiError
     */
    public static async archive(
id: string,
requestBody?: CaseArchiveDto,
): Promise<CaseDto> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/case/${id}/transition/archive`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param id 
     * @returns CaseDto Success
     * @throws ApiError
     */
    public static async share(
id: string,
): Promise<CaseDto> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/case/${id}/share`,
        });
        return result.body;
    }

    /**
     * @returns CaseDto Success
     * @throws ApiError
     */
    public static async getCasesToSubmit(): Promise<Array<CaseDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/pv/v1/case/toSubmit`,
        });
        return result.body;
    }

    /**
     * @param filter 
     * @returns CaseDto Success
     * @throws ApiError
     */
    public static async autocompleteCases(
filter?: string,
): Promise<Array<CaseDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/pv/v1/case/autocomplete`,
            query: {
                'filter': filter,
            },
        });
        return result.body;
    }

}