/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'

import {Redirect, Route, Switch} from 'react-router-dom'
import {DeleteCaseHeader} from './components/DeleteCaseHeader'
import {DeleteCase} from './DeleteCase'

const DeleteCaseWrapper: FC = (props) => {
  return (
    <>
      <DeleteCaseHeader />
      <Switch>
        <Route path='/cases/delete/:id'>
          <DeleteCase />
        </Route>
        <Redirect to='/cases/delete/:id' />
      </Switch>
    </>
  )
}

export {DeleteCaseWrapper}
