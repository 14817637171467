/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Table} from "antd";
import {CaseDto} from "../../../../../../generated/model";
import {useAuth0} from "@auth0/auth0-react";
import {parseUseCase} from "../../../../../utils/UseCaseModel";
import getColumnsDoctor from "./CasesColumnsDoctor";
import getColumnsCompany from "./CasesColumnsCompany";
import {CasesListItemDetails} from "./CasesListItemDetails";

interface Props {
    data: Array<CaseDto>,
    loading: boolean,
    foundDuplicate: (dto: CaseDto) => void
}

const CasesList: React.FC<Props> = (props) => {
    const {user} = useAuth0()
    const useCase = parseUseCase(user);

    let columns = getColumnsDoctor(props.foundDuplicate);
    if (useCase === 'Company')
        columns = getColumnsCompany(props.foundDuplicate);

    return (
        <Table dataSource={props.data} columns={columns} rowKey='id' loading={props.loading}
               expandable={{
                   expandedRowRender: record => <CasesListItemDetails data={record}/>,
                   rowExpandable: record => true,
               }}/>
    )
}
export {CasesList}
