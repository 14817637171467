/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Key, useState} from 'react'
import {Table} from 'antd'
import {useHistory} from 'react-router-dom'
import {
  CaseDto,
  CaseDtoPageDto,
  CaseExportService,
  CaseRequestDto,
  CaseStatus,
} from '../../../../../../generated/model'
import {useAuth0} from '@auth0/auth0-react'
import getColumnsCompany from './CasesColumnsCompany'
import {updateToken} from '../../../../../utils/AuthToken'
import {CaseFilters} from '../_shared/CaseFilters'
import {SorterResult} from 'antd/lib/table/interface'
import {toSortingDirection} from '../../../../../utils/ConversionUtils'

interface Props {
  data: CaseDtoPageDto
  request: CaseRequestDto
  onChangeTable: (req: CaseRequestDto) => void
  loading: boolean
}

const CasesListCompany: React.FC<Props> = (props) => {
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(false)

  const newCaseClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    history.push('new')
  }
  const detailsCaseClick = (id: string) => {
    history.push(`details/${id}`)
  }
  const editCaseClick = (id: string) => {
    history.push(`edit/${id}`)
  }
  const deleteCaseClick = (id: string) => {
    history.push(`delete/${id}`)
  }

  const {getAccessTokenSilently} = useAuth0()
  const exportClick = async () => {
    try {
      setLoading(true)
      updateToken(await getAccessTokenSilently())

      const result = await CaseExportService.export({caseIds: selectedRows.map((x) => x.id!)})
      history.push(`/exports/details/${result.id}`)

      setLoading(false)
    } catch (e) {
      console.log(e.message)
    }
  }

  const allInStateForExport = (rows: CaseDto[]) => {
    const exportStates: CaseStatus[] = [
      CaseStatus.ACCEPTED,
      CaseStatus.CREATED_COMPANY,
      CaseStatus.IN_REVIEW,
    ]
    for (const row of rows) {
      if (exportStates.indexOf(row.status!) < 0) return false
    }
    return true
  }

  let columns = getColumnsCompany(detailsCaseClick, editCaseClick, deleteCaseClick)

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [selectedRows, setSelectedRows] = useState<CaseDto[]>([])
  const rowSelection = {
    selectedRowKeys,
    onChange: (keys: Key[]) => {
      setSelectedRowKeys(keys)
      setSelectedRows(props.data.items!.filter((x) => keys.indexOf(x.id!) >= 0))
    },
  }
  const hasSelected = selectedRows.length > 0
  const hasSelectedForExport = hasSelected && allInStateForExport(selectedRows)

  return (
    <div className={`card mb-5 mb-xl-8}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>All Cases</span>
        </h3>
        <div className='card-toolbar'>
          <a
            href='#'
            onClick={exportClick}
            className={`btn btn-primary btn-sm mx-4 ${!hasSelectedForExport && 'disabled'}`}
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_create_project'
          >
            Export All
          </a>
          <a
            href='#'
            onClick={newCaseClick}
            className='btn btn-primary btn-sm'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_create_project'
          >
            New Case
          </a>
        </div>
      </div>
      <div className='card-body border-top'>
        <CaseFilters
          request={props.request}
          onChangeTable={(req) => {
            req.page = 0
            props.onChangeTable(req)
          }}
        />
        <div className='table-responsive'>
          <Table
            showSorterTooltip={false}
            dataSource={props.data.items!}
            columns={columns}
            rowKey='id'
            loading={props.loading || loading}
            pagination={{
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              total: props.data.totalCount,
              current: props.request?.page ? props.request.page + 1 : 1,
              pageSize: props.request.pageSize,
            }}
            rowSelection={rowSelection}
            onChange={(pagination, filters, sorter) => {
              const currentSorter = sorter as SorterResult<CaseDto>
              const req = props.request
              req.page = pagination.current ? pagination.current - 1 : props.request.page
              req.pageSize = pagination.pageSize ?? props.request.pageSize
              req.sortDirection = currentSorter.order
                ? toSortingDirection(currentSorter.order)
                : props.request.sortDirection
              req.sortColumn = currentSorter.column
                ? currentSorter.column.key!.toString()
                : props.request.sortColumn

              props.onChangeTable(req)
            }}
          />
        </div>
      </div>
    </div>
  )
}
export {CasesListCompany}
