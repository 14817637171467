/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {DatePicker} from 'antd'
import moment from 'moment'
import {Menu, Dropdown, Button} from 'antd'
import {DownOutlined} from '@ant-design/icons'
import {TimeFrameField} from '../../../../../../../generated/model'

const {RangePicker} = DatePicker

interface Props {
  onSelected: (from?: string, to?: string) => void
  onSelectedField: (field: TimeFrameField) => void
  field: TimeFrameField
}

function translate(field: TimeFrameField): string {
  switch (field) {
    case TimeFrameField.CREATED_AT:
      return 'Created At'
    case TimeFrameField.UPDATED_AT:
      return 'Update At'
    case TimeFrameField.REPORTED_AT:
      return 'Reported At'
    case TimeFrameField.SUBMIT_DEADLINE:
      return 'Internal Deadline'
    case TimeFrameField.SUBMIT_OFFICIAL_DEADLINE:
      return 'Official Deadline'
  }

  return 'Unknown'
}
const CaseTimeframeSelector: FC<Props> = (props) => {
  const menu = (
    <Menu
      onClick={(e) => {
        const key = e.key
        props.onSelectedField(key as TimeFrameField)
      }}
    >
      <Menu.Item key={TimeFrameField.CREATED_AT}>{translate(TimeFrameField.CREATED_AT)}</Menu.Item>
      <Menu.Item key={TimeFrameField.UPDATED_AT}>{translate(TimeFrameField.UPDATED_AT)}</Menu.Item>
      <Menu.Item key={TimeFrameField.REPORTED_AT}>
        {translate(TimeFrameField.REPORTED_AT)}
      </Menu.Item>
      <Menu.Item key={TimeFrameField.SUBMIT_DEADLINE}>
        {translate(TimeFrameField.SUBMIT_DEADLINE)}
      </Menu.Item>
      <Menu.Item key={TimeFrameField.SUBMIT_OFFICIAL_DEADLINE}>
        {translate(TimeFrameField.SUBMIT_OFFICIAL_DEADLINE)}
      </Menu.Item>
    </Menu>
  )
  return (
    <RangePicker
      allowClear
      onChange={(opt) => {
        const from = opt ? moment(opt[0]).utc().format() : undefined
        const to = opt ? moment(opt[1]).utc().format() : undefined
        props.onSelected(from, to)
      }}
      ranges={{
        Today: [moment(), moment()],
        'This Week': [moment().startOf('week'), moment().endOf('week')],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
      }}
      renderExtraFooter={() => (
        <>
          <div className='row'>
            <div className='col-lg-auto fv-row'>
              <label className='col-form-label fw-bold'>Timeframe field:</label>
            </div>

            <div className='col-lg fv-row'>
              <Dropdown overlay={menu}>
                <Button>
                  {translate(props.field)} <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          </div>
        </>
      )}
    />
  )
}

export {CaseTimeframeSelector}
