/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {Link, useParams} from "react-router-dom";
import {useLocation} from "react-router";

const HeaderNav: React.FC = (props) => {
    const location = useLocation();

    const {id} = useParams<{ id: string }>();
    const overviewPath = `/inputs/details/${id}/overview`
    const casesPath = `/inputs/details/${id}/cases`
    const documentsPath = `/inputs/details/${id}/documents`

    return (
        <>
            <div className='d-flex overflow-auto h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                    <li className='nav-item'>
                        <Link
                            className={
                                `nav-link text-active-primary me-6 ` +
                                (location.pathname === overviewPath && 'active')
                            }
                            to={overviewPath}
                        >
                            Overview
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            className={
                                `nav-link text-active-primary me-6 ` +
                                (location.pathname === casesPath && 'active')
                            }
                            to={casesPath}
                        >
                            Cases
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            className={
                                `nav-link text-active-primary me-6 ` +
                                (location.pathname === documentsPath && 'active')
                            }
                            to={documentsPath}
                        >
                            Documents
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    )
}

export {HeaderNav}

