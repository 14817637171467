import {CaseStepType} from '../../_shared_helpers/Model'

export const getSchemas = (step: CaseStepType) => {
  switch (step) {
    case CaseStepType.Common:
      break
    case CaseStepType.PatientId:
      break
    case CaseStepType.PatientData:
      break
    case CaseStepType.Reporters:
      break
    case CaseStepType.Evaluation:
      break
    case CaseStepType.Events:
      break
  }
}
