/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {useHistory} from 'react-router-dom'
import {CaseDto, CaseSource} from '../../../../../../../generated/model'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import moment from 'moment'
import {useRecoilValue} from 'recoil'
import {useCaseState} from '../../../../State'
import {UseCase} from '../../../../Model'
import {getCaseStatusName} from '../../../_shared/helpers/CasesStatusHelpers'
import {HeaderDataButtonsCompany} from './_mode_company/HeaderDataButtonsCompany'
import {HeaderDataButtonsDoctor} from './_mode_doctor/HeaderDataButtonsDoctor'

interface Props {
  case: CaseDto
}

const HeaderData: React.FC<Props> = (props) => {
  const history = useHistory()
  const useCase = useRecoilValue(useCaseState)

  const backClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    history.push('/cases/list')
  }

  return (
    <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
      <div className='me-7 mb-4'>
        <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
          <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px' />
        </div>
      </div>

      <div className='flex-grow-1'>
        <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
          <div className='d-flex flex-column'>
            <div className='d-flex align-items-center mb-2'>
              <span className='text-gray-800  fs-2 fw-bolder me-1'>
                {useCase === UseCase.Doctor && props.case.serial?.readableId}
                {useCase === UseCase.Company &&
                  props.case.source === CaseSource.COMPANY &&
                  props.case.serial?.readableId}
                {useCase === UseCase.Company &&
                  props.case.source === CaseSource.DOCTOR &&
                  props.case.serial?.globalId}
                {props.case.identifier && <span> ({props.case.identifier})</span>}
              </span>
            </div>

            <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
              <span className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                <KTSVG path='/media/icons/duotone/Map/Marker1.svg' className='svg-icon-4 me-1' />
                {props.case.serial?.serialId}
              </span>
            </div>
          </div>

          <div className='d-flex my-4'>
            {useCase === UseCase.Company && <HeaderDataButtonsCompany case={props.case} />}
            {useCase === UseCase.Doctor && <HeaderDataButtonsDoctor case={props.case} />}
            <a href='#' onClick={backClick} className='btn btn-primary btn-sm me-2'>
              Back
            </a>
          </div>
        </div>

        <div className='d-flex flex-wrap flex-stack'>
          <div className='d-flex flex-column flex-grow-1 pe-8'>
            <div className='d-flex flex-wrap'>
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                <div className='d-flex align-items-center'>
                  <KTSVG
                    path='/media/icons/duotone/Design/Border.svg'
                    className='svg-icon-3 svg-icon-success me-2'
                  />
                  <div className='fs-2 fw-bolder'>{getCaseStatusName(props.case.status!)}</div>
                </div>

                <div className='fw-bold fs-6 text-gray-400'>Status</div>
              </div>

              {props.case.submitDeadline && (
                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                  <div className='d-flex align-items-center'>
                    <KTSVG
                      path='/media/icons/duotone/Files/File-plus.svg'
                      className='svg-icon-3  me-2'
                    />
                    <div className='fs-2 fw-bolder'>
                      {moment(props.case.submitDeadline).format('DD.MM.yyyy HH:mm')}
                    </div>
                  </div>

                  <div className='fw-bold fs-6 text-gray-400'>Internal Deadline</div>
                </div>
              )}

              {props.case.submitOfficialDeadline && (
                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                  <div className='d-flex align-items-center'>
                    <KTSVG
                      path='/media/icons/duotone/Files/File-done.svg'
                      className='svg-icon-3  me-2'
                    />
                    <div className='fs-2 fw-bolder'>
                      {moment(props.case.submitOfficialDeadline).format('DD.MM.yyyy HH:mm')}
                    </div>
                  </div>

                  <div className='fw-bold fs-6 text-gray-400'>Official Deadline</div>
                </div>
              )}

              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                <div className='d-flex align-items-center'>
                  <KTSVG
                    path='/media/icons/duotone/Files/File-plus.svg'
                    className='svg-icon-3  me-2'
                  />
                  <div className='fs-2 fw-bolder'>
                    {moment(props.case.reportedAt).format('DD.MM.yyyy HH:mm')}
                  </div>
                </div>

                <div className='fw-bold fs-6 text-gray-400'>Reported On</div>
              </div>

              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                <div className='d-flex align-items-center'>
                  <KTSVG
                    path='/media/icons/duotone/Design/Brush.svg'
                    className='svg-icon-3 svg-icon-danger me-2'
                  />
                  <div className='fs-2 fw-bolder'>{props.case.caseSideEffects?.length}</div>
                </div>

                <div className='fw-bold fs-6 text-gray-400'>Events</div>
              </div>

              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                <div className='d-flex align-items-center'>
                  <KTSVG
                    path='/media/icons/duotone/Communication/Clipboard-check.svg'
                    className='svg-icon-3  me-2'
                  />
                  <div className='fs-2 fw-bolder'>{props.case.submitVersion}</div>
                </div>

                <div className='fw-bold fs-6 text-gray-400'>Version</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {HeaderData}
