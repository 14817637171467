import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {Routes} from './routing/Routes'

import 'antd/dist/antd.css'
import {OpenAPI} from '../generated/model'
import {RecoilRoot} from 'recoil'

type Props = {
  basename: string
}

OpenAPI.BASE = process.env.REACT_APP_API_URL!

const App: React.FC<Props> = ({basename}) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <RecoilRoot>
        <BrowserRouter basename={basename}>
          <I18nProvider>
            <LayoutProvider>
              <Routes />
            </LayoutProvider>
          </I18nProvider>
        </BrowserRouter>
      </RecoilRoot>
    </Suspense>
  )
}
export default App
