/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import clsx from 'clsx'

export interface HeaderParts {
  title: string
  isActive: boolean
  isSeparator?: boolean
}

interface Props {
  title: string
  parts: HeaderParts[]
}

const EventsHeader: React.FC<Props> = (props) => {
  return (
    <div className={`card}`}>
      <div className='card-header border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>
            <div
              id='kt_page_title'
              data-kt-swapper='true'
              data-kt-swapper-mode='prepend'
              data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
              className={clsx('page-title d-flex')}
            >
              <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
                {props.title}
              </h1>
              <span className='h-20px border-gray-200 border-start mx-4' />
              <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
                {props.parts.map((item, index) => (
                  <li
                    className={clsx('breadcrumb-item', {
                      'text-dark': !item.isSeparator && item.isActive,
                      'text-muted': !item.isSeparator && !item.isActive,
                    })}
                    key={`${index}`}
                  >
                    {!item.isSeparator ? (
                      item.title
                    ) : (
                      <span className='bullet bg-gray-200 w-5px h-2px' />
                    )}
                  </li>
                ))}
                <li className='breadcrumb-item text-dark'>{props.title}</li>
              </ul>
            </div>
          </span>
        </h3>
      </div>
    </div>
  )
}
export {EventsHeader}
