/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'

import {useAuth0} from '@auth0/auth0-react'
import {CaseInputDto, CaseInputService, CaseInputStatus} from '../../../../generated/model'
import {LoadingBoundary} from '../../../modules/_shared/LoadingBoundary'
import {ErrorBoundary} from '../../../modules/errors/ErrorBoundary'
import {ErrorData} from '../../../modules/errors/model/ErrorData'
import {updateToken} from '../../../utils/AuthToken'
import {Redirect, Route, Switch, useParams} from 'react-router-dom'
import {DetailsCaseHeader} from './components/Header'
import {Overview} from './components/Overview'
import {CasesList} from './components/CasesList'

const DetailsInputWrapper: FC = (props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [caseData, setCaseData] = useState<CaseInputDto>()
  const [error] = useState<ErrorData>()

  const {getAccessTokenSilently} = useAuth0()
  const {id} = useParams<{id: string}>()

  useEffect(() => {
    const getInput = async () => {
      updateToken(await getAccessTokenSilently())

      const data = await CaseInputService.getInputById(id)
      setCaseData(data)
      setLoading(false)
    }
    getInput()
  }, [getAccessTokenSilently, id])

  const transitionTo = async (to: CaseInputStatus) => {
    try {
      setLoading(true)
      updateToken(await getAccessTokenSilently())

      const data = await CaseInputService.transitionInput(id, to)
      setCaseData(data)
      setLoading(false)
    } catch (e) {
      console.log(e.message)
    }
  }

  return (
    <LoadingBoundary loading={loading}>
      <ErrorBoundary error={error}>
        {caseData && (
          <>
            <DetailsCaseHeader transitionTo={transitionTo} caseInput={caseData} />
            <Switch>
              <Route path='/inputs/details/:id/overview'>
                <Overview caseInput={caseData} />
              </Route>
              <Route path='/inputs/details/:id/cases'>
                <CasesList data={caseData.cases!} />
              </Route>
              <Route path='/inputs/details/:id/documents'></Route>
              <Redirect from='/inputs/details/:id' exact={true} to='/inputs/details/:id/overview' />
              <Redirect to='/inputs/details/:id/overview' />
            </Switch>
          </>
        )}
      </ErrorBoundary>
    </LoadingBoundary>
  )
}

export {DetailsInputWrapper}
