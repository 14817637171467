import {CaseDedupEventsRequestInfoDto} from '../../../../../../../../generated/model'

export const isValid = (data?: CaseDedupEventsRequestInfoDto): boolean => {
  if (!data || !data.patient) return false
  if (!data || !data.events) return false

  const events = data.events
  if (events.length === 0) return false

  return true
}
