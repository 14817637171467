/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'

import {useAuth0} from '@auth0/auth0-react'
import {CaseService, PatientService, Serial} from '../../../../../../generated/model'
import {LoadingBoundary} from '../../../../_shared/LoadingBoundary'
import {NewCase} from './NewCase'
import {updateToken} from '../../../../../utils/AuthToken'
import {CaseDedupMedicineInfo} from '../../_shared/components/case-deduplication-info/medicine/CaseDedupMedicineInfo'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {
  caseForDedupEventsState,
  caseForDedupMedicineState,
  caseForDedupPatientState,
  caseReporterStepState,
  caseSideEffectStepState,
  caseStepState,
} from '../../State'
import {CaseDedupPatientInfo} from '../../_shared/components/case-deduplication-info/patient/CaseDedupPatientInfo'
import {Header} from '../_shared/Header'
import {useHistory} from 'react-router-dom'
import {
  getHeaderBackTitle,
  getHeaderParts,
  getHeaderProgress,
  getHeaderTitle,
} from '../_shared_helpers/Utils'
import {CaseStepType, ReporterStepType, SideEffectStepType} from '../_shared_helpers/Model'
import {CaseDedupEventsInfo} from '../../_shared/components/case-deduplication-info/events/CaseDedupEventsInfo'
import {useCaseState} from '../../../State'

const NewCaseWrapper: React.FC = () => {
  const caseStep = useRecoilValue(caseStepState)
  const caseSideEffectStep = useRecoilValue(caseSideEffectStepState)
  const reporterStep = useRecoilValue(caseReporterStepState)
  const useCase = useRecoilValue(useCaseState)

  const headerTitle = getHeaderTitle(caseStep, caseSideEffectStep, reporterStep)
  const headerParts = getHeaderParts(caseStep, false)
  const headerProgress = getHeaderProgress(caseStep, caseSideEffectStep, reporterStep, useCase)
  const headerBackTitle = getHeaderBackTitle(caseStep)

  const setCaseForDedupMedicineState = useSetRecoilState(caseForDedupMedicineState)
  const setCaseForDedupPatientState = useSetRecoilState(caseForDedupPatientState)
  const setCaseForDedupEventsState = useSetRecoilState(caseForDedupEventsState)
  const setCaseStepState = useSetRecoilState(caseStepState)
  const setCaseSideEffectStepState = useSetRecoilState(caseSideEffectStepState)
  const setCaseReporterStepState = useSetRecoilState(caseReporterStepState)

  const history = useHistory()
  const backClick = () => {
    if (caseStep === CaseStepType.EventData) {
      setCaseStepState(CaseStepType.Events)
      return
    }
    if (caseStep === CaseStepType.ReporterData) {
      setCaseStepState(CaseStepType.Reporters)
      return
    }

    history.push('/cases/list')
  }

  const [loading, setLoading] = useState<boolean>(true)
  useEffect(
    () => {
      const initialSetup = () => {
        setCaseForDedupMedicineState(undefined)
        setCaseForDedupPatientState(undefined)
        setCaseForDedupEventsState(undefined)
        setCaseStepState(CaseStepType.Common)
        setCaseSideEffectStepState(SideEffectStepType.SideEffect)
        setCaseReporterStepState(ReporterStepType.ReporterId)
        setLoading(false)
      }
      initialSetup()
    }, // eslint-disable-next-line
    []
  )

  return (
    <>
      <LoadingBoundary loading={loading}>
        <Header
          title={headerTitle}
          parts={headerParts}
          progress={headerProgress}
          backTitle={headerBackTitle}
          onBackClick={backClick}
        ></Header>
      </LoadingBoundary>

      {(caseStep === CaseStepType.Common || caseStep === CaseStepType.PatientId) && (
        <CaseDedupMedicineInfo />
      )}
      {caseStep === CaseStepType.PatientData && <CaseDedupPatientInfo />}
      {(caseStep === CaseStepType.Events || caseStep === CaseStepType.EventData) && (
        <CaseDedupEventsInfo />
      )}

      <NewCaseWrapperInternal />
    </>
  )
}

const NewCaseWrapperInternal: FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [caseId, setCaseId] = useState<Serial>()
  const [patientId, setPatientId] = useState<Serial>()

  const {getAccessTokenSilently} = useAuth0()
  useEffect(() => {
    const getCaseId = async () => {
      updateToken(await getAccessTokenSilently())

      const caseId = await CaseService.getNewCaseId()
      const patientId = await PatientService.getNewPatientId()

      setCaseId(caseId)
      setPatientId(patientId)

      setLoading(false)
    }
    getCaseId()
  }, [getAccessTokenSilently])
  return (
    <LoadingBoundary loading={loading}>
      <NewCase caseId={caseId!} patientId={patientId!} />
    </LoadingBoundary>
  )
}

export {NewCaseWrapper}
