/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {CasesExportDto} from '../../../../../generated/model'
import beautify from "xml-formatter";

interface Props {
  casesExport: CasesExportDto
}

const Overview: React.FC<Props> = (props) => {
  return (
    <div className='card'>
      <div id='kt_account_deactivate' className='collapse show'>
        <div className='card-body border-top'>
          <div className='card' id='kt_profile_details_view'>
            <div className='card-body p-9'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Global Export ID / Serial</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {props.casesExport.serial?.globalId}
                  </span>
                  <span className='px-2'>({props.casesExport.serial?.serialId})</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Type</label>

                <div className='col-lg-8'>
                  <div>
                    <div>{props.casesExport.exportType}</div>
                  </div>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>File</label>

                <div className='col-lg-8'>
                  <div>
                    <div>
                      <a
                        href={URL.createObjectURL(
                          new Blob([props.casesExport.data!], {type: 'text/plain;charset=utf-8'})
                        )}
                        download='export.xml'
                      >
                        Download
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <h3>Raw Data</h3>
              <pre>{beautify(props.casesExport.data!)}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Overview}
