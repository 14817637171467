import {CaseDto} from '../../../../../../../generated/model'
import {IManageCase, PatientAccountType} from '../../_shared_helpers/Model'
import moment from 'moment'

export interface IEditCase extends IManageCase {
  // real entity
  case: CaseDto
}

export function createInitialState(cse: CaseDto): IEditCase {
  cse!.patient!.latestData = cse.patientData

  if (cse.caseReporters)
    for (const rep of cse.caseReporters) rep.reporter!.latestData = rep.reporterData

  if (!cse.caseSideEffects) cse.caseSideEffects = []

  return {
    case: cse,
    patientAccountType: PatientAccountType.Existing,

    birthdayTemp: cse!.patient!.latestData!.birthday!,
    reportedAtTemp: moment(cse.reportedAt).format(),
    submitDeadlineTemp: moment(cse.submitDeadline).format(),
    sexTemp: {
      value: cse.patient!.latestData?.sex,
      label: cse.patient!.latestData?.sex,
      key: cse.patient!.latestData?.sex,
    },
    categoryTemp: {
      value: cse.category,
      label: cse.category,
      key: cse.category,
    },
    seriousnessTemp: {
      value: cse.seriousness,
      label: cse.seriousness,
      key: cse.seriousness,
    },
  }
}
