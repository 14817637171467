import {ICreateCase} from './Model'
import {CaseDto, CaseService} from '../../../../../../../generated/model'
import {FormikValues} from 'formik'
import {updateToken} from '../../../../../../utils/AuthToken'

export const save = async (values: ICreateCase, actions: FormikValues, token: string) => {
  updateToken(token)

  if( values.case.identifier === '' )
    values.case.identifier = null;

  return await CaseService.createCase(values.case)
}

export const saveDuplicate = async (values: ICreateCase, duplicate: CaseDto, token: string) => {
  updateToken(token)

  if( values.case.identifier === '' )
    values.case.identifier = null;

  return await CaseService.createCaseDuplicate(duplicate.id, values.case)
}
