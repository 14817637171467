/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Redirect, Route, Switch} from "react-router-dom";
import {InputsListWrapper} from "./inputs-list/InputsListWrapper";
import {DetailsInputWrapper} from "./inputs-details/DetailsInputWrapper";

const InputsWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <Switch>
                <Route path='/inputs/list'>
                    <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.INPUTS'})}</PageTitle>
                    <InputsListWrapper/>
                </Route>
                <Route path='/inputs/details/:id'>
                    <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.INPUTS.DETAILS'})}</PageTitle>
                    <DetailsInputWrapper/>
                </Route>
                <Redirect to='/inputs/list'/>
            </Switch>
        </>
    )
}

export {InputsWrapper}
