import React from 'react'
import {Tag} from 'antd'
import {CaseDedupEventsInfoDto} from '../../../../../../../../generated/model'

type Props = {
  data: CaseDedupEventsInfoDto
}

const CaseDedupCaseInfoData: React.FC<Props> = (props) => {
  return (
    <>
      <span className='text-muted mt-1 fw-bold fs-7'>
        <span>
          Cases with same <b>medicine</b>, similar <b>patient data</b> and <b>events</b>:{' '}
        </span>
        <span>
          Last 2 days: <Tag>{props.data?.cases2dCnt}</Tag>
        </span>
        <span>
          Last 7 days: <Tag>{props.data?.cases7dCnt}</Tag>
        </span>
        <span>
          Total: <Tag>{props.data?.casesAllCnt}</Tag>
        </span>
      </span>
    </>
  )
}
export {CaseDedupCaseInfoData}
