/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Route, Switch} from 'react-router-dom'
import {RegistrationNew} from './components/RegistrationNew'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {RegistrationShareWrapper} from "./share/RegistrationShareWrapper";

export function RegisterPage() {
    useEffect(() => {
        document.body.classList.add('bg-white')
        return () => {
            document.body.classList.remove('bg-white')
        }
    }, [])

    return (
        <div
            className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
            style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
            }}
        >
            {/* begin::Content */}
            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                {/* begin::Wrapper */}
                <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
                    <Switch>
                        <Route path='/register/share' component={RegistrationShareWrapper}/>
                        <Route path='/register/new' component={RegistrationNew}/>
                    </Switch>
                </div>
                {/* end::Wrapper */}
            </div>
            {/* end::Content */}
            {/* begin::Footer */}
            <div className='d-flex flex-center flex-column-auto p-10'>
                <div className='d-flex align-items-center fw-bold fs-6'>
                    <a href='#' className='text-muted text-hover-primary px-2'>
                        About
                    </a>

                    <a href='#' className='text-muted text-hover-primary px-2'>
                        Contact
                    </a>

                    <a href='#' className='text-muted text-hover-primary px-2'>
                        Contact Us
                    </a>
                </div>
            </div>
            {/* end::Footer */}
        </div>
    )
}
