/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom';

interface Props {
    companyName: string,
    caseId: string
}

export function Header(props: Props) {
    const companyName = props.companyName;
    const caseId = props.caseId;

    return (
        <>
            <div className='mb-10 text-center'>
                {/* begin::Title */}
                <h1 className='text-dark mb-3'>Create an Account</h1>
                <div className='mb-3'>Company <b>{companyName}</b> shared case <b>{caseId}</b> with you. Create an
                    account to access the case.
                </div>
                {/* end::Title */}

                <div className='d-flex align-items-center mb-10'>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                </div>
                {/* begin::Link */}
                <div className='text-gray-400 fw-bold fs-4'>
                    Already have an account?
                    <Link to={`/auth/login?redirect=${window.location.href}`} className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
                        Login
                    </Link>
                </div>
                {/* end::Link */}
            </div>

            <div className='d-flex align-items-center mb-10'>
                <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span>
                <div className='border-bottom border-gray-300 mw-50 w-100'></div>
            </div>
        </>
    )
}
