/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Tag} from 'antd'
import {MedicineDto} from '../../../../../../../generated/model'

interface Props {
  medicine: MedicineDto
  showHeader?: boolean
}

const MedicinePackages: FC<Props> = (props) => {
  return (
    <>
      {(props.showHeader === true || props.showHeader === undefined) && (
        <label className='form-label mb-3 pt-5'>PZN (s)</label>
      )}
      <div>
        {props.medicine?.packages?.map((pkg) => (
          <Tag key={pkg.pzn}>{pkg.pzn}</Tag>
        ))}
      </div>
    </>
  )
}

export {MedicinePackages}
