/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {CaseEventDto} from '../../../../../../../generated/model'
import {Tag} from "antd";

interface Props {
  ev: CaseEventDto
}

const HistoryAttachment: React.FC<Props> = (props) => {
  const data = props.ev!.data!.attachmentData!
  return (
      <>
          <h6>Attachment <Tag>{data.action}</Tag></h6>
    <ul>
      <li>Name: {data.originalName}</li>
      <li>Description: {data.description}</li>
    </ul>
          </>
  )
}

export {HistoryAttachment}
