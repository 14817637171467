import React from 'react'
import {Tag} from 'antd'
import {useRecoilValue} from 'recoil'
import {caseDedupMedicineState, caseForDedupMedicineState} from '../../../../State'

type Props = {}

const CaseDedupMedicineInfoData: React.FC<Props> = (props) => {
  const data = useRecoilValue(caseDedupMedicineState)
  const dataForDedup = useRecoilValue(caseForDedupMedicineState)

  return (
    <>
      {!dataForDedup || !dataForDedup.medicineId ? (
        <span>Please select medicine...</span>
      ) : (
        <>
          <span className='text-muted mt-1 fw-bold fs-7'>
            <span>
              Cases with same <b>medicine</b>:{' '}
            </span>
            <span>
              Last 2 days: <Tag>{data?.cases2dCnt}</Tag>
            </span>
            <span>
              Last 7 days: <Tag>{data?.cases7dCnt}</Tag>
            </span>
            <span>
              Total: <Tag>{data?.casesAllCnt}</Tag>
            </span>
          </span>
        </>
      )}
    </>
  )
}

export {CaseDedupMedicineInfoData}
