/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserDto } from '../models/CreateUserDto';
import type { RegisterUserDto } from '../models/RegisterUserDto';
import { request as __request } from '../core/request';

export class RegisterService {

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async createUser(
requestBody?: CreateUserDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/auth/v1/user/create`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async registerUserByShare(
requestBody?: RegisterUserDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/auth/v1/user/register/share`,
            body: requestBody,
        });
        return result.body;
    }

}