/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const AdminWrapper: FC = () => {
  return (
    <Switch>
      <Route path='/administration'>
        <span>User</span>
      </Route>
      <Redirect to='/administration' />
    </Switch>
  )
}

export {AdminWrapper}
