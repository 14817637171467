import React, {useState} from 'react'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {caseDedupDuplicateFoundState, caseForDedupPatientState, caseStepState} from '../../../../State'
import {CaseDedupPatientInfoData} from './CaseDedupPatientInfoData'
import {DedupPatientListModal} from '../../../../deduplication/patient/list/DedupPatientListModal'
import {isValid} from './CaseDedupPatientInfoHelper'
import {CaseDto} from "../../../../../../../../generated/model";
import {CaseStepType} from "../../../../management/_shared_helpers/Model";

type Props = {}

const CaseDedupPatientInfo: React.FC<Props> = (props) => {
  const dataForDedup = useRecoilValue(caseForDedupPatientState)
  const [showModal, setShowModal] = useState<boolean>(false)

  const setCaseDedupDuplicateFoundState = useSetRecoilState(caseDedupDuplicateFoundState)
  const setCaseStepState = useSetRecoilState(caseStepState)

  const foundDuplicate = (dto: CaseDto) => {
    setCaseDedupDuplicateFoundState(dto);
    setCaseStepState(CaseStepType.Duplicate)
    setShowModal(false);
  }
  return (
    <>
      {dataForDedup && (
        <DedupPatientListModal
          foundDuplicate={foundDuplicate}
          show={showModal}
          handleClose={() => setShowModal(false)}
          request={dataForDedup!}
        />
      )}
      <div className={`card mb-5 mb-xl-8}`}>
        <>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Deduplication Information</span>

              <React.Suspense
                fallback={<span className='text-muted mt-1 fw-bold fs-7'>Loading...</span>}
              >
                <CaseDedupPatientInfoData />
              </React.Suspense>
            </h3>

            <div className='card-toolbar'>
              <a
                href='#'
                className={`btn btn-sm btn-light-primary ${isValid(dataForDedup) && 'disabled'}`}
                onClick={(e) => {
                  e.preventDefault()
                  setShowModal(true)
                }}
              >
                Details
              </a>
            </div>
          </div>
        </>
      </div>
    </>
  )
}

export {CaseDedupPatientInfo}
