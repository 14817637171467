/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Table} from "antd";
import {useHistory} from "react-router-dom";
import {CaseInputCaseDto} from "../../../../../generated/model";
import getColumns from "./CasesListColumns";

interface Props {
    data: Array<CaseInputCaseDto>
}

const CasesList: React.FC<Props> = (props) => {
    const history = useHistory();
    const detailsCaseClick = (id: string) => {
        history.push(`details/${id}`)
    };
    let columns = getColumns(detailsCaseClick);
    return (
        <div className={`card mb-5 mb-xl-8}`}>
            {/* begin::Body */}
            <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <Table dataSource={props.data} columns={columns} rowKey='id'/>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            {/* begin::Body */}

        </div>
    )
}
export {CasesList}
