import {CaseStepType, ReporterStepType, SideEffectStepType} from './Model'
import {HeaderParts, HeaderProgress} from '../_shared/Header'
import {UseCase} from '../../../Model'

export const getStepNumber = (step: CaseStepType): number => {
  switch (step) {
    case CaseStepType.Common:
      return 1
    case CaseStepType.Reporters:
      return 2
    case CaseStepType.PatientId:
      return 3
    case CaseStepType.PatientData:
      return 4
    case CaseStepType.Events:
      return 5
    case CaseStepType.Evaluation:
      return 6
  }

  return 0
}
export const getStepNumberSideEffect = (sideEffectStep: SideEffectStepType): number => {
  switch (sideEffectStep) {
    case SideEffectStepType.SideEffect:
      return 1
    case SideEffectStepType.SideEffectProperties:
      return 2
    case SideEffectStepType.ReactionProperties:
      return 3
    case SideEffectStepType.ReactionNarrative:
      return 4
    case SideEffectStepType.OutcomeProperties:
      return 5
    case SideEffectStepType.OutcomeNarrative:
      return 6
  }

  return 0
}
export const getStepNumberReporter = (reporterStep: ReporterStepType): number => {
  switch (reporterStep) {
    case ReporterStepType.ReporterId:
      return 1
    case ReporterStepType.ReporterData:
      return 2
  }

  return 0
}
export const getTotalSteps = (useCase: UseCase): number => {
  if (useCase === UseCase.Doctor) return 5

  return 6
}
export const getTotalStepsSideEffect = (): number => {
  return 6
}
export const getTotalStepsReporter = (): number => {
  return 2
}

export const getHeaderTitle = (
  step: CaseStepType,
  sideEffectStep: SideEffectStepType,
  reporterStep: ReporterStepType
): string => {
  switch (step) {
    case CaseStepType.Common:
      return 'Case Data'
    case CaseStepType.PatientId:
      return 'Patient ID'
    case CaseStepType.PatientData:
      return 'Patient Data'
    case CaseStepType.Reporters:
      return 'Reporters'
    case CaseStepType.Events:
      return 'Events'
    case CaseStepType.Duplicate:
      return 'Duplicate'
    case CaseStepType.Evaluation:
      return 'Evaluation'
    case CaseStepType.EventData:
      return getHeaderTitleSideEffect(sideEffectStep)
    case CaseStepType.ReporterData:
      return getHeaderTitleReporter(reporterStep)
  }

  return 'Undefined'
}
export const getHeaderTitleReporter = (reporterStep: ReporterStepType): string => {
  switch (reporterStep) {
    case ReporterStepType.ReporterId:
      return 'Reporter'
    case ReporterStepType.ReporterData:
      return 'Reporter Data'
  }

  return 'Undefined'
}
export const getHeaderTitleSideEffect = (sideEffectStep: SideEffectStepType): string => {
  switch (sideEffectStep) {
    case SideEffectStepType.SideEffect:
      return 'Side Effect Data'
    case SideEffectStepType.SideEffectProperties:
      return 'Side Effect Properties'
    case SideEffectStepType.ReactionProperties:
      return 'Reaction Properties'
    case SideEffectStepType.ReactionNarrative:
      return 'Reaction Narrative'
    case SideEffectStepType.OutcomeProperties:
      return 'Outcome Properties'
    case SideEffectStepType.OutcomeNarrative:
      return 'Outcome Narrative'
  }

  return 'Undefined'
}
export const getHeaderParts = (step: CaseStepType, isEditCase: boolean): HeaderParts[] => {
  const parts = [
    {
      title: isEditCase ? 'Edit PV Case' : 'New PV Case',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  if (step === CaseStepType.EventData) {
    parts.push({
      title: 'Event Data',
      isSeparator: false,
      isActive: false,
    })
    parts.push({
      title: '',
      isSeparator: true,
      isActive: false,
    })
  }
  if (step === CaseStepType.ReporterData) {
    parts.push({
      title: 'Reporter Data',
      isSeparator: false,
      isActive: false,
    })
    parts.push({
      title: '',
      isSeparator: true,
      isActive: false,
    })
  }

  return parts
}
export const getHeaderProgress = (
  step: CaseStepType,
  sideEffectStep: SideEffectStepType,
  reporterStep: ReporterStepType,
  useCase: UseCase
): HeaderProgress => {
  const progress = (getStepNumber(step) / getTotalSteps(useCase)) * 100
  switch (step) {
    case CaseStepType.Common:
    case CaseStepType.PatientId:
    case CaseStepType.PatientData:
    case CaseStepType.Reporters:
    case CaseStepType.Events:
    case CaseStepType.Evaluation:
      return {progress: progress, color: 'info', tooltip: 'Undefined'}
    case CaseStepType.EventData:
      return getHeaderProgressSideEffect(sideEffectStep)
    case CaseStepType.ReporterData:
      return getHeaderProgressReporter(reporterStep)
  }

  return {progress: 0, color: 'info', tooltip: 'Undefined'}
}
export const getHeaderProgressSideEffect = (sideEffectStep: SideEffectStepType): HeaderProgress => {
  const progress = (getStepNumberSideEffect(sideEffectStep) / getTotalStepsSideEffect()) * 100
  switch (sideEffectStep) {
    case SideEffectStepType.SideEffect:
    case SideEffectStepType.SideEffectProperties:
    case SideEffectStepType.ReactionProperties:
    case SideEffectStepType.ReactionNarrative:
    case SideEffectStepType.OutcomeProperties:
    case SideEffectStepType.OutcomeNarrative:
      return {progress: progress, color: 'primary', tooltip: 'Undefined'}
  }

  return {progress: 0, color: 'info', tooltip: 'Undefined'}
}

export const getHeaderProgressReporter = (reporterStep: ReporterStepType): HeaderProgress => {
  const progress = (getStepNumberReporter(reporterStep) / getTotalStepsReporter()) * 100
  switch (reporterStep) {
    case ReporterStepType.ReporterId:
    case ReporterStepType.ReporterData:
      return {progress: progress, color: 'primary', tooltip: 'Undefined'}
  }

  return {progress: 0, color: 'info', tooltip: 'Undefined'}
}
export const getHeaderBackTitle = (step: CaseStepType): string => {
  if (step === CaseStepType.EventData) return 'Back to Events'
  if (step === CaseStepType.ReporterData) return 'Back to Reporters'
  return 'Back'
}
