/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {FormikErrors, FormikValues, FormikTouched, FieldArray, FieldArrayRenderProps} from 'formik'
import {CaseSideEffectDto} from '../../../../../../generated/model'
import {Table} from 'antd'
import {getEventColumns} from './StepEventsColumns'
import {useSetRecoilState} from 'recoil'
import {caseForDedupEventsState, caseSideEffectInEditState} from '../../State'
import {CaseEventSummaryDetails} from '../../_shared/components/case-event-summary/CaseEventSummaryDetails'
import {convertDedupEventsInfo} from '../new/helpers/Utils'

interface EventsStepProps {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  values: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void

  showSideEffectData: () => void
}

const StepEvents: FC<EventsStepProps> = (props) => {
  const setCaseSideEffectInEditState = useSetRecoilState(caseSideEffectInEditState)
  const setCaseForDedupEventsState = useSetRecoilState(caseForDedupEventsState)

  const editSideEffect = (
    sideEffect: CaseSideEffectDto | undefined,
    helpers: FieldArrayRenderProps
  ) => {
    const editState = {
      case: props.values.case!,
      sideEffect: sideEffect!,
      sideEffectsNotInEdit: props.values.case!.caseSideEffects?.filter(
        (x: CaseSideEffectDto) => x !== sideEffect
      ),
    }
    setCaseSideEffectInEditState(editState)
    props.showSideEffectData()
  }
  useEffect(
    () => {
      setCaseForDedupEventsState(convertDedupEventsInfo(props.values.case))
    },
    // eslint-disable-next-line
    [props.values.case.caseSideEffects]
  )
  return (
    <>
      <FieldArray
        name='case.caseSideEffects'
        render={(helpers) => (
          <div className='w-100'>
            <div className='mb-10 fv-row'>
              <div className='card-header border-0'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label'>Events</span>
                </h3>
                <div className='card-toolbar'>
                  <button
                    type='button'
                    className='btn btn-sm btn-color-primary btn-active-light-primary'
                    onClick={(e) => {
                      e.preventDefault()
                      editSideEffect(undefined, helpers)
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
              <Table
                dataSource={props.values.case.caseSideEffects}
                columns={getEventColumns(editSideEffect, helpers)}
                rowKey='identifier'
                expandable={{
                  expandedRowRender: (record) => <CaseEventSummaryDetails data={record} />,
                  rowExpandable: (record) => true,
                }}
              />
            </div>
          </div>
        )}
      />
    </>
  )
}

export {StepEvents}
