/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {Link, useParams} from 'react-router-dom'
import {useLocation} from 'react-router'

const HeaderNav: React.FC = (props) => {
  const location = useLocation()

  const {id} = useParams<{id: string}>()
  const overviewPath = `/cases/details/${id}/overview`
  const eventsPath = `/cases/details/${id}/events`
  const commentsPath = `/cases/details/${id}/comments`
  const historyPath = `/cases/details/${id}/history`
  const documentsPath = `/cases/details/${id}/documents`
  const duplicatesPath = `/cases/details/${id}/duplicates`
  const versionsPath = `/cases/details/${id}/versions`

  return (
    <>
      <div className='d-flex overflow-auto h-55px'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
          <li className='nav-item'>
            <Link
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === overviewPath && 'active')
              }
              to={overviewPath}
            >
              Overview
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === eventsPath && 'active')
              }
              to={eventsPath}
            >
              Events
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === commentsPath && 'active')
              }
              to={commentsPath}
            >
              Communication
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === documentsPath && 'active')
              }
              to={documentsPath}
            >
              Attachments
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === historyPath && 'active')
              }
              to={historyPath}
            >
              History
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === versionsPath && 'active')
              }
              to={versionsPath}
            >
              Versions
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === duplicatesPath && 'active')
              }
              to={duplicatesPath}
            >
              Duplicates
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}

export {HeaderNav}
