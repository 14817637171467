/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useLocation} from "react-router";
import {ErrorBoundary} from "../../../modules/errors/ErrorBoundary";
import {createErrorData, ErrorData} from "../../../modules/errors/model/ErrorData";
import {useAuth0} from "@auth0/auth0-react";
import {CaseLinkService} from "../../../../generated/model";
import {useHistory} from "react-router-dom";
import {updateToken} from "../../../utils/AuthToken";


export function RegistrationLink() {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<ErrorData>();

    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const shareId = query.get("shareId");
    const companyName = query.get("companyName");
    const caseId = query.get("caseId");
    const caseIdentifier = query.get("caseIdentifier");

    const history = useHistory();
    const {getAccessTokenSilently} = useAuth0()
    const linkCase = async () => {
        try {
            setLoading(true)

            updateToken(await getAccessTokenSilently());
            await CaseLinkService.linkCase(caseId!, shareId!);

            history.push(`/cases/details/${caseId}/overview`)
        } catch (e) {
            setError(createErrorData(e))
            setLoading(false)
        }
    }
    return (
        <>
            <ErrorBoundary error={error}>
            </ErrorBoundary>

            <div className='mb-10 text-center'>
                {/* begin::Title */}
                <h1 className='text-dark mb-3'>Link Case to Account</h1>
                <div className='mb-3'>Company <b>{companyName}</b> shared case <b>{caseIdentifier}</b> with you. Link to
                    account?
                </div>
                {/* end::Title */}

                <div className='d-flex align-items-center mb-10'>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                </div>


                {/* begin::Action */}
                <button type='button' className='btn btn-primary fw-bolder w-100 mb-10'
                        disabled={loading} onClick={() => linkCase()}>
                    Accept
                </button>
                {/* end::Action */}
            </div>
        </>
    )
}
