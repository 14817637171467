import {FormikValues} from 'formik'
import {updateToken} from '../../../../../../utils/AuthToken'
import {CaseService} from '../../../../../../../generated/model'
import {IEditCase} from './Model'

export const save = async (values: IEditCase, actions: FormikValues, token: string) => {
  updateToken(token)

  if (values.case.identifier === '') values.case.identifier = null

  await CaseService.updateCase(values.case.id!, values.case)
}
