import {atom, selector} from 'recoil'
import {
  CaseDedupEventsInfoDto,
  CaseDedupEventsRequestInfoDto,
  CaseDeduplicationService,
  CaseDedupMedicineInfoDto,
  CaseDedupMedicineRequestInfoDto,
  CaseDedupPatientRequestInfoDto,
  CaseDto,
  CaseReporterDto,
  CaseSideEffectDto,
} from '../../../../generated/model'
import {
  CaseStepType,
  ReporterStepType,
  SideEffectStepType,
} from './management/_shared_helpers/Model'

export const caseStepState = atom<CaseStepType>({
  key: 'caseStepState',
  default: CaseStepType.Common,
})
export const caseSideEffectStepState = atom<SideEffectStepType>({
  key: 'caseSideEffectStepState',
  default: SideEffectStepType.SideEffect,
})
export const caseReporterStepState = atom<ReporterStepType>({
  key: 'caseReporterStepState',
  default: ReporterStepType.ReporterId,
})

// Medicine
export const caseForDedupMedicineState = atom<CaseDedupMedicineRequestInfoDto | undefined>({
  key: 'caseForDedupMedicineState',
  default: undefined,
})
export const caseDedupMedicineState = selector<CaseDedupMedicineInfoDto | undefined>({
  key: 'caseDedupMedicineState', // unique ID (with respect to other atoms/selectors)
  get: async ({get}) => {
    const cse = get(caseForDedupMedicineState)
    if (!cse) return undefined

    return CaseDeduplicationService.caseDedupMedicineInfo(cse)
  },
})
export const caseDedupDuplicateFoundState = atom<CaseDto | undefined>({
  key: 'caseDedupDuplicateFoundState',
  default: undefined,
})

// Patient
export const caseForDedupPatientState = atom<CaseDedupPatientRequestInfoDto | undefined>({
  key: 'caseForDedupPatientState',
  default: undefined,
})

export const caseDedupPatientState = selector<CaseDedupMedicineInfoDto | undefined>({
  key: 'caseDedupPatientState', // unique ID (with respect to other atoms/selectors)
  get: async ({get}) => {
    const cse = get(caseForDedupPatientState)
    if (!cse) return undefined
    if (!cse.patient?.sex || !cse.patient?.birthday) return undefined

    return CaseDeduplicationService.caseDedupPatientInfo(cse)
  },
})

// Events
export const caseForDedupEventsState = atom<CaseDedupEventsRequestInfoDto | undefined>({
  key: 'caseForDedupEventsState',
  default: undefined,
})

export const caseDedupEventsState = selector<CaseDedupEventsInfoDto | undefined>({
  key: 'caseDedupEventsState', // unique ID (with respect to other atoms/selectors)
  get: async ({get}) => {
    const cse = get(caseForDedupEventsState)
    if (!cse) return undefined
    if (!cse.events || cse.events.length === 0) return undefined

    return CaseDeduplicationService.caseDedupEventsInfo(cse)
  },
})

// Side Effect
export interface CaseSideEffectInEdit {
  case: CaseDto
  sideEffect: CaseSideEffectDto
  sideEffectsNotInEdit: CaseSideEffectDto[]
}
export const caseSideEffectInEditState = atom<CaseSideEffectInEdit | undefined>({
  key: 'caseSideEffectInEditState',
  default: undefined,
  dangerouslyAllowMutability: true,
})

// Reporter
export interface CaseReporterInEdit {
  case: CaseDto
  reporter: CaseReporterDto
  reportersNotInEdit: CaseReporterDto[]
}
export const caseReporterInEditState = atom<CaseReporterInEdit | undefined>({
  key: 'caseReporterInEditState',
  default: undefined,
  dangerouslyAllowMutability: true,
})
