/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useRecoilValue} from "recoil";
import {useCaseState} from "../State";
import {UseCase} from "../Model";
import {StatisticsWrapper} from "./statistics/StatisticWrapper";

const KpiPage: FC = () => {
    const useCase = useRecoilValue(useCaseState)
    return (
        <>
            <div className='row gy-5 g-xl-8'>
                <div className='col-xxl-6'>
                </div>
                <div className='col-xxl-6'>
                </div>
            </div>
            {useCase === UseCase.Company &&
            <div className='row gy-5 g-xl-8'>
                <div className='col-xxl-12'>
                </div>
            </div>
            }
            <StatisticsWrapper/>
        </>
    )
};


const KpiWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.KPI'})}</PageTitle>
            <KpiPage/>
        </>
    )
}

export {KpiWrapper}
