import {SortingDirection} from '../../generated/model'
import {SortOrder} from 'antd/lib/table/interface'

export function toSortingDirection(sortOrder: SortOrder): SortingDirection {
  switch (sortOrder) {
    case 'descend':
      return SortingDirection.DESCENDING

    default:
      return SortingDirection.ASCENDING
  }
}
