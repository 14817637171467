/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MeddraHighLevelTermDto } from '../models/MeddraHighLevelTermDto';
import type { MeddraHighLevelTermUpdateDto } from '../models/MeddraHighLevelTermUpdateDto';
import { request as __request } from '../core/request';

export class MeddraHighLevelTermService {

    /**
     * @param hgltId 
     * @param language 
     * @returns MeddraHighLevelTermDto Success
     * @throws ApiError
     */
    public static async getMeddraHighLevelTerms(
hgltId?: string,
language?: string,
): Promise<Array<MeddraHighLevelTermDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/core/v1/meddra/highLevelTerm`,
            query: {
                'hgltId': hgltId,
                'language': language,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async saveMeddraHighLevelTermUpdate(
requestBody?: MeddraHighLevelTermUpdateDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/core/v1/meddra/highLevelTerm`,
            body: requestBody,
        });
        return result.body;
    }

}