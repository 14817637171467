/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Tooltip} from 'antd'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {CaseDto, CaseSource, CaseStatus} from '../../../../../../generated/model'
import {CaseIdentifier} from '../../_shared/components/case-identifier/CaseIdentifier'
import {PatientIdentifier} from '../../_shared/components/patient-identifier/PatientIdentifier'
import ColumnMedicineStyle from '../../../../../modules/pv/cases/_shared/css/ColumnMedicine'
import ColumnCompanyStyle from '../../../../../modules/pv/cases/_shared/css/ColumnCompany'
import moment from 'moment'
import {SortOrder} from 'antd/es/table/interface'
import {getCaseStatusName} from '../../_shared/helpers/CasesStatusHelpers'
import {ReportersIdentifier} from '../../_shared/components/reporters-identifier/ReportersIdentifier'

export default function getColumnsDoctor(
  detailsCaseClick: (id: string) => void,
  editCaseClick: (id: string) => void,
  deleteCaseClick: (id: string) => void
) {
  return [
    {
      title: 'Case Id',
      dataIndex: '.',
      key: 'identifier',
      render: (text: string, record: CaseDto) => (
        <a
          href={`/cases/details/${record.id}`}
          onClick={(e) => {
            e.preventDefault()
            detailsCaseClick(record.id!)
          }}
        >
          <>
            {record.source === CaseSource.COMPANY && <span>{record?.serial?.globalId}</span>}
            {record.source === CaseSource.DOCTOR && <CaseIdentifier data={record} />}
          </>
        </a>
      ),
    },
    {
      title: 'Updated At',
      dataIndex: '.',
      key: 'modifiedAt',
      sorter: (a: CaseDto, b: CaseDto) => moment(a.modifiedAt).unix() - moment(b.modifiedAt).unix(),
      defaultSortOrder: 'descend' as SortOrder,
      render: (text: string, record: CaseDto) => (
        <span>{moment(record.modifiedAt).format('DD.MM.yyyy HH:mm')}</span>
      ),
      ellipsis: true,
      width: 150,
    },
    {
      title: 'Patient',
      dataIndex: '.',
      key: 'patient',
      render: (text: string, record: CaseDto) => (
        <>
          {record.source === CaseSource.COMPANY && <span>{record.patient?.serial?.globalId}</span>}
          {record.source === CaseSource.DOCTOR && <PatientIdentifier data={record.patient!} />}
        </>
      ),
    },
    {
      title: 'Reporters',
      dataIndex: '.',
      key: 'reporters',
      render: (text: string, record: CaseDto) => (
        <>
          <ReportersIdentifier data={record.caseReporters!} />
        </>
      ),
    },
    {
      title: 'Medicine',
      dataIndex: '.',
      key: 'medicine',
      sorter: (a: CaseDto, b: CaseDto) =>
        (a.medicine?.name ?? '').localeCompare(b.medicine?.name ?? ''),
      render: (text: string, record: CaseDto) => (
        <Tooltip title={record?.medicine?.name}>
          <div style={ColumnMedicineStyle}>{record?.medicine?.name}</div>
        </Tooltip>
      ),
    },
    {
      title: 'Company',
      dataIndex: '.',
      key: 'company',
      sorter: (a: CaseDto, b: CaseDto) =>
        (a.company?.name ?? '').localeCompare(b.company?.name ?? ''),
      render: (text: string, record: CaseDto) => (
        <Tooltip title={record?.company?.name}>
          <div style={ColumnCompanyStyle}>{record?.company?.name}</div>
        </Tooltip>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a: CaseDto, b: CaseDto) =>
        a!.status!.toString().localeCompare(b!.status!.toString()),
      sortDirections: ['ascend' as SortOrder, 'descend' as SortOrder],
      render: (text: string, record: CaseDto) => (
        <span className='badge badge-light-info'>{getCaseStatusName(record.status!)}</span>
      ),
    },
    {
      title: 'Actions',
      dataIndex: '.',
      key: 'actions',
      width: '150px',
      render: (text: string, record: any) => {
        return (
          <>
            {(record.status === CaseStatus.CREATED ||
              record.status === CaseStatus.WITHDRAWN ||
              record.status === CaseStatus.DECLINED) && (
              <a
                href='#'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                onClick={(e) => {
                  e.preventDefault()
                  editCaseClick(record.id)
                }}
              >
                <KTSVG path='/media/icons/duotone/Communication/Write.svg' className='svg-icon-3' />
              </a>
            )}
            {record.status === CaseStatus.CREATED && (
              <a
                href='#'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                onClick={(e) => {
                  e.preventDefault()
                  deleteCaseClick(record.id)
                }}
              >
                <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
              </a>
            )}
          </>
        )
      },
    },
  ]
}
