/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CaseMedicineSelector} from '../../_shared/components/case-medicine-selector/CaseMedicineSelector'
import {CaseRequestDto, TimeFrameField} from '../../../../../../generated/model'
import {CasePatientSelector} from '../../_shared/components/case-patient-selector/CasePatientSelector'
import {CaseReporterSelector} from '../../_shared/components/case-reporter-selector/CaseReporterSelector'
import {CaseSideEffectSelector} from '../../_shared/components/case-side-effect-selector/CaseSideEffectSelector'
import {CaseStatusSelector} from '../../_shared/components/case-status-selector/CaseStatusSelector'
import {CaseTimeframeSelector} from '../../_shared/components/case-timeframe-selector/CaseReporterSelector'
import {CaseFilter} from './CaseFilter'

interface Props {
  request: CaseRequestDto
  onChangeTable: (req: CaseRequestDto) => void
}
const CaseFilters: React.FC<Props> = (props) => {
  return (
    <>
      <div className='mb-5 row'>
        <CaseFilter sizeClass={'col-lg-4'} name='Medicine'>
          <CaseMedicineSelector
            onClear={() => {
              const req = props.request
              req.medicineId = undefined
              props.onChangeTable(req)
            }}
            onSelected={(m) => {
              const req = props.request
              req.medicineId = m?.id
              props.onChangeTable(req)
            }}
          />
        </CaseFilter>
        <CaseFilter sizeClass={'col-lg-4'} name='Side Effect'>
          <CaseSideEffectSelector
            onClear={() => {
              const req = props.request
              req.meddraLowLevelTermId = undefined
              props.onChangeTable(req)
            }}
            onSelected={(m) => {
              const req = props.request
              req.meddraLowLevelTermId = m?.id
              props.onChangeTable(req)
            }}
          />
        </CaseFilter>

        <CaseFilter sizeClass={'col-lg-4'} name='Timeframe'>
          <CaseTimeframeSelector
            field={props.request.timeFrameField ?? TimeFrameField.CREATED_AT}
            onSelected={(from, to) => {
              const req = props.request
              req.from = from
              req.to = to
              props.onChangeTable(req)
            }}
            onSelectedField={(field) => {
              const req = props.request
              req.timeFrameField = field
              props.onChangeTable(req)
            }}
          />
        </CaseFilter>
      </div>
      <div className='mb-5 row pb-5 border-bottom'>
        <CaseFilter sizeClass={'col-lg-3'} name='Reporter'>
          <CaseReporterSelector
            onClear={() => {
              const req = props.request
              req.reporterId = undefined
              props.onChangeTable(req)
            }}
            onSelected={(m) => {
              const req = props.request
              req.reporterId = m?.id
              props.onChangeTable(req)
            }}
          />
        </CaseFilter>
        <CaseFilter sizeClass={'col-lg-3'} name='Patient'>
          <CasePatientSelector
            onClear={() => {
              const req = props.request
              req.patientId = undefined
              props.onChangeTable(req)
            }}
            onSelected={(m) => {
              const req = props.request
              req.patientId = m?.id
              props.onChangeTable(req)
            }}
          />
        </CaseFilter>
        <CaseFilter sizeClass={'col-lg-6'} name='Status'>
          <CaseStatusSelector
            selected={props.request.status!}
            onClear={() => {
              const req = props.request
              req.status = undefined
              props.onChangeTable(req)
            }}
            onSelected={(m) => {
              const req = props.request
              req.status = m
              props.onChangeTable(req)
            }}
          />
        </CaseFilter>
      </div>
    </>
  )
}

export {CaseFilters}
