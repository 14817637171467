/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {useRecoilValue} from 'recoil'
import {useCaseState} from '../../../../State'
import {UseCase} from '../../../../Model'
import {EventsDoctor} from './_mode_doctor/EventsDoctor'
import {EventsCompany} from './_mode_company/EventsCompany'

interface Props {
}

const Events: React.FC<Props> = (props) => {
  const useCase = useRecoilValue(useCaseState)

  return (
    <>
      {useCase === UseCase.Doctor && <EventsDoctor />}
      {useCase === UseCase.Company && (
        <EventsCompany  />
      )}
    </>
  )
}

export {Events}
