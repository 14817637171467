/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Statistic} from "./Statistic";
import {CaseKpiStatisticsDto} from "../../../../../../generated/model";
import {Percentage} from "./Percentage";
import {Partition} from "./Partition";

interface Props {
    statistics: CaseKpiStatisticsDto
}

function round(num: number): number {
    return Math.round(num * 100) / 100
}

function getSubmittedInTimePercentage(statistics: CaseKpiStatisticsDto): number {
    if (statistics.caseCountExported === 0)
        return 100;

    return round((statistics.caseExportCountUnderDeadline! / statistics.caseExportCount!) * 100);
}

function getNotSubmittedInTimePercentage(statistics: CaseKpiStatisticsDto): number {
    if (statistics.caseCountExported === 0)
        return 0;

    return round((statistics.caseExportCountOverDeadline! / statistics.caseExportCount!) * 100);
}

function getColorForTime(time: number):string{
    if( time <= 12)
        return '#32CD32';
    if( time <= 18)
        return '#008000';
    if( time <= 24)
        return '#FFFF00';
    if( time <= 28)
        return '#FF0000';

    return '#8B0000'
}

function getTimesForExport(statistics: CaseKpiStatisticsDto): { label: string, value: number, color: string }[] {
    const result = statistics.timesToSubmit!.map(x => {
        return ({
            label: `${x.time === 32 ? '>' : '<'}${x.time!.toString()}h: ${x.count!}`,
            value: x.count!,
            color: getColorForTime(x.time!)
        })
    });
    return result;
}


const Statistics: FC<Props> = (props) => {
    const timesForExport = getTimesForExport(props.statistics);
    return (
        <>
            <div className='row g-6 g-xl-9'>
                <div className='col-sm-6 col-xl-4'>
                    <Statistic
                        image='/media/icons/duotone/Files/File.svg'
                        title='Open'
                        description={props.statistics.caseCountOpen!.toString()}
                        statusDesc='Number of open cases'
                    />
                </div>
                <div className='col-sm-6 col-xl-4'>
                    <Statistic
                        image='/media/icons/duotone/Files/Uploaded-file.svg'
                        title='Exported'
                        description={props.statistics.caseCountExported!.toString()}
                        statusDesc='Number of exported cases'
                    />
                </div>
                <div className='col-sm-6 col-xl-4'>
                    <Statistic
                        image='/media/icons/duotone/Files/File-done.svg'
                        title='Total'
                        description={props.statistics.caseCount!.toString()}
                        statusDesc='Total number of cases'
                    />
                </div>
            </div>
            <div className='row g-6 g-xl-9 py-10'>
                <div className='col-sm-6 col-xl-4'>
                    <Percentage title="Exported in time" subtitle="Cases exported within deadline time"
                                chartColor="green" percentage={getSubmittedInTimePercentage(props.statistics)}/>
                </div>
                <div className='col-sm-6 col-xl-4'>
                    <Percentage title="Not exported in time" subtitle="Cases not exported within deadline time"
                                chartColor="red" percentage={getNotSubmittedInTimePercentage(props.statistics)}/>
                </div>
                <div className='col-sm-6 col-xl-4'>
                    <Partition title="Times for export" subtitle="Overview of time till export"
                               timesForExport={timesForExport}/>
                </div>
            </div>
        </>
    )
}

export {Statistics}
