/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'

import {CaseEventDto, CaseEventType} from '../../../../../../../generated/model'
import moment from 'moment'
import {HistoryChange} from "./HistoryChange";
import {createCsv, createCsvHeaders} from "../comments/CommentsHelper";
import { CSVLink } from "react-csv";

interface Props {
  events: CaseEventDto[],
  reload: () => void;
}

const HistoryData: React.FC<Props> = (props) => {
  const [recentOnly, setRecentOnly] = useState<boolean>(true)

  let events:CaseEventDto[] = [];
  if( recentOnly ){
    let found = false;
    for(const ev of props.events!){

      if(ev.eventType === CaseEventType.EXPORT) {
        found = true;

        events.push(ev);
      }
      if( found )
        break;

      events.push(ev);
    }
  }
  else {
    events = props.events!;
  }

  const headersData = createCsvHeaders();
  const csvData = createCsv(events);

  return (
    <div className='card'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>History</span>
          <span className='text-muted mt-1 fw-bold fs-7'>
            Create and view all relevant case events ...
          </span>
        </h3>
        <div>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                  className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 ${
                      recentOnly && 'active'
                  }`}
                  href='#'
                  onClick={(e) => {
                    e.preventDefault()
                    setRecentOnly(true)
                  }}
              >
                Since last export
              </a>
            </li>
            <li className='nav-item'>
              <a
                  className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 ${
                      !recentOnly && 'active'
                  }`}
                  href='#'
                  onClick={(e) => {
                    e.preventDefault()
                    setRecentOnly(false)
                  }}
              >
                All events
              </a>
            </li>
            <li className='nav-item'>
              <CSVLink className={`btn btn-sm fw-bolder px-4 me-1`} data={csvData} headers={headersData} filename="Export.csv">Export</CSVLink>

          </li>
            <li className='nav-item'>
              <a
                  className={`btn btn-sm fw-bolder px-4 me-1`}
                  href='#'
                  onClick={(e) => {
                    e.preventDefault()
                    props.reload()
                  }}
              >
                Reload
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className='card-body'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bolder text-muted bg-light'>
                <th className='ps-4 rounded-start min-w-100px'>Date</th>
                <th className='ps-4 rounded-start min-w-100px'>Type</th>
                <th className='ps-4 min-w-400px'>Data</th>
                <th className='ps-4 min-w-100px'>User</th>
              </tr>
            </thead>
            <tbody>
              {events?.map((ev) => (
                <tr key={ev.id}>
                  <td>{moment(ev.createdAt).format('DD.MM.yyyy HH:mm')}</td>
                  <td>{ev.eventType}</td>
                  <td>
                    <HistoryChange ev={ev}></HistoryChange>
                  </td>
                  <td>{ev.user}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export {HistoryData}
