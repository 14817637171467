/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CaseEventType {
    TRANSITION = 'Transition',
    SHARE = 'Share',
    COMMENT = 'Comment',
    EXPORT = 'Export',
    CHANGE = 'Change',
    ATTACHMENT = 'Attachment',
}