/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {
  FormikErrors,
  FormikValues,
  FormikTouched,
  FieldArray,
  FieldArrayRenderProps,
  ErrorMessage,
} from 'formik'
import {CaseReporterDto, CaseSideEffectDto} from '../../../../../../generated/model'
import {Table} from 'antd'
import {useSetRecoilState} from 'recoil'
import {caseReporterInEditState} from '../../State'
import {CaseEventSummaryDetails} from '../../_shared/components/case-event-summary/CaseEventSummaryDetails'
import {getReporterColumns} from './StepReportersColumns'
import {DatePicker} from 'formik-antd'

interface EventsStepProps {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  values: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void

  showReporterData: () => void
}

const StepReporters: FC<EventsStepProps> = (props) => {
  const setCaseReporterInEditState = useSetRecoilState(caseReporterInEditState)
  const editReporter = (
    reporter: CaseSideEffectDto | undefined,
    helpers: FieldArrayRenderProps
  ) => {
    const editState = {
      case: props.values.case!,
      reporter: reporter!,
      reportersNotInEdit: props.values.case!.caseReporters?.filter(
        (x: CaseReporterDto) => x !== reporter
      ),
    }
    setCaseReporterInEditState(editState)
    props.showReporterData()
  }
  const onSelectReportedAt = (value: any) => {
    const cse = props.values['case']
    const reportedAt = value.utc()
    cse.reportedAt = reportedAt.format()

    props.setFieldValue('case', cse)
    props.setFieldValue('reportedAtTemp', value)
  }
  const onSelectSubmitDeadline = (value: any) => {
    const cse = props.values['case']
    cse.submitDeadline = value.utc().format()
    props.setFieldValue('case', cse)
    props.setFieldValue('submitDeadlineTemp', value)
  }
  function validateSubmitDeadline(value: string) {
    let error
    if (!value) {
      error = 'Reported At is required!'
    }
    return error
  }
  function validateReportedAt(value: string) {
    let error
    if (!value) {
      error = 'Reported At is required!'
    }
    return error
  }
  return (
    <>
      <div className='mb-10 row'>
        <div className='col-lg-6 fv-row'>
          <label className='form-label mb-3 required'>Reported At</label>

          <div>
            <DatePicker
              name='reportedAtTemp'
              validate={validateReportedAt}
              showTime={true}
              placeholder='DatePicker'
              className='form-control '
              onSelect={onSelectReportedAt}
              popupStyle={{zIndex: 10024}}
            />
            <div className='fv-plugins-message-container invalid-feedback d-block'>
              <ErrorMessage name='reportedAtTemp' />
            </div>
          </div>
        </div>
        <div className='col-lg-6 fv-row'>
          <label className='form-label mb-3 required'>Internal Deadline</label>

          <div>
            <DatePicker
              name='submitDeadlineTemp'
              validate={validateSubmitDeadline}
              showTime={true}
              placeholder='DatePicker'
              className='form-control '
              onSelect={onSelectSubmitDeadline}
              popupStyle={{zIndex: 10024}}
            />
            <div className='fv-plugins-message-container invalid-feedback d-block'>
              <ErrorMessage name='submitDeadlineTemp' />
            </div>
          </div>
        </div>
      </div>

      <FieldArray
        name='case.caseReporters'
        render={(helpers) => (
          <div className='w-100 border-top'>
            <div className='mb-10 fv-row'>
              <div className='card-header border-0'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label'>Reporters</span>
                </h3>
                <div className='card-toolbar'>
                  <button
                    type='button'
                    className='btn btn-sm btn-color-primary btn-active-light-primary'
                    onClick={(e) => {
                      e.preventDefault()
                      editReporter(undefined, helpers)
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
              <Table
                dataSource={props.values.case.caseReporters}
                columns={getReporterColumns(editReporter, helpers)}
                rowKey='identifier'
                expandable={{
                  expandedRowRender: (record) => <CaseEventSummaryDetails data={record} />,
                  rowExpandable: (record) => false,
                }}
              />
            </div>
          </div>
        )}
      />
    </>
  )
}

export {StepReporters}
