import {
  CaseRequestDto,
  CaseStatus,
  SortingDirection,
  TimeFrameField,
} from '../../../../../generated/model'

export function DefaultRequestDataCompany(): CaseRequestDto {
  return {
    page: 0,
    pageSize: 10,
    status: [
      CaseStatus.CREATED_COMPANY,
      CaseStatus.SUBMITTED,
      CaseStatus.IN_REVIEW,
      CaseStatus.ACCEPTED,
      CaseStatus.EXPORTED,
    ],
    sortDirection: SortingDirection.DESCENDING,
    sortColumn: 'modifiedAt',
    timeFrameField: TimeFrameField.UPDATED_AT,
  }
}

export function DefaultRequestDataDoctor(): CaseRequestDto {
  return {
    page: 0,
    pageSize: 10,
    status: [
      CaseStatus.CREATED,
      CaseStatus.SUBMITTED,
      CaseStatus.IN_REVIEW,
      CaseStatus.ACCEPTED,
      CaseStatus.WITHDRAWN,
      CaseStatus.DECLINED,
    ],
    sortDirection: SortingDirection.DESCENDING,
    sortColumn: 'modifiedAt',
    timeFrameField: TimeFrameField.UPDATED_AT,
  }
}
