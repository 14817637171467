/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {CaseCommentService, RecentCaseCommentDto} from '../../../../../generated/model'
import {ErrorBoundary} from '../../../errors/ErrorBoundary'
import {createErrorData, ErrorData} from '../../../errors/model/ErrorData'
import {updateToken} from '../../../../utils/AuthToken'
import {LoadingBoundary} from '../../../_shared/LoadingBoundary'
import {Notifications} from './Notifications'

const NotificationsWrapper: FC = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ErrorData>()
  const [data, setData] = useState<RecentCaseCommentDto[]>()

  const {getAccessTokenSilently} = useAuth0()

  useEffect(() => {
    const getData = async () => {
      try {
        updateToken(await getAccessTokenSilently())

        const data = await CaseCommentService.getRecentComments()
        setData(data)
        setError(undefined)
        setLoading(false)
      } catch (e) {
        setError(createErrorData(e))
        setData(undefined)
        setLoading(false)
      }
    }

    getData()
  }, [getAccessTokenSilently])

  return (
    <>
      <LoadingBoundary loading={loading}>
        <ErrorBoundary error={error}>{data && <Notifications data={data} />}</ErrorBoundary>
      </LoadingBoundary>
    </>
  )
}

export {NotificationsWrapper}
