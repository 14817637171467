/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CaseInputCaseDto, CaseStatus} from "../../../../../generated/model";
import moment from "moment";
import {SortOrder} from "antd/es/table/interface";
import {CaseIdentifier} from "../../../../modules/pv/cases/_shared/components/case-identifier/CaseIdentifier";

export default function getColumns(
    detailsCaseClick: (id: string) => void) {
    return [
        {
            title: 'Updated At',
            dataIndex: '.',
            key: 'modifiedAt',
            sorter: (a: CaseInputCaseDto, b: CaseInputCaseDto) => moment(a.modifiedAt).unix() - moment(b.modifiedAt).unix(),
            defaultSortOrder: 'descend' as SortOrder,
            render: (text: string, record: CaseInputCaseDto) =>
                <span>{moment(record.modifiedAt).format("DD.MM.yyyy")}</span>
        },
        {
            title: 'Case Id',
            dataIndex: '.',
            key: 'identifier',
            render: (text: string, record: CaseInputCaseDto) => <a
                href='#'
                onClick={(e) => {
                    e.preventDefault();
                    detailsCaseClick(record.id!)
                }}>
                <CaseIdentifier data={record}/>
            </a>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a: CaseInputCaseDto, b: CaseInputCaseDto) => a!.status!.toString().localeCompare(b!.status!.toString()),
            sortDirections: ['ascend' as SortOrder, 'descend' as SortOrder],
            render: (text: string) => <span className='badge badge-light-info'>{text}</span>,
            onFilter: (value: any, record: CaseInputCaseDto) => record!.status!.toString() === value,
            defaultFilteredValue: [CaseStatus.CREATED, CaseStatus.SUBMITTED, CaseStatus.IN_REVIEW, CaseStatus.ACCEPTED],
            filters: [
                {
                    text: 'Created',
                    value: CaseStatus.CREATED,
                },
                {
                    text: 'Submitted',
                    value: CaseStatus.SUBMITTED,
                },
                {
                    text: 'In Review',
                    value: CaseStatus.IN_REVIEW,
                },
                {
                    text: 'Accepted',
                    value: CaseStatus.ACCEPTED,
                },
                {
                    text: 'Exported',
                    value: CaseStatus.EXPORTED,
                },
            ],
        }
    ];
}
