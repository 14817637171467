/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'

import {CaseDocumentDto} from '../../../../../../../generated/model'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {Modal} from "react-bootstrap-v5";
import {AttachmentsForm} from "./AttachmentsForm";
import {Form, Formik, FormikValues} from "formik";
import * as Yup from "yup";

interface Props {
  show: boolean
  handleClose: () => void

  saveDocument: (document: CaseDocumentDto) => void
}

export const schema = Yup.object().shape({
  description: Yup.string().required('Description is required'),
  originalName: Yup.string().required(),
  storageName: Yup.string().required(),
})

const AttachmentsModal: React.FC<Props> = (props) => {
  const [document, setDocument] = useState<CaseDocumentDto>({})

  const submitStep = async (values: CaseDocumentDto, actions: FormikValues) => {
      props.saveDocument(values);
  };
  return (
      <Modal
          tabIndex={-1}
          aria-hidden='true'
          className='modal fade'
          dialogClassName='modal-dialog modal-dialog-centered mw-800px'
          show={props.show}
          onHide={() =>  {
            props.handleClose();}}
          onShow={() => {
            setDocument({})
          }}>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>Upload File</h2>

            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={props.handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body'>
            <Formik
                validationSchema={schema}
                initialValues={document}
                onSubmit={submitStep}
                enableReinitialize={true}>
              {({errors, touched, values, setFieldValue}) => (
              <Form noValidate>
                <AttachmentsForm errors={errors}
                                 touched={touched}
                                 values={values}
                                 setFieldValue={setFieldValue}/>
              </Form>
                  )}
            </Formik>
          </div>
        </div>
      </Modal>
  )
}

export {AttachmentsModal}
