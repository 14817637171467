import {CaseCreateDto, SerialDto} from '../../../../../../../generated/model'
import {IManageCase, PatientAccountType} from '../../_shared_helpers/Model'
import moment from 'moment'

export interface ICreateCase extends IManageCase {
  // real entity
  case: CaseCreateDto

  // additional data
  patientId: SerialDto
}

export function createInitialState(
  caseId: SerialDto,
  patientId: SerialDto,
  inputId: string | null
): ICreateCase {
  return {
    case: {
      reportedAt: moment().utc().format(),
      serial: caseId!,
      patient: {
        serial: patientId,
        latestData: {
          birthday: moment().utc().subtract(10, 'years').utc().format(),
        },
      },
      caseSideEffects: [],
      caseReporters: [],
      caseInputId: inputId,
      submitDeadline: moment().utc().add(1, 'days').utc().format(),
    },
    patientId,
    patientAccountType: PatientAccountType.Existing,

    birthdayTemp: moment().utc().subtract(10, 'years').utc().format(),
    reportedAtTemp: moment().utc().format(),
    submitDeadlineTemp: moment().utc().add(1, 'days').utc().format(),
    sexTemp: {
      key: '',
      label: '',
      value: '',
    },
    categoryTemp: {
      value: '',
      label: '',
      key: '',
    },
    seriousnessTemp: {
      value: '',
      label: '',
      key: '',
    },
  }
}
