/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {FormikErrors, FormikTouched, FormikValues} from 'formik'
import {CaseStepType} from '../_shared_helpers/Model'
import {StepPatientData} from './StepPatientData'
import {StepEvents} from './StepEvents'
import {StepEvaluation} from './StepEvaluation'
import {FieldInputProps} from 'formik/dist/types'
import {StepReporters} from './StepReporters'

interface Props {
  currentStep: CaseStepType

  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  values: FormikValues

  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>

  setupStep: (stepType: CaseStepType) => void
}

const SharedSteps: FC<Props> = (props) => {
  const currentStep = props.currentStep
  const errors = props.errors
  const touched = props.touched
  const values = props.values
  const setFieldValue = props.setFieldValue
  const getFieldProps = props.getFieldProps

  return (
    <>
      {currentStep === CaseStepType.Reporters && (
        <div className='current' data-kt-stepper-element='content'>
          <StepReporters
            errors={errors}
            touched={touched}
            values={values}
            setFieldValue={setFieldValue}
            showReporterData={() => props.setupStep(CaseStepType.ReporterData)}
          />
        </div>
      )}

      {currentStep === CaseStepType.PatientData && (
        <div className='current' data-kt-stepper-element='content'>
          <StepPatientData
            errors={errors}
            touched={touched}
            values={values}
            setFieldValue={setFieldValue}
          />
        </div>
      )}

      {currentStep === CaseStepType.Events && (
        <div className='current' data-kt-stepper-element='content'>
          <StepEvents
            errors={errors}
            touched={touched}
            values={values}
            setFieldValue={setFieldValue}
            showSideEffectData={() => props.setupStep(CaseStepType.EventData)}
          />
        </div>
      )}

      {currentStep === CaseStepType.Evaluation && (
        <div className='current' data-kt-stepper-element='content'>
          <StepEvaluation
            errors={errors}
            touched={touched}
            values={values}
            setFieldValue={setFieldValue}
            getFieldProps={getFieldProps}
          />
        </div>
      )}
    </>
  )
}

export {SharedSteps}
