/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {FormikErrors, FormikValues, FormikTouched, ErrorMessage} from 'formik'
import {FieldInputProps} from 'formik/dist/types'
import {DatePicker, Select} from 'formik-antd'
import countryList from 'react-select-country-list'

interface Step3Props {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  values: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>
}

const StepReactionProperties: FC<Step3Props> = (props) => {
  const [countryData, setCountryData] = useState<any[]>(countryList().getData())
  const onSelectStartOfReaction = (value: any) => {
    const cse = props.values['sideEffect']
    cse.reaction.startOfReaction = value.utc().format()
    props.setFieldValue('sideEffect', cse)
    props.setFieldValue('startOfReactionTemp', value)
  }
  const onSelectEndOfReaction = (value: any) => {
    const cse = props.values['sideEffect']
    cse.reaction.endOfReaction = value.utc().format()
    props.setFieldValue('endOfReaction', cse)
    props.setFieldValue('endOfReactionTemp', value)
  }
  const onSelectCountry = (value: any, option: any) => {
    const cse = props.values['sideEffect']
    cse.reaction.occuredInCountry = option.key
    props.setFieldValue('sideEffect', cse)
  }

  const onSearchCountry = async (filter: string) => {
    if (filter && filter.length > 0) {
      const data = countryList()
        .getData()
        .filter(
          (x) =>
            x.value.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
            x.label.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        )
      setCountryData(data)
    } else setCountryData(countryList().getData())
  }

  return (
    <div className='w-100' style={{minHeight: 530}}>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Properties</label>

        <div className='form-check form-check-custom form-check-solid me-5'>
          <input
            className='form-check-input'
            type='checkbox'
            id='inlineCheckboxHospitalization'
            checked={props.values['sideEffect'].reaction.hospitalisation}
            {...props.getFieldProps('sideEffect.reaction.hospitalisation')}
          />
          <label className='form-check-label fw-bold' htmlFor='inlineCheckboxHospitalization'>
            Hospitalization
          </label>

          <span className='px-lg-3' />

          <input
            className='form-check-input'
            type='checkbox'
            id='inlineCheckboxLifeThreatening'
            checked={props.values['sideEffect'].reaction.lifeThreatening}
            {...props.getFieldProps('sideEffect.reaction.lifeThreatening')}
          />
          <label className='form-check-label fw-bold' htmlFor='inlineCheckboxLifeThreatening'>
            Life Threatening
          </label>

          <span className=' px-lg-3' />

          <input
            className='form-check-input'
            type='checkbox'
            id='inlineCheckboxIncapacitating'
            checked={props.values['sideEffect'].reaction.incapacitating}
            {...props.getFieldProps('sideEffect.reaction.incapacitating')}
          />
          <label className='form-check-label fw-bold' htmlFor='inlineCheckboxIncapacitating'>
            Incapacitating
          </label>
        </div>
        <div className='form-check form-check-custom form-check-solid me-5 py-lg-5'>
          <input
            className='form-check-input'
            type='checkbox'
            checked={props.values['sideEffect'].reaction.otherMedicallyImportantCondition}
            id='inlineCheckboxOtherMedicallyImportantCondition'
            {...props.getFieldProps('sideEffect.reaction.otherMedicallyImportantCondition')}
          />
          <label
            className='form-check-label fw-bold'
            htmlFor='inlineCheckboxOtherMedicallyImportantCondition'
          >
            Other Medically Important Condition
          </label>

          <span className='px-lg-5' />
          <input
            className='form-check-input'
            type='checkbox'
            id='inlineCheckboxBirthDefect'
            checked={props.values['sideEffect'].reaction.birthDefect}
            {...props.getFieldProps('sideEffect.reaction.birthDefect')}
          />
          <label className='form-check-label fw-bold' htmlFor='inlineCheckboxBirthDefect'>
            Birth Defect
          </label>
        </div>
      </div>
      <div className='mb-10 fv-row col-lg-6'>
        <label className='form-label mb-3  required'>Start Of Reaction</label>

        <DatePicker
          name='startOfReactionTemp'
          showTime={true}
          placeholder='DatePicker'
          onSelect={onSelectStartOfReaction}
          popupStyle={{zIndex: 10024}}
        />
        <div className='fv-plugins-message-container invalid-feedback d-block'>
          <ErrorMessage name='startOfReactionTemp' />
        </div>
      </div>

      <div className='mb-10 fv-row col-lg-6'>
        <label className='form-label mb-3'>End Of Reaction</label>

        <DatePicker
          name='endOfReactionTemp'
          showTime={true}
          placeholder='Ongoing'
          onSelect={onSelectEndOfReaction}
          popupStyle={{zIndex: 10024}}
        />
        <div className='fv-plugins-message-container invalid-feedback d-block'>
          <ErrorMessage name='endOfReactionTemp' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Occured In Country</label>

        <Select
          name='occuredInCountryTemp'
          dropdownStyle={{zIndex: 1200}}
          labelInValue
          optionLabelProp='label'
          placeholder='Select country'
          onSelect={onSelectCountry}
          onSearch={onSearchCountry}
          showSearch
          filterOption={false}
          style={{width: '100%'}}
        >
          {countryData.map((country) => (
            <Select.Option key={country.value} value={country.value} label={country.label}>
              {country.label}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  )
}

export {StepReactionProperties}
