/* eslint-disable no-useless-concat */

import {SideEffectStepType} from '../../../management/_shared_helpers/Model'
import {CaseSideEffectDto} from '../../../../../../../generated/model'

export const getSchemas = (step: SideEffectStepType) => {
  switch (step) {
    case SideEffectStepType.SideEffect:
      break
    case SideEffectStepType.SideEffectProperties:
      break
    case SideEffectStepType.ReactionProperties:
      break
    case SideEffectStepType.ReactionNarrative:
      break
    case SideEffectStepType.OutcomeProperties:
      break
    case SideEffectStepType.OutcomeNarrative:
      break
  }
}
export const getPrevStep = (step: SideEffectStepType) => {
  let newStep = step

  switch (step) {
    case SideEffectStepType.SideEffectProperties:
      newStep = SideEffectStepType.SideEffect
      break
    case SideEffectStepType.ReactionProperties:
      newStep = SideEffectStepType.SideEffectProperties
      break
    case SideEffectStepType.ReactionNarrative:
      newStep = SideEffectStepType.ReactionProperties
      break
    case SideEffectStepType.OutcomeProperties:
      newStep = SideEffectStepType.ReactionNarrative
      break
    case SideEffectStepType.OutcomeNarrative:
      newStep = SideEffectStepType.OutcomeProperties
      break
  }

  return newStep
}
export const getNextStep = (step: SideEffectStepType) => {
  let newStep = step

  switch (step) {
    case SideEffectStepType.SideEffect:
      newStep = SideEffectStepType.SideEffectProperties
      break
    case SideEffectStepType.SideEffectProperties:
      newStep = SideEffectStepType.ReactionProperties
      break
    case SideEffectStepType.ReactionProperties:
      newStep = SideEffectStepType.ReactionNarrative
      break
    case SideEffectStepType.ReactionNarrative:
      newStep = SideEffectStepType.OutcomeProperties
      break
    case SideEffectStepType.OutcomeProperties:
      newStep = SideEffectStepType.OutcomeNarrative
      break
  }

  return newStep
}

export function createReactionDescription(cse: CaseSideEffectDto) {
  cse!.reaction!.descriptionGenerated = 'Patient experienced the following side effect: ' + '\n'
  cse!.reaction!.descriptionGenerated += '  - MedDRA Code: ' + cse!.lowLevelTerm!.code + '\n'

  let therapy = ''
  if (cse.medication) {
    if (cse.medication.startOfTherapy)
      therapy += ' - Started on: ' + cse.medication.startOfTherapy + '\n'
    if (cse.medication.endOfTherapy) therapy += ' - Ended on: ' + cse.medication.endOfTherapy + '\n'
    if (cse.medication.dosage) therapy += ' - Dosage: ' + cse.medication.dosage + '\n'
    if (cse.medication.wayOfAdministration)
      therapy += ' - Way of Administration: ' + cse.medication.wayOfAdministration + '\n'
    if (cse.medication.chargeNumber)
      therapy += ' - Charge number: ' + cse.medication.chargeNumber + '\n'
    if (cse.medication.description)
      therapy += '\nTherapy Reason: \n ' + cse.medication.description + '\n'
  }

  if (therapy.length > 0) {
    cse!.reaction!.descriptionGenerated += '' + '\n'
    cse!.reaction!.descriptionGenerated += 'Therapy: ' + '\n'
    cse!.reaction!.descriptionGenerated += therapy
  }

  cse!.reaction!.descriptionGenerated += '' + '\n'

  // Reaction
  cse!.reaction!.descriptionGenerated +=
    'Reaction Started on: ' + cse!.reaction!.startOfReaction?.toString()
  cse!.reaction!.descriptionGenerated += '' + '\n'

  if (cse!.reaction!.endOfReaction) {
    cse!.reaction!.descriptionGenerated += 'Ended on: ' + cse!.reaction!.endOfReaction?.toString()
    cse!.reaction!.descriptionGenerated += '' + '\n'
  }
  cse!.reaction!.descriptionGenerated += '' + '\n'

  let properties = ''
  if (cse!.reaction!.hospitalisation === true) properties += '  - Hospitalization: Yes' + '\n'
  if (cse!.reaction!.lifeThreatening === true) properties += '  - Life Threatening: Yes' + '\n'
  if (cse!.reaction!.incapacitating === true) properties += '  - Incapacitating: Yes' + '\n'
  if (cse!.reaction!.birthDefect === true) properties += '  - Birth Defect: Yes' + '\n'
  if (cse!.reaction!.otherMedicallyImportantCondition === true)
    properties += '  - Other Medically Important Condition: Yes' + '\n'

  if (cse!.reaction!.occuredInCountry) {
    cse!.reaction!.descriptionGenerated += 'Occured in country: ' + cse!.reaction!.occuredInCountry
    cse!.reaction!.descriptionGenerated += '' + '\n'
    cse!.reaction!.descriptionGenerated += '' + '\n'
  }

  if (properties.length > 0) {
    cse!.reaction!.descriptionGenerated += 'Properties:' + '\n'
    cse!.reaction!.descriptionGenerated += properties
  }
}

export function createOutcomeDescription(cse: CaseSideEffectDto) {
  cse!.outcome!.descriptionGenerated = 'Case outcome: ' + '\n\n'

  if (cse!.outcome!.resultsInDeath === true) {
    cse!.outcome!.descriptionGenerated += 'Resulted in death: '
    cse!.outcome!.descriptionGenerated += '' + '\n'
    if (cse!.outcome!.resultsInDeathCauseOfDeath) {
      cse!.outcome!.descriptionGenerated +=
        '   Cause of death: ' + cse!.outcome!.resultsInDeathCauseOfDeath
      cse!.outcome!.descriptionGenerated += '' + '\n'
    }
    if (cse!.outcome!.resultsInDeathMedContrib === true)
      cse!.outcome!.descriptionGenerated += '  - Medicine contribution possible: Yes' + '\n'
    if (cse!.outcome!.resultsInDeathNotFromMed === true)
      cse!.outcome!.descriptionGenerated += '  - No Medicine contribution: Yes' + '\n'
    if (cse!.outcome!.resultsInDeathDueToEffect === true)
      cse!.outcome!.descriptionGenerated += '  - Due to effect: Yes' + '\n'
    cse!.outcome!.descriptionGenerated += '' + '\n'
  }

  let properties = ''
  if (cse!.outcome!.restoredToHealth === true) properties += '  - Restored To Health: Yes' + '\n'
  if (cse!.outcome!.notRestoredToHealth === true)
    properties += '  - Not Restored To Health: Yes' + '\n'
  if (cse!.outcome!.permDamage === true) properties += '  - Permanent Damage: Yes' + '\n'
  if (cse!.outcome!.unknown === true) properties += '  - Unknown: Yes' + '\n'

  if (properties.length > 0) {
    cse!.outcome!.descriptionGenerated += 'Properties:' + '\n'
    cse!.outcome!.descriptionGenerated += properties
  }
}
