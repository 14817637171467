/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {updateToken} from '../../../../../../utils/AuthToken'
import {
  CaseDeduplicationService,
  CaseDedupPatientRequestDto,
  CaseDedupPatientRequestType,
  CaseDto,
} from '../../../../../../../generated/model'
import {CasesList} from '../../_shared/CasesList'

type Props = {
  request: CaseDedupPatientRequestDto
  foundDuplicate: (dto: CaseDto) => void
}

const DedupPatientList: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [type, setType] = useState<CaseDedupPatientRequestType>(
    CaseDedupPatientRequestType.LAST2DAYS
  )
  const [data, setData] = useState<CaseDto[]>()

  const {getAccessTokenSilently} = useAuth0()

  const load = async (type: CaseDedupPatientRequestType) => {
    setLoading(true)
    updateToken(await getAccessTokenSilently())
    setType(type)

    const result = await CaseDeduplicationService.caseDedupPatient({
      medicineId: props.request.medicineId,
      type,
      patient: props.request.patient,
      includeOccupation: false,
      includeHeight: false,
      includeBodyWeight: false,
    })
    setData(result.cases!)
    setLoading(false)
  }
  useEffect(() => {
    load(CaseDedupPatientRequestType.LAST2DAYS)
  }, [getAccessTokenSilently])

  return (
    <div className='card'>
      <div className='card-header' style={{minHeight: 40, padding: 0}}>
        <h3 className='align-items-start flex-column'>
          <span className='text-muted fw-bold fs-6'>
            Cases with same <b>medicine</b> and similar <b>patient data</b>
          </span>
        </h3>
        <div>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 ${
                  type === CaseDedupPatientRequestType.LAST2DAYS && 'active'
                }`}
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                  load(CaseDedupPatientRequestType.LAST2DAYS)
                }}
              >
                Last 2 Days
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 ${
                  type === CaseDedupPatientRequestType.LAST7DAYS && 'active'
                }`}
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                  load(CaseDedupPatientRequestType.LAST7DAYS)
                }}
              >
                Last 7 Days
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 ${
                  type === CaseDedupPatientRequestType.ALL && 'active'
                }`}
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                  load(CaseDedupPatientRequestType.ALL)
                }}
              >
                All
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className='card-body py-3' style={{minHeight: 200, padding: 0}}>
        <CasesList data={data!} loading={loading} foundDuplicate={props.foundDuplicate} />
      </div>
    </div>
  )
}

export {DedupPatientList}
