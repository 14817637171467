/* eslint-disable jsx-a11y/anchor-is-valid */

import {KTSVG} from '../../../../../../_metronic/helpers'
import {CaseReporterDto} from '../../../../../../generated/model'
import {FieldArrayRenderProps} from 'formik'
import {ReporterIdentifier} from '../../_shared/components/reporter-identifier/ReporterIdentifier'

export function getReporterColumns(
  editReporter: (reporter: CaseReporterDto, helpers: FieldArrayRenderProps, index: number) => void,
  helpers: FieldArrayRenderProps
): any[] {
  return [
    {
      title: 'Reporter',
      dataIndex: '.',
      key: 'reporterIdentifier',
      render: (text: string, record: any) => {
        return <ReporterIdentifier data={record.reporter} />
      },
    },
    {
      title: 'Classification',
      dataIndex: 'reporterType',
      key: 'reporterType',
      width: '150px',
    },
    {
      title: 'Actions',
      dataIndex: '.',
      key: 'actions',
      width: '150px',
      render: (text: string, record: any, index: number) => {
        return (
          <>
            <a
              href='#'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={(e) => {
                e.preventDefault()

                editReporter(record, helpers, index)
              }}
            >
              <KTSVG path='/media/icons/duotone/Communication/Write.svg' className='svg-icon-3' />
            </a>
            <a
              href='#'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              onClick={(e) => {
                e.preventDefault()
                helpers.remove(index)
              }}
            >
              <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
            </a>
          </>
        )
      },
    },
  ]
}
