/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {FieldArray, FieldArrayRenderProps, FormikErrors, FormikTouched, FormikValues} from 'formik'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {Table} from 'antd'
import {getEventColumns} from '../../_shared_steps/StepEventsColumns'
import {CaseEventSummaryDetails} from '../../../_shared/components/case-event-summary/CaseEventSummaryDetails'
import {CaseSideEffectDto} from '../../../../../../../generated/model'
import {
  CaseSideEffectInEdit,
  caseSideEffectInEditState,
  caseSideEffectStepState,
} from '../../../State'
import {Modal} from 'react-bootstrap-v5'
import {getHeaderParts, getHeaderTitle} from '../../_shared_helpers/Utils'
import {CaseStepType, ReporterStepType} from '../../_shared_helpers/Model'
import {EventsHeader} from './EventsHeader'
import {StepEventData} from '../../_shared_steps/StepEventData'

interface Step1Props {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  values: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

interface EditState {
  data: CaseSideEffectInEdit
  helpers: FieldArrayRenderProps
  index?: number
}

const Events: FC<Step1Props> = (props: Step1Props) => {
  const [expanded, setExpanded] = useState(true)
  const [caseInEdit, setCaseInEdit] = useState<EditState>()
  const [showModal, setShowModal] = useState(false)

  const setCaseSideEffectInEditState = useSetRecoilState(caseSideEffectInEditState)
  const editSideEffect = (
    sideEffect: CaseSideEffectDto | undefined,
    helpers: FieldArrayRenderProps,
    index: number
  ) => {
    const editState = {
      case: props.values.case!,
      sideEffect: sideEffect!,
      sideEffectsNotInEdit: props.values.case!.caseSideEffects?.filter(
        (x: CaseSideEffectDto) => x !== sideEffect
      ),
    }
    setCaseSideEffectInEditState(editState)
    setCaseInEdit({data: editState, helpers, index})
    setShowModal(true)
  }

  const caseSideEffectStep = useRecoilValue(caseSideEffectStepState)
  const headerTitle = getHeaderTitle(
    CaseStepType.Events,
    caseSideEffectStep,
    ReporterStepType.ReporterId
  )
  const headerParts = getHeaderParts(CaseStepType.Events, true)
  return (
    <>
      <Modal
        tabIndex={-1}
        aria-hidden='true'
        className='modal fade'
        dialogClassName='modal-dialog modal-dialog-centered mw-800px'
        show={showModal}
        onHide={() => setShowModal(false)}
        onShow={() => {}}
      >
        <Modal.Header>
          <EventsHeader title={headerTitle} parts={headerParts} />
          <div className='d-flex ms-2'>
            <div
              className='btn btn-icon btn-sm btn-light-primary ms-2'
              onClick={() => setShowModal(false)}
            >
              <KTSVG className='svg-icon-1' path='/media/icons/duotone/Navigation/Close.svg' />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <StepEventData
            showSideEffects={() => setShowModal(false)}
            editModel={caseInEdit?.data!}
            handleInsert={(sd) => {
              caseInEdit?.helpers.push(sd)
              setShowModal(false)
            }}
            handleUpdate={(sd) => {
              caseInEdit?.helpers.replace(caseInEdit?.index!, sd)
              setShowModal(false)
            }}
          />
        </Modal.Body>
      </Modal>

      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          onClick={() => setExpanded(!expanded)}
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Events Data</h3>
          </div>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              onClick={() => setExpanded(!expanded)}
            >
              {expanded && (
                <KTSVG path='/media/icons/duotone/Navigation/Angle-up.svg' className='svg-icon-2' />
              )}
              {!expanded && (
                <KTSVG
                  path='/media/icons/duotone/Navigation/Angle-down.svg'
                  className='svg-icon-2'
                />
              )}
            </button>
          </div>
        </div>
        {expanded && (
          <div className='card-body border-top p-9'>
            <FieldArray
              name='case.caseSideEffects'
              render={(helpers) => (
                <div className='w-100'>
                  <div className='mb-10 fv-row'>
                    <div className='card-header border-0'>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label'>Events</span>
                      </h3>
                      <div className='card-toolbar'>
                        <button
                          type='button'
                          className='btn btn-sm btn-color-primary btn-active-light-primary'
                          onClick={(e) => {
                            e.preventDefault()
                            editSideEffect(undefined, helpers, -1)
                          }}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    <Table
                      dataSource={props.values.case.caseSideEffects}
                      columns={getEventColumns(editSideEffect, helpers)}
                      rowKey='identifier'
                      expandable={{
                        expandedRowRender: (record) => <CaseEventSummaryDetails data={record} />,
                        rowExpandable: (record) => true,
                      }}
                    />
                  </div>
                </div>
              )}
            />
          </div>
        )}
      </div>
    </>
  )
}

export {Events}
