/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {StatisticsWrapper} from "./statistics/StatisticWrapper";
import {ActivityWrapper} from "./activity/ActivityWrapper";
import {NotificationsWrapper} from "./notifications/NotificationsWrapper";
import {useRecoilValue} from "recoil";
import {useCaseState} from "../State";
import {UseCase} from "../Model";
import {CasesToSubmitWrapper} from "./cases-to-submit/CasesToSubmitWrapper";

const DashboardPage: FC = () => {
    const useCase = useRecoilValue(useCaseState)
    return (
        <>
            {useCase === UseCase.Company &&
                <div className='row gy-5 g-xl-8'>
                    <div className='col-xxl-12'>
                        <CasesToSubmitWrapper/>
                    </div>
                </div>
            }
            <div className='row gy-5 g-xl-8'>
                <div className='col-xxl-6'>
                    <ActivityWrapper/>
                </div>
                <div className='col-xxl-6'>
                    <NotificationsWrapper/>
                </div>
            </div>
            <StatisticsWrapper/>
        </>
    )
};


const DashboardWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
            <DashboardPage/>
        </>
    )
}

export {DashboardWrapper}
