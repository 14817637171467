/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CaseDto} from '../../../../../../../generated/model'

interface Props {
  data: CaseDto
}

const CaseIdentifier: React.FC<Props> = (props) => {
  return (
    <>
      {props.data.serial!.readableId}
      {props.data.identifier && <span> ({props.data.identifier})</span>}
    </>
  )
}
export {CaseIdentifier}
