/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'

import {
  CaseDocumentDto,
  CaseDocumentService,
} from '../../../../../../../generated/model'
import {useAuth0} from '@auth0/auth0-react'
import {createErrorData, ErrorData} from "../../../../../errors/model/ErrorData";
import {useParams} from "react-router-dom";
import {updateToken} from "../../../../../../utils/AuthToken";
import {ErrorBoundary} from "../../../../../errors/ErrorBoundary";
import {LoadingBoundary} from "../../../../../_shared/LoadingBoundary";
import {Attachments} from "./Attachments";

interface Props {
}

const AttachmentsWrapper: React.FC<Props> = (props) => {
  const [documents, setDocuments] = useState<CaseDocumentDto[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<ErrorData>()

  const {getAccessTokenSilently} = useAuth0()
  const {id} = useParams<{id: string}>()

  const load = async () => {
    updateToken(await getAccessTokenSilently())

    try {
      setLoading(true)
      updateToken(await getAccessTokenSilently())

      const data = await CaseDocumentService.getDocuments(id!)
      setDocuments(data)
      setLoading(false)
    } catch (e) {
      setError(createErrorData(e))
      setDocuments(undefined)
      setLoading(false)
    }
  }
  const saveDocument = async (doc: CaseDocumentDto) => {
    try {
      setLoading(true)
      updateToken(await getAccessTokenSilently())

      await CaseDocumentService.saveDocument(id!, doc)

      await load();
    } catch (e) {
      setError(createErrorData(e))
      setDocuments(undefined)
      setLoading(false)
    }
  }
  const deleteDocument = async (doc: CaseDocumentDto) => {
    try {
      setLoading(true)
      updateToken(await getAccessTokenSilently())

      await CaseDocumentService.deleteDocumentById(id!, doc.id!)

      await load();
    } catch (e) {
      setError(createErrorData(e))
      setDocuments(undefined)
      setLoading(false)
    }
  }
  useEffect(() => {
        load()
      }, // eslint-disable-next-line
      [getAccessTokenSilently, id])

  return (<LoadingBoundary loading={loading}>
        <ErrorBoundary error={error}>
          {documents &&
              <Attachments documents={documents} saveDocument={saveDocument} deleteDocument={deleteDocument} />}
        </ErrorBoundary>
      </LoadingBoundary>
  )
}

export {AttachmentsWrapper}
