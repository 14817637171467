/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Popover} from "antd";
import {CaseStatus} from "../../../../../../../../../generated/model";

interface Props {
    transitionTo: (caseStatus: CaseStatus) => void
}

const ButtonDuplicate: React.FC<Props> = (props) => {
  return (
      <Popover content={
          <div>
              <div>Duplicate...</div>
              <div>...</div>
              <div>...</div>
          </div>
      } title="Duplicate">
      <button
          className='btn btn-sm btn-light-primary fw-bolder mx-2'
          onClick={() => props.transitionTo(CaseStatus.DUPLICATE)}
      >
          Mark as duplicate
      </button>
      </Popover>
  )
}

export {ButtonDuplicate}
