/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CasesExportDto} from '../../../../../generated/model'

interface Props {
  casesExport: CasesExportDto
}

const HeaderActions: React.FC<Props> = (props) => {
  return (
    <>
      <div className='d-flex flex-column'>
        <div className='text-dark me-2 fw-bolder pb-1 px-4'>Actions</div>
        <div className='border border-gray-300 border-dashed rounded py-4 px-4 mx-3 mb-3'>
          <div className='d-flex flex-start'>-</div>
        </div>
      </div>
    </>
  )
}

export {HeaderActions}
