/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MeddraLowLevelTermDto } from '../models/MeddraLowLevelTermDto';
import type { MeddraLowLevelTermUpdateDto } from '../models/MeddraLowLevelTermUpdateDto';
import type { MeddraLowLevelTermWithRefsDto } from '../models/MeddraLowLevelTermWithRefsDto';
import { request as __request } from '../core/request';

export class MeddraLowLevelTermService {

    /**
     * @param ptId 
     * @param language 
     * @returns MeddraLowLevelTermDto Success
     * @throws ApiError
     */
    public static async getLowLevelTerm(
ptId?: string,
language?: string,
): Promise<Array<MeddraLowLevelTermDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/core/v1/meddra/lowLevelTerm`,
            query: {
                'ptId': ptId,
                'language': language,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async saveMeddraLowLevelTerm(
requestBody?: MeddraLowLevelTermUpdateDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/core/v1/meddra/lowLevelTerm`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param id 
     * @returns MeddraLowLevelTermWithRefsDto Success
     * @throws ApiError
     */
    public static async getLowLevelTermDetails(
id: string,
): Promise<MeddraLowLevelTermWithRefsDto> {
        const result = await __request({
            method: 'GET',
            path: `/api/core/v1/meddra/lowLevelTerm/${id}/details`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async saveMeddraLowLevelTermBatch(
requestBody?: Array<MeddraLowLevelTermUpdateDto>,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/core/v1/meddra/lowLevelTerm/batch`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param filter 
     * @returns MeddraLowLevelTermDto Success
     * @throws ApiError
     */
    public static async autocompleteLowLevelTerms(
filter?: string,
): Promise<Array<MeddraLowLevelTermDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/core/v1/meddra/lowLevelTerm/autocomplete`,
            query: {
                'filter': filter,
            },
        });
        return result.body;
    }

    /**
     * @param filter 
     * @returns MeddraLowLevelTermDto Success
     * @throws ApiError
     */
    public static async searchLowLevelTerms(
filter?: string,
): Promise<Array<MeddraLowLevelTermDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/core/v1/meddra/lowLevelTerm/search`,
            query: {
                'filter': filter,
            },
        });
        return result.body;
    }

}