/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Table} from 'antd'
import {useHistory} from 'react-router-dom'
import getColumnsDoctor from './CasesColumnsDoctor'
import {CaseDto, CaseDtoPageDto, CaseRequestDto} from '../../../../../../generated/model'
import {SorterResult} from 'antd/lib/table/interface'
import {toSortingDirection} from '../../../../../utils/ConversionUtils'
import {CaseFilters} from '../_shared/CaseFilters'

interface Props {
  data: CaseDtoPageDto
  request: CaseRequestDto
  onChangeTable: (req: CaseRequestDto) => void
  loading: boolean
}

const CasesListDoctor: React.FC<Props> = (props) => {
  const history = useHistory()
  const newCaseClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    history.push('new')
  }
  const detailsCaseClick = (id: string) => {
    history.push(`details/${id}`)
  }
  const editCaseClick = (id: string) => {
    history.push(`edit/${id}`)
  }
  const deleteCaseClick = (id: string) => {
    history.push(`delete/${id}`)
  }

  let columns = getColumnsDoctor(detailsCaseClick, editCaseClick, deleteCaseClick)
  return (
    <div className={`card mb-5 mb-xl-8}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>All Cases</span>
        </h3>
        <div className='card-toolbar'>
          <a
            href='#'
            onClick={newCaseClick}
            className='btn btn-primary btn-sm'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_create_project'
          >
            New Case
          </a>
        </div>
      </div>
      <div className='card-body py-3'>
        <CaseFilters
          request={props.request}
          onChangeTable={(req) => {
            req.page = 0
            props.onChangeTable(req)
          }}
        />
        <div className='table-responsive'>
          {/* begin::Table */}
          <Table
            dataSource={props.data.items!}
            columns={columns}
            rowKey='id'
            loading={props.loading}
            showSorterTooltip={false}
            pagination={{
              showSizeChanger: true,
              current: props.request?.page ? props.request.page + 1 : 1,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              total: props.data.totalCount,
              pageSize: props.request.pageSize,
            }}
            onChange={(pagination, filters, sorter) => {
              const currentSorter = sorter as SorterResult<CaseDto>
              const req = props.request
              req.page = pagination.current ? pagination.current - 1 : props.request.page
              req.pageSize = pagination.pageSize ?? props.request.pageSize
              req.sortDirection = currentSorter.order
                ? toSortingDirection(currentSorter.order)
                : props.request.sortDirection
              req.sortColumn = currentSorter.column
                ? currentSorter.column.key!.toString()
                : props.request.sortColumn

              props.onChangeTable(req)
            }}
          />
        </div>
      </div>
    </div>
  )
}
export {CasesListDoctor}
