/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StatisticsCore } from '../models/StatisticsCore';
import { request as __request } from '../core/request';

export class StatisticsService {

    /**
     * @returns StatisticsCore Success
     * @throws ApiError
     */
    public static async getCoreStatistics(): Promise<StatisticsCore> {
        const result = await __request({
            method: 'GET',
            path: `/api/core/v1/statistics/core`,
        });
        return result.body;
    }

}