/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CaseDto} from '../../../../../../generated/model'
import {PatientIdentifier} from '../../_shared/components/patient-identifier/PatientIdentifier'
import moment from 'moment'
import {CaseEventSummary} from '../../_shared/components/case-event-summary/CaseEventSummary'

interface Props {
  data: CaseDto
}

const CasesListItemDetails: React.FC<Props> = (props) => {
  const caseData = props.data
  return (
    <>
      <div className='row mb-7 px-12'>
        <label className='col-lg-2 fw-bold text-muted'>Patient</label>

        <div className='col-lg-10'>
          <div>
            <div>
              <span className='fw-bolder fs-6 text-dark'>
                <PatientIdentifier data={caseData.patient!} />
              </span>
              <span className='px-2'>
                ({caseData.patient?.serial?.globalId} / {caseData.patient?.serialId})
              </span>
            </div>

            {caseData.patient?.latestData?.birthday && (
              <div>
                Birthday: {moment(caseData.patient?.latestData?.birthday).format('DD.MM.yyyy')}
              </div>
            )}
            <div>Sex: {caseData.patient?.latestData?.sex}</div>
            {caseData.patient?.latestData?.bodyWeight! > 0 && (
              <div>Body Weight: {caseData.patient?.latestData?.bodyWeight}</div>
            )}
            {caseData.patient?.latestData?.height! > 0 && (
              <div>Height: {caseData.patient?.latestData?.height}</div>
            )}
          </div>
        </div>
      </div>
      <div className='row mb-7 px-12'>
        <label className='col-lg-2 fw-bold text-muted'>Events</label>

        <div className='row g-3'>
          <div className='col-xl-12'>
            {caseData?.caseSideEffects?.map((ev) => (
              <CaseEventSummary data={ev} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
export {CasesListItemDetails}
