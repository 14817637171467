/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'

import {Form, Formik, FormikValues} from 'formik'
import {createInitialState, IEditCase} from './helpers/Model'
import {useAuth0} from '@auth0/auth0-react'
import {getSchemas} from './helpers/Utils'
import {save} from './helpers/Api'
import {useHistory} from 'react-router-dom'
import {ErrorBoundary} from '../../../../errors/ErrorBoundary'
import {createErrorData, ErrorData} from '../../../../errors/model/ErrorData'
import {CaseDto} from '../../../../../../generated/model'
import {CaseStepType} from '../_shared_helpers/Model'
import {Common} from './components/Common'
import {Patient} from './components/Patient'
import {Events} from './components/Events'
import {Reporters} from './components/Reporters'

interface Props {
  cse: CaseDto
}

const EditSpCase: React.FC<Props> = (props) => {
  const [currentSchema] = useState(getSchemas(CaseStepType.Common))
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ErrorData>()

  const [initValues] = useState<IEditCase>(createInitialState(props.cse))

  const {getAccessTokenSilently} = useAuth0()
  const history = useHistory()

  const saveCase = async (values: IEditCase, actions: FormikValues) => {
    try {
      setLoading(true)
      await save(values, actions, await getAccessTokenSilently())

      history.push(`/cases/details/${values.case.id}`)
    } catch (e) {
      setError(createErrorData(e))
      setLoading(false)
    }
  }

  return (
    <div>
      <ErrorBoundary error={error}></ErrorBoundary>

      <div>
        <Formik
          validationSchema={currentSchema}
          initialValues={initValues}
          onSubmit={saveCase}
          enableReinitialize={true}
        >
          {({errors, touched, values, setFieldValue, getFieldProps}) => (
            <Form noValidate id='kt_create_case_form'>
              <Common
                errors={errors}
                touched={touched}
                values={values}
                setFieldValue={setFieldValue}
                getFieldProps={getFieldProps}
              />
              <Reporters
                errors={errors}
                touched={touched}
                values={values}
                setFieldValue={setFieldValue}
              />
              <Patient
                errors={errors}
                touched={touched}
                values={values}
                setFieldValue={setFieldValue}
              />
              <Events
                errors={errors}
                touched={touched}
                values={values}
                setFieldValue={setFieldValue}
              />

              <div className='card mb-5 mb-xl-10'>
                <div className='d-flex flex-stack card-body border-top'>
                  <div className='mr-2' />
                  <button type='submit' className='btn btn-lg btn-primary me-3' disabled={loading}>
                    <span className='indicator-label'>Save</span>
                  </button>
                </div>
              </div>
              {/*<FormikDebug style={{maxWidth: 400}}/>*/}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export {EditSpCase}
