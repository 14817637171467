/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {FormikErrors, FormikValues, FormikTouched} from 'formik'
import {SideEffectSelector} from './components/SideEffectSelector'
import {SideEffectSelectionMode} from '../helpers/Model'
import {SideEffectAutocomplete} from './components/SideEffectAutocomplete'

interface Step1Props {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  values: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const StepSideEffect: FC<Step1Props> = (props) => {
  return (
    <div className='w-100' style={{minHeight: 530}}>
      <div className='border-bottom'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
          <li className='nav-item'>
            <a
              href='#'
              className={
                `nav-link text-active-primary me-6 ` +
                (props.values.sideEffectSelectionMode === SideEffectSelectionMode.Autocomplete &&
                  'active')
              }
              onClick={(e) => {
                e.preventDefault()
                props.setFieldValue('sideEffectSelectionMode', SideEffectSelectionMode.Autocomplete)
              }}
            >
              Simple (Full Text)
            </a>
          </li>
          <li className='nav-item'>
            <a
              href='#'
              className={
                `nav-link text-active-primary me-6 ` +
                (props.values.sideEffectSelectionMode === SideEffectSelectionMode.Selector &&
                  'active')
              }
              onClick={(e) => {
                e.preventDefault()
                props.setFieldValue('sideEffectSelectionMode', SideEffectSelectionMode.Selector)
              }}
            >
              Advanced (Selector)
            </a>
          </li>
        </ul>
      </div>

      <div className='pt-8'>
        {props.values.sideEffectSelectionMode === SideEffectSelectionMode.Autocomplete && (
          <SideEffectAutocomplete
            errors={props.errors}
            touched={props.touched}
            values={props.values}
            setFieldValue={props.setFieldValue}
          />
        )}
        {props.values.sideEffectSelectionMode === SideEffectSelectionMode.Selector && (
          <SideEffectSelector
            errors={props.errors}
            touched={props.touched}
            values={props.values}
            setFieldValue={props.setFieldValue}
          />
        )}
      </div>
    </div>
  )
}

export {StepSideEffect}
