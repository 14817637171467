/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Table} from 'antd'
import {useHistory} from 'react-router-dom'
import {CaseDto} from '../../../../../../generated/model'
import getColumnsCompany from './CasesColumnsCompany'

interface Props {
  data: Array<CaseDto>
}

const CasesListCompany: React.FC<Props> = (props) => {
  const history = useHistory()

  const detailsCaseClick = (id: string) => {
    history.push(`/cases/details/${id}`)
  }

  let columns = getColumnsCompany(detailsCaseClick)

  return (
      <Table
          dataSource={props.data}
          columns={columns}
          rowKey='id'
          showSorterTooltip={false}
      />
  )
}
export {CasesListCompany}
