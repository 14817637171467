/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {CaseDto} from '../../../../../../../generated/model'
import {OverviewData} from './OverviewData'

interface Props {
  case: CaseDto
}

const Overview: React.FC<Props> = (props) => {
  return (
    <div className='card'>
      <div className='card-body border-top'>
        <OverviewData case={props.case} />
      </div>
    </div>
  )
}

export {Overview}
