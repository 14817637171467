/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {ErrorData} from "./model/ErrorData";

export interface Props {
    error?: ErrorData
}

const ErrorBoundary: React.FC<Props> = (props) => {
    return (
        <>
            {props.error &&
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>
                        {props.error.message}
                    </div>
                    {props.error.subMessages &&
                        <ul>
                            {props.error.subMessages.map((err, index) => <li key={index}>{err}</li>)}
                        </ul>
                    }
                </div>
            }

            {props.children}
        </>
    )
}

export {ErrorBoundary}
