/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {Formik, FormikValues} from 'formik'
import {Form} from 'formik-antd'
import React, {useEffect, useState} from 'react'
import {CaseDto, CaseReporterDto, ReporterService} from '../../../../../generated/model'
import {ReporterStepType} from '../management/_shared_helpers/Model'
import {KTSVG} from '../../../../../_metronic/helpers'
import {StepReporterId} from './steps/StepReporterId'
import {StepReporterData} from './steps/StepReporterData'
import {createInitialState, IManageReporter} from './helpers/Model'
import {getNextStep, getPrevStep, getSchemas} from './helpers/Utils'
import {useSetRecoilState} from 'recoil'
import {caseReporterStepState} from '../State'
import {LoadingBoundary} from '../../../_shared/LoadingBoundary'
import {useAuth0} from '@auth0/auth0-react'
import {updateToken} from '../../../../utils/AuthToken'

type Props = {
  handleInsert: (sideEffect: CaseReporterDto) => void
  handleUpdate: (sideEffect: CaseReporterDto) => void
  close: () => void

  reporter?: CaseReporterDto
  case: CaseDto
}

function getInitialStep(reporter?: CaseReporterDto): ReporterStepType {
  if (reporter) return ReporterStepType.ReporterData
  return ReporterStepType.ReporterId
}

const ManageReporter: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [currentStep, setCurrentStep] = useState<ReporterStepType>(getInitialStep(props.reporter))
  const [currentSchema, setCurrentSchema] = useState(getSchemas(getInitialStep(props.reporter)))
  const [initValues, setInitValues] = useState<IManageReporter>()
  const setCaseReporterStepState = useSetRecoilState(caseReporterStepState)

  const {getAccessTokenSilently} = useAuth0()
  useEffect(() => {
    const getCaseId = async () => {
      updateToken(await getAccessTokenSilently())

      const reporterId = await ReporterService.getNewReporterId()
      const reporterMe = await ReporterService.meReporter()
      setInitValues(createInitialState(reporterMe, reporterId, props.reporter))

      setLoading(false)
    }
    getCaseId()
  }, [getAccessTokenSilently])

  const prevStep = () => {
    const newStep = getPrevStep(currentStep)
    setupStep(newStep)
  }
  const nextStep = () => {
    const newStep = getNextStep(currentStep)
    setupStep(newStep)
  }

  const setupStep = (step: ReporterStepType) => {
    setCurrentStep(step)
    setCurrentSchema(getSchemas(step))
    setCaseReporterStepState(step)
  }

  const submitStep = async (values: FormikValues, actions: FormikValues) => {
    if (currentStep === ReporterStepType.ReporterData) {
      if (props.reporter != null) props.handleUpdate(values.caseReporter)
      else props.handleInsert(values.caseReporter)
    } else nextStep()
  }

  let btnText = 'Continue'
  if (currentStep === ReporterStepType.ReporterData) btnText = 'Save'

  return (
    <LoadingBoundary loading={loading}>
      {initValues && (
        <div className='d-flex flex-row-fluid flex-center bg-white rounded'>
          <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
            {({errors, touched, values, setFieldValue, getFieldProps}) => (
              <Form className='py-10 w-100 w-xl-900px px-10' noValidate id='kt_create_account_form'>
                {currentStep === ReporterStepType.ReporterId && (
                  <div className='pb-5 current' data-kt-stepper-element='content'>
                    <StepReporterId
                      errors={errors}
                      touched={touched}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                )}
                {currentStep === ReporterStepType.ReporterData && (
                  <div className='pb-5 current' data-kt-stepper-element='content'>
                    <StepReporterData
                      errors={errors}
                      touched={touched}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                )}
                <div className='d-flex justify-content-between pt-0'>
                  <div className='mr-2'>
                    {currentStep !== ReporterStepType.ReporterId && (
                      <button
                        onClick={prevStep}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                      >
                        <KTSVG
                          path='/media/icons/duotone/Navigation/Left-2.svg'
                          className='svg-icon-4 me-1'
                        />
                        Back
                      </button>
                    )}
                  </div>

                  <div>
                    <button type='submit' className='btn btn-primary me-3'>
                      <span className='indicator-label'>
                        {btnText}
                        <KTSVG
                          path='/media/icons/duotone/Navigation/Right-2.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    </button>
                  </div>
                </div>
                {/*<FormikDebug style={{maxWidth: 400}}/>*/}
              </Form>
            )}
          </Formik>
        </div>
      )}
    </LoadingBoundary>
  )
}

export {ManageReporter}
