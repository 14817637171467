/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CaseDto, CaseSource, CaseStatus} from '../../../../../../../../generated/model'
import {ButtonShare} from "./components/ButtonShare";
import {ButtonAccept} from "./components/ButtonAccept";
import {ButtonExport} from "./components/ButtonExport";
import { ButtonDuplicate } from './components/ButtonDuplicate';
import { ButtonArchive } from './components/ButtonArchive';
import {ButtonUnarchive} from "./components/ButtonUnarchive";
import {ButtonUnduplicate} from "./components/ButtonUnduplicate";

interface Props {
  transitionTo: (to: CaseStatus) => void;
  share: () => void;
  export: () => void;
  reload: () => void;
  caseData: CaseDto
}

const HeaderActionsCompany: React.FC<Props> = (props) => {
  const caseData = props.caseData;
  let actions = [];

  if( caseData.source === CaseSource.DOCTOR ){
      if( caseData.status === CaseStatus.SUBMITTED){
          actions.push(<ButtonAccept key='accept' reload={props.reload} case={caseData}/>);
      }
      if( caseData.status === CaseStatus.ACCEPTED){
          actions.push(<ButtonExport key='export' export={props.export}/>);
          actions.push(<ButtonDuplicate key='duplicate' reload={props.reload} case={caseData}/>);
      }
      if( caseData.status === CaseStatus.IN_REVIEW){
          actions.push(<ButtonExport key='export' export={props.export}/>);
      }

  } else if( caseData.source === CaseSource.COMPANY ){
    if( caseData.status === CaseStatus.CREATED_COMPANY){
        actions.push(<ButtonExport key='export' export={props.export}/>);
        actions.push(<ButtonArchive key='archive' reload={props.reload} case={caseData}/>);
        actions.push(<ButtonDuplicate key='duplicate' reload={props.reload} case={caseData}/>);
        actions.push(<ButtonShare key='share'  share={props.share}/>);
    }
    if( caseData.status === CaseStatus.ARCHIVED_COMPANY){
        actions.push(<ButtonUnarchive key='unarchive' transitionTo={props.transitionTo}/>);
    }
      if( caseData.status === CaseStatus.DUPLICATE_COMPANY){
          actions.push(<ButtonUnduplicate key='unduplicate' transitionTo={props.transitionTo} source={caseData.source}/>);
      }
      if( caseData.status === CaseStatus.IN_REVIEW){
          actions.push(<ButtonExport key='export' export={props.export}/>);
          actions.push(<ButtonShare key='share' share={props.share}/>);
      }
  }

  return (
      <div className='d-flex flex-column'>
        <div className='text-dark me-2 fw-bolder pb-1 px-4'>Actions</div>
        <div className='border border-gray-300 border-dashed rounded py-4 px-4 mx-3 mb-3'>
          <div className='d-flex flex-start'>
              {actions.length > 0 ? actions : <span>-</span>}
          </div>
        </div>
      </div>
  )
}

export {HeaderActionsCompany}
