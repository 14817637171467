import {atom} from 'recoil'
import {FooterModel, RoleContext} from './Model'

export const footerState = atom<FooterModel>({
  key: 'footerState',
  default: {product: '', key: ''},
})

export const roleContextState = atom<RoleContext | null>({
  key: 'roleContextState',
  default: null,
})