/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MeddraHighLevelGroupTermDto } from '../models/MeddraHighLevelGroupTermDto';
import type { MeddraHighLevelGroupTermUpdateDto } from '../models/MeddraHighLevelGroupTermUpdateDto';
import { request as __request } from '../core/request';

export class MeddraHighLevelGroupTermService {

    /**
     * @param socId 
     * @param language 
     * @returns MeddraHighLevelGroupTermDto Success
     * @throws ApiError
     */
    public static async getMeddraHighLevelGroupTerms(
socId?: string,
language?: string,
): Promise<Array<MeddraHighLevelGroupTermDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/core/v1/meddra/highLevelGroupTerm`,
            query: {
                'socId': socId,
                'language': language,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async saveMeddraHighLevelGroupTerm(
requestBody?: MeddraHighLevelGroupTermUpdateDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/core/v1/meddra/highLevelGroupTerm`,
            body: requestBody,
        });
        return result.body;
    }

}