/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TimeFrameField {
    CREATED_AT = 'CreatedAt',
    UPDATED_AT = 'UpdatedAt',
    REPORTED_AT = 'ReportedAt',
    SUBMIT_DEADLINE = 'SubmitDeadline',
    SUBMIT_OFFICIAL_DEADLINE = 'SubmitOfficialDeadline',
}