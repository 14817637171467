/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReporterDto } from '../models/ReporterDto';
import type { SerialDto } from '../models/SerialDto';
import { request as __request } from '../core/request';

export class ReporterService {

    /**
     * @param filter 
     * @returns ReporterDto Success
     * @throws ApiError
     */
    public static async autocompleteReporters(
filter?: string,
): Promise<Array<ReporterDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/organization/v1/reporter/autocomplete`,
            query: {
                'filter': filter,
            },
        });
        return result.body;
    }

    /**
     * @returns ReporterDto Success
     * @throws ApiError
     */
    public static async meReporter(): Promise<ReporterDto> {
        const result = await __request({
            method: 'GET',
            path: `/api/organization/v1/reporter/me`,
        });
        return result.body;
    }

    /**
     * @returns SerialDto Success
     * @throws ApiError
     */
    public static async getNewReporterId(): Promise<SerialDto> {
        const result = await __request({
            method: 'GET',
            path: `/api/organization/v1/reporter/new/id`,
        });
        return result.body;
    }

}