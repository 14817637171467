/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'

import {useHistory, useParams} from 'react-router-dom'
import {useAuth0} from '@auth0/auth0-react'
import {CaseDto, CaseService} from '../../../../../../generated/model'
import {LoadingBoundary} from '../../../../_shared/LoadingBoundary'
import {updateToken} from '../../../../../utils/AuthToken'
import {EditSpCase} from './EditSpCase'
import {CaseStepType, SideEffectStepType} from '../_shared_helpers/Model'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {caseReporterStepState, caseSideEffectStepState, caseStepState} from '../../State'
import {getHeaderBackTitle, getHeaderParts, getHeaderTitle} from '../_shared_helpers/Utils'
import {Header} from '../_shared/Header'

const EditCaseSpWrapper: React.FC = () => {
  const caseStep = useRecoilValue(caseStepState)
  const caseSideEffectStep = useRecoilValue(caseSideEffectStepState)
  const caseReporterStep = useRecoilValue(caseReporterStepState)

  const setCaseStepState = useSetRecoilState(caseStepState)
  const setCaseSideEffectStepState = useSetRecoilState(caseSideEffectStepState)

  const headerTitle = getHeaderTitle(caseStep, caseSideEffectStep, caseReporterStep)
  const headerParts = getHeaderParts(caseStep, true)
  const headerProgress = undefined
  const headerBackTitle = getHeaderBackTitle(caseStep)

  const history = useHistory()
  const backClick = () => {
    if (caseStep === CaseStepType.EventData) {
      setCaseStepState(CaseStepType.Events)
      return
    }

    history.push('/cases/list')
  }

  const [loading, setLoading] = useState<boolean>(true)
  useEffect(() => {
    const initialSetup = () => {
      setCaseStepState(CaseStepType.Common)
      setCaseSideEffectStepState(SideEffectStepType.SideEffect)
      setLoading(false)
    }
    initialSetup()
  }, [setCaseStepState, setCaseSideEffectStepState, setLoading])

  return (
    <>
      <LoadingBoundary loading={loading}>
        <Header
          title={headerTitle}
          parts={headerParts}
          progress={headerProgress}
          backTitle={headerBackTitle}
          onBackClick={backClick}
        ></Header>
      </LoadingBoundary>

      <EditCaseWrapperInternal />
    </>
  )
}

const EditCaseWrapperInternal: FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [cse, setCase] = useState<CaseDto>()

  const {id} = useParams<{id: string}>()
  const {getAccessTokenSilently} = useAuth0()
  useEffect(() => {
    const getCaseById = async () => {
      updateToken(await getAccessTokenSilently())

      const cse = await CaseService.getCaseForEdit(id)

      setCase(cse)

      setLoading(false)
    }
    getCaseById()
  }, [getAccessTokenSilently, id])
  return (
    <LoadingBoundary loading={loading}>
      <EditSpCase cse={cse!} />
    </LoadingBoundary>
  )
}

export {EditCaseSpWrapper}
