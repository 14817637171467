/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CasesExportDto} from '../../../../../generated/model'
import moment from 'moment'
import {SortOrder} from 'antd/es/table/interface'

export default function getColumns(detailsCaseClick: (id: string) => void) {
  return [
    {
      title: 'Updated At',
      dataIndex: '.',
      key: 'modifiedAt',
      sorter: (a: CasesExportDto, b: CasesExportDto) =>
        moment(a.modifiedAt).unix() - moment(b.modifiedAt).unix(),
      defaultSortOrder: 'descend' as SortOrder,
      render: (text: string, record: CasesExportDto) => (
        <span>{moment(record.modifiedAt).format('DD.MM.yyyy')}</span>
      ),
    },
    {
      title: 'Export Id',
      dataIndex: '.',
      key: 'identifier',
      render: (text: string, record: CasesExportDto) => (
        <a
          href='#'
          onClick={(e) => {
            e.preventDefault()
            detailsCaseClick(record.id!)
          }}
        >
          <>
            <span>{record?.serial?.readableId}</span>
          </>
        </a>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'exportType',
      key: 'exportType',
      render: (text: string) => <span className='badge badge-light-success'>{text}</span>,
    },
  ]
}
