/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CaseExportDto } from '../models/CaseExportDto';
import type { CaseExportRequestDto } from '../models/CaseExportRequestDto';
import type { CasesExportDto } from '../models/CasesExportDto';
import { request as __request } from '../core/request';

export class CaseExportService {

    /**
     * @param requestBody 
     * @returns CasesExportDto Success
     * @throws ApiError
     */
    public static async export(
requestBody?: CaseExportRequestDto,
): Promise<CasesExportDto> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/export`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns CasesExportDto Success
     * @throws ApiError
     */
    public static async getAllExports(): Promise<Array<CasesExportDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/pv/v1/export`,
        });
        return result.body;
    }

    /**
     * @param id 
     * @returns CasesExportDto Success
     * @throws ApiError
     */
    public static async getExportById(
id: string,
): Promise<CasesExportDto> {
        const result = await __request({
            method: 'GET',
            path: `/api/pv/v1/export/${id}`,
        });
        return result.body;
    }

    /**
     * @param caseId 
     * @returns CaseExportDto Success
     * @throws ApiError
     */
    public static async getCaseExportsById(
caseId: string,
): Promise<Array<CaseExportDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/pv/v1/export/case/${caseId}`,
        });
        return result.body;
    }

}