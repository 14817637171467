import {CaseDto} from "../../../../../../generated/model";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

// @ts-ignore
momentDurationFormatSetup(moment);

export function calculateRemainingTime(record: CaseDto): string{
  const now = moment().utc()
  const deadline = moment(record.submitDeadline);

  let diff;
  let pre = '';
  if( now.isAfter(deadline) ){
    diff = now.diff(deadline);
    pre = ' - ';
  } else {
    diff = deadline.diff(now);
  }

  return `${pre} ${moment.duration(diff).format("HH:mm")}`
}

export enum RemainingTimeStatus{
  Overdue,
  Close,
  Nearing,
  Far
}

export function calculateRemainingTimeStatus(record: CaseDto): RemainingTimeStatus {
  const now = moment().utc()
  const deadline = moment(record.submitDeadline);

  if (now.isAfter(deadline)) {
    return RemainingTimeStatus.Overdue;
  } else {
    const diff = deadline.diff(now, "hours", true);

    if (diff > 8)
      return RemainingTimeStatus.Far;
    if (diff >= 5)
      return RemainingTimeStatus.Nearing;

    return RemainingTimeStatus.Close;
  }
}
