/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CaseEventDto } from '../models/CaseEventDto';
import type { RecentCaseEventDto } from '../models/RecentCaseEventDto';
import { request as __request } from '../core/request';

export class CaseEventService {

    /**
     * @returns RecentCaseEventDto Success
     * @throws ApiError
     */
    public static async getRecentEvents(): Promise<Array<RecentCaseEventDto>> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/caseEvents/recent`,
        });
        return result.body;
    }

    /**
     * @param id 
     * @returns CaseEventDto Success
     * @throws ApiError
     */
    public static async getEvents(
id: string,
): Promise<Array<CaseEventDto>> {
        const result = await __request({
            method: 'POST',
            path: `/api/pv/v1/case/${id}/events`,
        });
        return result.body;
    }

}