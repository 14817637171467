/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

interface Props {
  sizeClass: string
  name: string
  vertical?: boolean
  hideHeader?: boolean
}
const CaseFilter: React.FC<Props> = (props) => {
  return (
    <>
      {props.vertical === true ? (
        <div className={props.sizeClass}>
          <label className='form-label'>{props.name}</label>
          {props.children}
        </div>
      ) : (
        <div className={props.sizeClass}>
          <div className='row'>
            {!props.hideHeader && (
              <div className='col-lg-auto fv-row'>
                <label className='col-form-label fw-bold'>{props.name}</label>
              </div>
            )}
            <div className='col-lg fv-row'>{props.children}</div>
          </div>
        </div>
      )}
    </>
  )
}

export {CaseFilter}
