/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CaseReporterDto} from '../../../../../../../generated/model'
import {ReporterIdentifier} from '../reporter-identifier/ReporterIdentifier'

interface Props {
  data: CaseReporterDto[]
}

const ReportersIdentifier: React.FC<Props> = (props) => {
  return (
    <>
      {props.data.map((r) => (
        <div>
          <ReporterIdentifier key={r.id} data={r.reporter!} />
        </div>
      ))}
    </>
  )
}
export {ReportersIdentifier}
