/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {CaseSideEffectDto} from '../../../../../../../generated/model'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {CaseEventSummaryDetails} from './CaseEventSummaryDetails'

interface Props {
  data: CaseSideEffectDto
}

const CaseEventSummary: React.FC<Props> = (props) => {
  const eventData = props.data!
  const [expanded, setExpanded] = useState<boolean>(false)
  return (
    <div className={`card mb-xl-8`}>
      <div className='card-body d-flex flex-column'>
        <div className='flex-grow-1'>
          <div className='d-flex align-items-center pe-2 mb-5'>
            <a
              href='#'
              onClick={(e) => {
                e.preventDefault()
                setExpanded(!expanded)
              }}
            >
              {expanded && <KTSVG path='/media/icons/duotone/Navigation/Angle-down.svg' />}
              {!expanded && <KTSVG path='/media/icons/duotone/Navigation/Angle-right.svg' />}

              <span className='text-muted fw-bolder fs-5 flex-grow-1'>
                {eventData?.lowLevelTerm?.name} ({eventData?.lowLevelTerm?.code})
              </span>
            </a>
          </div>
        </div>

        {expanded && (
          <div className='d-flex align-items-center py-3'>
            <CaseEventSummaryDetails data={eventData} />
          </div>
        )}
      </div>
    </div>
  )
}
export {CaseEventSummary}
