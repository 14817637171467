/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {
  CaseAcceptDto,
  CaseCategory,
  CaseDto,
  CaseSeriousness,
  CaseService,
} from '../../../../../../../../../../generated/model'
import {useIntl} from 'react-intl'
import {ErrorMessage, Form, Formik, FormikValues} from 'formik'
import * as Yup from 'yup'
import {Input, Select} from 'formik-antd'
import {updateToken} from '../../../../../../../../../utils/AuthToken'
import {createErrorData, ErrorData} from '../../../../../../../../errors/model/ErrorData'
import {useAuth0} from '@auth0/auth0-react'
import {ErrorBoundary} from '../../../../../../../../errors/ErrorBoundary'

type Props = {
  case: CaseDto

  onSaved: () => void
}

interface AcceptCaseModel {
  cse: CaseAcceptDto
  categoryTemp: {
    key: string | undefined
    value: string | undefined
    label: string | undefined
  }
  seriousnessTemp: {
    key: string | undefined
    value: string | undefined
    label: string | undefined
  }
}

function getInitialValues(cse: CaseDto): AcceptCaseModel {
  let validity =
    cse.patient &&
    cse.caseReporters &&
    cse.caseReporters.length > 0 &&
    cse.caseSideEffects &&
    cse.caseSideEffects.length > 0
      ? true
      : false
  return {
    cse: {
      id: cse.id,
      category: cse.category,
      seriousness: cse.seriousness,
      validity: validity,
      comment: '',
    },
    categoryTemp: {
      value: cse.category,
      label: cse.category,
      key: cse.category,
    },
    seriousnessTemp: {
      value: cse.seriousness,
      label: cse.seriousness,
      key: cse.seriousness,
    },
  }
}
const currentSchema = Yup.object().shape({})

const AcceptForm: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ErrorData>()
  const intl = useIntl()

  const {getAccessTokenSilently} = useAuth0()
  const submitData = async (values: AcceptCaseModel, actions: FormikValues) => {
    try {
      setLoading(true)
      updateToken(await getAccessTokenSilently())

      await CaseService.accept(props.case.id!, values.cse)
      props.onSaved()

      setLoading(false)
    } catch (e) {
      setError(createErrorData(e))
      setLoading(false)
    }
  }

  function validateCategory(value: {key: string}) {
    let error
    if (!value || !value.key || value.key === '') {
      error = 'Category is required!'
    }
    return error
  }
  function validateSeriousness(value: {key: string}) {
    let error
    if (!value || !value.key || value.key === '') {
      error = 'Seriousness is required!'
    }
    return error
  }
  return (
    <>
      <ErrorBoundary error={error} />
      <Formik
        validationSchema={currentSchema}
        initialValues={getInitialValues(props.case)}
        onSubmit={submitData}
      >
        {({errors, touched, values, setFieldValue, getFieldProps}) => (
          <Form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
          >
            <div className='mb-10 fv-row'>
              <label className='form-label mb-3 required'>Category</label>

              <Select
                name='categoryTemp'
                labelInValue
                validate={validateCategory}
                dropdownStyle={{zIndex: 1200}}
                optionLabelProp='label'
                filterOption={false}
                onSelect={(value: any, option: any) => {
                  const key = option.key
                  const cse = values['cse']
                  cse.category = CaseCategory[key! as keyof typeof CaseCategory]
                  setFieldValue('cse', cse)
                }}
                placeholder='Select category'
                style={{width: '100%'}}
              >
                {Object.keys(CaseCategory).map((element: string) => (
                  <Select.Option
                    key={element}
                    value={element}
                    label={intl.formatMessage({id: `CASE_CATEGORY_${element}`})}
                  >
                    {intl.formatMessage({id: `CASE_CATEGORY_${element}`})}
                  </Select.Option>
                ))}
              </Select>
              <div className='fv-plugins-message-container invalid-feedback d-block'>
                <ErrorMessage name='categoryTemp' />
              </div>
            </div>

            <div className='mb-10 fv-row'>
              <label className='form-label mb-3 required'>Seriousness</label>

              <Select
                name='seriousnessTemp'
                labelInValue
                dropdownStyle={{zIndex: 1200}}
                optionLabelProp='label'
                filterOption={false}
                validate={validateSeriousness}
                onSelect={(value: any, option: any) => {
                  const key = option.key
                  const cse = values['cse']
                  cse.seriousness = CaseSeriousness[key! as keyof typeof CaseSeriousness]
                  setFieldValue('cse', cse)
                }}
                placeholder='Select seriousness'
                style={{width: '100%'}}
              >
                {Object.keys(CaseSeriousness).map((element: string) => (
                  <Select.Option
                    key={element}
                    value={element}
                    label={intl.formatMessage({id: `CASE_SERIOUSNESS_${element}`})}
                  >
                    {intl.formatMessage({id: `CASE_SERIOUSNESS_${element}`})}
                  </Select.Option>
                ))}
              </Select>
              <div className='fv-plugins-message-container invalid-feedback d-block'>
                <ErrorMessage name='seriousnessTemp' />
              </div>
            </div>

            <div className='mb-10 fv-row'>
              <div className='form-check form-check-custom form-check-solid me-5'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='inlineCheckboxValidity'
                  checked={values['cse'].validity}
                  {...getFieldProps('cse.validity')}
                />
                <label
                  className='form-check-label fw-bold'
                  htmlFor='inlineCheckboxRestoredToHealth'
                >
                  Validity
                </label>
              </div>
            </div>

            <div className='mb-10 fv-row'>
              <label className='form-label mb-3'>Comment</label>

              <Input.TextArea rows={5} name='cse.comment' placeholder='' />
              <div className='fv-plugins-message-container invalid-feedback d-block'>
                <ErrorMessage name='cse.comment' />
              </div>
            </div>

            <div className='d-flex flex-stack pt-15'>
              <div className='mr-2' />
              <div>
                <button type='submit' className='btn btn-lg btn-primary me-3' disabled={loading}>
                  <span className='indicator-label'>Save</span>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export {AcceptForm}
