/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Modal} from 'react-bootstrap-v5'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {CaseDedupEventsRequestDto, CaseDto} from '../../../../../../../generated/model'
import {DedupEventsList} from './DedupEventsList'

type Props = {
  show: boolean
  handleClose: () => void

  request: CaseDedupEventsRequestDto

  foundDuplicate: (dto: CaseDto) => void
}

const DedupEventsListModal: React.FC<Props> = (props) => {
  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      className='modal fade'
      dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
      show={props.show}
      onHide={props.handleClose}
      onShow={() => {}}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>Deduplication information</h2>

          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={props.handleClose}>
            <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
          </div>
        </div>

        <div className='modal-body'>
          <DedupEventsList request={props.request} foundDuplicate={props.foundDuplicate} />
        </div>
      </div>
    </Modal>
  )
}

export {DedupEventsListModal}
