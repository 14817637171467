/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Table} from 'antd'
import {useHistory} from 'react-router-dom'
import {CaseInputDto} from '../../../../../generated/model'
import getColumns from './InputsColumns'

interface Props {
  data: Array<CaseInputDto>
  loading: boolean
}

const InputsList: React.FC<Props> = (props) => {
  const history = useHistory()

  const detailsCaseClick = (id: string) => {
    history.push(`/inputs/details/${id}`)
  }
  const deleteCaseClick = (id: string) => {
    history.push(`/inputs/delete/${id}`)
  }

  let columns = getColumns(detailsCaseClick, deleteCaseClick)

  return (
    <div className={`card mb-5 mb-xl-8}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>All Inputs</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <Table dataSource={props.data} columns={columns} rowKey='id' loading={props.loading} />
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}
export {InputsList}
