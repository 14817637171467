import React from 'react'
import ReactDOM from 'react-dom'
// Axios
import {Chart, registerables} from 'chart.js'

// Apps
import App from './app/App'
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'

/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 **/
import './_metronic/assets/sass/style.scss'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

Chart.register(...registerables)

ReactDOM.render(
  <MetronicI18nProvider>
    <App basename={PUBLIC_URL} />
  </MetronicI18nProvider>,
  document.getElementById('root')
)
