/* eslint-disable jsx-a11y/anchor-is-valid */

import {KTSVG} from '../../../../../../_metronic/helpers'
import {CaseSideEffectDto} from '../../../../../../generated/model'
import {FieldArrayRenderProps} from 'formik'

export function getEventColumns(
  editSideEffect: (sideEffect: CaseSideEffectDto, helpers: FieldArrayRenderProps, index: number) => void,
  helpers: FieldArrayRenderProps
): any[] {
  return [
    {
      title: 'Side Effect',
      dataIndex: '.',
      key: 'sideEffectName',
      render: (text: string, record: any) => {
        return (
          <span>
            {record?.lowLevelTerm?.name}[{record?.lowLevelTerm?.code}]
          </span>
        )
      },
    },
    {
      title: 'Actions',
      dataIndex: '.',
      key: 'actions',
      width: '150px',
      render: (text: string, record: any, index: number) => {
        return (
          <>
            <a
              href='#'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={(e) => {
                e.preventDefault()

                editSideEffect(record, helpers, index)
              }}
            >
              <KTSVG path='/media/icons/duotone/Communication/Write.svg' className='svg-icon-3' />
            </a>
            <a
              href='#'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              onClick={(e) => {
                e.preventDefault()
                helpers.remove(index)
              }}
            >
              <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
            </a>
          </>
        )
      },
    },
  ]
}
