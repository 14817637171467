/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {FormikErrors, FormikValues, FormikTouched} from 'formik'
import {caseDedupDuplicateFoundState} from '../../../State'
import {useRecoilValue} from "recoil";

interface Props {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  values: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const StepDuplicate: FC<Props> = (props) => {
  const caseDedupDuplicateFound = useRecoilValue(caseDedupDuplicateFoundState)

  return (
    <div className='mx-auto mw-600px w-100'>
      <div className='mb-10 fv-row'>
        <h6>Save new case as duplicate:</h6>
      </div>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Reference: Duplicate case ID</label>
        <label className='form-control'>{caseDedupDuplicateFound!.serial!.readableId}</label>
      </div>

    </div>
  )
}

export {StepDuplicate}
