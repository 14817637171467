/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {ErrorMessage, FormikErrors, FormikTouched, FormikValues} from 'formik'
import {CompanyDetails} from '../../../_shared/components/company-details/CompanyDetails'
import {MedicinePackages} from '../../../_shared/components/medicine-packages/MedicinePackages'
import {DatePicker, Input, Select} from 'formik-antd'
import {useRecoilValue} from 'recoil'
import {useCaseState} from '../../../../State'
import {CaseCategory, CaseSeriousness} from '../../../../../../../generated/model'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {UseCase} from '../../../../Model'
import {FieldInputProps} from 'formik/dist/types'
import {isReadOnly} from '../helpers/Access'

interface Step1Props {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  values: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>
}

const Common: FC<Step1Props> = (props: Step1Props) => {
  const useCase = useRecoilValue(useCaseState)
  const intl = useIntl()
  const [expanded, setExpanded] = useState(true)

  function validateCategory(value: {key: string}) {
    let error
    if (!value || !value.key || value.key === '') {
      error = 'Category is required!'
    }
    return error
  }
  function validateSeriousness(value: {key: string}) {
    let error
    if (!value || !value.key || value.key === '') {
      error = 'Seriousness is required!'
    }
    return error
  }
  const onSelectReportedAt = (value: any) => {
    const cse = props.values['case']
    if (!cse.caseReporter.latestData) cse.caseReporter.latestData = {}

    const reportedAt = value.utc()
    cse.reportedAt = reportedAt.format()

    props.setFieldValue('case', cse)
    props.setFieldValue('reportedAtTemp', value)
  }
  const onSelectSubmitDeadline = (value: any) => {
    const cse = props.values['case']
    if (!cse.caseReporter.latestData) cse.caseReporter.latestData = {}

    cse.submitDeadline = value.utc().format()
    props.setFieldValue('case', cse)
    props.setFieldValue('submitDeadlineTemp', value)
  }
  function validateSubmitDeadline(value: string) {
    let error
    if (!value) {
      error = 'Reported At is required!'
    }
    return error
  }
  function validateReportedAt(value: string) {
    let error
    if (!value) {
      error = 'Reported At is required!'
    }
    return error
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        onClick={() => setExpanded(!expanded)}
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Common Data</h3>
        </div>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            onClick={() => setExpanded(!expanded)}
          >
            {expanded && (
              <KTSVG path='/media/icons/duotone/Navigation/Angle-up.svg' className='svg-icon-2' />
            )}
            {!expanded && (
              <KTSVG path='/media/icons/duotone/Navigation/Angle-down.svg' className='svg-icon-2' />
            )}
          </button>
        </div>
      </div>
      {expanded && (
        <div className='card-body border-top p-9'>
          <div className='row mb-6'>
            <label className='col-lg-3 col-form-label required fw-bold fs-6'>
              Case ID / Identifier
            </label>

            <div className='col-lg-9'>
              <div className='row'>
                <div className='col-lg-4 fv-row'>
                  <label className='form-control'>{props.values['case'].serial.readableId}</label>
                </div>

                <div className='col-lg-8 fv-row'>
                  <Input
                    type='text'
                    name='case.identifier'
                    placeholder='Custom case identifier.'
                    readOnly={isReadOnly(props.values.case.source, useCase)}
                    className='form-control'
                  />
                  <div className='fv-plugins-message-container invalid-feedback d-block'>
                    <ErrorMessage name='case.identifier' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-3 col-form-label fw-bold required fs-6'>Medicine</label>

            <div className='col-lg-9'>
              <label className='form-control'>{props.values['case'].medicine.name}</label>

              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  {props.values['case'].medicine && (
                    <MedicinePackages medicine={props.values['case'].medicine} />
                  )}
                </div>

                <div className='col-lg-6 fv-row'>
                  {props.values['case'].company && (
                    <CompanyDetails company={props.values['case'].company} />
                  )}
                </div>
              </div>
            </div>
          </div>
          {useCase === UseCase.Company && (
            <>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Category</label>

                <div className='col-lg-4'>
                  <Select
                    name='categoryTemp'
                    labelInValue
                    validate={validateCategory}
                    optionLabelProp='label'
                    filterOption={false}
                    onSelect={(value: any, option: any) => {
                      const key = option.key
                      const cse = props.values['case']
                      cse.category = CaseCategory[key! as keyof typeof CaseCategory]
                      props.setFieldValue('case', cse)
                    }}
                    placeholder='Select category'
                    style={{width: '100%'}}
                  >
                    {Object.keys(CaseCategory).map((element: string) => (
                      <Select.Option
                        key={element}
                        value={element}
                        label={intl.formatMessage({id: `CASE_CATEGORY_${element}`})}
                      >
                        {intl.formatMessage({id: `CASE_CATEGORY_${element}`})}
                      </Select.Option>
                    ))}
                  </Select>
                  <div className='fv-plugins-message-container invalid-feedback d-block'>
                    <ErrorMessage name='categoryTemp' />
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold required fs-6'>Seriousness</label>

                <div className='col-lg-4'>
                  <Select
                    name='seriousnessTemp'
                    labelInValue
                    dropdownStyle={{zIndex: 1200}}
                    optionLabelProp='label'
                    filterOption={false}
                    validate={validateSeriousness}
                    onSelect={(value: any, option: any) => {
                      const key = option.key
                      const cse = props.values['case']
                      cse.seriousness = CaseSeriousness[key! as keyof typeof CaseSeriousness]
                      props.setFieldValue('case', cse)
                    }}
                    placeholder='Select seriousness'
                    style={{width: '100%'}}
                  >
                    {Object.keys(CaseSeriousness).map((element: string) => (
                      <Select.Option
                        key={element}
                        value={element}
                        label={intl.formatMessage({id: `CASE_SERIOUSNESS_${element}`})}
                      >
                        {intl.formatMessage({id: `CASE_SERIOUSNESS_${element}`})}
                      </Select.Option>
                    ))}
                  </Select>
                  <div className='fv-plugins-message-container invalid-feedback d-block'>
                    <ErrorMessage name='seriousnessTemp' />
                  </div>
                </div>
              </div>
            </>
          )}

          <div className='row mb-6'>
            <label className='col-lg-3 col-form-label fw-bold required fs-6'>Reported At</label>

            <div className='col-lg-4'>
              <DatePicker
                name='reportedAtTemp'
                validate={validateReportedAt}
                showTime={true}
                placeholder='DatePicker'
                className='form-control '
                onSelect={onSelectReportedAt}
                popupStyle={{zIndex: 10024}}
              />
              <div className='fv-plugins-message-container invalid-feedback d-block'>
                <ErrorMessage name='reportedAtTemp' />
              </div>
            </div>
          </div>

          {useCase === UseCase.Company && (
            <>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold required fs-6'>
                  Internal Deadline
                </label>

                <div className='col-lg-4'>
                  <DatePicker
                    name='submitDeadlineTemp'
                    validate={validateSubmitDeadline}
                    showTime={true}
                    placeholder='DatePicker'
                    className='form-control '
                    onSelect={onSelectSubmitDeadline}
                    popupStyle={{zIndex: 10024}}
                  />
                  <div className='fv-plugins-message-container invalid-feedback d-block'>
                    <ErrorMessage name='submitDeadlineTemp' />
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'></label>

                <div className='col-lg-4'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='inlineCheckboxValidity'
                    checked={props.values['case'].validity}
                    {...props.getFieldProps('case.validity')}
                  />
                  <label
                    className='form-check-label fw-bold'
                    htmlFor='inlineCheckboxRestoredToHealth'
                  >
                    <span className='p-2'> Validity</span>
                  </label>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export {Common}
