/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {
  CaseDto,
  CaseService,
} from '../../../../../../../../../../generated/model'
import {ErrorMessage, Form, Formik, FormikValues} from 'formik'
import * as Yup from 'yup'
import {Input, Select} from 'formik-antd'
import {updateToken} from '../../../../../../../../../utils/AuthToken'
import {createErrorData, ErrorData} from '../../../../../../../../errors/model/ErrorData'
import {useAuth0} from '@auth0/auth0-react'
import {ErrorBoundary} from '../../../../../../../../errors/ErrorBoundary'
import {Spin} from "antd";
import {CaseIdentifier} from "../../../../../../_shared/components/case-identifier/CaseIdentifier";

type Props = {
  case: CaseDto

  onSaved: () => void
}

interface DuplicateCaseModel {
  cse: CaseDto
  comment: string
  duplicateCaseTemp: {
    key: string | undefined
    value: string | undefined
    label: string | undefined
  }
}

function getInitialValues(cse: CaseDto): DuplicateCaseModel {
  return {
    cse: cse,
    comment: '',
    duplicateCaseTemp: {
      value: '',
      label: '',
      key: '',
    }
  }
}
const currentSchema = Yup.object().shape({})

const DuplicateForm: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ErrorData>()
  const [fetching, setFetching] = useState<boolean>(false)
  const [options, setOptions] = useState<CaseDto[]>([])

  const {getAccessTokenSilently} = useAuth0()
  const submitData = async (values: DuplicateCaseModel, actions: FormikValues) => {
    try {
      setLoading(true)
      updateToken(await getAccessTokenSilently())

      await CaseService.duplicate(props.case.id!, values.duplicateCaseTemp.key!, {comment: values.comment})
      props.onSaved()

      setLoading(false)
    } catch (e) {
      setError(createErrorData(e))
      setLoading(false)
    }
  }

  function validateCase(value: {key: string}) {
    let error
    if (!value || !value.key || value.key === '') {
      error = 'Case Reference is required!'
    }
    return error
  }

  const onSearch = async (filter: string) => {
    setFetching(true)

    updateToken(await getAccessTokenSilently())
    const result = await CaseService.autocompleteCases(filter)
    setOptions(result)
    setFetching(false)
  }

  return (
    <>
      <ErrorBoundary error={error} />
      <Formik
        validationSchema={currentSchema}
        initialValues={getInitialValues(props.case)}
        onSubmit={submitData}
      >
        {({errors, touched, values, setFieldValue, getFieldProps}) => (
          <Form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
          >
            <div className='mb-10 fv-row'>
              <label className='form-label mb-3 required'>Reference: Duplicate case ID</label>

              <Select
                  validate={validateCase}
                  name='duplicateCaseTemp'
                  labelInValue
                  optionLabelProp='label'
                  filterOption={false}
                  onSearch={onSearch}
                  placeholder='Select case'
                  style={{width: '100%'}}
                  dropdownStyle={{zIndex: 1200}}
                  showSearch
                  notFoundContent={fetching ? <Spin size='small' /> : null}
              >
                {options.map((element: CaseDto) => (
                    <Select.Option key={element.id!} value={element.id!} label={<CaseIdentifier data={element}/>}>
                      <CaseIdentifier data={element}/>
                    </Select.Option>
                ))}
              </Select>

              <div className='fv-plugins-message-container invalid-feedback d-block'>
                <ErrorMessage name='duplicateCaseTemp' />
              </div>
            </div>
            <div className='mb-10 fv-row'>
              <label className='form-label mb-3'>Comment</label>

              <Input.TextArea rows={5} name='comment' placeholder='' />
              <div className='fv-plugins-message-container invalid-feedback d-block'>
                <ErrorMessage name='comment' />
              </div>
            </div>

            <div className='d-flex flex-stack pt-15'>
              <div className='mr-2' />
              <div>
                <button type='submit' className='btn btn-lg btn-primary me-3' disabled={loading}>
                  <span className='indicator-label'>Save</span>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export {DuplicateForm}
