/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {CaseEventDto, } from '../../../../../../../generated/model'
import {HistoryChangeList} from "./HistoryChangeList";

interface Props {
  ev: CaseEventDto
}

const HistoryChangeData: React.FC<Props> = (props) => {
  const data = props.ev!.data!.changeData!
  return (
    <div>
      <h6>Changes</h6>
        <HistoryChangeList changes={data.changes!}/>
    </div>
  )
}

export {HistoryChangeData}
