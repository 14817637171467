/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'

import {CaseEventDto, CaseEventService} from '../../../../../../../generated/model'
import {HistoryData} from './HistoryData'
import {ErrorBoundary} from "../../../../../errors/ErrorBoundary";
import {LoadingBoundary} from "../../../../../_shared/LoadingBoundary";
import {createErrorData, ErrorData} from "../../../../../errors/model/ErrorData";
import {useAuth0} from "@auth0/auth0-react";
import {useParams} from "react-router-dom";
import {updateToken} from "../../../../../../utils/AuthToken";

interface Props {
}

const HistoryWrapper: React.FC<Props> = (props) => {
  const [events, setEvents] = useState<CaseEventDto[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<ErrorData>()

  const {getAccessTokenSilently} = useAuth0()
  const {id} = useParams<{id: string}>()

  const load = async () => {
    try {
      setLoading(true)
      updateToken(await getAccessTokenSilently())

      const data = await CaseEventService.getEvents(id!)
      setEvents(data)
      setLoading(false)
    } catch (e) {
      setError(createErrorData(e))
      setEvents(undefined)
      setLoading(false)
    }
  }

  useEffect(() => {
        load()
      }, // eslint-disable-next-line
      [getAccessTokenSilently, id])
  return (

  <LoadingBoundary loading={loading}>
    <ErrorBoundary error={error}>
      {events &&
          <HistoryData events={events} reload={load}/>
      }
    </ErrorBoundary>
  </LoadingBoundary>)
}

export {HistoryWrapper}
