/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {ErrorMessage, FormikErrors, FormikValues, FormikTouched} from 'formik'
import {Select} from 'formik-antd'
import {useAuth0} from '@auth0/auth0-react'
import {updateToken} from '../../../../../../../utils/AuthToken'
import {
  MeddraHighLevelGroupTermDto,
  MeddraHighLevelGroupTermService,
  MeddraHighLevelTermDto,
  MeddraHighLevelTermService,
  MeddraLowLevelTermDto,
  MeddraLowLevelTermService,
  MeddraPreferedTermDto,
  MeddraPreferedTermService,
  MeddraSystemOrganClassDto,
  MeddraSystemOrganClassService,
} from '../../../../../../../../generated/model'
import {LoadingBoundary} from '../../../../../../_shared/LoadingBoundary'
import {useIntl} from 'react-intl'
import {convertDedupEventsInfoInSdEdit} from '../../../../management/new/helpers/Utils'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {caseForDedupEventsState, caseSideEffectInEditState} from '../../../../State'

interface Props {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  values: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const SideEffectSelector: FC<Props> = (props) => {
  const [loadingSoc, setLoadingSoc] = useState<boolean>(true)

  // eslint-disable-next-line
  const [loadingHglt, setLoadingHglt] = useState<boolean>(true)
  // eslint-disable-next-line
  const [loadingHlt, setLoadingHlt] = useState<boolean>(true)
  // eslint-disable-next-line
  const [loadingPtt, setLoadingPt] = useState<boolean>(true)
  // eslint-disable-next-line
  const [loadingLlt, setLoadingLlt] = useState<boolean>(true)

  const [optionsSoc, setOptionsSoc] = useState<MeddraSystemOrganClassDto[]>([])
  const [optionsHglt, setOptionsHglt] = useState<MeddraHighLevelGroupTermDto[]>([])
  const [optionsHlt, setOptionsHlt] = useState<MeddraHighLevelTermDto[]>([])
  const [optionsPt, setOptionsPt] = useState<MeddraPreferedTermDto[]>([])
  const [optionsLlt, setOptionsLlt] = useState<MeddraLowLevelTermDto[]>([])

  const editState = useRecoilValue(caseSideEffectInEditState)
  const setCaseForDedupEventsState = useSetRecoilState(caseForDedupEventsState)

  const {getAccessTokenSilently} = useAuth0()
  const intl = useIntl()

  const onSelectSoc = async (value: any, option: any) => {
    const key = option.key
    const selected = optionsSoc.filter((x) => x.id === key)[0]

    const model = props.values['sideEffect']
    model.systemOrganClass = selected
    model.highLevelGroupTerm = undefined
    model.highLevelTerm = undefined
    model.preferedTerm = undefined
    model.lowLevelTerm = undefined
    props.setFieldValue('sideEffect', model)
    props.setFieldValue('meddraHlgtTemp', undefined)
    props.setFieldValue('meddraHltTemp', undefined)
    props.setFieldValue('meddraPtTemp', undefined)
    props.setFieldValue('meddraLltTemp', undefined)
    await getHlgt(selected!.id!)
  }
  const getHlgt = async (id: string) => {
    setLoadingHglt(true)
    updateToken(await getAccessTokenSilently())
    const data = await MeddraHighLevelGroupTermService.getMeddraHighLevelGroupTerms(id, intl.locale)
    setOptionsHglt(data)
    setLoadingHglt(false)
  }
  const onSelectHlgt = async (value: any, option: any) => {
    const key = option.key
    const selected = optionsHglt.filter((x) => x.id === key)[0]

    const model = props.values['sideEffect']
    model.highLevelGroupTerm = selected
    model.highLevelTerm = undefined
    model.preferedTerm = undefined
    model.lowLevelTerm = undefined
    props.setFieldValue('sideEffect', model)
    props.setFieldValue('meddraHltTemp', undefined)
    props.setFieldValue('meddraPtTemp', undefined)
    props.setFieldValue('meddraLltTemp', undefined)

    await getHlt(selected!.id!)
  }
  const getHlt = async (id: string) => {
    setLoadingHlt(true)
    updateToken(await getAccessTokenSilently())
    const data = await MeddraHighLevelTermService.getMeddraHighLevelTerms(id, intl.locale)
    setOptionsHlt(data)
    setLoadingHlt(false)
  }
  const onSelectHlt = async (value: any, option: any) => {
    const key = option.key
    const selected = optionsHlt.filter((x) => x.id === key)[0]

    const model = props.values['sideEffect']
    model.highLevelTerm = selected
    model.preferedTerm = undefined
    model.lowLevelTerm = undefined
    props.setFieldValue('sideEffect', model)
    props.setFieldValue('meddraPtTemp', undefined)
    props.setFieldValue('meddraLltTemp', undefined)

    await getPt(selected!.id!)
  }
  const getPt = async (id: string) => {
    setLoadingPt(true)
    updateToken(await getAccessTokenSilently())
    const data = await MeddraPreferedTermService.getMeddraPreferedTerm(id, intl.locale)
    setOptionsPt(data)
    setLoadingPt(false)
  }
  const onSelectPt = async (value: any, option: any) => {
    const key = option.key
    const selected = optionsPt.filter((x) => x.id === key)[0]

    const model = props.values['sideEffect']
    model.preferedTerm = selected
    model.lowLevelTerm = undefined
    props.setFieldValue('sideEffect', model)
    props.setFieldValue('meddraLltTemp', undefined)

    await getLlt(selected!.id!)
  }
  const getLlt = async (id: string) => {
    setLoadingLlt(true)
    updateToken(await getAccessTokenSilently())
    const data = await MeddraLowLevelTermService.getLowLevelTerm(id, intl.locale)
    setOptionsLlt(data)
    setLoadingLlt(false)
  }
  const onSelectLlt = async (value: any, option: any) => {
    const key = option.key
    const selected = optionsLlt.filter((x) => x.id === key)[0]

    const model = props.values['sideEffect']
    model.lowLevelTerm = selected
    props.setFieldValue('sideEffect', model)

    let dedupVals = convertDedupEventsInfoInSdEdit(
      editState!.case!,
      editState!.sideEffectsNotInEdit,
      model
    )
    setCaseForDedupEventsState(dedupVals)
  }

  function validateSideEffect(value: {key: string}) {
    let error
    if (!value || !value.key || value.key === '') {
      error = 'SideEffect is required!'
    }
    return error
  }
  const onSelectSub = (nameField: string, id: string, name: string) => {
    props.setFieldValue(nameField, {key: id, value: id, label: name})
  }

  useEffect(() => {
    const getSocs = async () => {
      updateToken(await getAccessTokenSilently())

      const data = await MeddraSystemOrganClassService.getMeddraSystemOrganClasses(intl.locale)
      setOptionsSoc(data)

      if (props.values.sideEffect.systemOrganClass)
        await getHlgt(props.values.sideEffect.systemOrganClass.id)
      if (props.values.sideEffect.highLevelGroupTerm)
        await getHlt(props.values.sideEffect.highLevelGroupTerm.id)
      if (props.values.sideEffect.highLevelTerm)
        await getPt(props.values.sideEffect.highLevelTerm.id)
      if (props.values.sideEffect.preferedTerm)
        await getLlt(props.values.sideEffect.preferedTerm.id)

      if (props.values.sideEffect?.lowLevelTerm) {
        onSelectSub(
          'meddraLltTemp',
          props.values.sideEffect.lowLevelTerm.id,
          props.values.sideEffect.lowLevelTerm.name
        )
      }
      if (props.values.sideEffect?.preferedTerm) {
        onSelectSub(
          'meddraPtTemp',
          props.values.sideEffect.preferedTerm.id,
          props.values.sideEffect.preferedTerm.name
        )
      }
      if (props.values.highLevelTerm?.preferedTerm) {
        onSelectSub(
          'meddraHltTemp',
          props.values.sideEffect.highLevelTerm.id,
          props.values.sideEffect.highLevelTerm.name
        )
      }
      if (props.values.highLevelGroupTerm?.preferedTerm) {
        onSelectSub(
          'meddraHlgtTemp',
          props.values.sideEffect.highLevelGroupTerm.id,
          props.values.sideEffect.highLevelGroupTerm.name
        )
      }

      if (props.values.systemOrganClass?.preferedTerm) {
        onSelectSub(
          'meddraSocTemp',
          props.values.sideEffect.systemOrganClass.id,
          props.values.sideEffect.systemOrganClass.name
        )
      }

      setLoadingSoc(false)
    }
    getSocs()
    // eslint-disable-next-line
  }, [getAccessTokenSilently])

  return (
    <div className='w-100' style={{minHeight: 530}}>
      <LoadingBoundary loading={loadingSoc}>
        <div className='mb-10 fv-row'>
          <label className='form-label mb-3 required'>System Organ Class (SOC)</label>
          <Select
            name='meddraSocTemp'
            validate={validateSideEffect}
            labelInValue
            optionLabelProp='label'
            onSelect={onSelectSoc}
            filterOption={() => true}
            placeholder='Select system organ class'
            style={{width: '100%'}}
            dropdownStyle={{zIndex: 1200}}
            dropdownMatchSelectWidth={false}
          >
            {optionsSoc.map((element: MeddraSystemOrganClassDto) => (
              <Select.Option key={element.id!} value={element.id!} label={element!.name}>
                {element.name} [{element.code}]
              </Select.Option>
            ))}
          </Select>

          <div className='fv-plugins-message-container invalid-feedback d-block'>
            <ErrorMessage name='sideEffectTemp' />
          </div>
        </div>
        <div className='mb-10 fv-row'>
          <label className='form-label mb-3 required'>High Level Group Term (HLGT)</label>
          <Select
            name='meddraHlgtTemp'
            validate={validateSideEffect}
            labelInValue
            optionLabelProp='label'
            onSelect={onSelectHlgt}
            filterOption={() => true}
            placeholder='Select high level group term'
            style={{width: '100%'}}
            dropdownStyle={{zIndex: 1200}}
            dropdownMatchSelectWidth={false}
            disabled={!props.values.sideEffect.systemOrganClass}
          >
            {optionsHglt.map((element: MeddraHighLevelGroupTermDto) => (
              <Select.Option key={element.id!} value={element.id!} label={element!.name}>
                {element.name} [{element.code}]
              </Select.Option>
            ))}
          </Select>

          <div className='fv-plugins-message-container invalid-feedback d-block'>
            <ErrorMessage name='sideEffectTemp' />
          </div>
        </div>
        <div className='mb-10 fv-row'>
          <label className='form-label mb-3 required'>High Level Term (HLT)</label>
          <Select
            name='meddraHltTemp'
            validate={validateSideEffect}
            labelInValue
            optionLabelProp='label'
            onSelect={onSelectHlt}
            filterOption={() => true}
            placeholder='Select high level term'
            style={{width: '100%'}}
            dropdownStyle={{zIndex: 1200}}
            dropdownMatchSelectWidth={false}
            disabled={!props.values.sideEffect.highLevelGroupTerm}
          >
            {optionsHlt.map((element: MeddraHighLevelTermDto) => (
              <Select.Option key={element.id!} value={element.id!} label={element!.name}>
                {element.name} [{element.code}]
              </Select.Option>
            ))}
          </Select>

          <div className='fv-plugins-message-container invalid-feedback d-block'>
            <ErrorMessage name='sideEffectTemp' />
          </div>
        </div>
        <div className='mb-10 fv-row'>
          <label className='form-label mb-3 required'>Prefered Term (PT)</label>
          <Select
            name='meddraPtTemp'
            validate={validateSideEffect}
            labelInValue
            optionLabelProp='label'
            onSelect={onSelectPt}
            filterOption={() => true}
            placeholder='Select prefered term'
            style={{width: '100%'}}
            dropdownStyle={{zIndex: 1200}}
            dropdownMatchSelectWidth={false}
            disabled={!props.values.sideEffect.highLevelTerm}
          >
            {optionsPt.map((element: MeddraPreferedTermDto) => (
              <Select.Option key={element.id!} value={element.id!} label={element!.name}>
                {element.name} [{element.code}]
              </Select.Option>
            ))}
          </Select>

          <div className='fv-plugins-message-container invalid-feedback d-block'>
            <ErrorMessage name='sideEffectTemp' />
          </div>
        </div>
        <div className='mb-10 fv-row'>
          <label className='form-label mb-3 required'>Low Level Term (LLT)</label>
          <Select
            name='meddraLltTemp'
            validate={validateSideEffect}
            labelInValue
            optionLabelProp='label'
            onSelect={onSelectLlt}
            filterOption={() => true}
            placeholder='Select low level term'
            style={{width: '100%'}}
            dropdownStyle={{zIndex: 1200}}
            dropdownMatchSelectWidth={false}
            disabled={!props.values.sideEffect.preferedTerm}
          >
            {optionsLlt.map((element: MeddraLowLevelTermDto) => (
              <Select.Option key={element.id!} value={element.id!} label={element!.name}>
                {element.name} [{element.code}]
              </Select.Option>
            ))}
          </Select>

          <div className='fv-plugins-message-container invalid-feedback d-block'>
            <ErrorMessage name='sideEffectTemp' />
          </div>
        </div>
      </LoadingBoundary>
    </div>
  )
}

export {SideEffectSelector}
