/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class StorageService {

    /**
     * @returns string Success
     * @throws ApiError
     */
    public static async getBlobSasWrite(): Promise<string> {
        const result = await __request({
            method: 'GET',
            path: `/api/infrastructure/v1/storage/sas`,
        });
        return result.body;
    }

    /**
     * @param id 
     * @returns string Success
     * @throws ApiError
     */
    public static async getBlobSasRead(
id: string,
): Promise<string> {
        const result = await __request({
            method: 'GET',
            path: `/api/infrastructure/v1/storage/sas/${id}`,
        });
        return result.body;
    }

}