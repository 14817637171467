/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'

import {KTSVG} from '../../../../../../../_metronic/helpers'
import {CaseDto} from '../../../../../../../generated/model'
import {CaseIdentifier} from '../../../_shared/components/case-identifier/CaseIdentifier'

interface Props {
  caseData: CaseDto
}

const DeleteCaseNotice: FC<Props> = (props) => {
  return (
    <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6'>
      <KTSVG
        path='/media/icons/duotone/Code/Warning-1-circle.svg'
        className='svg-icon-2tx svg-icon-warning me-4'
      />

      <div className='d-flex flex-stack flex-grow-1'>
        <div className='fw-bold'>
          <h4 className='text-gray-800 fw-bolder'>
            <span>You Are Deleting Your Case </span>
            '<CaseIdentifier data={props.caseData} />'
          </h4>
          <div className='fs-6 text-gray-600'>
            Please validate that you really want to delete this case.
          </div>
        </div>
      </div>
    </div>
  )
}

export {DeleteCaseNotice}
