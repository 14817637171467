import {CaseEventData, CaseEventDto} from "../../../../../../../generated/model";
import moment from "moment";

const separator = "\u2028";
export function createCsvHeaders(): any[] {
  return [
    { label: "created at", key: "ca" },
    { label: "type", key: "type" },
    { label: "submit version", key: "sv" },
    { label: "user", key: "user" },
    { label: "data", key: "data" }
  ];
}
interface Data {
  ca: string,
  type: string,
  sv: string,
  user: string,
  data: string
}
export function createCsv(data: CaseEventDto[]): Data[] {
  const result: Data[] = [];

  // headers
  for(const ev of data){
    const line:Data = {
      ca: moment(ev.createdAt).format('DD.MM.yyyy HH:mm'),
        type: ev.eventType!.toString(),
        sv:ev.data!.submitVersion!.toString(),
        user: ev.user!,
        data: createData(ev.data!)
    }
    result.push(line);
  }

  return result;
}

function createData(data: CaseEventData) {
  let result = "";

  if( data.transitionData ){
    result += createDataTransition(data);
  }
  if( data.changeData ){
    result += createDataChange(data);
  }
  if( data.exportData ){
    result += createDataExport(data);
  }
  if( data.attachmentData ){
    result += createDataAttachment(data);
  }
  if( data.commentData ){
    result += createDataComment(data);
  }
  if( data.shareData ){
    result += createDataShare(data);
  }

  return result;
}

function createDataTransition(data: CaseEventData) {
  let result = "Transition" + separator
  if(data.transitionData!.from)
    result += "From: " + data.transitionData!.from + separator;
  result += "To: " + data.transitionData!.to;
  return result;
}
function createDataChange(data: CaseEventData) {
  let result = "Change " + data.changeData?.entity! + separator;
  for(const change of data.changeData?.changes!){
    result += change.eventType + " - " + change.fieldName + " - Old: " + change.oldValue + " New: " + change.newValue

  }
  return result;
}
function createDataExport(data: CaseEventData) {
  let result = "Export";
  return result;
}
function createDataAttachment(data: CaseEventData) {
  let result = `Attachment: ${data.attachmentData?.action}`;
  result+= separator
  result+=data.attachmentData?.originalName;
  result+= separator
  result+=data.attachmentData?.description;
  return result;
}
function createDataComment(data: CaseEventData) {
  let result = `Comment: ${data.commentData?.action}`;
  result+= separator
  result+=data.commentData?.comment;
  return result;
}
function createDataShare(data: CaseEventData) {
  let result = `Share: ${data.shareData?.shareStatus}`;
  return result;
}
