/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {CaseInputDto, CaseInputService} from '../../../../generated/model'
import {updateToken} from '../../../utils/AuthToken'
import {InputsList} from './components/InputsList'

const InputsListWrapper: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<Array<CaseInputDto>>([])

  const {getAccessTokenSilently} = useAuth0()

  useEffect(() => {
    const getCases = async () => {
      try {
        updateToken(await getAccessTokenSilently())

        const cases = await CaseInputService.getAllInputs()
        setData(cases)
        setLoading(false)
      } catch (e) {
        console.log(e.message)
      }
    }

    getCases()
  }, [getAccessTokenSilently])

  return <InputsList data={data} loading={loading} />
}

export {InputsListWrapper}
