/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PatientDto } from '../models/PatientDto';
import { request as __request } from '../core/request';

export class CasePatientService {

    /**
     * @param filter 
     * @returns PatientDto Success
     * @throws ApiError
     */
    public static async searchCasePatients(
filter?: string,
): Promise<Array<PatientDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/pv/v1/patient/search`,
            query: {
                'filter': filter,
            },
        });
        return result.body;
    }

}