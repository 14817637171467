import React, {useEffect, useState} from 'react'
import {
  CaseDedupEventsInfoDto,
  CaseDeduplicationService,
  CaseDto,
  CaseService,
  CaseStatus,
} from '../../../../../../../../generated/model'
import {updateToken} from '../../../../../../../utils/AuthToken'
import {useAuth0} from '@auth0/auth0-react'
import {LoadingBoundary} from '../../../../../../_shared/LoadingBoundary'
import {CaseDedupCaseInfoData} from './CaseDedupCaseInfoData'
import {DedupStandaloneListModal} from '../../../../deduplication/standalone/list/DedupStandaloneListModal'
import {createErrorData, ErrorData} from '../../../../../../errors/model/ErrorData'
import {useHistory} from 'react-router-dom'
import {ErrorBoundary} from '../../../../../../errors/ErrorBoundary'

type Props = {
  caseId: string
}

const CaseDedupCaseInfo: React.FC<Props> = (props) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<CaseDedupEventsInfoDto>()
  const [error, setError] = useState<ErrorData>()

  const {getAccessTokenSilently} = useAuth0()
  useEffect(() => {
    const getCaseById = async () => {
      updateToken(await getAccessTokenSilently())

      const data = await CaseDeduplicationService.caseDedupInfo(props.caseId)
      setData(data)

      setLoading(false)
    }
    getCaseById()
  }, [getAccessTokenSilently, props.caseId])

  const history = useHistory()
  const foundDuplicate = async (duplicate: CaseDto) => {
    try {
      setLoading(true)
      updateToken(await getAccessTokenSilently())

      await CaseService.duplicate(props.caseId, duplicate.id, {comment: ''})
      if (
        duplicate.status === CaseStatus.CREATED_COMPANY ||
        duplicate.status === CaseStatus.ACCEPTED ||
        duplicate.status === CaseStatus.IN_REVIEW ||
        duplicate.status === CaseStatus.EXPORTED
      ) {
        history.push(`/cases/edit/${duplicate.id}`)
      } else {
        history.push(`/cases/details/${duplicate.id}`)
      }

      setLoading(false)
    } catch (e) {
      setError(createErrorData(e))
      setLoading(false)
    }

    setShowModal(false)
  }

  return (
    <>
      <ErrorBoundary error={error}></ErrorBoundary>
      <LoadingBoundary loading={loading}>
        {data && (
          <>
            <DedupStandaloneListModal
              show={showModal}
              foundDuplicate={foundDuplicate}
              handleClose={() => setShowModal(false)}
              id={props.caseId}
            />
            <div className='d-flex flex-column pb-5'>
              <div className='row'>
                <div className='col-auto pt-4'>
                  <div className='text-dark me-2 fw-bolder pb-1 px-4'>
                    Deduplication Information
                  </div>
                </div>
                <div className='col' />
                <div className='col-auto p-2'>
                  <a
                    href='#'
                    className={`btn btn-sm btn-light-primary`}
                    onClick={(e) => {
                      e.preventDefault()
                      setShowModal(true)
                    }}
                  >
                    Details
                  </a>
                </div>
              </div>
              <div className='border border-gray-300 border-dashed rounded py-4 px-4 mx-3 mb-3'>
                <div className='d-flex flex-start'>
                  <CaseDedupCaseInfoData data={data} />
                </div>
              </div>
            </div>
          </>
        )}
      </LoadingBoundary>
    </>
  )
}

export {CaseDedupCaseInfo}
