/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import clsx from 'clsx'

export interface HeaderParts {
    title: string
    isActive: boolean
    isSeparator?: boolean
}

export interface HeaderProgress {
    tooltip: string
    progress: number
    color: string
}

interface Props {
    title: string
    parts: HeaderParts[]
    progress?: HeaderProgress

    backTitle: string
    onBackClick: () => void
}

const Header: React.FC<Props> = (props) => {
    const backClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        props.onBackClick()
    }
    return (
        <div className={`card mb-5 mb-xl-8}`}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>
            <div
                id='kt_page_title'
                data-kt-swapper='true'
                data-kt-swapper-mode='prepend'
                data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                className={clsx('page-title d-flex')}
            >
              <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
                {props.title}
              </h1>
              <span className='h-20px border-gray-200 border-start mx-4'/>
              <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
                {props.parts.map((item, index) => (
                    <li
                        className={clsx('breadcrumb-item', {
                            'text-dark': !item.isSeparator && item.isActive,
                            'text-muted': !item.isSeparator && !item.isActive,
                        })}
                        key={`${index}`}
                    >
                        {!item.isSeparator ? (
                            item.title
                        ) : (
                            <span className='bullet bg-gray-200 w-5px h-2px'/>
                        )}
                    </li>
                ))}
                  <li className='breadcrumb-item text-dark'>{props.title}</li>
              </ul>
            </div>
          </span>
                </h3>

                <div className='card-toolbar'>
                    <a
                        href='#'
                        onClick={backClick}
                        className='btn btn-primary btn-sm'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_create_project'
                    >
                        {props.backTitle}
                    </a>
                </div>
            </div>
            <div className='card-body py-3'>
                <div className='table-responsive'>
                    {props.progress && (
                        <div
                            className='h-4px w-300 bg-light mb-5'
                            data-bs-toggle='tooltip'
                            title={props.progress.tooltip}
                        >
                            <div
                                className={`bg-${props.progress.color} rounded h-4px`}
                                role='progressbar'
                                style={{width: `${props.progress.progress}%`}}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
export {Header}
