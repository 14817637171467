/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {FormikErrors, FormikValues, FormikTouched} from 'formik'
import {FieldInputProps} from 'formik/dist/types'
import {Input} from 'formik-antd'

interface Step3Props {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  values: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>
}

const StepOutcomeProperties: FC<Step3Props> = (props) => {
  return (
    <div className='w-100' style={{minHeight: 530}}>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Properties</label>

        <div className='form-check form-check-custom form-check-solid me-5'>
          <input
            className='form-check-input'
            type='checkbox'
            id='inlineCheckboxRestoredToHealth'
            checked={props.values['sideEffect'].outcome.restoredToHealth}
            {...props.getFieldProps('sideEffect.outcome.restoredToHealth')}
          />
          <label className='form-check-label fw-bold' htmlFor='inlineCheckboxRestoredToHealth'>
            Restored To Health
          </label>

          <span className='px-lg-10' />

          <input
            className='form-check-input'
            type='checkbox'
            id='inlineCheckboxOutPermDamage'
            checked={props.values['sideEffect'].outcome.permDamage}
            {...props.getFieldProps('sideEffect.outcome.permDamage')}
          />
          <label className='form-check-label fw-bold' htmlFor='inlineCheckboxOutPermDamage'>
            Permanent damage / Consequential damage
          </label>
        </div>

        <div className='form-check form-check-custom form-check-solid me-5 py-lg-5'>
          <input
            className='form-check-input'
            type='checkbox'
            id='inlineCheckboxOutNotRestoredToHealth'
            checked={props.values['sideEffect'].outcome.notRestoredToHealth}
            {...props.getFieldProps('sideEffect.outcome.notRestoredToHealth')}
          />
          <label
            className='form-check-label fw-bold'
            htmlFor='inlineCheckboxOutNotRestoredToHealth'
          >
            Not Restored To Health
          </label>
          <span className='px-lg-3' />
          <input
            className='form-check-input'
            type='checkbox'
            id='inlineCheckboxOutUnknown'
            checked={props.values['sideEffect'].outcome.unknown}
            {...props.getFieldProps('sideEffect.outcome.unknown')}
          />
          <label className='form-check-label fw-bold' htmlFor='inlineCheckboxOutUnknown'>
            Unknown
          </label>
        </div>

        <div className='form-check form-check-custom form-check-solid me-5 py-lg-5'>
          <input
            className='form-check-input'
            type='checkbox'
            id='inlineCheckboxResultsInDeath'
            checked={props.values['sideEffect'].outcome.resultsInDeath}
            {...props.getFieldProps('sideEffect.outcome.resultsInDeath')}
          />
          <label className='form-check-label fw-bold' htmlFor='inlineCheckboxResultsInDeath'>
            Results In Death
          </label>
        </div>

        {props.values['sideEffect'].outcome.resultsInDeath === true && (
          <div className={`card mb-5 mb-xl-8}`}>
            <div className='card-body py-3'>
              <div className='mb-10 fv-row'>
                <label className='form-label mb-3'>Cause of death</label>

                <Input
                  type='text'
                  name='sideEffect.outcome.resultsInDeathCauseOfDeath'
                  placeholder=''
                />
              </div>
              <div className='form-check form-check-custom form-check-solid me-5'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='inlineCheckboxResultsInDeathMedContrib'
                  checked={props.values['sideEffect'].outcome.resultsInDeathMedContrib}
                  {...props.getFieldProps('sideEffect.outcome.resultsInDeathMedContrib')}
                />
                <label
                  className='form-check-label fw-bold'
                  htmlFor='inlineCheckboxResultsInDeathMedContrib'
                >
                  Medicine contribution possible
                </label>

                <span className=' px-lg-3' />

                <input
                  className='form-check-input'
                  type='checkbox'
                  id='inlineCheckboxResultsInDeathNotFromMed'
                  checked={props.values['sideEffect'].outcome.resultsInDeathNotFromMed}
                  {...props.getFieldProps('sideEffect.outcome.resultsInDeathNotFromMed')}
                />
                <label
                  className='form-check-label fw-bold'
                  htmlFor='inlineCheckboxResultsInDeathNotFromMed'
                >
                  Not from medicine
                </label>

                <span className='px-lg-3' />

                <input
                  className='form-check-input'
                  type='checkbox'
                  id='inlineCheckboxResultsInDeathDueToEffect'
                  checked={props.values['sideEffect'].outcome.resultsInDeathDueToEffect}
                  {...props.getFieldProps('sideEffect.outcome.resultsInDeathDueToEffect')}
                />
                <label
                  className='form-check-label fw-bold'
                  htmlFor='inlineCheckboxResultsInDeathDueToEffect'
                >
                  Due to Effect
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export {StepOutcomeProperties}
