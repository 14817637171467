/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CaseDto} from '../../../../../../../generated/model'
import {CopyToClipboard} from 'react-copy-to-clipboard'

interface Props {
  case: CaseDto
}

const HeaderShared: React.FC<Props> = (props) => {
  const link = `https://${window.location.hostname}/register/share?shareId=${props.case.tenantId}&companyName=${props.case.company?.name}&caseIdentifier=${props.case.serial?.globalId}&caseId=${props.case.id}`
  return (
    <>
      <div className='d-flex flex-column'>
        <div className='text-dark me-2 fw-bolder pb-1 px-4'>Case Shared</div>
        <div className='border border-gray-300 border-dashed rounded py-4 px-4 mx-3 mb-3'>
          <div className='d-flex flex-start'>
            <span className='pt-2'>Access Link: </span>
            <span className='pt-2 mx-2'>{link}</span>
            <CopyToClipboard text={link}>
              <button className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'>
                Copy to clipboard
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </>
  )
}

export {HeaderShared}
